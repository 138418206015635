import { TEMPLATE_LIST,TEMPLATE_LIST_ERROR } from "../types";

let initialState = {
    templateData:[],
    error: false
}

export default function getTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case TEMPLATE_LIST:
            return {
                ...state,
                templateData: action.response
            }
        case TEMPLATE_LIST_ERROR:
            return {
                ...state,
                error: true
            }

        default:
            return state
    }
}