import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationLogList, myChapterList } from '../../Store/notifications/notificationLogaction';
import { withRouter } from 'react-router-dom';
import Timeago from 'react-timeago'
import HttpService from '../../Services/HttpService'
import swal from 'sweetalert';
import { AllusersphotosAjax } from '../../Store/photogallery/photogalleryaction';
import { getresourcesAjax } from '../../Store/resources/resourcesListaction';
import { getDiscountsAjax } from '../../Store/discounts/discountsListaction';
import { getActivityBasedOnChapter,getActivityAjax } from '../../Store/activities/activityListaction';
import { DashboardData } from '../../Store/dashboard/dashboardaction';
import { getActiveUsersAjax,getAgencyUsersAjax,getBigsUsersAjax,
getOrganiseUsersAjax,getInActiveUsersAjax } from '../../Store/UserManagement/userManagementAction';
import {getRangeEventListAjax } from '../../Store/events/eventsListAction';
import { RecievedMessages} from '../../Store/messages/allMessageAction';
import { getAllNotificationsAjax} from '../../Store/notifications/allNotificationsaction';
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import { userdetailsData } from '../../Store/userprofile/userdetailsaction';
import { getLittlesAjax } from '../../Store/mylittle/littlesListaction';
import Select from 'react-select'
import { defaultTheme } from 'react-select';


const currentuser = JSON.parse(localStorage.getItem('userdata'));

const options = [
  {value: '1', label: 'Item 1',test:'r'},
  {value: '2', label: 'Item 2',test:'r'},
  {value: '3', label: 'Item 3',test:'r'},
  {value: '4', label: 'Item 4',test:'r'},
  {value: '5', label: 'Item 5',test:'r'},
  {value: '6', label: 'Item 6',test:'r'},
  {value: '7', label: 'Item 7',test:'r'},
]
const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

// styled components

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'b',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  // <i class="fa fa-chevron-down" aria-hidden="true"></i>
  <Svg style={{ marginRight: -15 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
  )

class Header extends Component {
  state = { isOpen: false, value: undefined };
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };
  constructor() {
    super();
    this.state = {
      displayProfile: "",
      first_name: "",
      last_name: "",
      displayEmail: "",
      notifications: [],
      activeScreen: '',
      user_type_id: '',
      myChaptersListData: [],
      chapter_name: '',
      Spinner: false,
      notificationData: {},
      user_id: '',
      my_profile_data: {},
      selected_chapter:''
    }
  }
  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

/*To get the data for selected chapter*/
  onSelectChange = value => {
    this.toggleOpen();
    this.setState({ selected_chapter:value.chapter_name  });

   
      localStorage.setItem('chapter_id',value.chapter_id)
     this.state.chapter_name =value.chapter_name

     this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    
     var chapter_id = this.state.chapter_id 

     var data ={
       page : 1,
       limit:8,
       chapter_id:  this.state.chapter_id
     }
     this.setState({ chapter_id: value.chapter_id})
     var activescreen = JSON.parse(localStorage.getItem('activescreen'))
     switch (activescreen) {
       case 'resourcelist':
         return this.props.getresourcesAjax({ chapter_id });
       case 'discountlist':
         return this.props.getDiscountsAjax(data);
       case 'usermanagmentlist':
              if(JSON.parse(localStorage.getItem('activetab'))=='ActiveUsers'){
               return this.props.getActiveUsersAjax(data)
              }
              else if(JSON.parse(localStorage.getItem('activetab'))=='AgencyUsers'){
               this.props.getAgencyUsersAjax(data);
              }
              else if(JSON.parse(localStorage.getItem('activetab'))=='BigSUsers'){
               this.props.getBigsUsersAjax(data);
              }
              else if(JSON.parse(localStorage.getItem('activetab'))=='OrganizationUser'){
               this.props.getOrganiseUsersAjax(data);
              }
              else if(JSON.parse(localStorage.getItem('activetab'))=='InactiveUsers'){
               this.props.getInActiveUsersAjax(data);
              }
             break
       case 'AgecySponsoredlist':
         var data = {
           organized_by: "agency",
           month: "current",
           chapter_id: this.state.chapter_id
         }
         return this.props.getRangeEventListAjax(data)
       case 'bigscommunitylist':
         var data = {
           organized_by: "bigs",
           month: "current",
           chapter_id: this.state.chapter_id
         }
         return this.props.getRangeEventListAjax(data)

       case 'messagelist':
         return this.props.RecievedMessages()

       case 'notificationlist':
         return this.props.getAllNotificationsAjax({ chapter_id })

       case 'alluserphotogallery':
         return this.props.AllusersphotosAjax({ chapter_id });

       case 'activies':
         return this.props.getActivityAjax(data);
       case 'dashboard':
         return this.props.DashboardData({ chapter_id });
         case 'alllittles':
           return this.props.getLittlesAjax(data)

       default:
         return null
     
   }
  };
/* To logout */
  logout = () => {
    const { cookies } = this.props;
    swal({
      title: "Logout",
      text: "Are you sure you want to logout?",
      buttons: ["No, Cancel", "Yes, Logout"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({
            Spinner: true
          })
          HttpService.logout()
            .then(response => {
              if (response.data.status === 200) {
                cookies.remove("cookiedata")
                this.setState({
                  Spinner: false
                })
                localStorage.clear();
                window.location.href = '/';
              }
              else {
                this.setState({
                  Spinner: false
                })
                swal({ icon: 'error', text: "Something went wrong, Please try again after sometime." });
              }
            })
            .catch(error => {
              this.setState({
                Spinner: false
              })
              swal({
                icon: 'error',
                text: "Something went wrong, Please try again after sometime."

              });
            })
        }
      });
  }
 /* To make the api call initial page load */
  componentDidMount() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user.user_id;
    var display_name = this.currentuser.user.display_name;
    var email = this.currentuser.user.email;
    var userPic = this.currentuser.user.profile_pic;
    this.props.userdetailsData({ user_id, hit_from: 'header' })
    this.setState({
      displayProfile: userPic, displayEmail: email, displayname: display_name,
      first_name: this.currentuser.user.first_name,
      last_name: this.currentuser.user.last_name,
      user_type_id: this.currentuser.user.usertypeid,
      user_id: this.currentuser.user.user_id
    })
    this.props.NotificationLogList();
    HttpService.myChaptersService()
      .then(response => {
        if (response.data.status == 200) {
          this.setState({
            myChaptersListData: response.data.chapters_list,
            selected_chapter: response.data.chapters_list[0].chapter_name,
            Spinner:false
          })
          if(this.state.user_type_id === 1){
            localStorage.setItem("chapter_id", response.data.chapters_list[0]?.chapter_id);
            this.setState({
              chapter_id: localStorage.setItem("chapter_id", response.data.chapters_list[0]?.chapter_id)
            })
          }
          else {
            localStorage.setItem("chapter_id", JSON.stringify(0));         
          } 
        }
        else {
          this.setState({
            Spinner:false
          })
          swal({ text: response.data.message, icon: 'error' })
        }
      })
      .catch(error => {
        //  dispatch(myChapterResponceError())
      })
  }
/*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.notificationData = nextProps.NotificationLog.notificationLog
    this.state.notifications = nextProps.NotificationLog.notificationLog.list
    if (nextProps.userDetails.userdetails_res.hit_from == 'header') {
      this.state.my_profile_data = nextProps.userDetails.userdetails_res.user_details
    }
  }
/* To show the notifications selction */
  onViewnotification(item) {
    var note_id = item.note_id
    if (item.viewed_status == 0) {
      HttpService.viewNotificationservice({ note_id })
        .then(response => {
          if (response.data.status === 200) {
            this.props.NotificationLogList();
          }
          else {
            swal({ icon: 'error', text: "Something went wrong, Please try again after sometime." });
          }
        })
        .catch(error => {
          swal({ icon: 'error', text: "Something went wrong, Please try again after sometime." });
        })
    }
    this.props.history.push({
      pathname: '/dashboard/messages',
      state: { detail: item }
    })
  }
/* To update the ui based on chapter selection */
  handleUserInput(e, key) {

    if (key === 'chapters') {
       localStorage.setItem('chapter_id',e.currentTarget.value)
      this.state.chapter_name = e.currentTarget.value

      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
     
      var chapter_id = this.state.chapter_id 

      var data ={
        page : 1,
        limit:8,
        chapter_id:  this.state.chapter_id
      }
      this.setState({ chapter_id: e.currentTarget.value })
      var activescreen = JSON.parse(localStorage.getItem('activescreen'))
      switch (activescreen) {
        case 'resourcelist':
          return this.props.getresourcesAjax({ chapter_id });
        case 'discountlist':
          return this.props.getDiscountsAjax(data);
        case 'usermanagmentlist':
               if(JSON.parse(localStorage.getItem('activetab'))=='ActiveUsers'){
                return this.props.getActiveUsersAjax(data)
               }
               else if(JSON.parse(localStorage.getItem('activetab'))=='AgencyUsers'){
                this.props.getAgencyUsersAjax(data);
               }
               else if(JSON.parse(localStorage.getItem('activetab'))=='BigSUsers'){
                this.props.getBigsUsersAjax(data);
               }
               else if(JSON.parse(localStorage.getItem('activetab'))=='OrganizationUser'){
                this.props.getOrganiseUsersAjax(data);
               }
               else if(JSON.parse(localStorage.getItem('activetab'))=='InactiveUsers'){
                this.props.getInActiveUsersAjax(data);
               }
              break
        case 'AgecySponsoredlist':
          var data = {
            organized_by: "agency",
            month: "current",
            chapter_id: this.state.chapter_id
          }
          return this.props.getRangeEventListAjax(data)
        case 'bigscommunitylist':
          var data = {
            organized_by: "bigs",
            month: "current",
            chapter_id: this.state.chapter_id
          }
          return this.props.getRangeEventListAjax(data)

        case 'messagelist':
          return this.props.RecievedMessages()

        case 'notificationlist':
          return this.props.getAllNotificationsAjax({ chapter_id })

        case 'alluserphotogallery':
          return this.props.AllusersphotosAjax({ chapter_id });

        case 'activies':
          return this.props.getActivityAjax(data);
        case 'dashboard':
          return this.props.DashboardData({ chapter_id });
          case 'alllittles':
            return this.props.getLittlesAjax(data)

        default:
          return null
      }
    }
  }
  /* To show the profile pic */
  previewProfilePic(profilepic) {
    swal({ icon: profilepic,  className: 'swal-iamge-preview' })
  }
/* Goto feedback page */
  onNewFeedback() {
   this.props.history.push({ 
     pathname: '/dashboard/feedback'
    })

  }
/* Goto my profile page */
  onMyprofile(user_id) {
    this.props.history.push({
      pathname: '/dashboard/myprofile',
      state: { from_my_profile: 'myprofile', profile_id: user_id }
    })
  }
  /* Goto user account settings page */
  onAccountSettings(user_id) {
    this.props.history.push({
      pathname: '/dashboard/header_account_settings',
      profile_id: user_id,
      from_my_profile: 'settings'
    })
  }
/* render() function is used to update the UI. */
  render() {
    const { isOpen, value,myChaptersListData,selected_chapter } = this.state;

    return (
      <header className="main-header">
        <a className="logo">
          <span className="logo-lg"><img src="/img/bigsconnect.png" /></span>
        </a>
        <nav className="navbar navbar-expand">
          <div>
            <a className="sidebar-toggle" data-toggle="push-menu" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>
          </div>
          <ul className="navbar-nav ml-auto main-nav">
            <div className="user-panel d-flex">
            
              {this.state.user_type_id == 1 ?
                <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <button
          className='chapter-header'
          style={{backgroundColor:'white'}}
           // iconAfter={<ChevronDown />}
            onClick={this.toggleOpen}
            isSelected={isOpen}
          >
            {this.state.selected_chapter ? `${this.state.selected_chapter }` : 'Select a Chapter'} 
            <i style={{float:"right",marginTop:'5px'}} class="fa fa-chevron-down" aria-hidden="true"></i>
          </button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={this.onSelectChange}
          options={myChaptersListData}
          getOptionLabel={(options) => options['chapter_name']}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={selected_chapter}
        />
      </Dropdown>
            : null
              }
              <div>
                <li class="notification-container">
                  <div class="dropdown bellnotification">
                    <i className="fa fa-bell dropdown-toggle" aria-hidden="true" data-toggle="dropdown"></i>
                    <div className="dropdown-menu">
                      <h4 className="pb-3 pl-4 mb-0">Notifications</h4>
                      <div className="hr-line"></div>
                      {
                        this.state.notifications?.length ? this.state.notifications.map(item =>
                          <div className="dropdown-item">
                            <div class="grid-container" onClick={() => this.onViewnotification(item)} >
                              <div class="grid-child purple">
                                <img className='user-img' src={item.profile_pic}  alt="User Image" />
                              </div>
                              <div class="grid-child green">
                                <h5 className="mb-0">{item.display_name} <small>{item.message}</small></h5>
                                <small><Timeago  className="timeago" date={item.created_at}></Timeago></small>
                              </div>
                            </div>
                            </div>
                        ) :
                          <h5 className="App no-new-notifi">No New Notifications</h5>
                      }
                    </div>

                  </div>
                  {
                    this.state.notificationData.total_count == 0 ? null :
                      <span class="notification-counter"></span>}
                </li>
              </div>
              <div className="vertical-divide"></div>
              <div className="dropdown">
                <a className="nav-link dropdown-toggle user-name" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  {this.state.my_profile_data?.first_name} {this.state.my_profile_data?.last_name} <i class="fa fa-angle-down" aria-hidden="true"></i></a>

                <div style={{ textAlign: 'center' }} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <label className="dropdown-item header-list" onClick={() => this.onMyprofile(this.state.user_id)}>My Profile</label>
                  <div className="dropdown-divider"></div>
                  <label className="dropdown-item header-list" onClick={() => this.onAccountSettings(this.state.user_id)}>Account Settings</label>
                  <div className="dropdown-divider"></div>
                  <label className="dropdown-item header-list" href="#" onClick={() => this.onNewFeedback()}>Feedback</label>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item header-list" onClick={()=> this.logout()}>Logout</a>
                </div>
              </div>
            </div>
          </ul>
          <img onClick={() => this.previewProfilePic(this.state.my_profile_data?.profile_pic)} className="user-img" src={this.state.my_profile_data?.profile_pic} alt="User Image" />
        </nav>
      </header>
    )
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    user: state.user,
    NotificationLog: state.NotificationLog,
    myChapterResponce: state.myChapterResponce,
    userDetails: state.userDetails,

  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
  NotificationLogList,
  myChapterList,
  AllusersphotosAjax,
  getActivityBasedOnChapter,
  getresourcesAjax,
  getDiscountsAjax,  
  getRangeEventListAjax,
  RecievedMessages,
  getAllNotificationsAjax,
  userdetailsData,
  DashboardData,
  getActivityAjax,
  getActiveUsersAjax,
  getAgencyUsersAjax,
  getBigsUsersAjax,
  getOrganiseUsersAjax,
  getInActiveUsersAjax,
  getLittlesAjax
})(withRouter(withCookies(Header)))