import React, { Component } from "react";
import './Newdiscount.css';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { addDiscountForm, discountFetchInput, editDiscountForm } from '../../Store/discounts/addDiscountaction';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import { withRouter } from 'react-router-dom';
import { Tooltip, Overlay } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

var FormData = require('form-data');

const currentuser = JSON.parse(localStorage.getItem('userdata'));

class Newdiscount extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      input: {},
      Uid: '',
      pictures: [],
      images: [],
      image: [],
      active_status: '0',
      program_name: '',
      partner_name: '',
      description: '',
      documents: [],
      start_date: '',
      end_date: '',
      documentsFromEdit: [],
      deleteResponce: {},
      discount_id: '',
      spinner: false,
      deleteImageId: [],
      previewImage: '',
      chapter_id: '',
      showPopover: false,
      fileType: '',
      eventtarget: null,
    };   
    this.handleSubmit = this.handleSubmit.bind(this);
  }
/* To make the api call initial page load */
  componentDidMount() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user_id;
    this.setState({ Uid: user_id });
    if (this.props.location.state != null) {
      this.state.spinner = false
      this.state.documentsFromEdit = this.props.location.state.detail.documents
      this.state.discount_id = this.props.location.state.detail.discount_id
      this.state.program_name = this.props.location.state.detail.program_name
      this.state.partner_name = this.props.location.state.detail.partner_name
      this.state.description = this.props.location.state.detail.description
      this.state.start_date = this.props.location.state.detail.start_date
      this.state.end_date = this.props.location.state.detail.end_date
      this.state.active_status = this.props.location.state.detail.active_status
      this.state.documents = this.props.location.state.detail.documents
    }
  }
   /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.spinner = false
  }
   /* Event occurs when the value of an element has been changed */
  handleChange = (e, key) => {
    let discountInput
    if (key == 'documents') {
      if(e.target.files[0].size < 5e+6) {

      this.setState({
        image: e.target.files,
        previewImage: URL.createObjectURL(e.target.files[0]),
        fileType: e.target.files[0].name

      })
      this.state.images.push(this.state.image);
    }
    else{
      var errorAlert = {
        message: 'File size should be less than 5 mb',
        alertType: 'warning'
      }
      handleSwalAlert(errorAlert)
    }
    } 
    
    else {
      discountInput = {
        key: key,
        value: e.currentTarget.value
      }
      if (key === 'active_status') {
        this.setState({
          active_status: e.currentTarget.value
        })
      }
      if (key === 'program_name') {
        this.setState({
          program_name: e.currentTarget.value
        })
      }
      if (key === 'description') {
        this.setState({
          description: e.currentTarget.value
        })
      }
      if (key === 'partner_name') {
        this.setState({
          partner_name: e.currentTarget.value
        })
      }
      if (key === 'start_date') {
        this.setState({
          start_date: e.currentTarget.value
        })
      }
      if (key === 'end_date') {
        this.setState({
          end_date: e.currentTarget.value
        })
      }
      this.props.discountFetchInput(discountInput);
    }
  }
  /* To call the api for submitting the values */
  handleSubmit = (e) => {
    e.preventDefault();
    this.state.spinner = true
    const { adddiscount } = this.props
    const { Uid } = this.state
    if (this.props.location.state != null) {
      if (this.validator.allValid()) {
        var data = new FormData();
        data.append('discount_id', this.state.discount_id);
        data.append('program_name', this.state.program_name);
        data.append('partner_name', this.state.partner_name);
        data.append('description', this.state.description);
        data.append('start_date', this.state.start_date);
        data.append('end_date', this.state.end_date);
        data.append('active_status', this.state.active_status);
        for (var i = 0; i < this.state.deleteImageId.length; i++) {
          data.append('deletedocumentids[]', this.state.deleteImageId[i]);
        }
        for (var i = 0; i < this.state.image.length; i++) {
          data.append('documents[]', this.state.image[i]);
        }
        data.append('chapter_id', this.state.chapter_id)
        this.props.editDiscountForm(data);
        var successAlert = {
          message: "Discount Updated Successfully",
          alertType: "success",
          closeOnEsc: false,
          dangerMode: true,
          closeOnClickOutside: false,
          propsData : this.props,
          goToList : true,
          pathname : '/dashboard/discount_programs'
        }
        handleSwalAlert(successAlert)
      }
      else {
        this.state.spinner = false
        this.validator.showMessages();
      }
    }
    else {
      if (!this.validator.allValid()) {
        this.state.spinner = false
        this.validator.showMessages();
      }
      else {
        var data = new FormData();
        data.append('program_name', adddiscount.discountDetails.program_name);
        data.append('partner_name', adddiscount.discountDetails.partner_name);
        data.append('description', adddiscount.discountDetails.description);
        data.append('start_date', adddiscount.discountDetails.start_date);
        data.append('end_date', adddiscount.discountDetails.end_date);
        data.append('active_status', adddiscount.discountDetails.active_status);

        for (var i = 0; i < this.state.image.length; i++) {
          data.append('documents[]', this.state.image[i]);
        }
        data.append('chapter_id', this.state.chapter_id)
        for (var pair of data.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }
        this.props.addDiscountForm(data);

        var successAlert = {
          message: "Discount Added Successfully",
          alertType: "success",
          closeOnEsc: false,
          dangerMode: true,
          closeOnClickOutside: false,
          propsData : this.props,
          goToList : true,
          pathname : '/dashboard/discount_programs'
        }
        handleSwalAlert(successAlert)
        
        this.discountForm.reset();
        this.setState({
          active_status: '',
          program_name: '',
          partner_name: '',
          description: '',
          start_date: '',
          end_date: ''
        })
        this.validator.hideMessages();
      }
    }
  };

  /* Goto the discounts list */
  onCancel() {
    this.props.history.push('/dashboard/discount_programs');
  }
/* To show the pop up */
  showPopoverDialog(e) {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
    })
  }
/* To close the pop up */
  dismissPopoverDialog() {
    this.setState({
      showPopover: false
    })
  }
/* to delete the discount */
  onDelete = (e, discountdocument_id) => {
    var data = {
      discountdocument_id: discountdocument_id
    }
    HttpService.deleteDiscountdocument(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
        this.props.editDiscountForm(data);
        var successAlert = {
          message : "Document Deleted Successfully",
          alertType: "success",
        }
        handleSwalAlert(successAlert)          
          this.setState({
            spinner: false,
            showPopover: false,
          })
        }
        else {
          this.setState({
            spinner: false,
            showPopover: false
          })
          var errorAlert = {
            alertType : 'warning',
            message : this.state.deleteResponce.message,
          }
          handleSwalAlert(errorAlert)          
        }
      })
      .catch(error => {
        this.setState({
          spinner : false
        })
        var errorAlert = {
          alertType : 'error'
        }
       handleSwalAlert(errorAlert)
      })
  }
  /* To remove the image  */
  removeImage = (event, discountdocument_id) => {

    this.state.deleteImageId.push(discountdocument_id);
    let documentsFromEdit = [...this.state.documentsFromEdit];
    this.state.documentsFromEdit.forEach(function (arrayItem) {
      if (arrayItem.discountdocument_id == discountdocument_id) {
        arrayItem.document_name = '';
      }
    });
    var successAlert = {
      message: "Discounts Document Deleted",
      alertType: "success"
    }
   handleSwalAlert(successAlert)
    this.setState({
      spinner: false,
      showPopover: false,
      documents: []
    })
    this.setState({ documentsFromEdit: this.state.documentsFromEdit });
  }
/* Goto new discounts page to create new discount */
  onDiscounts() {
    this.props.history.push('/dashboard/discount_programs');
  }
  /* To disable past dates */
  disablepastdates() {
    const today = new Date();
    const dd = String(today.getDate() + 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }
/* render() function is used to update the UI. */
  render() {
    const { showPopover } = this.state
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    return (
      <div>
        <form className="form" ref={form => this.discountForm = form} onSubmit={this.handleSubmit}>
          <div className="container">
            <Row className="mt-3 mb-4">
              <Col xs lg="12">
                <div className="horizontal-container">
                  <label className="label-discount" onClick={() => this.onDiscounts()}>Discount Programs</label>
                  <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                  {!this.props.location.state ?
                    <label className="label-discount">New Discount</label> : <div className="horizontal-container">
                    <label className="label-discount">Edit {this.state.program_name}</label>
                    </div>}
                </div>
              </Col>
            </Row>
            <section className="newuser-sec">
              <div className="mb-3">
                <div>
                  <label className="lehead" defaultValue={this.state.active_status}>Status<span>*</span></label>
                </div>
                <div className="custom-control custom-radio custom-control-inline" >
                  <input type="radio" className="radio" id="active_status" name="active_status" value="0" checked={this.state.active_status == '0'}
                    onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                  <label className="custom-control-label" htmlFor="active_status">Active</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="radio" id="active_status_two" name="active_status" value="1" checked={this.state.active_status == '1'}
                    onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                  <label className="custom-control-label" htmlFor="active_status_two">InActive</label>
                </div>
                <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
              </div>
              <Row>
                <Col xs lg="5">
                  <label for="Discount program name" className="lehead">Discount Program Name<span>*</span></label>
                  <input id="Discount program name" className="form-control" type="text" name="Discount program name" defaultValue={this.state.program_name}
                    onChange={(e) => this.handleChange(e, 'program_name')} onBlur={this.handleChange} />
                  <span className="text-danger">{this.validator.message("program_name", this.state.program_name, "required")}</span>
                </Col>
                <Col xs lg="3">
                  <div className="form-group">
                    <label for="startdate" className="lehead">Start Date<span>*</span></label>
                    <input type="date" id="startdate" name="startdate" defaultValue={this.state.start_date} className="form-control" min={this.disablepastdates()}
                      onChange={(e) => this.handleChange(e, 'start_date')} />
                    <span className="text-danger">{this.validator.message("start_date", this.state.start_date, "required")}</span>

                  </div>
                </Col>
                <Col xs lg="3">
                  <div className="form-group">
                    <label for="enddate" className="lehead">End Date<span>*</span></label>
                    <input type="date" id="enddate" name="enddate" className="form-control" defaultValue={this.state.end_date} min={this.disablepastdates()}
                      onChange={(e) => this.handleChange(e, 'end_date')} />
                    <span className="text-danger">{this.validator.message("end_date", this.state.end_date, "required")}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs lg="5">
                  <div className="form-group">
                    <label for="partnername" className="lehead">Business/Partner Name<span>*</span></label>
                    <input id="partnername" className="form-control" type="text" name="partnername" defaultValue={this.state.partner_name}
                      onChange={(e) => this.handleChange(e, 'partner_name')} onBlur={this.handleChange} />
                    <span className="text-danger">{this.validator.message("partner_name", this.state.partner_name, "required")}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs lg="5">
                  <div className="mb-3">
                    <div className="form-group">
                      <label for="comment" className="lehead">Discount Description*</label>
                      <textarea id="comment" row="6" name="comment" defaultValue={this.state.description} className="form-control"
                        onChange={(e) => this.handleChange(e, 'description')} />
                      <span className="text-danger">{this.validator.message("description", this.state.description, "required")}</span>
                    </div>
                  </div>
               </Col>
              </Row>
              <div className="form-group">
                <label className="lehead">Documentation</label>
                <div className="d-flex">
                  {this.state.documentsFromEdit.length > 0 ?
                    <div className="discountsgallery-sec photogallery-sec">
                        <Row>
                          {
                            this.state.documentsFromEdit && this.state.documentsFromEdit?.map(item => (
                              <Col id="uploaded-image-left">
                                <div className="containerdiscount">
                                  {(item.document_name).match('.pdf') ?
                                    <img className="preview-image"  src={`${process.env.PUBLIC_URL}/img/pdfimage.png`} /> :
                                    <img className="preview-image"  src={item.document_name} />
                                  }
                                  <div className="overlaydiscount"> 
                                  <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                      <Tooltip>
                                        <div className='text-center p-4'>
                                          <p className='tool-title'>Delete Document</p>
                                          <p className='tool-para'>Are you sure you want to delete this discount document? Deleted documents may not be restored.</p>
                                        </div>
                                        <div className='d-flex btn-sec'>
                                          <button onClick={(e) => this.removeImage(e, item.discountdocument_id)} className='tool-btn-yes'>Yes, Delete</button>
                                          <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                        </div>
                                      </Tooltip>
                                    </Overlay>
                                    <div className="discount-img-ontop">
                                      <a href={item.document_name} target="_blank" >
                                        <i class="fa fa-eye" aria-hidden="true" />
                                      </a>
                                      <i class="fa fa-trash" aria-hidden="true" onClick={(e) => this.showPopoverDialog(e)}></i>
                                    </div>
                                  </div>
                                </div>
                              </Col>))}
                        </Row>
                    </div>
                    : null}
                  <div>
                    <div id="wrapper" className="d-flex">
                      <div id="uploaded-image-left">
                        {
                          !this.state.fileType.match('.pdf') ?
                            this.state.previewImage ?
                              <img className="preview-image" src={this.state.previewImage}></img> :
                              null :
                            <img className="preview-image" src={`${process.env.PUBLIC_URL}/img/pdfimage.png`}></img>
                        }
                      </div>
                      <div id="upload-right">
                        <div className="profile-upload rect">
                          <label className="custom-file-upload rectangle">
                            <input type="file" name="documents" className="form-control"  multiple onChange={(e) => this.handleChange(e, 'documents')} />
                            <i className="fas fa-plus"></i>
                          </label>
                          <label className="up-lbl">Upload an image or PDF</label><br />
                          <span className="up-span">(5MB Limit)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <Row className="my-4">
                <Col xs lg="6">
                  <div className="save-sec">
                    <button type="submit" className="btn btn-info btn-success mt-1" onClick={this.handleSubmit}>Save</button>
                  </div>
                </Col>
                   <Col xs lg="6">
                  <div className="cancel-sec">
                    <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                  </div>
               </Col>
              </Row>
            </section>
          </div>
        </form>
      </div>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    adddiscount: state.adddiscount
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { addDiscountForm, discountFetchInput, editDiscountForm })(withRouter(Newdiscount));
