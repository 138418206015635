import handleSwalAlert from "../SwalAlert";

const UploadMedia = (i, e, uploadType) => {
  var result = ''
  switch (uploadType) {

    case "typeOfUpload":
      result = e.target.value
      break;

    case "images":

      if (e.target.name === "bannerPreview") {

        if (e.target.files[0].type === 'video/mp4' && e.target.files[0].size > 10e+6) {
          var errorAlert = {
            message: 'Uploading file size should be less than 10mb',
            alertType: 'warning'
          }
          result = handleSwalAlert(errorAlert)
        }
        else if (e.target.files[0].size > 5e+6) {
          var errorAlert = {
            message: 'Uploading file size should be less than 5 mb',
            alertType: 'warning'
          }
          result = handleSwalAlert(errorAlert)
        }
        else {
          result = e.target.files[0];
        }
      }
      else {
        result = e.target.value
      }
      break;

    case "url":
      result = e.target.value
      break;

    default:
      break;
  }
  return result
}
export default UploadMedia