import { SIDENAV_SETTINGS_DATA,SIDENAV_SETTINGS_ERROR } from "../types";
import HttpService from "../../Services/HttpService";

export const settingsdata = (response)=>{
    return {
        type : SIDENAV_SETTINGS_DATA,
        response : response
    }
}

export const getsettingsdata = ()=>{
    return(dispatch) => {
        HttpService.showsettingsdata().
        then(response=>{
            dispatch(settingsdata(response.data))
        })
    }
}