export const FETCH_LOGIN_INPUT = "FETCH_LOGIN_INPUT";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const USER_FETCH_INPUT = "USER_FETCH_INPUT";
export const USER_ADD = "USER_ADD";
export const USER_ERROR = "USER_ERROR";
export const DELETE_USER = "DELETE_USER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const SEND_PASSWORDMAIL = "SEND_PASSWORDMAIL";
export const RESEND_INVITE = "RESEND_INVITE";
export const PROFILEPIC_UPLOAD = "PROFILEPIC_UPLOAD";
export const ORGANISE_USER = "ORGANISE_USER";

export const ACTIVE_USERS = "ACTIVE_USERS";
export const INACTIVE_USERS = "INACTIVE_USERS";
export const AGENCY_USERS = "AGENCY_USERS";
export const BIGS_USERS = "BIGS_USERS";

export const DISCOUNT_FETCH_INPUT = "DISCOUNT_FETCH_INPUT";
export const DISCOUNT_ADD = "DISCOUNT_ADD";
export const EDIT_DISCOUNT = "EDIT_DISCOUNT";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DISCOUNT_ERROR = "DISCOUNT_ERROR";
export const DSCOUNTS_LIST = "DSCOUNTS_LIST";

export const NOTIFICATION_FETCH_INPUT = "NOTIFICATION_FETCH_INPUT";
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS";
export const SENT_NOTIFICATIONS = "SENT_NOTIFICATIONS";
export const SCHEDULED_NOTIFICATIONS = "SCHEDULED_NOTIFICATIONS";

export const USER_DETAILS = "USER_DETAILS";


export const LITTLE_FETCH_INPUT = "LITTLE_FETCH_INPUT";
export const LITTLE_ADD = "LITTLE_ADD";
export const LITTLE_ERROR = "LITTLE_ERROR";
export const LITTLES_LIST = "LITTLES_LIST";
export const DELETE_LITTLE = "DELETE_LITTLE";


export const USERINFO_INPUT = "USERINFO_INPUT";
export const USERINFO_ADD = "USERINFO_ADD";
export const USERINFO_ERROR = "USERINFO_ERROR";


export const GET_PERMISSIONS = "GET_PERMISSIONS";

export const SETTINGS_INPUT = "SETTINGS_INPUT";
export const SETTINGS = "SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

export const GALLERY_INPUT = "GALLERY_INPUT";
export const PHOTO_GALLERY = "PHOTO_GALLERY";
export const DELETE_PHOTO = "DELETE_PHOTO";
export const GALLERY_ERROR = "GALLERY_ERROR";
export const IMAGEDOWNLOAD = "IMAGEDOWNLOAD";

export const RESOURCES_LIST = "RESOURCES_LIST";
export const RESOURCE_FETCH_INPUT = "RESOURCE_FETCH_INPUT";
export const ADD_RESOURCE = "ADD_RESOURCE";
export const EDIT_RESOURCE = "EDIT_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const RESOURCE_ERROR = "RESOURCE_ERROR";

export const EVENTS_LIST = "EVENTS_LIST";

export const EVENT_TYPES = "EVENT_TYPES";
export const EVENT_FETCH_INPUT = "EVENT_FETCH_INPUT";
export const EVENT_ADD = "EVENT_ADD";
export const EDIT_EVENT = "EDIT_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const EVENT_ERROR = "EVENT_ERROR";

export const SEND_MESSAGE_INPUT = "SEND_MESSAGE_INPUT";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const RECIEVED_MESSAGES = "RECIEVED_MESSAGES";
export const RECIEVED_MESSAGE_ERROR = "RECIEVED_MESSAGE_ERROR";

export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";

export const TRASH_MESSAGE = "TRASH_MESSAGE";
export const TRASH_MESSAGE_ERROR = "TRASH_MESSAGE_ERROR";

export const SENT_MESSAGES = "SENT_MESSAGES";
export const SENT_MESSAGES_ERROR = "SENT_MESSAGES_ERROR";

export const DRAFT_MESSAGES = "DRAFT_MESSAGES";
export const DRAFT_MESSAGE_ERROR = "DRAFT_MESSAGE_ERROR";

export const VIEW_MESSAGE = "VIEW_MESSAGE";
export const VIEW_MESSAGE_ERROR = "VIEW_MESSAGE_ERROR";

export const USER_TYPE = "USER_TYPE";
export const BIGS_EVENTS = "BIGS_EVENTS";
export const RANGE_EVENTS = "RANGE_EVENTS";


export const CASE_MANAGER = "CASE_MANAGER";
export const CASE_MANAGER_ERROR = 'CASE_MANAGER_ERROR';

export const NOTIFICATION_LOG = "NOTIFICATION_LOG";
export const NOTIFICATION_LOG_ERROR = 'NOTIFICATION_LOG_ERROR';

// CHAPTER LIST
export const CHAPTERS_LIST = 'CHAPTERS_LIST';
export const CHAPTERS_ERROR = 'CHAPTERS_ERROR';

//ADD CHAPTER
export const CHAPTER_FETCH_INPUT = 'CHAPTER_FETCH_INPUT';
export const CHAPTER_ADD = 'CHAPTER_ADD';
export const CHAPTER_ADD_ERROR = 'CHAPTER_ADD_ERROR'

//MYCHAPTERS
export const MYCHAPTERS_LIST = 'MYCHAPTERS_LIST';
export const MYCHAPTERS_ERROR = 'MYCHAPTERS_ERROR'

//VIEWSETTINGS
export const VIEWSETTINGS_LIST = 'VIEWSETTINGS_LIST';
export const VIEWSETTINGS_ERROR = 'VIEWSETTINGS_ERROR'

//FEEDBACK
export const FEEDBACK_COMMENT = 'FEEDBACK_COMMENT';
export const FEEDBACK_COMMENT_ERROR = 'FEEDBACK_COMMENT_ERROR'
export const FEEDBACK_FETCH_INPUT = 'FEEDBACK_FETCH_INPUT'

//ADDActivity
export const ACTIVITY_FETCH_INPUT = 'ACTIVITY_FETCH_INPUT';
export const ACTIVITY_ADD = 'ACTIVITY_ADD'
export const ACTIVITY_ADD_ERROR = 'ACTIVITY_ADD_ERROR'

//activitylist
export const ACTIVITY_LIST = 'ACTIVITY_LIST'
export const ACTIVITY_ERROR = 'ACTIVITY_ERROR'

//activityhistory
export const ACTIVITY_HISTORY = 'ACTIVITY_HISTORY'
export const ACTIVIY_HISTORY_ERROR = 'ACTIVIY_HISTORY_ERROR'

//Ddashboard
export const DASHBOARD_LIST = 'DASHBOARD_LIST'
export const DASHBOARD_CHEPATER_ERROR = 'DASHBOARD_CHEPATER_ERROR'
export const DASHBOARD_CHEPATER_DATA = 'DASHBOARD_CHEPATER_DATA'


export const SIDENAV_SETTINGS_DATA = 'SIDE_NAV_SETTINGS_DATA'
export const SIDENAV_SETTINGS_ERROR = 'SIDE_NAV_SETTINGS_ERROR'

//cms help center
export const CMS_HELP_LIST_DATA = 'CMS_HELP_LIST_DATA'
export const CMS_HELP_LIST_ERROR = 'CMS_HELP_LIST_ERROR'

export const EVENT_TAG_LIST = 'EVENT_TAG_LIST'
export const EVENT_TAG_LIST_ERROR = 'EVENT_TAG_LIST_ERROR'

//Template 
export const TEMPLATE_LIST_ERROR = 'TEMPLATE_LIST_ERROR'
export const TEMPLATE_LIST = 'TEMPLATE_LIST'


//ADDtemplate
export const TEMPLATE_FETCH_INPUT = 'TEMPLATE_FETCH_INPUT';
export const TEMPLATE_ADD = 'TEMPLATE_ADD'
export const TEMPLATE_ADD_ERROR = 'TEMPLATE_ADD_ERROR'

//VIEWTEMPLATE
export const TEMPLATE_VIEW = 'TEMPLATE_VIEW'

//Plan list
export const PLAN_LIST = 'PLAN_LIST'
export const PLAN_LIST_ERROR = 'PLAN_LIST_ERROR'

//Switch to features
export const SWITCHTOFEATURE_SUCCESS = 'SWITCHTOFEATURE_SUCCESS'
export const SWITCHTOFEATURE_ERROR = 'SWITCHTOFEATURE_ERROR'
