import {LITTLES_LIST,DELETE_LITTLE} from '../types';
import HttpService from '../../Services/HttpService';

export const LittlesData = (response) =>{
    return {
        type: LITTLES_LIST,
        response: response
    }
}

export const deletelittlefor = (response) => {
    return {
        type: DELETE_LITTLE,
        response
    }
}


export const getLittlesAjax = (data) => {
    return (dispatch) => {
        HttpService.mylittleListUrlAjax(data)
        .then(response => {
            console.log("littles list:- ",response.data)
            dispatch(LittlesData(response.data));
        })
    }
}

export const deleteLittle = (mylittle_id) => {
    console.log('mylittle_id',mylittle_id);
    return (dispatch) => {
        HttpService.deleteLittle(mylittle_id)
            .then(response => {
                console.log('response',response);
                dispatch(deletelittlefor(response.data));
            })
            .catch(error => {
                dispatch();
            })
    }
}
    

