import React, { Component } from 'react';
import Header from './Header.jsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchLoginInput, loginUser } from '../../Store/login/login';
import HttpService from './../../Services/HttpService';
import { Circles } from 'react-loader-spinner'
import 'react-responsive-modal/styles.css';
import handleSwalAlert from '../SwalAlert'
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { withCookies, Cookies } from 'react-cookie'

toast.configure()
class LogIn extends Component {

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleLoginButton = this.handleLoginButton.bind(this);
    this.state = {
      errorMessage: '',
      username: '',
      password: '',
      spinner: false,
      showPasswordText: false,
      showPassworTextlength: 0,
      onOpenModal: false,
      onCloseModal: false,
      Femail: '',
      forgotvalidation: false,
      forgoterrormessage: ''
    }
  }
  navigateTo = () => {

    var user = JSON.parse(localStorage.getItem('userdata'));

    if (user == null) {
      this.props.history.push('/');
    } else {
      this.props.history.push('/dashboard/newdashboard');
    }
  }

  /* Event occurs when the value of an element has been changed */
  handleUserInput = (event, key) => {
    if (key === "username") {
      this.setState({ email: event.currentTarget.value });
    }
    else if (key === "Femail") {
      this.setState({
        Femail: event.currentTarget.value,
        forgotvalidation: false
      });
    }
    else {
      this.setState({
        password: event.currentTarget.value,
        showPassworTextlength: event.currentTarget.value
      });
    }
  }

  /* To call api for login */
  handleLoginButton = (e) => {
    const { cookies } = this.props;
    e.preventDefault();
    this.setState({
      spinner: true,

    })
    var logindetails = {
      username: this.state.email,
      password: this.state.password,
      login_from: "web"
    }

    if (this.validator.allValid()) {
      this.setState({ validated: false });
      HttpService.login(logindetails)
        .then(response => {
          if (response.status == 200) {
            cookies.set('cookiedata', response.data.user.token, { path: "/" })
            this.setState({ user: cookies.get('cookiedata') });
            localStorage.setItem("userdata", JSON.stringify(response.data.user));
            localStorage.setItem("chapter_id", JSON.stringify(response.data.user.chapter_id));
            this.setState({
              spinner: false,
            })
            this.props.history.push('/dashboard/newdashboard');
            this.setState({ validated: false });
          }
          else {
            this.setState({
              spinner: false,
            })
            var errorAlert = {
              message: response.data.message,
              alertType: 'warning'
            }
            handleSwalAlert(errorAlert)
          }
        })
        .catch(error => {
          this.setState({
            spinner: false
          })
          var errorAlert = {
            alertType: 'error'
          }
          handleSwalAlert(errorAlert)
        })
    }
    else {
      this.setState({
        spinner: false,
        validated: true
      })
      this.validator.showMessages();
    }
  }

  hidepassword() {
    this.setState({
      showPasswordText: false
    })
  }
  /* to show the password text */
  showpassword() {
    this.setState({
      showPasswordText: true
    })
  }
  /* to show the pop up */
  handleOpenModal() {
    this.setState({
      onOpenModal: true

    })
  }
  /* to close the pop up */
  handleCloseModal() {
    this.setState({
      onOpenModal: false,
      forgotvalidation: false,
      forgoterrormessage: '',
      Femail: ''
    })
  }

  /* render() function is used to update the UI. */
  render() {
    this.validator.purgeFields();
    const { showPasswordText, showPassworTextlength,
    } = this.state;

    const bigsLogo = {
      height: "100px",
      margin: "20px"
    };
    return (
      <div className="wrapper">
        <main className='body-new'>
          <Header />
          {/* /.header */}
          <div className="section-form">
            <div className="shell">
              <div className="section__form">
                <div className="form">
                  <form onSubmit={this.handleLoginButton}>
                    <div className="form__head">
                      <h1 className="form__title">Sign In</h1>{/* /.form__title */}
                      <h5>Hello there. Sign in and start managing your Bigs Connect organization.</h5>
                    </div>{/* /.form__head */}
                    <div className="form__body form__body--small">
                      <div className="form__row">
                        <label for="field-email" className="form__label">Email Address</label>
                        <div className={this.state.validated ? 'redBorder' : 'form__controls'}>
                          <div className="form__icon">
                            <i className="fa fa-envelope" aria-hidden="true"></i>
                          </div>{/* /.form__icon */}
                          <input type="email" className="field" name="username" value={this.state.email}
                            onChange={(event) => { this.handleUserInput(event, 'username') }} />
                        </div>{/* /.form__controls */}
                        <span className="text-danger">
                          {this.validator.message('email', this.state.email, 'required')}
                        </span>{/* /.form__error */}
                      </div>{/* /.form__row */}
                      <div className="form__row">
                        <label for="field-password" className="form__label">Password</label>
                        <div className={this.state.validated ? 'redBorder' : 'form__controls'}>
                          <div className="form__icon">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>{/* /.form__icon */}
                          <input
                            type={showPasswordText ? 'text' : 'password'}
                            className="field"
                            name="password" value={this.state.password}
                            onChange={(event) => { this.handleUserInput(event, 'password') }} />
                          {showPassworTextlength == 0 ? null :
                            showPasswordText ?
                              <i class=" fa fa-eye-slash loginnew-eye-slash" aria-hidden="true" onClick={() => this.hidepassword()}></i>
                              : <i class="fa fa-eye loginnew-eye" aria-hidden="true" onClick={() => this.showpassword()}></i>}
                        </div>{/* /.form__controls */}
                        <span className="text-danger">
                          {this.validator.message('password', this.state.password, 'required')}
                        </span>
                      </div>{/* /.form__row */}
                    </div>{/* /.form__body */}
                    <div className="form__actions">
                      <input type="submit" className="btn btn--blue form__btn" value="Sign In" />
                      {/* <a href="#" className="form__link">Forgot password?</a> */}
                      <Link className="form__link" to='/forgotPassword'>Forgot password?</Link>
                    </div>{/* /.form__actions */}
                  </form>
                  <center>
            <div class="loader-spinner mt-3">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
                </div>{/* /.form */}
              </div>{/* /.section__form */}
              <div className="section__foot">
                <p>Don’t have an account?</p>
                <Link className="btn btn--border-blue" to="/signup">Sign Up</Link>
              </div>{/* /.section__foot */}
            </div>{/* /.shell */}
          </div>{/* /.section */}
          {/* Your footer goes here */}
          {/* wrapper */}
        </main>
      </div>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
  return {
    login: state.login
  }
}
/*Connects a React component to a Redux store */

export default connect(mapStateToProps, { fetchLoginInput, loginUser })(withCookies(LogIn));