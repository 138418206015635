import React, { Component } from 'react';
import Header from '../TemplateHeader';
import BodyConent from './BodyConent';
import Carousels from '../TemplateCarousal';
import Footer from './Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createTemplate } from '../../../Store/template/addTemplateAction'
import { viewTemplate } from '../../../Store/template/viewTemplateoneAction'
import HttpService from '../../../Services/HttpService';
import { Circles } from 'react-loader-spinner';
import { getDiscountsAjax } from '../../../Store/discounts/discountsListaction';

class Templete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapterData: {},
            viewTemplateData: {},
            viewDiscountData: {},
            spinner: false,
            employee: [
                {
                    id: 1,
                    name: "Abc",
                    age: 25
                },
                {
                    id: 2,
                    name: "Def",
                    age: 28
                },
                {
                    id: 3,
                    name: "Ghi",
                    age: 30
                }
            ]

        }

    }

    componentDidMount() {
        this.state.chapterData = JSON.parse(localStorage.getItem("chpaterdata"))
        var data = {
            chapter_id: this.state.chapterData.chapter_id,
            template_id: this.state.chapterData.template.templateid,
            type:'view'

        }
        this.props.viewTemplate(data)
    }

    componentWillReceiveProps(nextProps) {
        this.state.viewTemplateData = nextProps.viewtemplate.viewTemplate
        this.state.viewDiscountData = nextProps.discountsList.discountslist
        localStorage.setItem("ViewDiscountData", JSON.stringify(this.state.viewDiscountData))
    }

    componentWillMount() {
        var data = {
            chapter_id: JSON.parse(localStorage.getItem("chpaterdata")).chapter_id,
            template_id: JSON.parse(localStorage.getItem("chpaterdata")).template.templateid,
            type:'view'
        }
        HttpService.viewTemplate(data)
            .then(response => {

                localStorage.setItem("ViewTemplateData", JSON.stringify(response.data))
            })

        var postdata = {
            page: 1,
            limit: 8,
            chapter_id: JSON.parse(localStorage.getItem("chpaterdata")).chapter_id
        }
        this.props.getDiscountsAjax(postdata);

    }

    /* render() function is used to update the UI. */
    render() {
        return (
            <div style={{ background: 'aliceblue' }}>
                <center>
                    <div class="loader-spinner">
                        {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                    </div>
                </center>
                <Header />
                <Carousels />
                <BodyConent />
                <Footer />
            </div>
        )
    }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        addtemplate: state.addtemplate,
        viewtemplate: state.viewtemplate,
        discountsList: state.discountsList,
    }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { createTemplate, viewTemplate, getDiscountsAjax })(withRouter(Templete));
