import {ACTIVITY_HISTORY} from '../types';
import HttpService from '../../Services/HttpService';

export const activityhistoryData = (response) =>{
    return {
        type: ACTIVITY_HISTORY,
        response:response
    }
}

export const getActivityHistory = (data) => {
    return (dispatch) => {
        HttpService.activitieseventsHistory(data)
        .then(response => {
            dispatch(activityhistoryData(response.data))
        })
    }
} 

