import React, { Component } from 'react';
import { connect } from 'react-redux';
import { photogalleryData, downloadImage } from '../../Store/photogallery/photogalleryaction';
import "./Imagestyle.css";
import ReactPagenate from 'react-paginate';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import { Circles } from 'react-loader-spinner';
import { Tooltip, Overlay } from 'react-bootstrap';

const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };

class UserPhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      photos: [],
      spinner: true,
      offset: 0,
      perPage: 10,
      currentPage: 0,
      slice: [],
      filterPhotos: [],
      message: '',
      deleteResponce: {},
      showPopover: false,
      eventtarget: null,
      photo_id: null
    };
  }
  /** To delete the photo */
  onDelete = (photo_id) => {

    if (photo_id > -1) {
      this.state.photos.splice(photo_id, 1);
    }
    var data = {
      photo_id: this.state.photo_id
    }
    HttpService.deletephoto(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          this.state.spinner = true
          this.state.photos = ''
          var user_id = this.props.userId;
          this.setState({ user_id: user_id });
          this.props.photogalleryData({ user_id });
          this.setState({
            showPopover: false
          })
          swal({
            title: this.state.deleteResponce.message,
            icon: "success"
          });
        }
        else {
          this.setState({
            showPopover: false
          })
          this.state.spinner = true
          swal({
            title: this.state.deleteResponce.message,
            icon: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false
        })
        this.state.spinner = true
        swal({
          icon: 'error',
          text: "Something went wrong, Please try again after sometime."
      })
      })
    this.setState({
      spinner: !this.state.spinner
    });
  }
  /** To download the photo */
  onDownload = (photo) => {
    var image_name = photo.replace(/^.*[\\\/]/, '');
    this.props.downloadImage({ image_name })
  }
  /** To make the api call initial page load */
  componentDidMount() {
    var user_id = this.props.userId;
    this.setState({ user_id: user_id });
    this.props.photogalleryData({ user_id });
  }
  /** To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.setState({
      spinner: !this.state.spinner,
      photos: nextProps.photoGallery.gallery_res.photos
    });

    if (this.state.photos.length === 0) {
      this.setState({
        spinner: !this.state.spinner,
        pageCount: 0,
        message: nextProps.photoGallery.gallery_res.message
      })
    }
    else {
      this.setState({
        spinner: false,
      });

      this.state.filterPhotos = nextProps.photoGallery.gallery_res.photos.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = this.state.filterPhotos.map(pd => <React.Fragment>

      </React.Fragment>)
      this.setState({
        pageCount: Math.ceil(this.state.photos.length / this.state.perPage),
        postData
      })
    }
  }
  /** For pagination */
  receivedData() {
    this.state.slice = this.state.photos.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.photos.length / this.state.perPage),
      postData
    })
  }
  /** Goto next,previous pages in pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.receivedData()
    });
  };

  /** To show the pop up */
  showPopoverDialog = (e, pid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      photo_id: pid.photo_id,

    })
  }
  /** To close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /** render() function is used to update the UI. */
  render() {
    const { showPopover } = this.state

    if (this.state.photos.length === 0) {
      return (

        <div style={{ height: '100vh' }} className="App">
          <center>
            <div class="loader-spinner">
              {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
              }
            </div>
          </center>
          <h3 className="pagename">{this.state.message}</h3>
        </div>
      )
    }
    else {

      return (
        <div style={{ marginTop: '-40px' }} className="tabs-photgallery-sec">
          <div className="container">
            <div className="imgContainer photogallery-sec">
              <div className="row" style={{ width: '100%' }}>
                {
                  this.state.photos?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
                    this.state.photos < 0 ? (<p>no images</p>) : (
                      <div className="col-md-4 displayicons-sec">
                        <img className="photogallery-img" key={item.photo_id} src={item.photo_name} />
                        <div className="img-ontop">
                          <i class="fa fa-download" aria-hidden="true" onClick={() => this.onDownload(item.photo_name)}></i>
                          {
                            <><Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                              <Tooltip>
                                <div className='text-center p-4'>
                                  <p className='tool-title'>Delete Photo</p>
                                  <p className='tool-para'>Are you sure you want to delete photo?</p>
                                </div>
                                <div className='d-flex btn-sec'>
                                  <button onClick={() => this.onDelete(item.photo_id)} className='tool-btn-yes'>Yes, Delete</button>
                                  <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                </div>
                              </Tooltip>
                            </Overlay>
                            </>
                          }
                          <i class="fa fa-trash" aria-hidden="true" onClick={(e) => this.showPopoverDialog(e, item)}></i>
                        </div>
                        <div className="text-center userdata-sec mb-5">
                          <p>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</p>
                        </div>
                      </div>)
                  ))}
              </div>
              <div>
                <center>
                  <div class="loader-spinner">
                    {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                    }
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className="App">
            <div className="pagination">
              {this.state.postData}
              <ReactPagenate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
            </div>
          </div>
        </div>
      )
    }
  }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => ({
  photoGallery: state.photoGallery
});
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, { photogalleryData, downloadImage })(UserPhotoGallery)