import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createTemplate,
  editTemplate,
} from "../../Store/template/addTemplateAction";
import { viewTemplate } from "../../Store/template/viewTemplateoneAction";
import swal from "sweetalert";
import { Circles } from "react-loader-spinner";
import handleSwalAlert from "../SwalAlert";
import "./TemplateTwo/Templatetwo.css";
import HttpService from "../../Services/HttpService";
import SimpleReactValidator from "simple-react-validator";
import { uploadMediaType } from "./UploadTypeResponce";
import UploadMedia from "./UploadMedia";

class TemplateFormTwo extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      formValues: [{ name: "", email: "", menu_id: "", index: 0, show: 0 }],
      editFormValues: [],

      imageValues: [
        { imageUrl: "", bannerPreview: "", templatephotogallery_id: "", index: 0, show: 0 },
      ],
      editImageValues: [],

      bannerImageValues: [
        {
          imageName: "",
          imageUrl: "",
          bannerPreview: "",
          banner_id: "",
          media: "",
          index: 0,
          show: 0,
          upload: 'Images',
          videoUrl: '',
          urlStatus: null
        },
      ],
      bannerVideoValues: [
        {
          videoImageName: "",
          videoImageUrl: "",
          videoBannerPreview: "",
          videoBanner_id: "",
          media: "",
          index: 0, show: 0
        },
      ],
      EditBannerImageValues: [],

      descriptionValues: [
        { imageUrl: "", description: "", templateresource_id: "", index: 0, show: 0 },
      ],
      editDescriptionValues: [],

      cardValues: [
        {
          imageUrl: "",
          description: "",
          templateresource_id: "",
          bannerPreview: "",
          index: 0, show: 0
        },
      ],
      editCardValues: [],

      footerValues: [{ label: "", labelValue: "", footer_id: "", index: 0, show: 0 }],
      editFooterValues: [],
      templateLogo: "",
      previewLogo: "",
      menuValues: [
        { menuName: "About us", menuId: "1" },
        { menuName: "Contact Us", menuId: "2" },
        { menuName: "Settings", menuId: "3" },
      ],
      logoName: "",
      menuItem: "",
      postBannersData: [],
      postCardsData: [],
      postImagesData: [],
      postResourcesData: [],
      spinner: false,
      cardstitle: "",
      secondTitle: "",
      gallerytitle: "",
      resourcetitle: "",
      editTemplate: {},
      removedMenuIds: [],
      removedBannerIds: [],
      bannerPreview: [],
      removedFooterIds: [],
      removedTitleOneIds: [],
      removedTitleSecondIds: [],
      titleOneImagePreview: [],
      removedTitleThirdIds: [],
      galleryPreview: [],
      editResourceIds: [],
      orinalResponce: {},
      showHideConent: [],
      menuHasChanged: 0,
      footerHasChanged: 0,
      bannersHasChanged: 0,
      descriptionHasChanged: 0,
      galleryHasChanged: 0,
      titleOneHasChanged: 0,
      banner_upload: '0',
      showButtonText: 'Show Menu',
      hideButtonText: 'Hide Menu',
      urlName: '',
      urlValue: '',
      uploaded_banner_id: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this)
    this.ImagehandleSubmit = this.ImagehandleSubmit.bind(this)


  }
  /* goto chapters list */
  onCancel() {
    this.props.history.push('/dashboard/Chapters/ChaptersList');

  }
  /* to view the created template*/
  onViewTemplate() {
    var newWindowTemplate = window.open(`/${this.props.location.state.detail.view_temp_chapter_name}`)
    localStorage.setItem("chpaterdata", JSON.stringify(this.props.location.state.detail))
  }

  /* To make the api call initial page load */
  componentDidMount() {

    if (this.props.location.state.detail.temp_status === 0) {
      this.setState({
        spinner: true
      })
      var data = {
        chapter_id: this.props.location.state.detail.chapter_id,
        template_id: this.props.location.state.detail.template.templateid
      }

      HttpService.viewTemplate(data)
        .then(response => {

          this.setState({
            spinner: false,
            editTemplate: response.data,
            secondTitle: response.data.template.activities[0]?.title,
            resourcetitle: response.data.template.resources[0]?.title,
            gallerytitle: response.data.template.gallery[0]?.title
          })




          this.setState({
            previewLogo: this.state.editTemplate.template.template_logo,
            bannerImageValues: this.state.editTemplate.template?.banners.map((item, index) => {
              return {
                urlStatus: item.url_status,
                imageName: item.banner_name,
                imageUrl: item.banner_image,
                bannerPreview: item.banner_image,
                banner_id: item.banner_id,
                show: item.active_status,
                videoUrl: item.banner_image.includes('s3.amazonaws.com') ? null : item.banner_image,
                upload: item.url_status === 1 ? 'Url' : item.banner_image.includes('.mp4') ? 'Video' : 'Images'
              }
            }),
            descriptionValues: this.state.editTemplate.template.activities.map((item, index) => {
              return { secondTitle: item.title, description: item.description, templateactivities_id: item.templateactivities_id, show: item.active_status }
            }),
            imageValues: this.state.editTemplate.template.gallery.map((item, index) => {
              return { imageName: item.image_name, imageUrl: '', bannerPreview: item.photo_name, templatephotogallery_id: item.templatephotogallery_id, show: item.active_status }
            }),

            cardValues: this.state.editTemplate.template.resources.map((item, index) => {
              return { description: item.description, imageUrl: '', bannerPreview: item.files, templateresource_id: item.templateresource_id, show: item.active_status }
            }),

            footerValues: this.state.editTemplate.template.footer.map((item, index) => {
              return { label: item.footer_description, labelValue: item.footer_name, footer_id: item.footer_id, show: item.active_status }
            }),
            formValues: this.state.editTemplate.template.menus.map((item, index) => {
              return { name: item.menu_title, email: item.menu_title, menu_id: item.menu_id, show: item.active_status }
            }),
          })
        })
    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.setState({
      spinner: false
    })

    if (nextProps.addtemplate.template_res.status != 200) {
      this.setState({
        spinner: false,
      });
      swal({
        icon: "warning",
        text: nextProps.addtemplate.template_res.error,
      });
    } else {
      this.setState({
        spinner: false,
      });
      var successAlert = {
        message: nextProps.addtemplate.template_res.message,
        alertType: "success",
        closeOnEsc: false,
        dangerMode: true,
        pathname: "/dashboard/Chapters/ChaptersList",
        propsData: this.props,
        goToList: true,
        closeOnClickOutside: false,
      };
      handleSwalAlert(successAlert);
    }
  }
  /* handle the menu inputs */

  handleChange(i, e, from) {
    if (from === "create") {
      let formValues = this.state.formValues;
      formValues[i][e.target.name] = e.target.value;
      this.setState({ formValues, menuHasChanged: 1 });
    } else {
      let editFormValues = this.state.editFormValues;
      editFormValues[i][e.target.name] = e.target.value;
      this.setState({ editFormValues });
    }
  }

  /* handle the cards details */
  cardHandleChange(i, e, uploadType) {
    let cardValues = this.state.cardValues;

    var cardData = UploadMedia(i, e, uploadType)
    if (cardData?.name && cardData.name != null) {

      this.state.postResourcesData[i] = cardData;
      this.state.titleOneImagePreview[i] = e.target.value;
      cardValues[i][e.target.name] = URL.createObjectURL(cardData);
      cardValues[i]["imageUrl"] = cardData;
      this.setState({ titleOneHasChanged: 1 });

    }
    else {
      cardValues[i][e.target.name] = e.target.value;
      this.setState({ cardValues, titleOneHasChanged: 1 });
    }
  }
  /* upload the banner images */

  bannerImageHandleChange(i, e, uploadType) {
    var data = UploadMedia(i, e, uploadType)
    let bannerImageValues = this.state.bannerImageValues;

    if (data?.name && data.name != null) {
      this.state.postBannersData[i] = data;
      //this.state.bannerPreview[i] = e.target.value;
      bannerImageValues[i][e.target.name] = URL.createObjectURL(
        data
      );
      bannerImageValues[i]["imageUrl"] = data;
      bannerImageValues[i]["urlStatus"] = 0;

      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
    else {
      bannerImageValues[i][e.target.name] = data;
      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
  }
  /* upload the template logo*/
  templateLogoHandleChange(i, e, uploadType) {
    var logoData = UploadMedia(i, e, uploadType)

    this.state.logoName = logoData;
    {
      logoData && this.setState({
        templateLogo: e.target.file,
        previewLogo: URL.createObjectURL(logoData),
      });
    }
  }
  /* upload the gallery images*/
  imageHandleChange(i, e, uploadType) {
    let imageValues = this.state.imageValues;

    var galleryData = UploadMedia(i, e, uploadType)

    if(galleryData?.name && galleryData.name !=null){
      this.state.postImagesData[i] = galleryData;
      this.state.galleryPreview[i] = e.target.value;
      imageValues[i][e.target.name] = URL.createObjectURL(galleryData);
      imageValues[i]["imageUrl"] = galleryData;
      this.setState({ galleryHasChanged: 1 });
    }
    else{
      imageValues[i][e.target.name] = e.target.value;
      this.setState({ galleryHasChanged: 1 });
    }

   
  }
  /* add multiple menus*/
  addFormFields() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { name: "", email: "", menu_id: "", index: "", show: 0 },
      ],
    });
  }
  /* add multiple descriptions*/
  addDescriptionFields() {
    this.setState({
      descriptionValues: [
        ...this.state.descriptionValues,
        { description: "", imageUrl: "", templateactivities_id: "", show: 0 },
      ],
    });
  }
  /* add multiple gallery images*/
  addImageFields() {
    this.setState({
      imageValues: [
        ...this.state.imageValues,
        { imageUrl: "", bannerPreview: "", templatephotogallery_id: "", show: 0 },
      ],
    });
  }
  /* add multiple cards*/
  addCardFields() {
    this.setState({
      cardValues: [
        ...this.state.cardValues,
        {
          imageUrl: "",
          description: "",
          templateresource_id: "",
          bannerPreview: "",
          index: "", show: 0
        },
      ],
    });
  }
  /* add multiple banners */
  addBannersFields() {
    this.setState({
      bannerImageValues: [
        ...this.state.bannerImageValues,
        { imageName: "", imageUrl: "", bannerPreview: "", banner_id: "", show: 0, upload: 'Images' },
      ],
    });
  }
  /* add multiple footers*/
  addFooterFields() {
    this.setState({
      footerValues: [
        ...this.state.footerValues,
        { label: "", labelValue: "", footer_id: "", show: 0 },
      ],
    });
  }

  /* to show and hide the fields in view template*/
  showHideFields(i, e, from, typeOfField) {

    switch (typeOfField) {
      case 'menus':
        if (from === "create") {
          let formValues = this.state.formValues;
          formValues[i][e.target.name] === 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        else {
          let editFormValues = this.state.editFormValues;
          editFormValues.splice(i, 1);
          this.setState({ editFormValues });
        }
        break;

      case 'banners':
        if (from === "create") {
          let bannerImageValues = this.state.bannerImageValues;
          bannerImageValues[i][e.target.name] === 0 ? bannerImageValues[i][e.target.name] = 1 :
            bannerImageValues[i][e.target.name] = 0;

          this.setState({ bannerImageValues, bannersHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;

      case 'titleOne':
        if (from === "create") {
          let cardValues = this.state.cardValues;
          cardValues[i][e.target.name] === 0 ? cardValues[i][e.target.name] = 1 :
            cardValues[i][e.target.name] = 0;

          this.setState({ cardValues, titleOneHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;

      case 'titleTwo':
        if (from === "create") {
          let descriptionValues = this.state.descriptionValues;
          descriptionValues[i][e.target.name] === 0 ? descriptionValues[i][e.target.name] = 1 :
            descriptionValues[i][e.target.name] = 0;

          this.setState({ descriptionValues, descriptionHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;

      case 'titleThree':
        if (from === "create") {
          let imageValues = this.state.imageValues;
          imageValues[i][e.target.name] === 0 ? imageValues[i][e.target.name] = 1 :
            imageValues[i][e.target.name] = 0;

          this.setState({ imageValues, galleryHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;

      case 'footer':
        if (from === "create") {
          let footerValues = this.state.footerValues;
          footerValues[i][e.target.name] === 0 ? footerValues[i][e.target.name] = 1 :
            footerValues[i][e.target.name] = 0;

          this.setState({ footerValues, footerHasChanged: 1 });
        }
        else {
          let editFooterValues = this.state.editFooterValues;
          editFooterValues.splice(i, 1);
          this.setState({ editFooterValues });
        }
      default:
        break;
    }
  }
  /* remove the menu fields */
  removeFormFields(i, element, from) {
    if (from === "create") {
      let formValues = this.state.formValues;
      formValues.splice(i, 1);
      this.setState({ formValues });
      this.setState((previousState) => ({
        removedMenuIds: [...previousState.removedMenuIds, element.menu_id],
      }));
    } else {
      let editFormValues = this.state.editFormValues;
      editFormValues.splice(i, 1);
      this.setState({ editFormValues });
    }
  }
  /* remove the footer fields*/
  removeFooterFields(i, element, from) {
    if (from === "create") {
      let footerValues = this.state.footerValues;
      footerValues.splice(i, 1);
      this.setState({ footerValues });
      this.setState((previousState) => ({
        removedFooterIds: [
          ...previousState.removedFooterIds,
          element.footer_id,
        ],
      }));
    } else {
      let editFooterValues = this.state.editFooterValues;
      editFooterValues.splice(i, 1);
      this.setState({ editFooterValues });
    }
  }
  /* remove the card fields */
  removeImageFields(i, element, from) {
    if (from === "create") {
      let cardValues = this.state.cardValues;
      cardValues.splice(i, 1);
      this.setState({ cardValues });

      this.setState((previousState) => ({
        removedTitleOneIds: [
          ...previousState.removedTitleOneIds,
          element.templateresource_id,
        ],
      }));
    } else {
      let editCardValues = this.state.editCardValues;
      editCardValues.splice(i, 1);
      this.setState({ editCardValues });
    }
  }
  /* remove the banner fields*/
  removeBannerFields(i, element, from) {
    if (from === "edit") {
      let EditBannerImageValues = this.state.EditBannerImageValues;
      EditBannerImageValues.splice(i, 1);
      this.setState({ EditBannerImageValues });
    } else {
      let bannerImageValues = this.state.bannerImageValues;
      bannerImageValues.splice(i, 1);
      this.setState({ bannerImageValues });
      this.setState((previousState) => ({
        removedBannerIds: [
          ...previousState.removedBannerIds,
          element.banner_id,
        ],
      }));
    }
  }
  /* remove the description fields*/
  removeDescriptionFields(i, element, from) {
    if (from === "create") {
      let descriptionValues = this.state.descriptionValues;
      descriptionValues.splice(i, 1);
      this.setState({ descriptionValues });
      this.setState((previousState) => ({
        removedTitleSecondIds: [
          ...previousState.removedTitleSecondIds,
          element.templateactivities_id,
        ],
      }));
    } else {
      let editDescriptionValues = this.state.editDescriptionValues;
      editDescriptionValues.splice(i, 1);
      this.setState({ editDescriptionValues });
    }
  }
  /* remove the image fields*/
  removeimageUrlFields(i, element, from) {
    if (from === "create") {
      let imageValues = this.state.imageValues;
      imageValues.splice(i, 1);
      this.setState({ imageValues });

      this.setState((previousState) => ({
        removedTitleThirdIds: [
          ...previousState.removedTitleThirdIds,
          element.templatephotogallery_id,
        ],
      }));
    } else {
      let editImageValues = this.state.editImageValues;
      editImageValues.splice(i, 1);
      this.setState({ editImageValues });
    }
  }
  /* handle to uplaod the banner images or videos */
  mediaHandleChange = (e, type) => {
    if (type === 'url') {
      this.setState({
        banner_upload: '2',

      });
    }
    if (type === "video") {
      if (this.state.bannerPreview != null) {
        this.setState({
          banner_upload: '1',
          // bannerImageValues:[{bannerPreview:''}]
        });
      } else {
        this.setState({
          banner_upload: '0',
          bannerImageValues: [{ bannerPreview: "" }],
        });
      }
    }
    if (type === 'image') {
     
      if (!this.state.editTemplate.template?.banners[0]?.url_status == '1' &&
        !this.state.editTemplate.template?.banners[0]?.banner_image.includes('.mp4')) {
        this.setState({
          banner_upload: '0',
          // bannerImageValues: this.state.editTemplate.template?.banners.map(
          //   (item, index) => {
          //     return {
          //       imageName: item.banner_name,
          //       imageUrl: "",
          //       bannerPreview: item.banner_image,
          //       banner_id: this.state.uploaded_banner_id,
          //     };
          //   }
          // ),
        });
      }
      else {
        this.setState({
          banner_upload: '0',
          uploaded_banner_id: this.state.editTemplate.template?.banners[0].banner_id
          // bannerImageValues: this.state.editTemplate.template?.banners.map(
          //   (item, index) => {
          //     return {
          //       imageName: '',
          //       imageUrl: "",
          //       bannerPreview: '',
          //       banner_id: this.state.uploaded_banner_id,
          //     };
          //   }
          // ),
        });
      }

    }
  };

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  ImagehandleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.imageValues));
  }
  /* handle the descriptioin fields*/
  OnDescriptionhandleChange(i, e) {
    let descriptionValues = this.state.descriptionValues;

    if (e.target.name === "imageUrl") {
      this.state.postCardsData[i] = e.target.files[0];
      descriptionValues[i][e.target.name] = e.target.value;
      this.setState({ descriptionHasChanged: 1 });
    } else {
      descriptionValues[i][e.target.name] = e.target.value;
      this.setState({ descriptionHasChanged: 1 });
    }
    this.setState({ descriptionValues });
  }
  onFooterhandleChange(i, e) {
    this.setState({
      footerHasChanged: 1,
    });

    let footerValues = this.state.footerValues;
    footerValues[i][e.target.name] = e.target.value;
    this.setState({ footerValues });
  }
  /* goto chapters*/
  onChapters() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* api call to create the template*/
  createTemplate() {
  
    this.setState({
      spinner: true,
    });

    if (!this.validator.allValid()) {
      this.setState({
        spinner: false,
      });
     
      this.validator.showMessages();
    } else {
      var data = new FormData();
      data.append("template_id", this.props.location.state.detail.template.templateid);
      data.append("chapter_id", this.props.location.state.detail.chapter_id);
      data.append("logo", this.state.logoName);
      data.append("active_status", this.props.location.state.detail.active_status);

      if (this.state.menuHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.formValues.map((item, index) => {
              if (item.menu_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("menu_id[" + index + "]", item.menu_id);
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.formValues.map((item, index) => {
              data.append("menu[" + index + "]", item.name);
              data.append("menu_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      if (this.state.bannersHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              if (item.banner_id === "") {

                data.append("edit_flag[" + index + "]", "create");
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);
                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("banner_id[" + index + "]", item.banner_id);
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);

                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              }
            })
          );



        } else {

          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              data.append("banner_name[" + index + "]", item.imageName);
              item.upload === 'Url' ?
                data.append("video_url[" + index + "]", item.videoUrl) :
                data.append("banner_image[" + index + "]", item.imageUrl);
              data.append("banner_active_status[" + index + "]", item.show);
              item.upload === 'Url' ?
                data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
            }))
        }
      }

      if (this.state.footerHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              if (item.footer_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("footer_id[" + index + "]", item.footer_id);
                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              data.append("footer_name[" + index + "]", item.labelValue);
              data.append("footer_description[" + index + "]", item.label);
              data.append("footer_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      if (this.state.descriptionHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.descriptionValues.map((item, index) => {
              if (item.templateactivities_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("activities_description[" + index + "]", item.description);
                data.append("activities_title", this.state.secondTitle);
                data.append("activities_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("activities_title", this.state.secondTitle);
                data.append("templateactivities_id[" + index + "]", item.templateactivities_id);
                data.append("activities_description[" + index + "]", item.description);
                data.append("activities_active_status[" + index + "]", item.show);

                if (
                  this.state.editTemplate.template.activities[0]?.title !=
                  this.state.secondTitle
                ) {
                  data.append("activities_title", this.state.secondTitle);
                  JSON.stringify(
                    this.state.editTemplate.template.activities.map(
                      (item, index) => {
                        data.append("editactivities[" + index + "]", item.templateactivities_id);
                        data.append("activities_active_status[" + index + "]", item.show);
                      }
                    )
                  );
                }
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.descriptionValues.map((item, index) => {
              if (item.description === "") {
              } else {
                data.append("activities_description[" + index + "]", item.description);
                data.append("activities_active_status[" + index + "]", item.show);

              }
            })
          );
          data.append("activities_title", this.state.secondTitle);
        }
      }
      if (this.state.titleOneHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.cardValues.map((item, index) => {
              if (item.templateresource_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("resource_description[" + index + "]", item.description);
                data.append("resource_title", this.state.resourcetitle);
                data.append("resource_files[" + index + "]", item.imageUrl);
                data.append("resource_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("templateresource_id[" + index + "]", item.templateresource_id);
                data.append("resource_description[" + index + "]", item.description);
                data.append("resource_title", this.state.resourcetitle);
                data.append("resource_files[" + index + "]", item.imageUrl);
                data.append("resource_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.cardValues.map((item, index) => {
              data.append(
                "resource_description[" + index + "]",
                item.description);
              data.append("resource_files[" + index + "]", item.imageUrl);
              data.append("resource_active_status[" + index + "]", item.show);
            })
          );
          data.append("resource_title", this.state.resourcetitle);
        }
      }

      if (this.state.galleryHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.imageValues.map((item, index) => {
              if (item.templatephotogallery_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("gallery_images[" + index + "]", item.imageUrl);
                data.append("gallery_title[" + index + "]", this.state.gallerytitle);
                data.append("gallery_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("templatephotogallery_id[" + index + "]", item.templatephotogallery_id);
                data.append("gallery_images[" + index + "]", item.imageUrl);
                data.append("gallery_title[" + index + "]", this.state.gallerytitle);
                data.append("gallery_active_status[" + index + "]", item.show);

              }
            })
          );
        } else {
          data.append("gallery_title", this.state.gallerytitle);
          JSON.stringify(
            this.state.postImagesData.map((item, index) => {
              data.append("gallery_images[" + index + "]", item)
            })
          );
          JSON.stringify(
            this.state.imageValues.map((item, index) => {
              data.append("gallery_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      JSON.stringify(
        this.state.removedBannerIds.map((item, index) => {
          data.append("deletebanners[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedTitleThirdIds.map((item, index) => {
          data.append("deletegallery[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedTitleSecondIds.map((item, index) => {
          data.append("deleteactivities[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedTitleOneIds.map((item, index) => {
          data.append("deleteresources[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedMenuIds.map((item, index) => {
          data.append("deletemenu[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedFooterIds.map((item, index) => {
          data.append("deletefooter[" + index + "]", item);
        })
      );
      if (this.state.editTemplate.success === true) {
        this.props.editTemplate(data);
      } else {
        this.props.createTemplate(data);
      }
      for (const value of data.values()) {
        console.log("form data values:-", value);
      }
      for (const value of data.keys()) {
        console.log("form data keys:-", value);
      }
    }
  }
  /* handle the title twp fields */
  onTitleChange(e, title) {
    if (title === "secondTitle") {
      this.setState({
        secondTitle: e.currentTarget.value,
        descriptionHasChanged: 1,
      });
    }
  }
  /* handle the gallery fields*/
  onGalleryeChange(e, title) {
    if (title === "gallerytitle") {
      this.setState({
        gallerytitle: e.currentTarget.value,
        galleryHasChanged: 1,
      });
    }
  }
  /* handle the resources fileds*/
  onresourceChange(e, title) {
    if (title === "resourcetitle") {
      this.setState({
        resourcetitle: e.currentTarget.value,
        titleOneHasChanged: 1,
      });
    }
  }
  /* confirmation pop up and remove fields */
  confirmationForRemove(i, element, from, type) {
    let typeOfTitle = ''
    switch (type) {
      case 'menu':
        typeOfTitle = "Menu"
        break;
      case 'banners':
        typeOfTitle = "Banner"
        break;
      case 'images':
        typeOfTitle = "Images"
        break;
      case 'description':
        typeOfTitle = 'Description'
        break;
      case 'gallery':
        typeOfTitle = "Gallery"
        break;
      case 'footer':
        typeOfTitle = 'Footer'
        break;

      default:
        break;
    }

    swal({
      title: "Delete " + typeOfTitle,
      text: "Are you sure you want to delete this " + typeOfTitle + " item?",
      buttons: ["No, Cancel", "Yes, Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          Spinner: true
        })
        switch (typeOfTitle) {
          case 'Menu':

            this.removeFormFields(i, element, from)
            break;
          case 'Banner':
            this.removeBannerFields(i, element, from)
            break;
          case 'Images':
            this.removeImageFields(i, element, from)
            break;
          case 'Description':
            this.removeDescriptionFields(i, element, from)
            break;
          case 'Gallery':
            this.removeimageUrlFields(i, element, from)
            break;
          case 'Footer':
            this.removeFooterFields(i, element, from)
            break;
          default:
            break;
        }
      }
    });
  }
  /* render() function is used to update the UI. */
  render() {
    return (
      <div className="container">
        <div className="row mt-4 mb-5">
          <div className="col-md-12">
            <div className="horizontal-container">
              <label
                className="label-discount"
                onClick={() => this.onChapters()}
              >
                {this.props.location.state.detail.chapter_name}
              </label>
              <i
                className="fa fa-chevron-right right-arrow"
                aria-hidden="true"
              ></i>
              <label className="label-discount">Create Template-2</label>
            </div>
          </div>
        </div>
        <div className="box">
          {/* Template logo creation */}

          <div className="row">
            <div className="viewtemp-mbl">
              {this.props.location.state.detail.temp_status === 0 && (
                <button
                  className="viewTemplateButton"
                  onClick={() => this.onViewTemplate()}
                >
                  View Template
                </button>
              )}

              <button
                type="cancel"
                className="closeTemplateButton"
                onClick={() => this.onCancel()}
              >
                Close
              </button>
            </div>
            <div className="col-md-4">
              <label className="lehead">
                Add Template Logo<span>*</span>
              </label>
              <div>
                {this.state.previewLogo != "" && (
                  <img
                    className="preview-image"
                    src={this.state.previewLogo}
                  ></img>
                )}

                <div id="upload-right">
                  <div className="profile-upload rect">
                    <label className="custom-file-upload rectangle">
                      <input
                        type="file"
                        name="bannerPreview"
                        className="form-control"
                        multiple
                        onChange={(e) =>
                          this.templateLogoHandleChange(0, e, "images")
                        }
                      />
                      <i className="fas fa-plus"></i>
                    </label>
                    <label className="up-lbl">Upload an image or PDF</label>
                    <br />
                    <span className="up-span">(5MB Limit)</span>
                  </div>
                </div>
              </div>

              <button
                style={{ display: "none" }}
                className="btn btn-info btn-success mt-4"
              >
                Upload
              </button>
            </div>
            <span className="text-danger">
              {this.validator.message(
                "template_logo",
                this.state.previewLogo,
                "required"
              )}
            </span>
          </div>
          {/* Tempalte menu creation */}
          <hr className="divider-line" />

          {/* Template menu */}

          <label className="lehead">
            Template Menu<span>*</span>
          </label>

          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div>
                {this.state.formValues.map((element, index, arr) => (
                  <div className="sec-borser" key={index}>
                    <div className="hider">
                      {arr.length - 1 === index ? null : (
                        <div>
                          <div className="addadency-sec d-flex">
                            <p className="lehead">Hide In Template View</p>
                            <div>
                              <label class="switchtog ml-2">
                                <input name="show" type="checkbox" checked={element.show === 0 ? false : true} onChange={(e) => this.showHideFields(index, e, "create", 'menus')} />
                                <span class="slidered"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {arr.length - 1 === index ? null : (
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                          this.confirmationForRemove(index, element, "create", 'menu')
                          // this.removeFormFields(index, element, "create")
                        }></i>
                      )}
                    </div>
                    <label className="lehead mr-3">Menu Title</label>

                    <div className="dflex">
                      <div className="col-md-5" style={{ marginLeft: '-12px' }}>
                        <input
                          className="form-template"
                          type="text"
                          name="name"
                          value={element.name || ""}
                          onChange={(e) =>
                            this.handleChange(index, e, "create")
                          }
                        />
                      </div>

                    </div>

                  </div>
                ))}
              </div>
            </div>
            <span className="text-danger">
              {this.validator.message("template_menu", this.state.formValues[0]?.name, "required")}
            </span>
            <div className="clearfix"></div>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button className="template-add-button" type="button" onClick={() => this.addFormFields()}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>

          <hr className="divider-line" />

          <label className="lehead ml-2">
            Template Banners<span>*</span>
          </label>
          <div style={{ margin: "5px" }}>
            <label style={{ marginLeft: "5px" }} class="lehead">
              Upload Banners
            </label>


          </div>
          {this.state.bannerImageValues?.map((element, index, arr) => (

            <div className="sec-borser" key={index}>
              <div className="hider-banner">

                {arr.length - 1 === index ? null : (
                  <div className="addadency-sec d-flex">
                    <label data-tooltip="To show or hide this fields in the template view."
                      data-tooltip-location="right" className="lehead">Hide In Template View<i style={{ color: "#cecfd0", marginLeft: "10px" }} class="fa fa-info-circle" aria-hidden="true"></i></label>
                    <div>
                      <label class="switchtog ml-2">
                        <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'banners')} />
                        <span class="slidered"></span>
                      </label>
                    </div>
                  </div>

                )}
                {arr.length - 1 === index ? null : (
                  <i class="fa fa-trash temp-del ml-2" aria-hidden="true"

                    onClick={() =>
                      this.confirmationForRemove(index, element, "create", 'banners')
                      //this.removeBannerFields(index, element, "create")

                    }

                  ></i>
                )}
              </div>
             
              <div className="dflex">
                <div >
                  <label class="lehead">Name</label>
                  <input

                    type="text"
                    className="form-control"
                    name="imageName"
                    value={element.imageName || ""}
                    onChange={(e) => this.bannerImageHandleChange(index, e, 'images')}
                  />
                </div>
                <div className="ml-2">
                  <label class="lehead mt-2">Upload Type</label>
                  <div>
                    <select
                      name='upload'
                      value={element.upload || ""}
                      onChange={(e) => this.bannerImageHandleChange(index, e, 'typeOfUpload')}
                      style={{ width: '120px', height: '45px', padding: '5px' }} >
                      {/* <option value="">Select Type of Upload</option> */}

                      {uploadMediaType?.map((e) => {
                        return <option value={e.type}>{e.name}</option>;
                      })}
                    </select>

                    {/* <span className="text-danger">{this.validator.message("template", this.state.selectedUploadType, "required")}</span> */}
                  </div>
                </div>
                <div className="m-2">
                  <div className="row">
                   

                    {element.upload === 'Images' && <div className="d-flex">
                      {element.bannerPreview && !element.bannerPreview.includes('.mp4') &&
                        <div>

                          <label class="lehead">Image</label>
                          <div className="clearfix"></div>
                          <img
                            name="bannerPreview"
                            value={element.bannerPreview || ""}
                            className="preview-image"
                            src={element.bannerPreview}
                          ></img>

                        </div>
                      }
                      <div>
                        <label class="lehead">
                          Upload a Image
                          <span className="mr-1" style={{ fontSize: "11px" }}>

                            (Upload dimentions should be 960 X 360 and below 5 mb)
                          </span>
                        </label>
                        <div className="profile-upload rect">
                          <label className="custom-file-upload rectangle">
                            <img src="" />
                            <input
                              type="file"
                              name="bannerPreview"
                              className="form-control"
                              multiple
                              onChange={(e) =>
                                this.bannerImageHandleChange(index, e, 'images')
                              }
                            />
                            <i className="fas fa-plus"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    }
                    {element.upload === 'Video' && <div className="d-flex">
                    
                      {element.upload === 'Video' && element.bannerPreview !== '' && element.urlStatus == '0' && ((!element.bannerPreview.includes('.jfif')) && (!element.bannerPreview.includes('.png')) && (!element.bannerPreview.includes('.jpg') && !element.bannerPreview.includes('.jpeg'))) &&
                        <div>

                          <label class="lehead">Video</label>
                          <div className="clearfix"></div>
                          <video className="preview-image" controls>
                            <source
                              src={element.bannerPreview || ""}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      }

                      <div>
                        <label class="lehead">
                          Upload a Video
                          <span className="mr-1" style={{ fontSize: "11px" }}>

                            (Upload size should be less than 10mb)
                          </span>
                        </label>
                        <div className="profile-upload rect">
                          <label className="custom-file-upload rectangle">
                            <img src="" />
                            <input
                              type="file"
                              name="bannerPreview"
                              className="form-control"
                              multiple
                              onChange={(e) =>
                                this.bannerImageHandleChange(index, e, 'images')
                              }
                            />
                            <i className="fas fa-plus"></i>
                          </label>
                        </div>
                      </div>
                    </div>}
                    {element.upload === 'Url' &&
                      <div className="m-0">
                        <label data-tooltip="Upload url content should be less than 5 mb."
                          data-tooltip-location="right" className="lehead">Upload A URL
                          <i style={{ color: "#cecfd0", marginLeft: "10px" }}
                            class="fa fa-info-circle" aria-hidden="true">
                          </i>
                        </label>
                        <div className="profile-upload rect">

                          <input
                            type="text"
                            name="videoUrl"
                            className="form-control"
                            value={element.videoUrl}
                            onChange={(e) =>
                              this.bannerImageHandleChange(index, e, 'url')
                            }
                          />


                        </div>
                      </div>

                    }

                  </div>

                </div>

              </div>
            </div>

          ))}
          <span className="text-danger">
            {this.validator.message(
              "template_banner_name",
              this.state.bannerImageValues[0]?.imageName,
              "required"
            )}
          </span>

          <span className="text-danger">
            {this.validator.message(
              "template_banner_upload",
              this.state.bannerImageValues[0]?.upload,
              "required"
            )}
          </span>

          <div className="row">
            <div className="col-md-3">
              <div className="m-2">

                <button
                  className="template-add-button"
                  type="button"
                  onClick={() => this.addBannersFields()}
                >
                  Add
                </button>
              </div>

              <div className="m-2">

                <button
                  style={{ display: "none" }}
                  className="template-upload-button"
                  onClick={(e) => this.ImagehandleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <hr className="divider-line" />

          <div className="row">
            <div className="col-md-4">
              <label className="lehead"> Title 1<span>*</span></label>
              <input type="text" className="form-template" name="secTitle" value={this.state.resourcetitle} onChange={(e) => this.onresourceChange(e, "resourcetitle")} />
            </div>
          </div>
          <div>
            <div>
              {this.state.cardValues.map((element, index, arr) => (

                <div className="sec-borser">
                  <div className="hider">

                    {arr.length - 1 === index ? null : (
                      <div className="d-flex">
                        <p className="lehead-para">Hide In Template View</p>

                        <label class="switchtog ml-2">
                          <input name="show" type="checkbox" checked={element.show === 0 ? false : true} onChange={(e) => this.showHideFields(index, e, "create", 'titleOne')} />
                          <span class="slidered"></span>
                        </label>
                      </div>
                    )}
                    {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" onClick={() =>
                        this.confirmationForRemove(index, element, "create", 'images')
                        // this.removeImageFields(index, element, "create")
                      }></i>
                    )}
                    <hr />
                  </div>
                  <div className="row">
                    <div className="col-md-6 p-0">
                      <div className="dflex ml-2">
                        {element.bannerPreview && (
                          <div className="m-0">
                            <label class="lehead">Image</label>
                            <div className="clearfix"></div>
                            <img
                              name="titleOneImagePreview"
                              value={element.bannerPreview || ""}
                              className="preview-image"
                              src={element.bannerPreview}
                            ></img>
                          </div>
                        )}

                        <div className="m-0">
                          <label class="lehead">
                            Upload
                            <span style={{ fontSize: "10px" }}>

                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.cardHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="lehead">Description</label>
                      <textarea
                        type="text"
                        className="form-template"
                        rows="4"
                        name="description"
                        value={element.description}
                        onChange={(e) => this.cardHandleChange(index, e, 'images')}
                      />
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <div className="clearfix"></div>
            <span className="text-danger">
              {this.validator.message(
                "Title",
                this.state.resourcetitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Image",
                this.state.cardValues[0]?.bannerPreview,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Description",
                this.state.cardValues[0]?.description,
                "required"
              )}
            </span>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button
                    className="template-add-button "
                    type="button"
                    onClick={() => this.addCardFields()}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="divider-line" />
          {/* Template images  */}

          <div className="row">
            <div className="col-md-4">
              <label className="lehead">
                Title 2 <span>*</span>
              </label>

              <input
                type="text"
                className="form-template"
                name="secTitle"
                value={this.state.secondTitle}
                onChange={(e) => this.onTitleChange(e, "secondTitle")}
              />
            </div>
            {this.state.descriptionValues.map((element, index, arr) => (
              <div className="sec-borser">
                <div className="hider">

                  {arr.length - 1 === index ? null : (
                    <div>
                      <div className="addadency-sec  d-flex">
                        <p className="lehead">Hide In Template View</p>
                        <div>
                          <label class="switchtog ml-2">
                            <input name="show" type="checkbox" checked={element.show === 0 ? false : true} onChange={(e) => this.showHideFields(index, e, "create", 'titleTwo')} />
                            <span class="slidered"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {arr.length - 1 === index ? null : (
                    <div>
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                        onClick={() =>
                          this.confirmationForRemove(index, element, "create", 'description')
                          // this.removeDescriptionFields(index, element, "create")
                        }></i>
                    </div>
                  )}
                </div>
                <div key={index}>

                  <div className="dflex">
                    <div className="col-4" style={{ marginLeft: '-10px' }}>
                      <label class="lehead">Description</label>
                      <textarea
                        className="form-template"
                        type="text"
                        row="2"
                        name="description"
                        value={element.description || ""}
                        onChange={(e) =>
                          this.OnDescriptionhandleChange(index, e)
                        }
                      />
                    </div>

                  </div>

                </div>
              </div>
            ))}

          </div>
          <span className="text-danger">
            {this.validator.message(
              "Title",
              this.state.secondTitle,
              "required"
            )}
          </span>
          <span className="text-danger">
            {this.validator.message(
              "Description",
              this.state.descriptionValues[0]?.description,
              "required"
            )}
          </span>
          <div className="row">
            <div className="col-md-3 dflex ">
              <div className="m-2">

                <button
                  className="template-add-button"
                  type="button"
                  onClick={() => this.addDescriptionFields()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <hr className="divider-line" />

          <div>
            <div >
              <div className="col-4">
                <label className="lehead">
                  Title 3<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-template"
                  name="secTitle"
                  value={this.state.gallerytitle}
                  onChange={(e) => this.onGalleryeChange(e, "gallerytitle")}
                />
              </div>

              <form onSubmit={this.handleSubmit}>
                {this.state.imageValues.map((element, index, arr) => (
                  <div className="sec-borser">
                    <div className="hider">
                      {arr.length - 1 === index ? null : (
                        <div className="">
                          <div className="addadency-sec d-flex">
                            <p className="lehead">Hide In Template View</p>
                            <div>
                              <label class="switchtog ml-2">
                                <input name="show" type="checkbox" checked={element.show === 0 ? false : true} onChange={(e) => this.showHideFields(index, e, "create", 'titleThree')} />
                                <span class="slidered"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {arr.length - 1 === index ? null : (
                        <div >
                          <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                            onClick={() =>
                              this.confirmationForRemove(index, element, "create", 'gallery')
                              // this.removeimageUrlFields(index, element, "create")
                            }
                          ></i>
                        </div>
                      )}
                    </div>
                    <div className="row" key={index}>
                      <div className="dflex" style={{ marginLeft: '10px' }}>
                        <div >

                          <div className="dflex">
                            {element.bannerPreview && (
                              <div className="mb-3">
                                <label class="lehead">Image</label>
                                <div className="clearfix"></div>
                                <img
                                  name="bannerPreview"
                                  value={element.bannerPreview || ""}
                                  className="preview-image mr-2"
                                  src={element.bannerPreview}
                                ></img>
                              </div>
                            )}

                            <div className="m-0">
                              <label class="lehead"> Upload
                                <span style={{ fontSize: "10px" }}>
                                  (Upload dimentions should be 960 X 360 below 5 mb)
                                </span>
                              </label>
                              <div className="profile-upload rect">
                                <label className="custom-file-upload rectangle">
                                  <img src="" />
                                  <input
                                    type="file"
                                    name="bannerPreview"
                                    className="form-control"
                                    multiple
                                    onChange={(e) =>
                                      this.imageHandleChange(index, e,'images')
                                    }
                                  />
                                  <i className="fas fa-plus"></i>
                                </label>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))}
                <span className="text-danger">
                  {this.validator.message(
                    "Title",
                    this.state.gallerytitle,
                    "required"
                  )}
                </span>
                <span className="text-danger">
                  {this.validator.message(
                    "Description",
                    this.state.imageValues[0]?.bannerPreview,
                    "required"
                  )}
                </span>
                <div className="clearfix"></div>
                <div className="row">
                  <div className="col-md-3 justify-content-between">
                    <div className="m-2">
                      <button
                        className="template-add-button "
                        type="button"
                        onClick={() => this.addImageFields()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <hr className="divider-line" />

          {/* Template footer */}
          <div className="row">
            <div className="col-md-4">
              <label className="lehead">
                Template Footer<span>*</span>
              </label>
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              {this.state.footerValues.map((element, index, arr) => (
                <div className="sec-borser">
                  <div className="hider">
                    {arr.length - 1 === index ? null : (
                      <div style={{ marginLeft: '30px' }}>
                        <div className="addadency-sec d-flex">
                          <p className="lehead">Hide In Template View</p>
                          <div>
                            <label class="switchtog ml-2">
                              <input name="show" type="checkbox" checked={element.show === 0 ? false : true} onChange={(e) => this.showHideFields(index, e, "create", 'footer')} />
                              <span class="slidered"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {arr.length - 1 === index ? null : (
                      <div>
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                          onClick={() =>
                            this.confirmationForRemove(index, element, "create", 'footer')
                            //this.removeFooterFields(index, element, "create")
                          }></i>
                      </div>
                    )}
                  </div>
                  <div className="col-md-8 dflex" key={index}>
                    <div className="mr-5">
                      <label className="lehead">Label</label>
                      <input
                        className="form-template"
                        type="text"
                        name="label"
                        value={element.label || ""}
                        onChange={(e) => this.onFooterhandleChange(index, e)}
                      />
                    </div>
                    <div>
                      <label className="lehead">Value</label>
                      <input
                        className="form-template"
                        type="text"
                        name="labelValue"
                        value={element.labelValue || ""}
                        onChange={(e) => this.onFooterhandleChange(index, e)}
                      />
                    </div>

                  </div>
                </div>
              ))}
            </div>
            <span className="text-danger">
              {this.validator.message(
                "footer_label",
                this.state.footerValues[0]?.label,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "footer_value",
                this.state.footerValues[0]?.labelValue,
                "required"
              )}
            </span>
            <div className="clearfix"></div>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button
                    className="template-add-button "
                    type="button"
                    onClick={() => this.addFooterFields()}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
          <Row className="my-4">
            <Col xs lg="6">
              <div className="save-sec">
                <button
                  type="submit"
                  onClick={() => this.createTemplate()}
                  className="btn btn-info btn-success mt-1"
                >
                  Submit
                </button>
              </div>
            </Col>

            <Col xs lg="6">
              <div className="cancel-sec">
                <button
                  type="cancel"
                  className="cancelbtnnew"
                  onClick={() => this.onCancel()}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
          <center>
            <div className="loader-spinner">
              {this.state.spinner ? (
                <Circles color="#7cc9a9" height={50} width={50} />
              ) : null}
            </div>
          </center>

        </div>
      </div >
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    addtemplate: state.addtemplate,
    viewtemplate: state.viewtemplate,
  };
};

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
  createTemplate,
  editTemplate,
  viewTemplate,
})(withRouter(TemplateFormTwo));