import { ACTIVITY_LIST, ACTIVITY_ERROR } from '../types';
import HttpService from '../../Services/HttpService';

export const activityData = (response) => {
    return {
        type: ACTIVITY_LIST,
        response: response
    }
}
export const activityerror = () => {
    return {
        type: ACTIVITY_ERROR
    }
}

export const getActivityBasedOnChapter = (cid) => {
    return (dispatch) => {
        HttpService.activityListData(cid)
            .then(response => {
                console.log("activity responce:- ", response)
                dispatch(activityData(response.data));
            })
            .catch(error => {
                dispatch(activityerror());
            })
    }
}

export const getActivityAjax = (data) => {
    return (dispatch) => {
        HttpService.activityListDataAjax(data)
            .then(response => {
                console.log("activity responce:- ", response)
                dispatch(activityData(response.data));
            })
            .catch(error => {
                dispatch(activityerror());
            })
    }
}