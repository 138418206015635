import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Header extends Component {
  constructor() {
    super();
    this.state = {
      responsiveNav: '0'
    }
  }
  onHandleResponsiveNav(data) {
    this.state.responsiveNav === '0' ?
      data === 'activate' ?
        this.setState({ responsiveNav: "1" }) :
        this.setState({ responsiveNav: '0' }) :
      this.setState({ responsiveNav: "0" })
  }
    /* render() function is used to update the UI. */

  render() {
    return (
      <main className='body-new'>
        <header className="header-new">
          <div className="header__inner">
            <a href="#" className="header__logo logo-new"></a>
            <div className={this.state.responsiveNav === '0' ? "header__aside" : "header__aside is-active"}>
              <nav className="nav nav--blue">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="/features">Features</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <Link to="/login">Log In</Link>
                  </li>
                </ul>
              </nav>
              <Link className="btn btn--yellow btn--medium header__btn" to="/signup">Sign Up</Link>
            </div>
            <a onClick={() => this.onHandleResponsiveNav('activate')}

              className={this.state.responsiveNav === '0' ? "nav-trigger" : "nav-trigger is-active"}>
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
      </main>
    )
  }
}
export default Header;