import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import HttpService from '../../Services/HttpService';
import { getActivityAjax } from '../../Store/activities/activityListaction';
import ReactPagenate from 'react-paginate';
import { Circles } from 'react-loader-spinner';
import { Tooltip, Overlay } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import '../../App.css'
import { Edit } from '@material-ui/icons';
import handleSwalAlert from '../SwalAlert'

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      activityList: [],
      data: [],
      offset: 0,
      perPage: 8,
      currentPage: 0,
      activity: [],
      filteractivity: [],
      slice: [],
      deleteResponce: '',
      DefaultMessage: '',
      activitySearchList: [],
      activitySearchInput: '',
      showPopover: false,
      chapter_id: '',
      activity_id: null,
      eventtarget: null,
      usertypeid: null,
      pageCount : 0,
      enabledpage:0,
      searchName :''
    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.activity = nextProps.activityList.activitylist.activities

    if (this.state.activity?.length === 0) {
      this.setState({
        spinner: false,
        pageCount: 0,
        DefaultMessage: 'No Activities Found.'
      });
    }
    else {
      this.state.filteractivity = nextProps.activityList.activitylist.activities?.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = this.state.filteractivity?.map(pd => <React.Fragment>
      </React.Fragment>)
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.activityList.activitylist.count / this.state.perPage),
        postData
      });
    }
  }
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.activity.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)

    this.setState({
      pageCount: Math.ceil(this.state.activity.length / this.state.perPage),
      postData
    })
  }
  /* For search name of the activity in the list  */
  searchHandler = (event) => {
    this.state.searchName = event.target.value

    if (this.state.searchName.length === 0) {
     
      var data = {
          page: 1,
          limit: 8,
          chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }
      this.props.getActivityAjax(data);
      this.setState({
          spinner: !this.state.spinner,
          SearchList: [],
          searchInput: '',
          DefaultMessage: '',
          activity: [],
          searchName:''
      })
  }
  else {

      this.setState({ 
          spinner:true
      })
      if(this.state.searchName.length === 1){
          this.setState({
            activity: []
          })
      }
      var data = {
          page: this.state.selectedPage,
          limit: 8,
          chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
          search: this.state.searchName
      }
      this.props.getActivityAjax(data);
  }
  }
  /* To make the api call initial page load */
  componentDidMount() {
    localStorage.setItem('selectedpage', 1)
    this.setState({
      spinner: true
    })
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    this.state.usertypeid = this.currentuser.user.usertypeid;
    if (this.state.usertypeid == 1) {
      var data = {
        page : localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }
      this.props.getActivityAjax(data);
    }
    else {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: 0
      }
      this.props.getActivityAjax(data);
    }
  }
  /* Go to next page in the pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.state.enabledpage = selectedPage + 1
    localStorage.setItem('selectedpage', this.state.enabledpage)
    if(this.state.searchName.length>0){
      var data = {
          page: this.state.enabledpage,
          limit: 8,
          chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
          search : this.state.searchName
      }
  }
  else {
      var data = {
          page: this.state.enabledpage,
          limit: 8,
          chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }     
  }
    this.props.getActivityAjax(data);    
  };
  /* Goto a new activity */
  onnewActivity() {
    this.props.history.push({
      pathname: '/dashboard/Activities/NewActivity'
    })
  }
  /*Edit the created acitivy */
  onEditActivity(item) {
    this.props.history.push({
      pathname: '/dashboard/Activities/NewActivity',
      state: { detail: item }
    })
  }
  /* To show the pop up */
  showPopoverDialog = (e, aid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      activity_id: aid
    })
  }
  /* To close the pop up  */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  onErrorAlert () {
    var errorAlert = {
          alertType: 'error'
    }
    handleSwalAlert(errorAlert)
  }
  /* To delete the activity */
  onDelete = (activity_id, index) => {
    var data = {
      activity_id: this.state.activity_id
    }
    HttpService.deleteActivityData(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          if (this.state.deleteResponce.message == 'Activity was in use not able to delete') {
            var successAlert = {
              message : this.state.deleteResponce.message,
              alertType: 'warning'
            }
            handleSwalAlert(successAlert)          
            this.setState({
              showPopover: false
            })
          }
          else {
            this.setState({
              showPopover: false
            })           
            var data = {
              page :  localStorage.getItem('selectedpage'),
              limit: 8,
              chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
            }
            this.props.getActivityAjax(data);
            this.refs.removesearch.value = '';
            var successAlert = {
              message : this.state.deleteResponce.message,
              alertType: 'success'
            }
            handleSwalAlert(successAlert)         
          }
        }
        else {
          this.setState({
            showPopover: false,
            spinner : false
          })
          this.onErrorAlert()       
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false,
          spinner: false
        })
        this.onErrorAlert()
      })
  }
  /* render() function is used to update the UI. */
  render() {
    const { activityList, showPopover } = this.state;
    localStorage.setItem("activescreen", JSON.stringify('activies'));
    console.log('active screen:- ', JSON.parse(localStorage.getItem('activescreen')))
    return (
      <div className="container">
        <Row className='pt-4 mb-4'>
          <Col xs lg="6">
            <h3 className="pagename mb-3">Activities</h3>
          </Col>
          <Col xs lg="6">
            <div className='d-flex'>
              <div className='pr-3' style={{ width: '70%' }}>
                <span className="fa fa-search head-search"></span>
                <input type="text" className="form-control pl-5" ref="removesearch" placeholder="Search activity name..." onChange={(e) => this.searchHandler(e)} />
              </div>
              <div>
                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewActivity()}>New Activity</button>
              </div>
            </div>
          </Col>
        </Row>
        <div className='box'>
          <center>
            <div class="loader-spinner">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
          {this.state.activity.length > 0 ?
            <div className="table-responsive">
              <table id="dataTable">
                <thead>
                  {this.state.activity.length > 0 ?
                    <>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Activity Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </>
                    : null
                  }
                </thead>
                <tbody>
                  {
                    this.state.activitySearchInput.length ? this.state.activitySearchList.length > 0 ? this.state.activitySearchList?.slice(this.state.offset, this.state.offset + this.state.perPage).map((item) => (
                      <>
                        <tr key={item.activity_id} className="td_width">
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>{item.activity_date}</td>
                          <td>{item.active_status == 0 ? "Active" : 'InActive'}</td>
                          <td>
                            <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                              <Tooltip>
                                <div className='text-center p-4'>
                                  <p className='tool-title'>Delete Activity</p>
                                  <p className='tool-para'>Are you sure you want to delete this user Activity?</p> </div>
                                <div className='d-flex btn-sec'>
                                  <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                                  <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                </div>
                              </Tooltip>
                            </Overlay>
                            <div className='cross-center'>
                              <span name="edit" onClick={(e) => this.onEditActivity(item)}><Edit /></span>
                            </div>
                            <div className='cross-center'>
                              <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.activity_id)}><CloseIcon /></span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr> :
                      this.state.activity.length ? this.state.activity?.slice(this.state.offset, this.state.offset + this.state.perPage).map((item, index) => (
                        <tr key={item.activity_id} className="td_width">
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>{item.activity_date}</td>
                          <td>{item.active_status == 0 ? "Active" : 'InActive'}</td>
                          <td>
                            <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                              <Tooltip>
                                <div className='text-center p-4'>
                                  <p className='tool-title'>Delete Activity</p>
                                  <p className='tool-para'>Are you sure you want to delete this user Activity?</p>
                                </div>
                                <div className='d-flex btn-sec'>
                                  <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                                  <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                </div>
                              </Tooltip>
                            </Overlay>
                            <div className='cross-center'>
                              <span name="edit" onClick={(e) => this.onEditActivity(item)}><Edit /></span>
                            </div>
                            <div className='cross-center'>
                              <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.activity_id)}><CloseIcon /></span>
                            </div>
                          </td>
                        </tr>)) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                  }
                </tbody>
              </table>
              {this.state.pageCount > 0 ? <div className="pagination">
                {this.state.postData}
                <ReactPagenate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />
              </div> : null
              }
            </div> : <center><h3>{this.state.DefaultMessage}</h3></center>}
        </div>
      </div>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
  return {
    activityList: state.activityList,
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getActivityAjax })(Activity)

