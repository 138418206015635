import { EVENTS_LIST, BIGS_EVENTS, RANGE_EVENTS, EVENT_TAG_LIST, EVENT_TAG_LIST_ERROR} from '../types';
import HttpService from '../../Services/HttpService';

export const eventsData = (response) => {
    return {
        type: EVENTS_LIST,
        response: response
    }
}


export const bigseventsData = (response) => {
    return {
        type: BIGS_EVENTS,
        response: response
    }
}

export const rangeevetsdata = (response) => {
    return {
        type: BIGS_EVENTS,
        response: response
    }
}


export const EventTags = (response) => {
    return {
        type: EVENT_TAG_LIST,
        response
    }
}
export const EventTagsError = (response) => {
    return {
        type: EVENT_TAG_LIST_ERROR,
        response
    }
}
export const getEvents = (chapter_id) => {
    return (dispatch) => {
        HttpService.eventslist(chapter_id)
            .then(response => {
                console.log('Events list:- ', response.data);
                dispatch(eventsData(response.data));
            })
    }
}

export const getRangeEventListAjax = (inputrange) => {
    return (dispatch) => {
        HttpService.allEventsListAjax(inputrange)
            .then(response => {
                console.log('range Events list:- ', response.data);
                dispatch(rangeevetsdata(response.data));
            })
    }
}

export const getEventTagList = () => {
    return (dispatch) => {
        HttpService.TagList()
            .then(response => {
                console.log('tag list response', response);
                dispatch(EventTags(response.data));
            })
            .catch(error => {
                
                dispatch(EventTagsError());
            })
    }
}

export const getEventTagListAjax = (data) => {
    return (dispatch) => {
        HttpService.TagListAjax(data)
            .then(response => {
                console.log('tag list response', response);
                dispatch(EventTags(response.data));
            })
            .catch(error => {
                dispatch(EventTagsError());
            })
    }
}
