import HttpService from '../../Services/HttpService';
import { DASHBOARD_CHEPATER_DATA, DASHBOARD_CHEPATER_ERROR } from "../types";

export const getDashboard = (response) => {

    return {
        type: DASHBOARD_CHEPATER_DATA,
        response: response
    }
}

export const dashboarderror = () => {
    return {
        type: DASHBOARD_CHEPATER_ERROR
    }
}






export const getDashboardData = () => {
    return (dispatch) => {
        HttpService.getDashboardData()
            .then(response => {
                console.log("dashboard responce:- ", response)
                dispatch(getDashboard(response.data));
            })
            .catch(error => {
                dispatch(dashboarderror());
            })
    }
}

export const DashboardData = (cid) => {
    return (dispatch) => {
        HttpService.getDashboardData(cid).then(response => {
                console.log("dashboard responce:- ", response)
                dispatch(getDashboard(response.data));
            })
            .catch(error => {
                dispatch(dashboarderror());
            })
    }
}