import { CMS_HELP_LIST_DATA, CMS_HELP_LIST_ERROR } from "../types";

const initialState = {
    cmshelplistdata:[],
    cmsErrorData:''
}

export default function cmshelpListReducer(state = initialState, action){

    switch(action.type){

        case CMS_HELP_LIST_DATA:
        return {
            ...state,
            cmshelplistdata:action.response,
        }
        case CMS_HELP_LIST_ERROR:
            return {
            ...state,
            cmsErrorData : action.response
            }
        default: return state
    }

}