import React, { Component } from 'react';
import Header from './Header.jsx';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { getplanListData } from '../../Store/planList/planListAction'
import { connect } from 'react-redux';
import { Circles } from 'react-loader-spinner';
import HttpService from '../../Services/HttpService.js';
import handleSwalAlert from '../SwalAlert.js';
import axios from 'axios';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sameAddressCheckbox: false,
      showPasswordText: false,
      showPassworTextlength: 0,
      showConfirmPasswordText: false,
      showConfromPassworTextlength: 0,
      spinner: false,
      defaultMessage: '',
      planListResponce: [],
      email: "",
      cardNum: '',
      cvv: "",
      cardExp: "",
      zipcodeTwo: "",
      PhoneNumber: "",
      CreditCard: "",
      Website: "",
      sub_plan_id: 2000,
      Businessphone: "",
      zipcode: "",
      State: "",
      City: "",
      AddressTwo: "",
      Address: "",
      BusinessName: "",
      ConfirmPassword: "",
      Password: "",
      EmailAddress: "",
      FullName: "",
      cityTwo: "",
      bullingAddresstwo: "",
      bullingAddress: "",
      zipcodeThree: "",
      stateTwo: "",
      cityTwo: "",
      validated: false,

      EmailAddress_validated: false,
      fullName_validated: false,
      Password_validated: false,
      ConfirmPassword_validated: false,
      BusinessName_validated: false,
      Address_validated: false,
      AddressTwo_validated: false,
      City_validated: false,
      State_validated: false,
      zipcode_validated: false,
      Businessphone_validated: false,
      Website_validated: false,
      CreditCard_validated: false,
      PhoneNumber_validated: false,
      zipcodeTwo_validated: false,
      cardNum_validated: false,
      cardExp_validated: false,
      cvv_validated: false,
      bullingAddress_validated: false,
      bullingAddresstwo_validated: false,
      cityTwo_validated: false,
      stateTwo_validated: false,
      zipcodeThree_validated: false,
      RegisterFrom: 1,
      reg_from: 'with_chapter',
      userTypeId: 5
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  
  }
  onHandleInputChange(e, key) {
    switch (key) {
      case "RegisterFrom":

        if (this.state.RegisterFrom === 1) {
         
          this.setState({
            RegisterFrom: 0,
            reg_from: 'without_chapter',
            userTypeId:1
          })
        }
        else {
        
          this.setState({
            RegisterFrom: 1,
            reg_from: 'with_chapter',
            userTypeId:5
          })
        }
        break;

      case "FullName":
        e.currentTarget.value === '' ?
          this.setState({ FullName: '', fullName_validated: true }) :
          this.setState({ FullName: e.currentTarget.value, fullName_validated: false })

        break;

      case "EmailAddress":
        e.currentTarget.value === '' ?
          this.setState({ EmailAddress: '', EmailAddress_validated: true }) :
          this.setState({ EmailAddress: e.currentTarget.value, EmailAddress_validated: false })

        break;

      case "Password":
        e.currentTarget.value === '' ?
          this.setState({ Password: '', Password_validated: true }) :
          this.setState({ Password: e.currentTarget.value, Password_validated: false })
        break;

      case "ConfirmPassword":

        e.currentTarget.value === '' ?
          this.setState({ ConfirmPassword: '', ConfirmPassword_validated: true }) :
          this.setState({ ConfirmPassword: e.currentTarget.value, ConfirmPassword_validated: false })
        break;

      case "BusinessName":

        e.currentTarget.value === '' ?
          this.setState({ BusinessName: '', BusinessName_validated: true }) :
          this.setState({ BusinessName: e.currentTarget.value, BusinessName_validated: false })

        break;

      case "Address":
        e.currentTarget.value === '' ?
          this.setState({ Address: '', Address_validated: true }) :
          this.setState({ Address: e.currentTarget.value, Address_validated: false })

        break;

      case "AddressTwo":

        e.currentTarget.value === '' ?
          this.setState({ AddressTwo: '', AddressTwo_validated: true }) :
          this.setState({ AddressTwo: e.currentTarget.value, AddressTwo_validated: false })

        break;

      case "City":

        e.currentTarget.value === '' ?
          this.setState({ City: '', City_validated: true }) :
          this.setState({ City: e.currentTarget.value, City_validated: false })

        break;

      case "State":
        e.currentTarget.value === '' ?
          this.setState({ State: '', State_validated: true }) :
          this.setState({ State: e.currentTarget.value, State_validated: false })

        break;

      case "zipcode":

        e.currentTarget.value === '' ?
          this.setState({ zipcode: '', zipcode_validated: true }) :
          this.setState({ zipcode: e.currentTarget.value, zipcode_validated: false })

        break;

      case "Businessphone":
        e.currentTarget.value === '' ?
          this.setState({ Businessphone: '', Businessphone_validated: true }) :
          this.setState({ Businessphone: e.currentTarget.value, Businessphone_validated: false })

        break;

      case "Website":
        e.currentTarget.value === '' ?
          this.setState({ Website: '', Website_validated: true }) :
          this.setState({ Website: e.currentTarget.value, Website_validated: false })
        break;

      case "CreditCard":
        e.currentTarget.value === '' ?
          this.setState({ CreditCard: '', CreditCard_validated: true }) :
          this.setState({ CreditCard: e.currentTarget.value, CreditCard_validated: false })

        break;

      case "PhoneNumber":
        e.currentTarget.value === '' ?
          this.setState({ PhoneNumber: '', PhoneNumber_validated: true }) :
          this.setState({ PhoneNumber: e.currentTarget.value, PhoneNumber_validated: false })

        break;

      case "zipcodeTwo":
        e.currentTarget.value === '' ?
          this.setState({ zipcodeTwo: '', zipcodeTwo_validated: true }) :
          this.setState({ zipcodeTwo: e.currentTarget.value, zipcodeTwo_validated: false })

        break;

      case "CardNum":
        e.currentTarget.value === '' ?
          this.setState({ cardNum: '', cardNum_validated: true }) :
          this.setState({ cardNum: e.currentTarget.value, cardNum_validated: false })

        break;

      case "cardExp":
        e.currentTarget.value === '' ?
          this.setState({ cardExp: '', cardExp_validated: true }) :
          this.setState({ cardExp: e.currentTarget.value, cardExp_validated: false })
        break;

      case "cvv":
        e.currentTarget.value === '' ?
          this.setState({ cvv: '', cvv_validated: true }) :
          this.setState({ cvv: e.currentTarget.value, cvv_validated: false })
        break;

      case "bullingAddress":
        e.currentTarget.value === '' ?
          this.setState({ bullingAddress: '', bullingAddress_validated: true }) :
          this.setState({ bullingAddress: e.currentTarget.value, bullingAddress_validated: false })

        break;

      case "bullingAddresstwo":
        e.currentTarget.value === '' ?
          this.setState({ bullingAddresstwo: '', bullingAddresstwo_validated: true }) :
          this.setState({ bullingAddresstwo: e.currentTarget.value, bullingAddresstwo_validated: false })
        break;

      case "cityTwo":
        e.currentTarget.value === '' ?
          this.setState({ cityTwo: '', cityTwo_validated: true }) :
          this.setState({ cityTwo: e.currentTarget.value, cityTwo_validated: false })

        break;

      case "stateTwo":
        e.currentTarget.value === '' ?
          this.setState({ stateTwo: '', stateTwo_validated: true }) :
          this.setState({ stateTwo: e.currentTarget.value, stateTwo_validated: false })

        break;

      case "zipcodeThree":
        e.currentTarget.value === '' ?
          this.setState({ zipcodeThree: '', zipcodeThree_validated: true }) :
          this.setState({ zipcodeThree: e.currentTarget.value, zipcodeThree_validated: false })

        break;

      default:
        break;
    }

  }
  hidepassword(type) {
    if (type === 'password') {
      this.setState({
        showPasswordText: false
      })
    }
    else {
      this.setState({
        showConfirmPasswordText: false
      })
    }

  }
  /* to show the password text */
  showpassword(type) {
    if (type === 'password') {
      this.setState({
        showPasswordText: true
      })
    }
    else {
      this.setState({
        showConfirmPasswordText: true
      })
    }
  }

  onPlanHandle(data) {
    if (data.plan_id === 1) {
      this.setState({ sub_plan_id: 199.99 })
    }
    if (data.plan_id === 2) {
      this.setState({ sub_plan_id: 599.99 })
    }
    if (data.plan_id === 3) {
      this.setState({ sub_plan_id: 2000 })
    }
  }

  handlepaymentAndSubmit = async () => {
    
    if(this.state.reg_from == "with_chapter"){
      var userInputData = {
        "full_name": this.state.FullName,
        "email": this.state.EmailAddress,
        "password": this.state.Password,
        "repeat_password": this.state.ConfirmPassword,
        "business_name": this.state.BusinessName,
        "address": this.state.Address,
        "address2": this.state.AddressTwo,
        "city": this.state.City,
        "state": this.state.State,
        "zip_code": this.state.zipcode,
        "businees_phonenumber": this.state.Businessphone,
        "website": this.state.Website,
        "plan_id": this.state.sub_plan_id,
        "reg_from": this.state.reg_from,
        "usertype_id": this.state.userTypeId,
        "payment_id": 12345
      }
    }
    else{
      var userInputData = {
        "full_name": this.state.FullName,
        "email": this.state.EmailAddress,
        "password": this.state.Password,
        "repeat_password": this.state.ConfirmPassword,
        "reg_from": this.state.reg_from,
        "usertype_id": this.state.userTypeId,
        
      }
    }

   

    HttpService.register(userInputData)
      .then(response => {
        if (response.data.status === 200) {
          var successAlert = {
            message: response.data.message,
            alertType: "success",
            closeOnEsc: false,
            dangerMode: true,
            closeOnClickOutside: false,
            goToList: true,
            pathname: '/login',
            propsData: this.props
          }
          handleSwalAlert(successAlert)
        }
        else {
          var handleErrorMessage = {
            message: response.data.message,
            alertType: "warning",

          }
          handleSwalAlert(handleErrorMessage)
        }

      }).catch(error => {
        var handleErrorMessage = {
          alertType: "error",

        }
        handleSwalAlert(handleErrorMessage)
      })

  }
  initPayment = (data) => {
    const options = {
      key: 'rzp_test_cgJAA8kZQD8TIL',
      amount: this.state.sub_plan_id * 100,
      currency: "USD",
      name: 'Bigs',
      description: 'Selected plan',

      //image:book.img,
      order_id: data.order_id,
      handler: async (response) => {
        try {
          const verifyUrl = "http://localhost:8080/api/payment/verify"

          const { data } = await axios.post(verifyUrl, response)
          this.setState({ payment_id: response.razorpay_payment_id })
          if (this.validator.allValid()) {

            this.setState({
              EmailAddress_validated: false,
              fullName_validated: false,
              Password_validated: false,
              ConfirmPassword_validated: false,
              BusinessName_validated: false,
              Address_validated: false,
              AddressTwo_validated: false,
              City_validated: false,
              State_validated: false,
              zipcode_validated: false,
              Businessphone_validated: false,
              Website_validated: false,
              CreditCard_validated: false,
              PhoneNumber_validated: false,
              zipcodeTwo_validated: false,
              cardNum_validated: false,
              cardExp_validated: false,
              cvv_validated: false,
              bullingAddress_validated: false,
              bullingAddresstwo_validated: false,
              cityTwo_validated: false,
              stateTwo_validated: false,
              zipcodeThree_validated: false
            });

            var userInputData = {
              "full_name": this.state.FullName,
              "email": this.state.EmailAddress,
              "password": this.state.Password,
              "repeat_password": this.state.ConfirmPassword,
              "business_name": this.state.BusinessName,
              "address": this.state.Address,
              "address2": this.state.AddressTwo,
              "city": this.state.City,
              "state": this.state.State,
              "zip_code": this.state.zipcode,
              "businees_phonenumber": this.state.Businessphone,
              "website": this.state.Website,
              "plan_id": this.state.sub_plan_id,
              "reg_from": this.state.reg_from,
              "usertype_id":this.state.userTypeId,
              "payment_id": response.razorpay_payment_id
            }

            HttpService.register(userInputData)
              .then(response => {
               
                if (response.data.status === 200) {
                  var successAlert = {
                    message: response.data.message,
                    alertType: "success",
                    closeOnEsc: false,
                    dangerMode: true,
                    closeOnClickOutside: false,
                    goToList: true,
                    pathname: '/login',
                    propsData: this.props
                  }
                  handleSwalAlert(successAlert)
                }
                else {
                  var handleErrorMessage = {
                    message: response.data.message,
                    alertType: "warning",

                  }
                  handleSwalAlert(handleErrorMessage)
                }

              }).catch(error => {
                var handleErrorMessage = {
                  alertType: "error",

                }
                handleSwalAlert(handleErrorMessage)
              
              })

          } else {
            this.setState({
              EmailAddress_validated: true,
              fullName_validated: true,
              Password_validated: true,
              ConfirmPassword_validated: true,
              BusinessName_validated: true,
              Address_validated: true,
              AddressTwo_validated: true,
              City_validated: true,
              State_validated: true,
              zipcode_validated: true,
              Businessphone_validated: true,
              Website_validated: true,
              CreditCard_validated: true,
              PhoneNumber_validated: true,
              zipcodeTwo_validated: true,
              cardNum_validated: true,
              cardExp_validated: true,
              cvv_validated: true,
              bullingAddress_validated: true,
              bullingAddresstwo_validated: true,
              cityTwo_validated: true,
              stateTwo_validated: true,
              zipcodeThree_validated: true,
            })
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
          }

        } catch (error) {
          var handleErrorMessage = {
            alertType: "error",

          }
          handleSwalAlert(handleErrorMessage)

        }
      },
      theme: {
        color: "#3399cc"
      },
      method: {
        netbanking: false,
        card: true,
        wallet: false,
        upi: false,
        paylater: false
      },
      prefill: {
        email: 'test@gmail.com',
        contact: '0123456789',
        name: 'Name'
      },
      config: {
        display: {
          hide: [
            { method: 'paylater' },
            { method: 'emi' }
          ],
          preferences: { show_default_blocks: true }
        }
      }
    }
    const rzpl = new window.Razorpay(options);
    rzpl.open()
  }

  onemailChange = (e) => {
    this.setState({
      email: e.currentTarget.value,
    });

  }
  componentDidMount() {
    this.setState({ spinner: true })
    this.props.getplanListData()
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.planList.planList_res.plans_list?.length === 0) {
      this.setState({ spinner: false, defaultMessage: "You don't have any plans" })

    }
    else {
      this.setState({ spinner: false, planListResponce: nextProps.planList.planList_res.plans_list })


    }

  }
  sameAsAddess(e) {
    if (this.state.sameAddressCheckbox === false) {
      this.setState({
        sameAddressCheckbox: true,
        bullingAddress: this.state.Address,
        bullingAddresstwo: this.state.AddressTwo,
        cityTwo: this.state.City,
        stateTwo: this.state.State,
        zipcodeThree: this.state.zipcode
      })
    }
    else {
      this.setState({
        sameAddressCheckbox: false,
        bullingAddress: '',
        bullingAddresstwo: '',
        cityTwo: '',
        stateTwo: '',
        zipcodeThree: ''
      })
    }



  }

  render() {
    const { showPasswordText, showPassworTextlength, showConfirmPasswordText, showConfromPassworTextlength } = this.state;
    return (
      <div className="wrapper">
        <main className='body-new'>
          <Header />{/* /.header */}
          <div className="section-form">
            <center>
              <div class="loader-spinner mt-3">
                {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
              </div>
            </center>
            <div className="shell">
              <div className="section__form">
                <div className="form form--alt">
                  <div className="form__head">
                    <h1 className="form__title">Sign Up</h1>{/* <!-- /.form__title --> */}
                    <h5>Hello there. Sign up and start managing your Bigs Connect organization.</h5>
                    <h5>Already have an account? <Link to="/login">Sign In</Link></h5>
                  </div>{/* <!-- /.form__head --> */}
                  <div style={{textAlign:'center', margin:'20px'}}>
                    {/* <div className="switch-container">
                <label>
                    <input ref="switch" checked={ this.state.isChecked } onChange={ this._handleChange } className="switch" type="checkbox" />
                    <div>
              
                        <div></div>
                    </div>
                </label>
            </div> */}
            <div className='row' style={{justifyContent:'center'}}>

                  <label className="lehead col-md-2">Chapter Based: <i style={{ color: "#cecfd0", marginLeft: "0px" }} ></i></label>
                  <div style={{marginLeft:'-70px'}} className='col-md-1'>
                    <label>
                      <span></span>
                      <label class="switchtog">
                        <input type="checkbox" checked={this.state.RegisterFrom === 1 ? true : false} onChange={(e) => this.onHandleInputChange(e, 'RegisterFrom')} />
                        <span class="slidered"></span>
                      </label>

                      <span> </span>
                    </label>
                  </div>
                  </div>
                  </div>
                  <div className="form__groups">
                    <div className="form__group">
                      <div className="form__body">
                        <div className="form__row">
                          <div className="form__cols">
                            <div className="form__col form__col--1of2">

                              <label for="field-name" className="form__label">Full Name *</label>
                              <div className={this.state.fullName_validated ? 'redBorder' : ' form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-user" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="text" className="field" value={this.state.FullName} onChange={(e) => this.onHandleInputChange(e, "FullName")} id="field-name" />
                              </div>{/* <!-- /.form__controls --> */}

                              <span className="text-danger">
                                {this.validator.message('FullName', this.state.FullName, 'required')}
                              </span>
                            </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                            <div className="form__col form__col--1of2">
                              <label for="field-email" className="form__label">Email Address *</label>
                              <div className={this.state.EmailAddress_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-envelope" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="email" className="field" value={this.state.EmailAddress} onChange={(e) => this.onHandleInputChange(e, "EmailAddress")} id="field-email" name="EmailAddress" />
                              </div>{/* <!-- /.form__controls --> */}
                              <span className="text-danger">
                                {this.validator.message('EmailAddress', this.state.EmailAddress, 'required')}
                              </span>
                            </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                          </div>{/* <!-- /.form__cols --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row">
                          <div className="form__cols">
                            <div className="form__col form__col--1of2">
                              <label for="field-password" className="form__label">Password *</label>
                              <div className={this.state.Password_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-lock" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input
                                  type={showPasswordText ? 'text' : 'password'}
                                  className="field" value={this.state.Password} onChange={(e) => this.onHandleInputChange(e, "Password")} id="field-password" name="Password" />
                                <a className="form__check js-show-pwd" data-toggle="#field-password">
                                  {this.state.Password.length === 0 ? null :
                                    showPasswordText ?
                                      <i class=" fa fa-eye-slash" aria-hidden="true" onClick={() => this.hidepassword("password")}></i>
                                      : <i class="fa fa-eye" aria-hidden="true" onClick={() => this.showpassword("password")}></i>}
                                </a>{/* <!-- /.form__icon --> */}
                              </div>{/* <!-- /.form__controls --> */}
                              <span className="text-danger">
                                {this.validator.message('Password', this.state.Password, 'Password')}
                              </span>
                              <div className="form__note">
                                <p>Your password must be at least 8 characters and Include at least one uppercase letter, one lowercase letter and one number.  </p>
                              </div>{/* <!-- /.form__note --> */}
                            </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                            <div className="form__col form__col--1of2">
                              <label for="field-password-confirm" className="form__label">Confirm Password *</label>
                              <div className={this.state.ConfirmPassword_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-lock" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input
                                  type={showConfirmPasswordText ? "text" : "password"}

                                  className="field" value={this.state.ConfirmPassword} onChange={(e) => this.onHandleInputChange(e, "ConfirmPassword")} id="field-password-confirm" name="passwordConfirm" />
                                <a className="form__check js-show-pwd" data-toggle="#field-password-confirm">
                                  {this.state.ConfirmPassword.length === 0 ? null :
                                    showConfirmPasswordText ?
                                      <i class=" fa fa-eye-slash" aria-hidden="true" onClick={() => this.hidepassword("confrom_password")}></i>
                                      : <i class="fa fa-eye" aria-hidden="true" onClick={() => this.showpassword("confrom_password")}></i>}
                                </a>{/* <!-- /.form__icon --> */}
                              </div>{/* <!-- /.form__controls --> */}
                              <span className="text-danger">
                                {this.validator.message('ConfirmPassword', this.state.ConfirmPassword, 'required')}
                              </span>
                            </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                          </div>{/* <!-- /.form__cols --> */}
                        </div>{/* <!-- /.form__row --> */}
                      </div>{/* <!-- /.form__body --> */}
                    </div>{/* <!-- /.form__group --> */}

                    {/* Account information */}

                    {this.state.reg_from == 'with_chapter' ?  <>
                        <div className="form__group">
                          <div className="form__subhead">
                            <h3 className="form__subtitle">Account Information</h3>{/* <!-- /.form__title --> */}
                          </div>{/* <!-- /.form__subhead --> */}
                          <div className="form__body">
                            <div className="form__row">
                              <label for="field-bussines-name" className="form__label">Business Name*</label>
                              <div className={this.state.BusinessName_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-building" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="text" className="field" value={this.state.BusinessName} onChange={(e) => this.onHandleInputChange(e, "BusinessName")} id="field-bussines-name" name="BusinessName" />
                              </div>{/* <!-- /.form__controls --> */}
                              <span className="text-danger">
                                {this.validator.message('BusinessName', this.state.BusinessName, 'required')}
                              </span>
                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <label for="field-address" className="form__label">Address*</label>
                              <div className={this.state.Address_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="text" className="field" value={this.state.Address} onChange={(e) => this.onHandleInputChange(e, "Address")} id="field-address" name="Address" />
                              </div>{/* <!-- /.form__controls --> */}
                              <span className="text-danger">
                                {this.validator.message('Address', this.state.Address, 'required')}
                              </span>
                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--1of2">
                                  <label for="field-address-2" className="form__label">Address 2</label>
                                  <div className={this.state.AddressTwo_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.AddressTwo} onChange={(e) => this.onHandleInputChange(e, "AddressTwo")} id="field-address-2" name="field-address-2" />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('AddressTwo', this.state.AddressTwo, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--1of2">
                                  <label for="field-city" className="form__label">City*</label>
                                  <div className={this.state.City_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.City} onChange={(e) => this.onHandleInputChange(e, "City")} id="field-city" name="City" />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('City', this.state.City, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--size1">
                                  <label for="field-state" className="form__label">State*</label>
                                  <div className={this.state.State_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.State} onChange={(e) => this.onHandleInputChange(e, "State")} id="field-state" name="State" />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('State', this.state.State, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}

                                <div className="form__col form__col--size2">
                                  <label for="field-zipcode" className="form__label">ZIP Code*</label>
                                  <div className={this.state.zipcode_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="number" className="field js-zipcode" id="field-zipcode" name="zipcode" value={this.state.zipcode} onChange={(e) => this.onHandleInputChange(e, "zipcode")} />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('zipcode', this.state.zipcode, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--size1">
                                  <label for="field-phone" className="form__label">Business Phone Number*</label>
                                  <div className={this.state.Businessphone_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="number" className="field js-phone" id="field-phone" name="Businessphone" onChange={(e) => this.onHandleInputChange(e, "Businessphone")} value={this.state.Businessphone} />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('Businessphone', this.state.Businessphone, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--size3">
                                  <label for="field-website" className="form__label">Website*</label>
                                  <div className={this.state.Website_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-globe" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.Website} onChange={(e) => this.onHandleInputChange(e, "Website")} id="field-website" name="Website" />
                                  </div>{/* <!-- /.form__controls --> */}
                                  <span className="text-danger">
                                    {this.validator.message('Website', this.state.Website, 'required')}
                                  </span>
                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                          </div>{/* <!-- /.form__body --> */}
                        </div>{/* <!-- /.form__group --> */}
                        <div className="form__group">
                          <div style={{ display: 'none' }} className="form__subhead">
                            <h3 className="form__subtitle">Payment Information</h3>{/* <!-- /.form__title --> */}
                            <img src="/img/cards.png" alt="" />
                          </div>{/* <!-- /.form__subhead --> */}
                          <div className="form__body">
                            <div style={{ display: 'none' }} className="form__row">
                              <label for="field-name-cc" className="form__label">Name on Credit Card*</label>
                              <div className={this.state.CreditCard_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-user" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="text" className="field" value={this.state.CreditCard} onChange={(e) => this.onHandleInputChange(e, "CreditCard")} id="field-name-cc" name="CreditCard" />
                              </div>{/* <!-- /.form__controls --> */}

                              {/* <span className="text-danger">
                            {this.validator.message('CreditCard', this.state.CreditCard, 'required')}                       
                          </span> */}


                            </div>{/* <!-- /.form__row --> */}
                            <div style={{ display: 'none' }} className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--size1">
                                  <label for="field-phone-2" className="form__label">Phone Number*</label>
                                  <div className={this.state.PhoneNumber_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field js-phone" value={this.state.PhoneNumber} onChange={(e) => this.onHandleInputChange(e, "PhoneNumber")} id="field-phone-2" name="PhoneNumber" />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('PhoneNumber', this.state.PhoneNumber, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--size2">
                                  <label for="field-zipcode-2" className="form__label">ZIP Code*</label>
                                  <div className={this.state.zipcodeTwo_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field js-zipcode" id="field-zipcode-2" name="zipcodeTwo" value={this.state.zipcodeTwo} onChange={(e) => this.onHandleInputChange(e, "zipcodeTwo")} />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('zipcodeTwo', this.state.zipcodeTwo, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                            <div style={{ display: 'none' }} className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--size1">
                                  <label for="field-card-num" className="form__label">Card Number*</label>
                                  <div className={this.state.cardNum_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field js-card-number" id="field-card-num" name="cardNum" value={this.state.cardNum} onChange={(e) => this.onHandleInputChange(e, "CardNum")} />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('cardNum', this.state.cardNum, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--size4">
                                  <label for="field-card-exp" className="form__label">Exp*</label>
                                  <div className={this.state.cardExp_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field js-exp" id="field-card-exp" name="cardExp" value={this.state.cardExp} onChange={(e) => this.onHandleInputChange(e, "cardExp")} />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('cardExp', this.state.cardExp, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--size4">
                                  <label for="field-ccv" className="form__label">CVV*</label>
                                  <div className={this.state.cvv_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.cvv} onChange={(e) => this.onHandleInputChange(e, "cvv")} id="field-ccv" name="cvv" />
                                  </div>{/* <!-- /.form__controls --> */
                                  }
                                  {/* <span className="text-danger">
                                {this.validator.message('cvv', this.state.cvv, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}

                            {/* Billing address */}

                            <div className="form__checkbox">
                              <div className="checkbox">
                                <input onChange={(e) => this.sameAsAddess(e)} type="checkbox" name="checkbox-billign-address" id="checkbox-billign-address" value="" />
                                <label for="checkbox-billign-address">Billing Address same as Account Address</label>
                              </div>{/* <!-- /.checkbox --> */}
                            </div>{/* <!-- /.form__checkbox --> */}
                            <div className="form__row">
                              <label for="field-bulling-address" className="form__label">Billing Address*</label>
                              <div className={this.state.bullingAddress_validated ? 'redBorder' : 'form__controls'}>
                                <div className="form__icon">
                                  <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                </div>{/* <!-- /.form__icon --> */}
                                <input type="text" className="field" value={this.state.bullingAddress} onChange={(e) => this.onHandleInputChange(e, "bullingAddress")} id="field-bulling-address" name="bullingAddress" />
                              </div>{/* <!-- /.form__controls --> */}

                              {/* <span className="text-danger">
                            {this.validator.message('bullingAddress', this.state.bullingAddress, 'required')}
                          </span> */}

                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--1of2">
                                  <label for="field-bulling-address-2" className="form__label">Address 2</label>
                                  <div className={this.state.bullingAddresstwo_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.bullingAddresstwo} onChange={(e) => this.onHandleInputChange(e, "bullingAddresstwo")} id="field-bulling-address-2" name="bullingAddresstwo" />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('bullingAddresstwo', this.state.bullingAddresstwo, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--1of2">
                                  <label for="field-city-2" className="form__label">City*</label>
                                  <div className={this.state.cityTwo_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.cityTwo} onChange={(e) => this.onHandleInputChange(e, "cityTwo")} id="field-city-2" name="cityTwo" />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('cityTwo', this.state.cityTwo, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                            <div className="form__row">
                              <div className="form__cols">
                                <div className="form__col form__col--size1">
                                  <label for="field-state-2" className="form__label">State*</label>
                                  <div className={this.state.stateTwo_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field" value={this.state.stateTwo} onChange={(e) => this.onHandleInputChange(e, "stateTwo")} id="field-state-2" name="stateTwo" />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('stateTwo', this.state.stateTwo, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                                <div className="form__col form__col--size2">
                                  <label for="field-zipcode-3" className="form__label">ZIP Code*</label>
                                  <div className={this.state.zipcodeThree_validated ? 'redBorder' : 'form__controls'}>
                                    <div className="form__icon">
                                      <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                                    </div>{/* <!-- /.form__icon --> */}
                                    <input type="text" className="field js-zipcode" id="field-zipcode-3" name="zipcodeThree" value={this.state.zipcodeThree} onChange={(e) => this.onHandleInputChange(e, "zipcodeThree")} />
                                  </div>{/* <!-- /.form__controls --> */}

                                  {/* <span className="text-danger">
                                {this.validator.message('zipcodeThree', this.state.zipcodeThree, 'required')}
                              </span> */}

                                </div>{/* <!-- /.form__col form__col-/-1of2 --> */}
                              </div>{/* <!-- /.form__cols --> */}
                            </div>{/* <!-- /.form__row --> */}
                          </div>{/* <!-- /.form__body --> */}
                        </div>{/* <!-- /.form__group --> */}
                      </>  :
                     null

                    }
                    {/* Subscriptions */}

                    <div className="form__group">
                      <div className="form__subhead">
                        <h3 className="form__subtitle">Subscription Type</h3>{/* <!-- /.form__title --> */}
                      </div>{/* <!-- /.form__subhead --> */}


                      <div className="form__body">
                        <div className="radios">
                          <ul>
                            {this.state.planListResponce?.length > 0 ?
                              this.state.planListResponce?.map((item) => (
                                <li>

                                  <div className="radio" onClick={() => this.onPlanHandle(item)}>
                                    <input type="radio" defaultChecked={item.recommended === 1 ? true : false} name="field-type" id={`field-${item.plan_id}#`} />
                                    <label for={`field-${item.plan_id}#`}>
                                      <p>{item.plan_name}</p>
                                      <div className='clearfix'></div>
                                      <span className='ratetag'>{item.amount}</span>
                                      <small>{"(" + item.subject + ")"}</small>

                                      {item.recommended === 1 ? <span className="radio__circle">
                                        Save <br />
                                        20%
                                      </span> : null}

                                    </label>
                                  </div>

                                </li>
                              )) : <h3>{this.state.defaultMessage}</h3>}
                          </ul>
                        </div>
                      </div>

                      {/* <!-- /.radios --> */}
                      {/* <!-- /.form__body --> */}
                    </div>{/* <!-- /.form__group --> */}
                  </div>{/* <!-- /.form__groups --> */}
                  <div className="form__actions">
                    <button className="btn btn--blue btn--large form__btn"
                      onClick={this.handlepaymentAndSubmit}>Subscribe</button>
                  </div>{/* <!-- /.form__actions --> */}
                </div>{/* <!-- /.form --> */}
              </div>{/* <!-- /.section__form --> */}
            </div>{/* <!-- /.shell --> */}
          </div>{/* <!-- /.section --> */}
          {/* <!-- Your footer goes here --> */}
        </main>
        {/* <!-- /.wrapper --> */}
      </div>
    )
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    planList: state.planList,
  }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getplanListData })(SignUp)
