import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import HttpService from '../../Services/HttpService';
import { connect } from 'react-redux';
import ReactPagenate from 'react-paginate';
import { Button, Spinner } from 'react-bootstrap'
import '../../App.css'
import Edit from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import * as moment from 'moment';
import { Tooltip, OverlayTrigger, Overlay } from 'react-bootstrap'
import { Circles } from 'react-loader-spinner';
import { getEventTagList ,getEventTagListAjax} from '../../Store/events/eventsListAction';
import handleSwalAlert from '../SwalAlert'

class AgencyTagsList extends Component {
    constructor() {
        super()
        this.state = {
            tagList: [],
            spinner: false,
            offset: 0,
            perPage: 8,
            currentPage: 0,
            filtertags: [],
            slice: [],
            deleteResponce: '',
            DefaultMessage: '',
            tagSearchList: [],
            tagSearchInput: '',
            showPopover: false,
            eventtarget: null,
            tag_id: null,
            pageCount : 0,
            enabledpage: 1
        }
    }
    /* To make the api call initial page load */
    componentDidMount() {
        this.setState({
            spinner: true,
            tagList:[]
        })
        const data = `page=${this.state.enabledpage}&limit=8`
     
        this.props.getEventTagListAjax(data)
    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        console.log('tags nextProps:- ',nextProps)
        if (nextProps.tagsList.eventtags.length === 0) {
            this.setState({
                tagList:[],
                spinner:false,
                DefaultMessage: 'No Tags Found.'
            })
        }
        else {
            this.state.tagList = nextProps.tagsList.eventtags
            this.setState({
                spinner: false,
                pageCount: Math.ceil(nextProps.tagsList.count / this.state.perPage),
            })
        }
      
       
    }
    /* Goto new event tag */
    onnewtag() {
        this.props.history.push({
            pathname: '/dashboard/agencysponsored_tags/create-new-event-tag'
        })
    }
    /* To edit the event */
    onEdit(item) {
        this.props.history.push({
            pathname: '/dashboard/agencysponsored_tags/create-new-event-tag',
            state: { detail: item }
        })
    }
    /* To show the pop up */
    showPopoverDialog = (e, tid) => {
        this.setState({
            showPopover: true,
            eventtarget: e.target,
            tag_id: tid
        })
    }
    /* To close the pop up */
    dismissPopoverDialog = (e) => {
        this.setState({
            showPopover: false
        })
    }
    onErrorAlert (){
        var errorAlert = {
            alertType : 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /* To delete the event tag */
    onDelete = (item, index) => {
        var data = {
            tag_id: this.state.tag_id
        }

        HttpService.deleteTag(data)
            .then(response => {
                if (response.data.status === 200) {
                    var succesAlert = {
                        message: response.data.message,
                        alertType: "success"
                    }
                    handleSwalAlert(succesAlert)
                  
                    this.setState({
                        showPopover: false,
                        spinner: false
                    })
                    this.componentDidMount()
                }
                else {
                    this.setState({
                        showPopover: false,
                        spinner: false
                    })
                    this.onErrorAlert()                  
                }
            })
            .catch(error => {
                this.setState({
                    showPopover: false
                })
                this.onErrorAlert()                
            })
    }
    /* For paginaiton */
    receivedData() {
        this.state.slice = this.state.tagList.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = this.state.slice.map(pd => <React.Fragment>
            <img src={pd.thumbnailUrl} alt="" />
        </React.Fragment>)

        this.setState({
            pageCount: Math.ceil(this.state.tagList.length / this.state.perPage),
            postData
        })
    }
    /* Go to next page in the pagination */
    handlePageClick = (e) => {
        this.setState({
            spinner:true
        })
        const selectedPage = e.selected;
        localStorage.setItem('tagsselectedpage', selectedPage + 1)
        this.state.enabledpage = localStorage.getItem('tagsselectedpage')
        
        const data = `page=${this.state.enabledpage}&limit=8`
     
        this.props.getEventTagListAjax(data)
    };
    /* For search name of the activity in the list  */
    searchHandler = (event) => {
        this.setState({
            spinner:true
        })
        this.state.tagSearchInput = event.target.value
        if (this.state.tagSearchInput.length === 0) {
            this.componentDidMount()
            this.setState({ spinner: !this.state.spinner })
        }
        else {
            const data = `page=${this.state.enabledpage}&limit=8&search=${this.state.tagSearchInput}`
     
            this.props.getEventTagListAjax(data)            
        }
    }
    /* render() function is used to update the UI. */
    render() {
        const { showPopover } = this.state
        return (
            <div className="container">
                <Row className='pt-4'>
                    <Col xs lg="6">
                        <h3 className="pagename mb-3">Agency Sponsored Event Tags</h3>
                    </Col>
                    <Col xs lg="6">
                        <div className='d-flex'>
                            <div className='pr-3' style={{ width: '70%' }}>
                                <span className="fa fa-search head-search"></span>
                                <input style={{ paddingLeft: '40px' }} type="text" ref="removesearch" className="form-control" placeholder="Search tag name..." onChange={(e) => this.searchHandler(e)} />
                            </div>
                            <div>
                                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewtag()}>New Tag</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="box">
                    <center>
                        <div class="loader-spinner">
                            {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                        </div>
                    </center>
                    {this.state.tagList.length > 0 ?
                        <div style={{ width: '100%' }} className="row">
                            <div className="mb-3 col-12 text-center">
                                <div className="table-responsive">
                                    <table id="dataTable">
                                        <thead>
                                            {this.state.tagList.length > 0 ? <tr>
                                                <th>Tag Name</th>

                                                <th>Created Date</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr> : null
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                               
                                                    this.state.tagList.length ? this.state.tagList?.slice(this.state.offset, this.state.offset + this.state.perPage).map((item, index) => (
                                                        <tr key={item.tag_id}>
                                                            <td> <label style={{ backgroundColor: item.tag_color }} className="tag-name">{item.tag_name}</label></td>
                                                            <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                                                            {item.active_status == '0' ? <td>Active</td> : <td>InActive</td>}
                                                            <td>
                                                                <div className="float-right">
                                                                    <span className="pr-2" name="edit" onClick={() => this.onEdit(item)}><Edit /></span>
                                                                    <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                        <Tooltip>
                                                                            <div className='text-center p-4'>
                                                                                <p className='tool-title'>Delete Tag</p>
                                                                                <p className='tool-para'>Are you sure you want to delete this tag?</p>
                                                                            </div>
                                                                            <div className='d-flex btn-sec'>
                                                                                <button onClick={() => this.onDelete(item, index)} className='tool-btn-yes'>Yes, Delete</button>
                                                                                <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Overlay>
                                                                    <div className='cross-center'>
                                                                        <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.tag_id)}><CloseIcon /></span>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    {this.state.pageCount > 0 ? <div className="pagination">
                                   
                                        <ReactPagenate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={8}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div> : null
                                    }
                                </div>
                            </div>
                        </div> : <center><h3>{this.state.DefaultMessage}</h3></center>}
                </div>
            </div>
        );
    }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    console.log('event state:- ', state)
    return {

        tagsList: state.eventsList.tagListData
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getEventTagList,getEventTagListAjax })(AgencyTagsList);