import { EVENTS_LIST,BIGS_EVENTS,RANGE_EVENTS,EVENT_TAG_LIST,EVENT_TAG_LIST_ERROR } from "../types";

const initialState = {
    eventslist:[],
    bigseventslist:[],
    rangeeventslist:[],
    tagListData : {},
    errorTagList:{}
}

export default function eventsListreducer(state = initialState, action){

    switch(action.type){

        case EVENTS_LIST:
        return {
            ...state,
            eventslist:action.response,
        }

        case BIGS_EVENTS:
        return {
            ...state,
            bigseventslist:action.response,
        }
        case RANGE_EVENTS:
            return{
                ...state,
                rangeeventslist:action.response
            }
            case EVENT_TAG_LIST:
                return{
                    ...state,
                    tagListData:action.response
                }
                case EVENT_TAG_LIST_ERROR:
                    return{
                        ...state,
                        errorTagList:action
                    }
        default: return state
    }

}