import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import './template.css'

class Navbar extends Component {
  handleClick = () => this.props.onClick(this.props.index)
  /* render() function is used to update the UI. */
  render() {
    console.log('nav bar props:- ',this.props)
    return (
      //<Link to={`/${this.props.name}`}>
          <Link>
        <li
          className={this.props.isActive ? 'active' : ''}
          onClick={this.handleClick}
          >
          {this.props.name}
        </li>
      </Link>
    );
  }
}

export default Navbar;