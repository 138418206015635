import { SWITCHTOFEATURE_SUCCESS, SWITCHTOFEATURE_ERROR, } from '../types';
import HttpService from '../../Services/HttpService';

export const switchToFeatureResonce = (response) => {
    return {
        type: SWITCHTOFEATURE_SUCCESS,
        response
    }
}

export const switchToFeatureResonceError = () => {
    return {
        type: SWITCHTOFEATURE_ERROR
    }
}


export const getswitchToFeatureData = () => {
    return (dispatch) => {
        HttpService.switchToFeatures()
        .then(response => {
            console.log('response',response);
            dispatch(switchToFeatureResonce(response.data));
        }).catch(error=>(
            dispatch(switchToFeatureResonceError((error)))
        ))
    }
}

    

