import React, { Component } from "react";
import { uploadMediaType } from "./UploadTypeResponce";

class AllTemplateDesigns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTemplateOne: 0,
            openTemplateTwo: 0,
            openTemplateThree: 0,

            formValues: [{ name: "", email: "", menu_id: '', index: 0 }],


            imageValues: [{ imageName: '', imageUrl: '', imagePreview: '', image_id: '' }],


            bannerImageValues: [{ imageName: '', imageUrl: '', bannerPreview: '', banner_id: '' }],


            descriptionValues: [{ description: '', imageUrl: '', card_id: '', descriptionPreview: '' }],


            footerValues: [{ label: '', labelValue: '', footer_id: '' }],


            formValues: [{ name: "", email: "", menu_id: '' }],


            imageValues: [{ imageUrl: '', galleryPreview: '', templatephotogallery_id: '' }],


            bannerImageValues: [{ imageName: '', imageUrl: [], bannerPreview: '', banner_id: '' }],

            descriptionValues: [{ imageUrl: '', description: '', templateresource_id: '' }],


            cardValues: [{ imageUrl: '', description: '', templateresource_id: '', titleOneImagePreview: '' }],


            footerValues: [{ label: '', labelValue: '', footer_id: '' }],

            templateLogo: '',
            previewLogo: '',
            menuValues: [{ menuName: 'About us', menuId: '1' }, { menuName: 'Contact Us', menuId: '2' }, { menuName: 'Settings', menuId: '3' }],

            menuValue: [{ title: "", menu_id: '' }],


            bannerValue: [{ imageName: "", imageUrl: "", bannerPreview: "", banner_id: '' }],


            titleOneValues: [{ aboutFile: "", aboutTitle: "", aboutDescription: "", previewaboutFile: "", templateabout_id: '' }],


            titleTwoValues: [{ serviceTitle: "", serviceDescription: "", templateservice_id: '' }],


            titleThreeValues: [{ contactTitle: "", contactDescription: "", contactFile: '', templatecontactus_id: '', contactPreview: '' }],

            footerValues: [{ footerLabel: "", footerTitle: "", footer_id: '' }],

            expandTemplateOne: false,
            expandTemplateTwo: false,
            expandTemplateThree: false,
            expandTemplateFour: false

        }

    }

    /* Exapand and collapse the sample template view */
    expandCollapseTemplate(openclose, typeOfTempalte) {

        switch (typeOfTempalte) {
            case 'template_one':
                this.setState({
                    expandTemplateOne: openclose,
                    expandTemplateTwo: false,
                    expandTemplateThree: false,
                    expandTemplateFour: false
                })
                break;

            case 'template_two':
                this.setState({
                    expandTemplateTwo: openclose,
                    expandTemplateOne: false,
                    expandTemplateThree: false,
                    expandTemplateFour: false
                })
                break;
            case 'template_three':
                this.setState({
                    expandTemplateThree: openclose,
                    expandTemplateOne: false,
                    expandTemplateTwo: false,
                    expandTemplateFour: false
                })
                break;
            case 'template_three':
                this.setState({
                    expandTemplateThree: openclose,
                    expandTemplateOne: false,
                    expandTemplateTwo: false,
                    expandTemplateFour: false
                })
                break;
            case 'template_four':
                this.setState({
                    expandTemplateThree: false,
                    expandTemplateOne: false,
                    expandTemplateTwo: false,
                    expandTemplateFour: openclose
                })
                break;
            default:
                break;
        }


    }
    banners() {
        return (
            <div className="m-0">
                <label class="lehead mt-2">Upload Type</label>
                <div>
                    <select
                        name='upload'


                        style={{ width: '120px', height: '45px', padding: '5px' }} >

                        {uploadMediaType?.map((e) => {
                            return <option value={e.type}>{e.type}</option>;
                        })}
                    </select>
                </div>
            </div>
        )
    }

    /* render() function is used to update the UI. */
    render() {
        return (
            <div className="container">
                <div className="row mt-3 mb-4">
                    <div className="col-md-12">
                        <div className="horizontal-container">

                            <label className="label-discount">All Templates</label>
                        </div>
                    </div>
                </div>
                <div>
                    <button type="cancel" className="expandCollapseTempalte" onClick={(e) => this.expandCollapseTemplate(!this.state.expandTemplateOne, 'template_one')}>Template 1 <span> {this.state.expandTemplateOne ? <i class="fa fa-chevron-up" aria-hidden="true"></i> : <i class="fa fa-chevron-down" aria-hidden="true"></i>}</span></button>

                    {this.state.expandTemplateOne &&
                        <div style={{ marginTop: '10px' }}>
                            <div className='box'>
                                <label class="templateTextCenter">Template 1</label>
                                {/* Template logo creation */}
                                <section className='p-4'>
                                    <div className="row">


                                        <div className="col-md-4">
                                            <label class="lehead">Add Template Logo<span>*</span></label>

                                            <div className="mt-4">



                                                <div id="upload-right">
                                                    <div className="profile-upload rect">
                                                        <label className="custom-file-upload rectangle">
                                                            <i className="fas fa-plus"></i>
                                                        </label>
                                                        <label className="up-lbl">Upload an image</label><br />
                                                        <span className="up-span">(2MB Limit)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button style={{ display: 'none' }} className="btn btn-info btn-success mt-4">Upload</button>
                                        </div>
                                    </div>

                                    {/* Tempalte menu creation */}
                                    <hr className='divider-line' />
                                    {/* Template menu */}

                                    <label class="lehead">Template Menu<span>*</span></label>

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">

                                            {this.state.formValues.map((element, index, arr) => (

                                                <div className="col-md-8" >

                                                    <label class="lehead mr-3">Menu Title</label>
                                                    <div className='row'>
                                                        <div className="col-12 d-flex">
                                                            <input className="form-control" style={{ width: '300px' }} type="text" name="name" value={element.name || ""} />
                                                            {
                                                                arr.length - 1 === index ? null
                                                                    : <button type="button" className="template-remove ml-4 mb-3" onClick={() => this.removeFormFields(index, element, 'create')}>Remove</button>

                                                            }
                                                        </div>
                                                    </div></div>

                                            ))}
                                        </div>

                                        <div className="row">

                                            <div className="col-3 d-flex justify-content-between">

                                                <div className="m-2"><button className="template-add-button " type="button" >Add</button></div>
                                                <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" type="submit">Submit</button></div>

                                            </div>

                                        </div>

                                    </form>


                                    <hr className='divider-line' />
                                    {/* Template banners */}
                                    <section>

                                        <label class="lehead">Template Banners<span>*</span></label>
                                        {this.state.bannerImageValues.map((element, index, arr) => (

                                            <div className=" row " >

                                                <div className="col-8 d-flex">
                                                    <div className="m-2">
                                                        <label class="lehead">Name</label>
                                                        <input type="text" className='form-control' name="imageName" value={element.imageName || ""} />
                                                    </div>
                                                    <div className="m-2">

                                                        <div className="row">
                                                            <div className="col-8 d-flex">
                                                                {element.bannerPreview &&
                                                                    <div className="m-0">
                                                                        <div>
                                                                            <label class="lehead">Image</label>

                                                                            <img name="bannerPreview" value={element.bannerPreview || ""} className="preview-image" src={element.bannerPreview}></img>
                                                                        </div>

                                                                    </div>
                                                                }

                                                                {this.banners()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {

                                                        arr.length - 1 === index ? null

                                                            : <div className="mt-5">
                                                                <button className='template-remove ' type="button" onClick={() => this.removeBannerFields(index, element, 'create')}>Remove</button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}

                                        <div className="row">
                                            <div className="col-3 d-flex ">
                                                <div className="m-2"> <button className="template-add-button" type="button">Add</button> </div>

                                                <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" >Submit</button></div>
                                            </div>
                                        </div>


                                    </section>
                                    <hr className='divider-line' />
                                    {/* Template images  */}

                                    <section>
                                        <label class="lehead">Template Images<span>*</span></label>
                                        {this.state.imageValues.map((element, index, arr) => (

                                            <div className=" row " >
                                                <div className="col-8 d-flex">
                                                    <div className="m-2">
                                                        <label class="lehead">Name</label>
                                                        <input type="text" className='form-control' name="imageName" value={element.imageName || ""} />
                                                    </div>

                                                    <div className="m-2">
                                                        <div className="row">
                                                            <div className="col-8 d-flex">
                                                                {element.imagePreview &&

                                                                    <div className="m-0">
                                                                        <label class="lehead">Image</label>
                                                                        <img name="imagePreview" value={element.imagePreview || ""} className="preview-image" src={element.imagePreview}></img>
                                                                    </div>
                                                                }



                                                                <div className="m-0">
                                                                    <label class="lehead">Upload</label>
                                                                    <div className="profile-upload rect">
                                                                        <label className="custom-file-upload rectangle">
                                                                            <img src="" />
                                                                            {/* <input type="file" name="imagePreview" className="form-control" /> */}
                                                                            <i className="fas fa-plus"></i>
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        arr.length - 1 === index ? null

                                                            : <div className="mt-5">
                                                                <button className='template-remove ' type="button" onClick={() => this.removeImageFields(index, element, 'create')}>Remove</button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                        ))}



                                        <div className="row">
                                            <div className="col-3 d-flex ">
                                                <div className="m-2"> <button className="template-add-button" type="button" >Add</button> </div>

                                                <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" >Submit</button></div>
                                            </div>
                                        </div>

                                    </section>
                                    <hr className='divider-line' />

                                    {/* Template description */}
                                    <section>

                                        <label class="lehead">Template Descriptions<span>*</span></label>

                                        <form onSubmit={this.handleSubmit}>

                                            {this.state.descriptionValues.map((element, index, arr) => (
                                                <div className="row" >
                                                    <div className="col-md-4" >
                                                        <div className='mr-5'>
                                                            <label class="lehead">Description</label>
                                                            <textarea className="form-control" rows={3} type="text" name="description" value={element.description || ""} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 d-flex">
                                                        <div>
                                                            {element.descriptionPreview &&
                                                                <>
                                                                    <label class="lehead">Image</label>

                                                                    <div className="m-0">

                                                                        <img name="imagePreview" value={element.descriptionPreview || ""} className="preview-image" src={element.descriptionPreview}></img>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>

                                                        <div className="m-0">
                                                            <label class="lehead">Upload</label>
                                                            <div className="profile-upload rect">
                                                                <label className="custom-file-upload rectangle">
                                                                    <img src="" />
                                                                    {/* <input type="file" name="descriptionPreview" className="form-control" /> */}
                                                                    <i className="fas fa-plus"></i>
                                                                </label>

                                                            </div>
                                                        </div>
                                                        {

                                                            arr.length - 1 === index ? null
                                                                : <div className="mt-5">
                                                                    <button type="button" className="template-remove ml-4 mb-3" onClick={() => this.removeDescriptionFields(index, element, 'create')}>Remove</button>
                                                                </div>
                                                        }


                                                    </div>

                                                </div>
                                            ))}

                                            <div className='clearfix'></div>

                                            <div className="row">
                                                <div className="col-md-6 d-flex justify-content-between">

                                                    <div className="m-2"><button className="template-add-button " type="button" >Add</button></div>


                                                    <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" type="submit">Submit</button></div>

                                                </div>
                                            </div>

                                        </form>

                                    </section>

                                    <hr className='divider-line' />
                                    {/* Template footer */}
                                    <section>
                                        <label class="lehead">Template Footer<span>*</span></label>

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                {this.state.footerValues.map((element, index, arr) => (
                                                    <div className="d-flex col-md-8" >
                                                        <div className='mr-5'>
                                                            <label class="lehead">Label</label>
                                                            <input className="form-control" type="text" />
                                                        </div>
                                                        <div>
                                                            <label class="lehead">Value</label>
                                                            <input className="form-control" type="text" />
                                                        </div>
                                                        {
                                                            // this.state.editTemplate.success === true ?
                                                            //     <div className="mt-5">
                                                            //         <button type="button" className="template-remove ml-4 mb-3" onClick={() => this.removeFooterFields(index, element, 'create')}>Remove</button>
                                                            //     </div>
                                                            //     :
                                                            arr.length - 1 === index ? null
                                                                : <div className="mt-5">
                                                                    <button type="button" className="template-remove ml-4 mb-3" onClick={() => this.removeFooterFields(index, element, 'create')}>Remove</button>
                                                                </div>
                                                        }
                                                    </div>

                                                ))}
                                            </div>
                                            <div className='clearfix'></div>
                                            <div className="row">
                                                <div className="col-3 d-flex justify-content-between">
                                                    <div className="m-2"><button className="template-add-button " type="button">Add</button></div>

                                                    <div className="m-2"> <button onClick={() => this.expandCollapseTemplate(!this.state.expandTemplateOne, 'template_one')} className="template-upload-button" type="submit">Cancel</button></div>

                                                </div>
                                            </div>

                                        </form>
                                        <div className='clearfix'></div>
                                    </section>
                                </section>


                            </div>

                        </div>

                    }
                </div>
                <div>
                    <button type="cancel" className="expandCollapseTempalte" onClick={(e) => this.expandCollapseTemplate(!this.state.expandTemplateTwo, 'template_two')}>Template 2 <span> {this.state.expandTemplateTwo ? <i class="fa fa-chevron-up" aria-hidden="true"></i> : <i class="fa fa-chevron-down" aria-hidden="true"></i>}</span></button>

                    {this.state.expandTemplateTwo && <div>
                        <div style={{ marginTop: '10px' }} className="box">
                            <label class="templateTextCenter">Template 2</label>
                            {/* Template logo creation */}
                            <section className="p-4">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="lehead">Add Template Logo<span>*</span></label>
                                        <div>


                                            <div id="upload-right">
                                                <div className="profile-upload rect">
                                                    <label className="custom-file-upload rectangle">

                                                        <i className="fas fa-plus"></i>
                                                    </label>
                                                    <label className="up-lbl">Upload an image or PDF</label><br />
                                                    <span className="up-span">(2MB Limit)</span>
                                                </div>
                                            </div>
                                        </div>

                                        <button style={{ display: 'none' }} className="btn btn-info btn-success mt-4">Upload</button>
                                    </div>
                                </div>
                                {/* Tempalte menu creation */}
                                <hr className='divider-line' />

                                {/* Template menu */}
                                <section>
                                    <label className="lehead">Template Menu<span>*</span></label>

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            {this.state.formValues.map((element, index, arr) => (
                                                <div className="col-md-12" >
                                                    <label className="lehead">Menu Title</label>
                                                    <div className="row">
                                                        <div className="col-12 d-flex">
                                                            <div>
                                                                <input style={{ width: '300px' }} className="form-template" type="text" />
                                                            </div>
                                                            <div>
                                                                {
                                                                    arr.length - 1 === index ? null :
                                                                        <button type="button" className=" template-remove ml-4 mb-3" >Remove</button>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="row">
                                            <div className="col-3 d-flex justify-content-between">

                                                <div className="m-2"><button className="template-add-button " type="button">Add</button></div>


                                                <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" type="submit">Submit</button></div>

                                            </div>
                                        </div>


                                    </form>
                                </section>
                                <hr className='divider-line' />
                                <section>
                                    <label className="lehead">Template Banners<span>*</span></label>
                                    {this.state.bannerImageValues.map((element, index, arr) => (

                                        <div className=" row " >

                                            <div className="col-8 d-flex">
                                                <div className="m-2">
                                                    <label class="lehead">Name</label>
                                                    <input type="text" className='form-control' name="imageName" value={element.imageName || ""} />
                                                </div>
                                                <div className="m-2">

                                                    <div className="row">
                                                        <div className="col-8 d-flex">
                                                            {element.bannerPreview &&
                                                                <div className="m-0">
                                                                    <div>
                                                                        <label class="lehead">Image</label>

                                                                        <img name="bannerPreview" value={element.bannerPreview || ""} className="preview-image" src={element.bannerPreview}></img>
                                                                    </div>

                                                                </div>
                                                            }

                                                            {this.banners()}
                                                        </div>
                                                    </div>
                                                </div>
                                                {

                                                    arr.length - 1 === index ? null

                                                        : <div className="mt-5">
                                                            <button className='template-remove ' type="button" onClick={() => this.removeBannerFields(index, element, 'create')}>Remove</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3 d-flex ">
                                            <div className="m-2"> <button className="template-add-button" type="button" >Add</button> </div>

                                            <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" >Submit</button></div>
                                        </div>
                                    </div>


                                </section>
                                <hr className='divider-line' />
                                <section>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="lehead">Title 1<span>*</span></label>
                                            <input type="text" className="form-template" name="secTitle" value={this.state.resourcetitle} onChange={(e) => this.onresourceChange(e, "resourcetitle")} />

                                        </div>
                                    </div>
                                    <div>
                                        {this.state.cardValues.map((element, index, arr) => (
                                            <div className="row" style={{ border: '1px solid lightgray', borderRadius: '5px', width: '50%', marginLeft: '2px', marginBottom: '5px' }}>
                                                <div className="col-md-8">
                                                    <div className="m-2">

                                                        <div className="row">
                                                            <div className="col-8 d-flex">


                                                                <div className="m-0">
                                                                    <label class="lehead">Upload</label>
                                                                    <div className="profile-upload rect">
                                                                        <label className="custom-file-upload rectangle">

                                                                            <i className="fas fa-plus"></i>
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <label className="lehead">Description</label>
                                                    <textarea type="text" className="form-template" rows="4" name="description" value={element.description} />
                                                    {

                                                        arr.length - 1 === index ? null :
                                                            <button type="button" className=" template-remove mb-3" >Remove</button>

                                                    }
                                                </div>

                                            </div>

                                        ))}
                                        <div className="clearfix"></div>
                                        <div className="row">
                                            <div className="col-3 d-flex justify-content-between">
                                                <div className="m-2"><button className="template-add-button " type="button" >Add</button></div>


                                                <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" type="submit">Submit</button></div>

                                            </div>
                                        </div>
                                    </div>



                                </section>
                                <hr className='divider-line' />
                                {/* Template images  */}
                                <section>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="lehead">Title 2 <span>*</span></label>

                                            <input type="text" className="form-template" name="secTitle" value={this.state.secondTitle} onChange={(e) => this.onTitleChange(e, "secondTitle")} />

                                            {this.state.descriptionValues.map((element, index, arr) => (
                                                <div >
                                                    <div className="row">
                                                        <div className="col-8 d-flex">
                                                            <div >
                                                                <label class="lehead">Description</label>
                                                                <textarea className="form-template" style={{ width: '350px' }} type="text" row="2" name="description" />
                                                            </div>
                                                            <div>
                                                                {

                                                                    arr.length - 1 === index ? null :
                                                                        <div className="mt-5 ml-2">
                                                                            <button type="button" className=" template-remove mb-3">Remove</button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3 d-flex ">
                                            <div className="m-2"> <button className="template-add-button" type="button" >Add</button> </div>

                                            <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" onClick={(e) => this.ImagehandleSubmit(e)}>Submit</button></div>
                                        </div>
                                    </div>


                                </section>

                                <hr className='divider-line' />
                                <section>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="lehead">Title 3<span>*</span></label>
                                            <input type="text" className="form-template" name="secTitle" value={this.state.gallerytitle} />


                                            <form onSubmit={this.handleSubmit}>

                                                {this.state.imageValues.map((element, index, arr) => (
                                                    <>
                                                        <div >

                                                            <div className="m-2">

                                                                <div className="row">
                                                                    <div className="col-8 d-flex">


                                                                        <div className="m-0">
                                                                            <label class="lehead">Upload</label>
                                                                            <div className="profile-upload rect">
                                                                                <label className="custom-file-upload rectangle">

                                                                                    <i className="fas fa-plus"></i>
                                                                                </label>

                                                                            </div>
                                                                        </div>
                                                                        {

                                                                            arr.length - 1 === index ? null :
                                                                                <div className="mt-5 ml-2">
                                                                                    <button type="button" className="template-remove mb-3" >Remove</button>
                                                                                </div>
                                                                        }
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div>


                                                        </div>
                                                    </>
                                                ))}

                                                <div className="clearfix"></div>
                                                <div className="row">
                                                    <div className="col-3 d-flex justify-content-between">
                                                        <div className="m-2"><button className="template-add-button " type="button" >Add</button></div>

                                                        <div className="m-2"> <button style={{ display: 'none' }} className="template-upload-button" type="submit">Submit</button></div>

                                                    </div>
                                                </div>



                                            </form>
                                        </div>
                                    </div>
                                </section>
                                <hr className='divider-line' />

                                <section>
                                    {/* Template footer */}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="lehead">Template Footer<span>*</span></label>
                                        </div>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            {this.state.footerValues.map((element, index, arr) => (
                                                <div className="col-md-8 d-flex" >

                                                    <div className="mr-5">
                                                        <label className="lehead">Label</label>
                                                        <input className="form-template" type="text" />
                                                    </div>
                                                    <div>
                                                        <label className="lehead">Value</label>
                                                        <input className="form-template" type="text" />


                                                    </div>
                                                    {

                                                        arr.length - 1 === index ? null :
                                                            <div className="mt-5 ml-2">
                                                                <button type="button" className="template-remove ml-4 mb-3" >Remove</button>
                                                            </div>
                                                    }

                                                </div>
                                            ))}
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="row">
                                            <div className="col-3 d-flex justify-content-between">
                                                <div className="m-2"><button className="template-add-button " type="button" >Add</button></div>


                                                <div className="m-2"> <button onClick={() => this.expandCollapseTemplate(!this.state.expandTemplateTwo, 'template_two')} className="template-upload-button" type="submit">Cancel</button></div>

                                            </div>
                                        </div>


                                    </form>
                                </section>
                                <hr className='divider-line' />


                            </section>

                        </div>

                    </div>

                    }
                </div>
                <div>
                    <button type="cancel" className="expandCollapseTempalte" onClick={(e) => this.expandCollapseTemplate(!this.state.expandTemplateThree, 'template_three')}>Template 3 <span> {this.state.expandTemplateThree ? <i class="fa fa-chevron-up" aria-hidden="true"></i> : <i class="fa fa-chevron-down" aria-hidden="true"></i>}</span></button>

                    {this.state.expandTemplateThree &&
                        <div style={{ marginTop: '10px' }} className="box">
                            <label class="templateTextCenter">Template 3</label>
                            <main className="p-4">

                                <label className="lehead">
                                    Add Template Logo<span>*</span>
                                </label>
                                <div id="upload-right">
                                    <div className="profile-upload rect">
                                        <label className="custom-file-upload rectangle">

                                            <i className="fas fa-plus"></i>
                                        </label>
                                        <label className="up-lbl">Upload an image</label>
                                        <br />
                                        <span className="up-span">(2MB Limit)</span>
                                    </div>
                                </div>
                                <button
                                    style={{ display: "none" }}
                                    className="btn btn-info btn-success mt-4"
                                >
                                    Upload
                                </button>

                                <hr className="divider-line" />
                                {/* template menu */}
                                <section>
                                    <div className="row">
                                        <label className="lehead">
                                            Template Menu<span>*</span>
                                        </label>
                                        <div className="row">
                                            {this.state.menuValue.map((element, index, arr) => (
                                                <div className="col-md-8" >

                                                    <label class="lehead mr-3">Menu Title</label>
                                                    <div className='row'>
                                                        <div className="col-12 d-flex">
                                                            <input className="form-control" style={{ width: '300px' }} type="text" name="title" />

                                                        </div>
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">

                                                <button
                                                    className="template-add-button "
                                                    type="button"

                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <hr className="divider-line" />
                                <section>
                                    <label className="lehead">
                                        Template Banner<span>*</span>
                                    </label>
                                    {this.state.bannerImageValues.map((element, index, arr) => (

                                        <div className=" row " >

                                            <div className="col-8 d-flex">
                                                <div className="m-2">
                                                    <label class="lehead">Name</label>
                                                    <input type="text" className='form-control' name="imageName" value={element.imageName || ""} />
                                                </div>
                                                <div className="m-2">

                                                    <div className="row">
                                                        <div className="col-8 d-flex">
                                                            {element.bannerPreview &&
                                                                <div className="m-0">
                                                                    <div>
                                                                        <label class="lehead">Image</label>

                                                                        <img name="bannerPreview" value={element.bannerPreview || ""} className="preview-image" src={element.bannerPreview}></img>
                                                                    </div>

                                                                </div>
                                                            }

                                                            {this.banners()}
                                                        </div>
                                                    </div>
                                                </div>
                                                {

                                                    arr.length - 1 === index ? null

                                                        : <div className="mt-5">
                                                            <button className='template-remove ' type="button" onClick={() => this.removeBannerFields(index, element, 'create')}>Remove</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button
                                                    className="template-add-button"
                                                    type="button">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <hr className="divider-line" />
                                <section>
                                    <label className="lehead">
                                        Title One<span>*</span>
                                    </label>

                                    {this.state.titleOneValues.map((element, index, arr) => (
                                        <div className="row" style={{ border: '1px  solid lightgray', borderRadius: '5px', padding: '5px', marginTop: '5px' }}>
                                            <label className="lehead">Title</label>
                                            <input
                                                style={{ width: '200px' }}
                                                type="text"
                                                className="form-template"
                                                name="aboutTitle" />

                                            <label className="lehead">Description</label>
                                            <textarea
                                                style={{ width: '400px' }}
                                                type="text"
                                                className="form-template"
                                                row="2"
                                                name="aboutDescription" />
                                            <div className="col-md-3">
                                                <label className="lehead">Image</label>
                                                <div className="d-flex">

                                                    <div className="profile-upload rect">
                                                        <label className="custom-file-upload rectangle">
                                                            <img src="" />

                                                            <i className="fas fa-plus"></i>
                                                        </label>
                                                    </div>


                                                    {

                                                        arr.length - 1 === index ? null
                                                            : <button type="button" className="template-remove ml-4 mb-3">Remove</button>

                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button
                                                    className="template-add-button"
                                                    type="button">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <hr className="divider-line" />
                                <section>
                                    <label className="lehead">
                                        Title Two<span>*</span>
                                    </label>

                                    {this.state.titleTwoValues.map((element, index, arr) => (
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="lehead">Title</label>
                                                <input
                                                    type="text"
                                                    className="form-template"
                                                    name="serviceTitle" />
                                            </div>
                                            <div className="col-md-3">
                                                <div className="d-flex">
                                                    <div>
                                                        <label className="lehead">Description</label>

                                                        <textarea
                                                            type="text"
                                                            style={{ width: '300px' }}
                                                            className="form-template"
                                                            name="serviceDescription"
                                                            row="2" />
                                                    </div>
                                                    {

                                                        arr.length - 1 === index ? null
                                                            : <button type="button" className="template-remove ml-4 mt-2" >Remove</button>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button
                                                    className="template-add-button "
                                                    type="button"

                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <hr className="divider-line" />
                                <section>
                                    <label className="lehead">
                                        Title Three<span>*</span>
                                    </label>
                                    {this.state.titleThreeValues.map((element, index, arr) => (
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="lehead">Desription</label>
                                                <textarea

                                                    type="text"
                                                    className="form-template"
                                                    row="2"
                                                    name="contactDescription"

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="lehead">Image</label>
                                                <div className="d-flex">

                                                    <div className="profile-upload rect">
                                                        <label className="custom-file-upload rectangle">

                                                            <i className="fas fa-plus"></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    ))}

                                </section>
                                <hr className="divider-line" />
                                <section>
                                    <label className="lehead">
                                        Footer<span>*</span>
                                    </label>
                                    {this.state.footerValues.map((element, index, arr) => (
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="lehead">Label</label>
                                                <input
                                                    type="text"
                                                    className="form-template"
                                                    name="footerLabel" />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="lehead">Value</label>
                                                <input
                                                    type="text"
                                                    className="form-template"
                                                    name="footerTitle"

                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <div><label className="lehead" style={{ visibility: 'hidden' }}>Value</label></div>
                                                {

                                                    arr.length - 1 === index ? null
                                                        : <button type="button" className="template-remove  mb-3">Remove</button>
                                                }

                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button
                                                    className="template-add-button "
                                                    type="button">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <button
                                    onClick={() => this.expandCollapseTemplate(!this.state.expandTemplateThree, 'template_three')}
                                    className="btn btn-info btn-success mt-4 mb-5"
                                    type="submit"
                                >
                                    Cancel
                                </button>
                            </main>
                        </div>
                    }
                </div>
                <div>
                    <button type="cancel" className="expandCollapseTempalte" onClick={(e) => this.expandCollapseTemplate(!this.state.expandTemplateFour, 'template_four')}>Template 4 <span> {this.state.expandTemplateFour ? <i class="fa fa-chevron-up" aria-hidden="true"></i> : <i class="fa fa-chevron-down" aria-hidden="true"></i>}</span></button>

                    {this.state.expandTemplateFour &&
                        <div className="box mt-3">
                            <label class="templateTextCenter">Template 4</label>
                            <section className="p-4">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label class="lehead">
                                            Add Template Logo<span>*</span>
                                        </label>
                                        <div id="upload-right">
                                            <div className="profile-upload rect">
                                                <label className="custom-file-upload rectangle">

                                                    <i className="fas fa-plus"></i>
                                                </label>
                                                <label className="up-lbl">Upload an image</label>
                                                <br />
                                                <span className="up-span">(2MB Limit)</span>
                                            </div>
                                        </div>
                                        <button
                                            style={{ display: "none" }}
                                            className="btn btn-info btn-success mt-4"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>

                                <hr className="divider-line" />

                                <label class="lehead">Template Menu<span>*</span></label>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-8" >
                                            <label class="lehead mr-3">Menu Title</label>
                                            <div className="row">
                                                <div className="col-12 d-flex">
                                                    <input className="form-control" style={{ width: '300px' }} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="m-2">
                                            <button className="template-add-button" type="button">Add</button>
                                        </div>
                                    </div>
                                </div>

                                <hr className="divider-line" />
                                {/* Template banners */}
                                <div>
                                    <label style={{ marginLeft: "5px" }} class="lehead">
                                        Template Banners<span>*</span>
                                    </label>
                                    {this.state.bannerImageValues.map((element, index, arr) => (

                                        <div className=" row " >

                                            <div className="col-8 d-flex">
                                                <div className="m-2">
                                                    <label class="lehead">Name</label>
                                                    <input type="text" className='form-control' name="imageName" value={element.imageName || ""} />
                                                </div>
                                                <div className="m-2">

                                                    <div className="row">
                                                        <div className="col-8 d-flex">
                                                            {element.bannerPreview &&
                                                                <div className="m-0">
                                                                    <div>
                                                                        <label class="lehead">Image</label>

                                                                        <img name="bannerPreview" value={element.bannerPreview || ""} className="preview-image" src={element.bannerPreview}></img>
                                                                    </div>

                                                                </div>
                                                            }

                                                            {this.banners()}
                                                        </div>
                                                    </div>
                                                </div>
                                                {

                                                    arr.length - 1 === index ? null

                                                        : <div className="mt-5">
                                                            <button className='template-remove ' type="button" onClick={() => this.removeBannerFields(index, element, 'create')}>Remove</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <hr className="divider-line" />
                                <div>
                                    <h4>Section 1</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label class="lehead"> Title<span>*</span> </label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="col-md-3">
                                            <label class="lehead">
                                                Description<span>*</span>
                                            </label>
                                            <textarea type="text" className="form-template" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="m-2">
                                            <div className="row" >
                                                <div className="col-8 d-flex">
                                                    <div id="upload-right">
                                                        <div className="profile-upload rect">
                                                            <label className="custom-file-upload rectangle"><i className="fas fa-plus"></i></label>
                                                            <label className="up-lbl">Upload an image</label><br />
                                                            <span className="up-span">(2MB Limit)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label class="lehead">User Name</label>
                                                        <input className="form-control" type="text" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label class="lehead">User Designation</label>
                                                        <input className="form-control" type="text" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label class="lehead">User Descripition</label>
                                                        <textarea type="text" className="form-template" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button className="template-add-button" type="button">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="divider-line" />
                                <div>
                                    <h4>Section 2</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label class="lehead"> Title<span>*</span></label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="col-md-3">
                                            <label class="lehead"> Description<span>*</span></label>
                                            <textarea type="text" className="form-template" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="m-2">
                                            <div className="row">
                                                <div className="col-8 d-flex">

                                                    <div className="m-0">
                                                        <label class="lehead">Upload</label>
                                                        <div id="upload-right">
                                                            <div className="profile-upload rect">
                                                                <label className="custom-file-upload rectangle"><i className="fas fa-plus"></i></label>
                                                                <label className="up-lbl">Upload an image</label><br />
                                                                <span className="up-span">(2MB Limit)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label class="lehead">Title</label>
                                                        <input className="form-control" type="text" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label class="lehead">Description</label>
                                                        <textarea type="text" className="form-template" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button className="template-add-button" type="button">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="divider-line" />
                                <div>
                                    <h4>Section 3</h4>
                                    <div className="my-4">
                                        <div id="upload-right">
                                            <div className="profile-upload rect">
                                                <label className="custom-file-upload rectangle"><i className="fas fa-plus"></i></label>
                                                <label className="up-lbl">Upload an image</label><br />
                                                <span className="up-span">(2MB Limit)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label class="lehead"> Title<span>*</span> </label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="col-md-3">
                                            <label class="lehead"> Description<span>*</span></label>
                                            <textarea type="text" className="form-template" />
                                        </div>
                                    </div>

                                </div>
                                <hr className="divider-line" />
                                <div>
                                    <h4>Section 4</h4>
                                    <div className="my-4">
                                        <div id="upload-right">
                                            <div className="profile-upload rect">
                                                <label className="custom-file-upload rectangle"><i className="fas fa-plus"></i></label>
                                                <label className="up-lbl">Upload an image</label><br />
                                                <span className="up-span">(2MB Limit)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label class="lehead"> Title<span>*</span> </label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="col-md-3">
                                            <label class="lehead"> Description<span>*</span> </label>
                                            <textarea type="text" className="form-template" />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label class="lehead">Question</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                            <div className="col-md-3">
                                                <label class="lehead">Answer</label>
                                                <textarea type="text" className="form-template" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button className="template-add-button" type="button">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="divider-line" />
                                {/* Template footer */}
                                <div>
                                    <label className="lehead"> Footer<span>*</span> </label>
                                    {this.state.footerValues.map((element, index, arr) => (
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="lehead">Label</label>
                                                <input type="text" className="form-template" name="footerLabel" />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="lehead">Value</label>
                                                <input type="text" className="form-template" name="footerTitle" />
                                            </div>
                                            <div className="col-md-2">
                                                <div><label className="lehead" style={{ visibility: 'hidden' }}>Value</label></div>
                                                {
                                                    arr.length - 1 === index ? null
                                                        : <button type="button" className="template-remove  mb-3">Remove</button>
                                                }

                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="m-2">
                                                <button className="template-add-button " type="button"> Add </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => this.expandCollapseTemplate(!this.state.expandTemplateFour, 'template_four')}
                                        className="btn btn-info btn-success mt-4 mb-5"
                                        type="submit"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </section>
                        </div>
                    }
                </div>
            </div>
        )

    }
}
export default AllTemplateDesigns