import {ACTIVITY_HISTORY} from "../types";

const initialState = {
    activityhistory:[],
}

export default function activityhistoryreducer(state = initialState, action){

    switch(action.type){

        case ACTIVITY_HISTORY:
        return {
            ...state,
            activityhistory:action.response,
        }
        default: return state
    }

}