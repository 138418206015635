import React, { Component } from "react";
import swal from "sweetalert";

const handleSwalAlert = (alertData) => {
    if (alertData.alertType === 'error') {
        swal({
            text: 'Something went wrong, Please try again after sometime.',
            icon: alertData.alertType
        })
    }

    if (alertData.alertType === 'success' || alertData.alertType === 'warning') {
        swal({
            text: alertData.message,
            icon: alertData.alertType
        })
    }

    if (alertData.goToList === true) {
        swal({
            text: alertData.message,
            icon: alertData.alertType,
            closeOnEsc: alertData.closeOnEsc,
            closeOnClickOutside: alertData.closeOnClickOutside
        }).then(function () {
            alertData.propsData.history.push({
                pathname: alertData.pathname
            })
        })
    }
}
export default handleSwalAlert