import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { getChapters } from '../../Store/chapters/chapterListAction'
import { userManagementFetchInput, getPermissions, addUserForm, getCasemanagers } from '../../Store/UserManagement/newUserManagementAction';
import HttpService from '../../Services/HttpService';
import NumberFormat from 'react-number-format';
import { Circles } from 'react-loader-spinner';
import Model from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert  from '../SwalAlert'

var FormData = require('form-data');

const currentuser = JSON.parse(localStorage.getItem('userdata'));

class NewUserManagement extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      blobFile: '',
      isOpen: false,
      spinner: false,
      crop: {
        disabled: true,
        locked: true,
        unit: 'px',
        width: 100,
        height: 100
      },
      src: null,
      permissions: [],
      user_permissions: [],
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      user_type: '1',
      casemanagerid: '',
      profile_pic: '',
      image: null,
      hidePermissions: false,
      AllPermissions: [],
      addPermissions: [],
      dividepermisions: [],
      caseMangagers: [],
      user_case_manager: [],
      caseManagerCheckBox: '',
      OnAddToAgency: 'on',
      addtoAgencyValue: '0',
      caseManagerValue: '0',
      OnCaseManger: 'on',
      previewUploadImage: '',
      file_name: '',
      chaptersList: [],
      selected_chapters: [],
      addChapter: [],
      dividedChapters: [],
      selectedChapterIds: [],
      chapter_id: '',
      hideCaseManager: false,
      user_id: '',
      bigsSelectedChapterId: '',
      disablebuttons: false,
      sent_to: 'all'
    }
    this.onChange = this.onChange.bind(this);
  }
  /* Event occurs when the value of an element has been changed */
  handleUserInput(e, key) {
    let userInput;
    const user_permissions = this.state.user_permissions;
    const selected_case_manager = this.state.user_case_manager;
    let index
    console.log('check:- ', e.currentTarget.value)
    if (key == 'user_type') {
      if (e.currentTarget.value == "1") {
        this.state.user_type = '1'

        this.props.getPermissions()
        this.state.hidePermissions = true
        this.state.hideCaseManager = false
      }
      if (e.currentTarget.value == "2") {
        this.state.user_type = '2'
        this.state.selectedChapterIds = []
        this.state.permissions = true
        this.validator.message("permissions", this.state.user_permissions, "notrequired")
        this.validator.message("Chapters", this.state.selectedChapterIds, "notrequired")

        this.state.hidePermissions = false
        this.state.hideCaseManager = true
        this.props.newUserManagementDetails.permissions = []
        this.props.getCasemanagers()
        this.props.getChapters();
      }
      if (e.currentTarget.value == "4") {
        this.state.user_type = '4'
        this.validator.message("permissions", this.state.user_permissions, "notrequired")
        this.validator.message("Chapters", this.state.selectedChapterIds, "notrequired")
        this.state.hideCaseManager = false
      }

      this.setState({
        user_type: e.currentTarget.value
      })
      userInput = {
        key: key,
        value: e.currentTarget.value
      }
      if (key === 'first_name') {
        this.setState({
          first_name: e.currentTarget.value
        })
      }
      if (key === 'last_name') {
        this.setState({
          last_name: e.currentTarget.value
        })
      }
      if (key === 'email') {
        this.setState({
          email: e.currentTarget.value
        })
      }
      if (key === 'phone_number') {
        this.setState({
          phone_number: e.currentTarget.value
        })
      }
      this.props.userManagementFetchInput(userInput)
    }
    else if (key == 'permissions') {
      if (e.target.checked) {
        user_permissions.push(+e.target.value)
      } else {
        index = user_permissions.indexOf(+e.target.value)
        user_permissions.splice(index, 1)
      }
      this.setState({ user_permissions: user_permissions })
      this.state.user_permissions = user_permissions
      userInput = {
        key: key,
        value: this.state.permissions
      }
      if (key === 'first_name') {
        this.setState({
          first_name: e.currentTarget.value
        })
      }
      if (key === 'last_name') {
        this.setState({
          last_name: e.currentTarget.value
        })
      }
      if (key === 'email') {
        this.setState({
          email: e.currentTarget.value
        })
      }
      if (key === 'phone_number') {
        this.setState({
          phone_number: e.currentTarget.value
        })
      }
      this.props.userManagementFetchInput(userInput)
    }
    else if (key == 'sent_to') {
      this.setState({
        sent_to: e.currentTarget.value
      })

      if (e.currentTarget.value == 'selected') {
        this.props.getChapters();
      }
    }
    else {
      userInput = {
        key: key,
        value: e.currentTarget.value
      }
      if (key === 'first_name') {
        this.setState({
          first_name: e.currentTarget.value
        })
      }
      if (key === 'last_name') {
        this.setState({
          last_name: e.currentTarget.value
        })
      }
      if (key === 'email') {
        this.setState({
          email: e.currentTarget.value
        })
      }
      if (key === 'phone_number') {
        this.setState({
          phone_number: e.currentTarget.value
        })
      }
      if (key === "casemanager") {
        this.setState({
          casemanagerid: e.currentTarget.value
        })
      }
      if (key === "bigsuserchapters") {
        this.setState({
          bigsSelectedChapterId: e.currentTarget.value
        })
      }
      if (key === 'addToAgencyContacts') {
        if (this.state.OnAddToAgency == 'on') {
          this.state.OnAddToAgency = 'off'
          this.state.addtoAgencyValue = 1
        }
        else {
          this.state.OnAddToAgency = 'on'
          this.state.addtoAgencyValue = 0
        }

      }
      if (key === 'caseManagerCheckBox') {
        if (this.state.OnCaseManger == 'on') {
          this.state.OnCaseManger = 'off'
          this.state.caseManagerValue = 1
        }
        else {
          this.state.OnCaseManger = 'on'
          this.state.caseManagerValue = 0
        }
      }


      this.props.userManagementFetchInput(userInput)
    }
  }
  onChange(e) {
    this.setState({
      profile_pic: e.target.files[0],
      previewUploadImage: URL.createObjectURL(e.target.files[0]),
      file_name: e.target.files[0].name
    })

  }
  /* Goto user management list page */
  onCancel() {
    this.props.history.push('/dashboard/user_management');
  }
  /* Goto user management list page */
  onUserManagement() {
    this.props.history.push('/dashboard/user_management');
  }
  /** To select the chapters in multiselect dropdown */
  onSelectItem = (selected, ii) => {
    const chapter_ids = this.state.selectedChapterIds;
    console.log('selected:- ', ii.chapter_id)

    if (chapter_ids == null) {

      this.setState({
        selectedChapterIds: selected.map(id => (
          id.chapter_id
        ))
      })
    }
    else {
      chapter_ids.push(+ii.chapter_id)
      this.setState({
        selectedChapterIds: chapter_ids
      })
    }
  }
  /** To remove the chapters from multiselect drop down */
  onRemove = (removeusers, removeitem, yyy) => {
    const remove_chapter_ids = this.state.selectedChapterIds
    let index
    index = remove_chapter_ids.indexOf(-removeitem.chapter_id)
    remove_chapter_ids.splice(index, 1)
    this.state.selectedChapterIds = remove_chapter_ids
  }
  /** To call the api to submit the values */
  handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state
    this.setState({
      spinner: true,
      disablebuttons: true
    })

    const { blobFile } = this.state;
    const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });
    var data = new FormData();

    if (this.validator.allValid()) {
      var data = new FormData();
      data.append('first_name', this.props.newUserManagementDetails.formDetails.first_name);
      data.append('last_name', this.props.newUserManagementDetails.formDetails.last_name);
      data.append('phone_number', this.props.newUserManagementDetails.formDetails.phone_number);
      if (newImage.name == 'undefined') {
        data.append('profile_pic', '');

      }
      else {
        data.append('profile_pic', newImage);

      }
      data.append('user_type', this.state.user_type);
      data.append('email', this.props.newUserManagementDetails.formDetails.email);
      data.append('casemanagerid', this.state.casemanagerid);
      data.append('addto_agency', this.state.addtoAgencyValue);
      data.append('case_manager', this.state.caseManagerValue);
      data.append('created_by', this.state.user_id);
      data.append('chapter_id', this.state.bigsSelectedChapterId)
      for (var i = 0; i < this.state.user_permissions.length; i++) {
        this.state.addPermissions = 'user_permissions' + '[' + [i] + ']'
        this.state.dividepermisions = this.state.user_permissions[i]
        data.append(this.state.addPermissions, this.state.dividepermisions);
      }

      if (this.state.selectedChapterIds.length == 0) {
        data.append('user_chapters', 'all');
      }
      else {
        for (var i = 0; i < this.state.selectedChapterIds.length; i++) {
          this.state.addChapter = 'user_chapters' + '[' + [i] + ']'
          this.state.dividedChapters = this.state.selectedChapterIds[i]
          data.append(this.state.addChapter, this.state.dividedChapters);
        }
      }

      for (var pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      HttpService.adduser(data)
        .then(response => {
          console.log('response', response);
          if (response.data.status == 200) {
            this.setState({
              spinner: false,
              disablebuttons: false,
              email: ''
            })
            this.userform.reset();
            this.setState({
              permissions: [],
              caseMangagers: [],
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              user_type: '',
              profile_pic: '',
              image: null,
            })

            var successAlert = {
              message: response.data.message,
              alertType: "success",
              closeOnEsc: false,
              dangerMode: true,
              closeOnClickOutside: false,
              goToList : true,
              propsData : this.props,
              pathname: '/dashboard/user_management'
            }
            handleSwalAlert(successAlert)            
          }
          else {
            this.setState({
              spinner: false,
              disablebuttons: false,
              email: ''

            })

            var errorAlert = {
              message: response.data.error,
              alertType: "warning",
            
            }
            handleSwalAlert(errorAlert)
          }
        })
        .catch(error => {
          this.setState({
            spinner: false,
            disablebuttons: false,
            email: ''
          })
          var errorAlert = {          
            alertType: "error",          
          }
          handleSwalAlert(errorAlert)
        })
      this.validator.hideMessages();
    }
    else {
      this.setState(
        {
          spinner: false,
          disablebuttons: false
        }
      )
      this.validator.showMessages();
    }
  }
  /** To make the api call initial page load */
  componentDidMount() {
    this.props.getPermissions();
  }
  /** To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.setState({
      permissions: nextProps.newUserManagementDetails.permissions,
      caseMangagers: nextProps?.newUserManagementDetails.caseManager.data?.case_managers,
      chaptersList: nextProps.newUserManagementDetails.chaptersList.chapters_list
    }, console.log('chapters list:- ', this.state.chaptersList));
    console.log('nextProps:- ', nextProps)
  }
  /** To select the file from device */
  onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ isOpen: true, src: null })
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result, file_name: e.target.files[0].name })
      );
      reader.readAsDataURL(e.target.files[0]);

    }
  };
  /** This is a callback that is triggered when the image gets loaded. */
  onImageLoaded = (image) => {
    this.imageRef = image;
    return false
  };
  /** A callback which happens after a resize, drag, or nudge. Passes the current crop state object in pixels and percent. */
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  /** change the aspect ratio of crop tool as you preferred */
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };
  /** To convert the cropped image into url */
  async makeClientCrop(crop) {
    if (document.getElementById("photo") && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        document.getElementById("photo"),
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }
  /* To get the cropped image */
  getCroppedImg(image, crop, fileName) {

    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = document.getElementById("photo").naturalWidth / document.getElementById("photo").width;
    const scaleY = document.getElementById("photo").naturalHeight / document.getElementById("photo").height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      document.getElementById("photo"),
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          this.setState({ blobFile: blob })
          resolve(this.fileUrl);
        }, 'image/jpeg',
        1
      );
    });
  }
  /* To close the dialog */
  handleCloseDialog = () => {
    this.setState({ isOpen: false, src: null, croppedImageUrl: '', file_name: '' });
  };
  /* To open the dialog */
  handleOpenDialog = () => {

    this.setState({ isOpen: true });
  };
  /* To save the cropped image */
  saveCroppedImage() {
    const { blobFile } = this.state;
    const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });
    var data = new FormData();
    this.setState({
      previewUploadImage: newImage,
      isOpen: false, src: null
    })
  }

  /* render() function is used to update the UI. */
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));

    this.state.user_id = this.currentuser.user.user_id

    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))

    }
    return (
      <div className="container">
        <Row className="mt-3 mb-4">
          <Col xs lg="12">
            <div className="horizontal-container">
              <label className="label-discount" onClick={() => this.onUserManagement()}>User Management</label>
              <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
              <label className="label-discount">New User</label>
            </div>
          </Col>
        </Row>
        <section className="newuser-sec">
          <form className="form" ref={form => this.userform = form}>
            <div className="mb-3">
              <div>
                <label className="lehead">User Type<span>*</span></label>
              </div>
              <Row>
                <Col md="2" className="custom-control custom-radio">
                  <input type="radio" className="radio" id="agencyUser" name='user_type' value='1' checked={this.state.user_type == '1'}
                    onChange={(e) => { this.handleUserInput(e, 'user_type') }} />
                  <label className="custom-control-label" htmlFor="agencyUser">Agency User</label>
                </Col>
                <Col md="2" className="custom-control custom-radio">
                  <input type="radio" className="radio" id="bigsUser" name="user_type" value='2' checked={this.state.user_type == '2'}
                    onChange={(e) => { this.handleUserInput(e, 'user_type') }} />
                  <label className="custom-control-label" htmlFor="bigsUser">BigS User</label>
                </Col>
                <Col md="2" className="custom-control custom-radio">
                  {this.currentuser.user.usertypeid == 5 ? <div>
                    <input type="radio" className="radio" id="Organization" name="user_type" value='4' checked={this.state.user_type == '4'}
                      onChange={(e) => { this.handleUserInput(e, 'user_type') }} />
                    <label className="custom-control-label" htmlFor="Organization">Organization User</label>
                  </div> : null}
                </Col>
              </Row>
              <span className="text-danger">{this.validator.message("user_type", this.state.user_type, "required")}</span>
            </div>
            <Row className="mb-3">
              <Col md="3">
                <div className="form-group">
                  <label for="First Name" className="lehead">First Name<span>*</span></label>
                  <input type="text" id="First Name" className="form-control" name="First Name" onChange={(e) => { this.handleUserInput(e, 'first_name') }} />
                  <span className="text-danger">{this.validator.message("first_name", this.state.first_name, "required|alpha")}</span>
                </div>
              </Col>
              <Col dangerMode="3">
                <div className="form-group">
                  <label for="Last Name" className="lehead">Last Name<span>*</span></label>
                  <input type="text" id="Last Name" className="form-control" name="Last Name" onChange={(e) => { this.handleUserInput(e, 'last_name') }} />
                  <span className="text-danger">{this.validator.message("last_name", this.state.last_name, "required|alpha")}</span>
                </div>
              </Col>
              <Col md="3">
                <div className="form-group">
                  <label for="Email" className="lehead">Email Address<span>*</span></label>
                  <input type="text" id="Email" className="form-control" name="Email" onChange={(e) => { this.handleUserInput(e, 'email') }} />
                  <span className="text-danger">{this.validator.message("email", this.state.email, "required|email")}</span>
                </div>
              </Col>
              <Col md="3">
                <div className="form-group">
                  <label for="Phone Number" className="lehead">Phone Number<span>*</span></label>
                  <NumberFormat format="(###) ###-####" id="Phone Number" mask='' type="tel" className="form-control" name="Phone Number" onChange={(e) => { this.handleUserInput(e, 'phone_number') }} />
                  <span className="text-danger"> {this.validator.message("phone_number", this.state.phone_number, "required|min:14")}</span>
                </div>
              </Col>
            </Row>
            {this.state.user_type == 1 ?
              <div>
                <div className="mb-3">
                  <label className="lehead">User Permissions*</label>
                  <Row>
                    {this.state.permissions?.map((item, index) =>
                      <Col xs lg="3">
                        <div className="form-check-inline usermgmt-checkbox">
                          <label style={{ fontSize: '15px' }} className="checkbox-container">{item.permission_name}
                            <input type="checkbox" className='checkbox' id="inlineCheckbox1" name="groupPermissions[]"
                              checked={this.state.user_permissions.includes(item.permission_id)}
                              onChange={(e) => { this.handleUserInput(e, 'permissions') }} value={item.permission_id} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </Col>
                    )}
                    <span className="text-danger">{this.validator.message("permissions", this.state.user_permissions, "required")}</span>
                  </Row>
                </div>
                <div className="mb-3">
                  <label className="lehead">Chapters*</label>
                  <Row>
                    <Col xs lg="2">
                      <input checked={this.state.sent_to == 'all'} type="radio" id="defaultRadio" name="sent_to" value='all' onChange={(e) => this.handleUserInput(e, 'sent_to')} />
                      <label for="defaultRadio" className="lehead">All Chapters</label>
                    </Col>
                    <Col xs lg="2">
                      <input checked={this.state.sent_to == "selected"}
                        type="radio" id="defaultRadioone" name="sent_to" value="selected" onChange={(e) => this.handleUserInput(e, 'sent_to')} />
                      <label className="lehead"
                        for="defaultRadioone"
                      >Select Chapters</label>
                    </Col>
                  </Row>
                  <Row>
                    {this.state.sent_to == 'selected' ?
                      <Col xs lg="3" className="addadency-sec">
                        <Multiselect placeholder="Select Chapters" options={this.state.chaptersList} displayValue={'chapter_name'}
                          onSelect={this.onSelectItem} onRemove={this.onRemove} selectedValues={this.state.selected_chapters}
                          style={{
                            chips: { border: 'none', 'borderRadius': '15px' },
                            multiselectContainer: { color: '#8f9199' },
                            searchBox: { border: 'none', 'border': '1px solid gray', 'borderRadius': '5px' }
                          }}
                          className="multiselect"
                        />
                        <span className="text-danger">{this.validator.message("Chapters", this.state.selectedChapterIds, "required")}</span>
                      </Col> : null}
                  </Row>
                </div>
                <Row>
                  <div>
                <label  data-tooltip="Enabling this setting will display this   agency user in the mobile app contacts list for BIGS to contact!"
                      data-tooltip-location="right" className="lehead">Add to Agency Contacts: <i style={{ color: "#cecfd0", marginLeft: "10px" }} class="fa fa-info-circle" aria-hidden="true"></i></label>
                    </div>
                  <Col xs lg="6" className="addadency-sec">
                    <div>
                      <label>
                        <span>No</span>
                        <label class="switchtog">
                          <input type="checkbox" value={this.state.OnAddToAgency} onChange={(e) => this.handleUserInput(e, 'addToAgencyContacts')} />
                          <span class="slidered"></span>
                        </label>

                        <span>Yes</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs lg="6" className="addadency-sec">
                    <label className="lehead">Case Manager: </label>
                    <div>
                      <label>
                        <span>No</span>
                        <label class="switchtog">
                          <input type="checkbox" value={!this.state.OnCaseManger} onChange={(e) => this.handleUserInput(e, 'caseManagerCheckBox')} />
                          <span class="slidered"></span>
                        </label>
                        <span>Yes</span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </div> : null}

            {this.state.hideCaseManager ? <Row className="my-3">
              <Col xs lg="6" className="addadency-sec">
                <label className="lehead">Case Manager: </label>
                <div>
                  <select onChange={(e) => this.handleUserInput(e, 'casemanager')} style={{ width: '200px', height: '40px', padding: '5px' }} >
                    <option value="">Select Case Manager</option>
                    {this.state.caseMangagers?.map((e) => {
                      return <option value={e.user_id}>{e.display_name}</option>;
                    })}
                  </select>
                </div>
              </Col>
            </Row> : null}

            {this.state.hideCaseManager ?
              <Row>
                <Col xs lg="6" className="addadency-sec">
                  <label className="lehead">Chapters: </label>
                  <div>
                    <select onChange={(e) => this.handleUserInput(e, 'bigsuserchapters')} style={{ width: '200px', height: '40px', padding: '5px' }}>
                      <option value="">Select Chapters</option>
                      {this.state.chaptersList?.map((e) => {
                        return <option value={e.chapter_id}>{e.chapter_name}</option>;
                      })}
                    </select>
                  </div>
                </Col>
              </Row> : null}
            <div className="my-4">
              <label className="lehead">Profile Image</label>
              <div id="wrapper">
                <div id="upload-right">
                  <div className="profile-upload circle">
                    <label className="custom-file-upload circle">
                      <input type="file" name="myImage" href="{this.fileInput}" className="form-control" onChange={this.onFileChange} />

                      {src ?
                        <Model isOpen={this.state.isOpen} shouldCloseOnEsc={true} ariaHideApp={false} onRequestClose={this.handleCloseDialog}>
                          <ReactCrop
                            src={src}
                            circularCrop={true}
                            for='photo-upload'
                            crop={crop}
                            locked
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange} >
                            <img id='photo' src={src}></img>
                          </ReactCrop>

                          <Row className="my-4">
                            <Col xs lg="6" className="save-sec">
                              <button type="submit" className="btn btn-info btn-success mt-1" onClick={() => { this.saveCroppedImage() }}>Save</button>
                            </Col>
                            <Col xs lg="6">
                              <div className="cancel-sec">
                                <button type="cancel" className="cancelbtnnew" onClick={() => this.handleCloseDialog()}>Cancel</button>
                              </div>
                            </Col>
                          </Row>
                        </Model> :
                        <img for="photo-upload" className="img-circle elevation-2" />
                      }

                      {
                        croppedImageUrl ?
                          <div className="profile-upload circle">
                            <img className="img-circle elevation-2"
                              style={{ width: '80px', height: '80px' }}
                              src={croppedImageUrl}
                            />
                          </div> : <i className="fas fa-plus"></i>
                      }
                    </label>
                    {croppedImageUrl ? null :
                      <div> <label className="up-lbl">Upload an image</label><br />
                        <h8 style={{ color: '#4d4f5c', fontSize: '11px' }} className="up-lbl italic-mb">(2MB Limit)</h8>
                      </div>}
                    <span className="up-span">{this.state.file_name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="App">
              <center>
                <div class="loader-spinner">
                  {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                  }
                </div>
              </center>
            </div>
            <Row className="my-4">
              <Col md="6">
                <div className="save-sec">
                  <button type="submit" disabled={this.state.disablebuttons} className="btn btn-info btn-success mt-1" onClick={(e) => { this.handleSubmit(e) }}>Save</button>
                </div>
              </Col>
              <Col md="6">
                <div className="cancel-sec">
                  <button type="cancel" disabled={this.state.disablebuttons} className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                </div>
              </Col>
            </Row>
          </form>
        </section>
      </div>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    newUserManagementDetails: state.newUserManagementDetails,
    chaptersList: state.chaptersList
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { userManagementFetchInput, getPermissions, addUserForm, getCasemanagers, getChapters })(withRouter(NewUserManagement))