import React, { Component } from 'react';
import '../../assets/css/style.css';
import { connect } from 'react-redux';
import { RecievedMessages, sentMessages, draftMessages, trashMessages, deleteMessageService, viewMessagedata } from '../../Store/messages/allMessageAction';
import ViewMessage from './ViewMessage';
import NewMessage from './NewMessage';
import DraftNewMessage from './DraftNewMessage';
import { Circles } from 'react-loader-spinner';
import { Row, Col, Button } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'
const DATE_OPTIONS = { month: 'short', day: 'numeric' };

class MessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recievedMessages: [],
      Uid: '',
      message_id: '',
      activeScreen: '',
      spinner: false,
      typeToRefresh: '',
      dataview: { props },
      allViewMessage: '',
      DefaultMessage: '',
      searchedMessages: [],
      displayedMessages: [],
      messagesSearchInput: '',
      inbox_unread_count: 0,
      draft_unread_count: 0,
      senderId: 0

    }
  }
  /* To make the api call initial page load */
  componentDidMount() {

    this.state.spinner = true
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user_id;
    this.setState({
      Uid: user_id,
      typeToRefresh: 'inbox'
    });

    this.props.RecievedMessages()
    if (this.props.location.state != null) {
      this.state.allViewMessage = this.props.location.state.detail.source_id
      this.onViewmessage(this.state.allViewMessage)
    }
  }
  /* refresh the list of message when any action done */
  listofMessagesTypes = (event, typeOfMessage) => {
    this.state.typeToRefresh = typeOfMessage
    this.state.spinner = true
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user_id;
    this.setState({ Uid: user_id });

    if (typeOfMessage === "inbox") {
      this.state.recievedMessages = []
      this.setState({
        activeScreen: ''
      })
      this.props.RecievedMessages();

    }
    else if (typeOfMessage === "sent") {
      this.state.recievedMessages = []
      this.setState({
        activeScreen: ''
      })
      this.props.sentMessages();

    }
    else if (typeOfMessage === "drafts") {
      this.state.recievedMessages = []
      this.setState({
        activeScreen: ''
      })
      this.props.draftMessages();

    }
    else if (typeOfMessage === "trash") {
      this.state.recievedMessages = []
      this.setState({
        activeScreen: ''
      })
      this.props.trashMessages();

    }
  }
  /* to compose the message */
  onCompose() {
    this.setState({
      activeScreen: 'compose'
    })
  }
  /* To view the message */
  onViewmessage(item, data) {

    if (data === 'direct') {
      if (this.state.typeToRefresh === 'drafts') {
        this.setState({
          activeScreen: 'draftcompose',
          data: { item },
          typeOfMessage: this.state.typeToRefresh,
          senderId: item.sender_id
        })
      }
      else {
        this.setState({
          activeScreen: 'messageview',
          messageId: item.message_id,
          typeOfMessage: this.state.typeToRefresh,
          senderId: item.sender_id
        })
      }
    }
    else {
      this.setState({
        activeScreen: 'messageview',
        messageId: this.state.allViewMessage,
        typeOfMessage: this.state.typeToRefresh,
        senderId: item.sender_id
      })
    }
  }
  /* To delete the message */
  deleteMessage(message_id) {
    this.props.deleteMessageService(message_id);
    var successAlert = {
      message: "Message Deleted Successfully",
      alertType: "success"
    }
    handleSwalAlert(successAlert)

  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.spinner = false
    this.state.recievedMessages = nextProps.Messageslistdata.allMessageList.list
    this.state.inbox_unread_count = nextProps.Messageslistdata.allMessageList.inbox_count
    this.state.draft_unread_count = nextProps.Messageslistdata.allMessageList.drafts_count
  }
  /* For search the messagae in the list  */

  searchHandler = (event, typeOfMessage) => {
    if (event.target.value.length === 0) {
      this.setState({
        spinner: true,
        messagesSearchInput: ''
      })
      this.currentuser = JSON.parse(localStorage.getItem('userdata'));
      var user_id = this.currentuser.user_id;

      if (this.state.typeToRefresh === "inbox") {
        this.state.recievedMessages = []
        this.props.RecievedMessages();
        this.state.spinner = false;
      }
      else if (this.state.typeToRefresh === "sent") {
        this.state.spinner = false
        this.state.recievedMessages = []
        this.props.sentMessages();
        this.state.spinner = false
      }
      else if (this.state.typeToRefresh === "trash") {
        this.state.spinner = false
        this.state.recievedMessages = []
        this.props.trashMessages()
        this.state.spinner = false
      }
      else if (this.state.typeToRefresh === "drafts") {
        this.state.spinner = false
        this.state.recievedMessages = []
        this.props.draftMessages()
        this.state.spinner = false
      }
    }
    else {
      let searcjQery = event.target.value,
        displayedMessages = this.state.recievedMessages.filter((el) => {
          if (el.first_name === null) {
            return ""
          }
          else {
            let searchValue = el.first_name;
            return searchValue.toLowerCase().indexOf(searcjQery.toLowerCase()) !== -1;
          }
        })
      if (displayedMessages.length > 0) {
        this.setState({ DefaultMessage: '', searchedMessages: displayedMessages, messagesSearchInput: event.target.value })
      }
      else {
        this.setState({ DefaultMessage: 'No Messages Found.', searchedMessages: [] })
      }
    }
  }
  /* after delete the message the view message section will get empty */
  quitComposeScreen = () => {
    this.setState({
      activeScreen: '',
    })
  }
  /* To make the api call initial page load */
  componentDidUpdate(prevProps) {
    if (this.props.location.state != null) {
      if (this.props.location.state.detail.source_id !== prevProps.location.state.detail.source_id) {

        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        var user_id = this.props.location.state.detail.user_id;
        var message_id = this.props.location.state.detail.source_id;
        this.props.viewMessagedata({ message_id, user_id });
      }
    }
  }
  /* render() function is used to update the UI. */

  render() {
    const { inbox_unread_count, draft_unread_count } = this.state
    localStorage.setItem("activescreen", JSON.stringify('messagelist'));
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 mb-4">
            <h3 className="pagename">Messaging</h3>
          </div>
        </div>
        <section className="msg-mailbox-sec">
          <Row>
            <Col xs lg="2">
              <div className="mail-main-sec">
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" onClick={() => this.onCompose()}>Compose</button>
                </div>
                <hr />
                <div className="userManagement-page">
                  <div className="tabs-sec">
                    <ul className="mail-items-list nav nav-tabs">
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" onClick={(event) => { this.listofMessagesTypes(event, 'inbox') }} href="#">Inbox <span style={{ float: 'right' }} className="mail-list-num-active"><b>{inbox_unread_count > 0 ? (inbox_unread_count) : null}</b></span> </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listofMessagesTypes(event, 'sent') }} href="#">Sent</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listofMessagesTypes(event, 'drafts') }} href="#">Drafts <span style={{ float: 'right' }} className="mail-list-num-active"><b>{draft_unread_count > 0 ? (draft_unread_count) : null}</b></span></a></li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listofMessagesTypes(event, 'trash') }} href="#">Trash</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs lg="4">
              <div className="mail-users-sec">
                <div>
                  <span className="fa fa-search head-search"></span>
                  <input onChange={(e) => this.searchHandler(e)} style={{ paddingLeft: '40px' }} className="form-control" type="text" placeholder="Search Message or Name " name="search2" />
                </div>

                <center>
                  <div class="loader-spinner">
                    {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                    }
                  </div>
                </center>
                {
                  this.state.messagesSearchInput.length ? this.state.searchedMessages.length ? this.state.searchedMessages.map(item =>
                    <div className="users-styling">
                      <div className="media  p-3">
                        <div className="media-body" key={item.message_id}>
                          <div className="float-container">
                            <div className='d-flex' style={{ width: '100%' }}>
                              <div className='mr-4'>
                                <img className="image-circle" src={item.profile_pic} width="50" height="50" />

                              </div>
                              <div onClick={() => this.onViewmessage(item, 'direct')} style={{ width: '100%' }}>
                                {item.viewed_status == '0' ?
                                  <h8 style={{ color: '#4D4F5C', marginLeft: '-15px' }} ><b>{item.first_name + " " + item.last_name}</b> <small style={{ float: "right" }}>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8> :
                                  <h8 style={{ color: '#4D4F5C', marginLeft: '-15px' }} >{item.first_name + " " + item.last_name} <small style={{ float: "right" }}>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>}
                                {item.subject != null ?
                                  <h9 style={{ color: '#4D4F5C', marginLeft: '-15px' }} className="truncate">{item.subject}</h9> :
                                  <h9 style={{ color: '#4D4F5C', marginLeft: '-15px' }} className="truncate">(No Subject)</h9>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : <h4 className="App">You don't have any messages</h4> :

                    this.state.recievedMessages.length ? this.state.recievedMessages.map(item =>
                      <div className="users-styling">
                        <div className="media  p-3">
                          <div className="media-body" key={item.message_id}>
                            <div className="float-container">
                              <div className="d-flex" style={{ width: '100%' }}>
                                <div className="mr-4">
                                  <img className="image-circle mgslist-img" src={item.profile_pic} />

                                </div>
                                <div onClick={() => this.onViewmessage(item, 'direct')} style={{ width: '100%' }}>
                                  {item.viewed_status == '0' && this.state.typeToRefresh == 'sent' ?
                                    <h8 style={{ color: '#4D4F5C', marginLeft: '-15px' }}>{item.first_name + " " + item.last_name} <small style={{ float: "right" }}>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8> :
                                    item.viewed_status == '0' ?
                                      <h8 style={{ color: '#4D4F5C', marginLeft: '-15px' }}><b>{item.first_name + " " + item.last_name}</b> <small style={{ float: "right" }}>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8> :
                                      <h8 style={{ color: '#4D4F5C', marginLeft: '-15px' }}>{item.first_name + " " + item.last_name} <small style={{ float: "right" }}>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>}
                                  {item.subject != null ?
                                    <h9 style={{ color: '#4D4F5C', marginLeft: '-15px' }} className="truncate">{item.subject}</h9> :
                                    <h9 style={{ color: '#4D4F5C', marginLeft: '-15px' }} className="truncate">(No Subject)</h9>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : <h4 className="App">You don't have any messages</h4>
                }
              </div>
            </Col>
            <Col xs lg="6">
              <div className="mail-usercontent-sec">
                <div className="main-preventative-heading">
                  {
                    this.state.activeScreen == 'messageview' ?
                      <ViewMessage quitScreen={this.quitComposeScreen.bind(this)} messageId={this.state.messageId} typeOfMessage={this.state.typeOfMessage} senderId={this.state.senderId} />
                      : this.state.activeScreen == 'draftcompose' ?
                        <DraftNewMessage
                          quitScreen={this.quitComposeScreen.bind(this)} data={this.state.data} typeOfMessage={this.state.typeOfMessage}
                        />
                        : this.state.activeScreen == 'compose' ?
                          <NewMessage
                            quitScreen={this.quitComposeScreen.bind(this)}
                          />
                          : <div style={{ display: 'flex', justifyContent: 'center', height: '70vh' }} >
                            <h3 style={{ display: 'flex', alignItems: 'center', color: '#ccccd4' }} className="pagename">Select a Message</h3>

                          </div>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    Messageslistdata: state.Messageslistdata
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { RecievedMessages, sentMessages, draftMessages, deleteMessageService, trashMessages, viewMessagedata })(MessagesList)