import { CHAPTERS_ERROR, CHAPTERS_LIST } from "../types";

const initialState = {
    chapterslist:[],
    chaptersErrorData : {}
}

export default function chaptersListreducer(state = initialState, action){

    switch(action.type){

        case CHAPTERS_LIST:
        return {
            ...state,
            chapterslist:action.response,
        }
        case CHAPTERS_ERROR:
            return {
                ...state,
                chaptersErrorData : action.response
            }
        default: return state
    }

}