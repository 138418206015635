import {CHAPTERS_LIST,CHAPTERS_ERROR} from '../types';
import HttpService from '../../Services/HttpService';

export const chaptersData = (response) =>{
    return {
        type: CHAPTERS_LIST,
        response: response
    }
}
export const chapterserror = (response) => {
    return {
        type: CHAPTERS_ERROR,
        response:response
    }
}

export const getChapters = () => {
    return (dispatch) => {
        HttpService.chapterslist()
        .then(response => {
            console.log("chapters responce:- ",response)
            dispatch(chaptersData(response.data));
        })
        .catch(error => {
            dispatch(chapterserror());
        })
    }
}

export const getChaptersAjax = (data) => {
    return (dispatch) => {
        HttpService.chapterslistAjax(data)
        .then(response => {
            console.log("chapters responce:- ",response)
            dispatch(chaptersData(response.data));
        })
        .catch(error => {
            dispatch(chapterserror(error.response));
        })
    }

}
    

