import { ACTIVITY_FETCH_INPUT, ACTIVITY_ADD, ACTIVITY_ADD_ERROR } from "../types";

let initialState = {
    acitivityDetails: {},
    addacitivity_res: '',
    error: false,
}

export default function addActivityReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVITY_FETCH_INPUT:
            return {
                ...state,
                acitivityDetails: {
                    ...state.acitivityDetails,
                    [action.Input.key]: action.Input.value
                }
            }
        case ACTIVITY_ADD:
            return {
                ...state,
                addacitivity_res: action.response
            }

        case ACTIVITY_ADD_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}