import React, { Component } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import './templatethree.css'
import "react-multi-carousel/lib/styles.css";
import 'react-alice-carousel/lib/alice-carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};
class BodyConent extends Component {
    /* render() function is used to update the UI. */
    render() {
        var settingsdiscounts = {
            dots: true,
            infinite: JSON.parse(
              localStorage.getItem("ViewDiscountData")
            )?.discounts?.length > 4,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [{
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            }]
          };
        var settingsphotos = {
            dots: true,
            infinite: JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.userphotos?.length > 4,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }]
        }
        var settingsevents = {
            dots: true,
            infinite: JSON.parse(
                localStorage.getItem("ViewTemplateData")
              )?.template.events?.length > 3,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }]
        }
        return (
            <>
                <main className="my-5">
                    <div className="container">
                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.about != 0 ?
                            <>
                                <h2 class="section-title"><span className="underline">About </span></h2>

                                <section className="section-bg">
                                    <div className="row">
                                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.about.map((about, index) => {
                                            return <>
                                                <div className="col-md-6 mb-4">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img src={about?.files} style={{ height: '100%', width: '100%' }} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <h2>{about?.title}</h2>
                                                            <p class="feat-sec"><ReactReadMoreReadLess
                                                                charLimit={100}
                                                                readMoreText={"Read more ▼"}
                                                                readLessText={"Read less ▲"}
                                                            >
                                                                {about?.description}
                                                            </ReactReadMoreReadLess></p>
                                                        </div>
                                                    </div>
                                                </div></>
                                        })}
                                    </div>
                                </section></> : null}
                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.services != 0 ?
                            <>
                                <h2 class="section-title"><span className="underline">Services</span></h2>
                                <section className="section-bg">

                                    <div class="row">
                                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.services.map((services, index) => {
                                            return <>
                                                <div class="col-sm-6 col-md-3 ">
                                                    <h3 class="text-center captalise">{services?.title}</h3>
                                                    <p class="feat-sec"><ReactReadMoreReadLess
                                                        charLimit={100}
                                                        readMoreText={"Read more ▼"}
                                                        readLessText={"Read less ▲"}
                                                    >
                                                        {services?.description}
                                                    </ReactReadMoreReadLess></p>
                                                </div>
                                            </>
                                        })}
                                    </div>
                                </section>
                            </> : null}
                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.contactus != 0 ?
                            <>
                                <h2 class="section-title"><span className="underline">Discounts</span></h2>
                           

                                    <div className="row">
                                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.contactus.map((contactus, index) => {
                                            return <div className="col-md-6 ">
                                                <div className="row section-bg">
                                                <div className="col-md-6">
                                                    <img src={contactus?.contact_files} style={{ height: '150px', width: '100%' }} />

                                                </div>
                                                <div className="col-md-6">
                                                    <p class="feat-sec">{contactus?.contact_description}<br />
                                                    </p>
                                                </div>
                                                </div>
                                                </div>
                                        })}

                                    </div>
                          
                            </>
                            : null}

                        {/* All Photos */}

                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.userphotos != undefined ? <>
                            <h1 className="text-center">All Photos</h1>
                            <hr class="divider"></hr>
                            <Slider {...settingsphotos}>
                                {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.userphotos?.map((users, index) => {
                                    return <div className="col">
                                        <div className="gallery-item">


                                            <img className="gallery-image" src={users.photo_name} style={{ height: '230px' }} />

                                        </div>


                                    </div>
                                })
                                }
                            </Slider>
                        </> : null}
                        {/* Events */}
                        {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.events != 0 ?
                            <>
                                <h1 className="text-center">Events</h1>
                                <hr class="divider"></hr>
                                <Slider {...settingsevents}>
                                    {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.events.map((element, index) => {
                                        return <div className="col mt-3">
                                            <div className="p-4 gallery-item" style={{ backgroundColor: 'white', width: '360px' }}>

                                                <div className="d-flex">
                                                    <h6><b>Event Name:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element?.event_name}</h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>Description:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                                                        charLimit={25}
                                                        readMoreText={"Read more ▼"}
                                                        readLessText={"Read less ▲"}
                                                    >
                                                        {element?.description}
                                                    </ReactReadMoreReadLess></h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>Start Date & Time:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.start_date + " " + element.start_time}</h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>End Date & Time:-</b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.end_date + " " + element.end_time}</h6>
                                                </div>
                                                <div className="d-flex">
                                                    <h6><b>Available Seats:-</b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.available_seats}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    })


                                    }
                                </Slider>


                            </> : null}

                        {/* Discounts */}

                        {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts != 0 ?
                            <>
                                <h1 className="text-center mt-5">Discounts</h1>
                                <hr class="divider"></hr>

                                <Slider {...settingsdiscounts}>
                                    {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts?.map((element, index) => {
                                        return <div className="col mt-3">
                                            <div className="p-2 gallery-item" style={{ backgroundColor: 'white' }}>

                                                <div className="d-flex">
                                                    <h6><b>Location:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.program_name}</h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>Description:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                                                        charLimit={25}
                                                        readMoreText={"Read more ▼"}
                                                        readLessText={"Read less ▲"}
                                                    >
                                                        {element?.description}
                                                    </ReactReadMoreReadLess></h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>Start Date:- </b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.start_date}</h6>
                                                </div>

                                                <div className="d-flex">
                                                    <h6><b>End Date:-</b></h6>
                                                    <h6 style={{ marginLeft: "5px" }}>{element.end_date}</h6>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                    }
                                </Slider>
                            </> : null}
                    </div>
                </main>
            </>
        )
    }
}
export default BodyConent;