import React, { Component } from 'react';
// import Login from './components/Login/Loginpage';
import Dashboard from './components/Dashboard/Dashboard'
import history from './history';
import { Router, Switch, Route } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css'
import NewDashBoard from './components/Dashboard/NewDashBoard';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ViewTemplate from './components/Templates/ViewTemplate';
import TemplateTwo from './components/Templates/TemplateTwo/Template';
import TempleteOne from './components/Templates/TemplateOne/Template';
import TempleteThree from './components/Templates/TemplateThree/Template';
import TemplateFour from './components/Templates/TemplateFour/Template';
import Home from './components/Bigs_ConnectNew/Home';
import LogIn from './components/Bigs_ConnectNew/LogIn';
import LogInOld from './components/Login/Loginpage';
import SignUp from './components/Bigs_ConnectNew/SignUp';
import Pricing from './components/Bigs_ConnectNew/Pricing';
import Features from './components/Bigs_ConnectNew/Features';
import ForgotPassword from './components/Bigs_ConnectNew/ForgotPassword';
import { connect } from 'react-redux';

import { getswitchToFeatureData } from './Store/switchToFeatures/swithToFeatureAction';
import swal from 'sweetalert';
import { Icon } from '@material-ui/core';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter_data: JSON.parse(localStorage.getItem("chpaterdata"))
    }

  }

  render() {

    console.log('appjsdata:-', this.state.chapter_data)
    const chaptername = '/' + this.state.chapter_data?.view_temp_chapter_name

    return (
      <Router history={history}>
        <Switch>
          {/* <Route exact path='/dashboard' component={Dashboard} /> */}

          {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ?
            <Route path='/' exact component={Home} /> :
            <Route path='/' exact component={Home} />}

          <Route path='/login' component={LogIn} />
          {this.state.chapter_data?.template?.templateid === 1 &&
            <Route path={chaptername} component={TempleteOne} />
          }

          <Route path='/signup' component={SignUp} />

          {this.state.chapter_data?.template?.templateid === 2 &&
            <Route path={chaptername} component={TemplateTwo} />
          }

          <Route path='/pricing' component={Pricing} />
          {this.state.chapter_data?.template?.templateid === 3 &&
            <Route path={chaptername} component={TempleteThree} />}


          <Route path='/features' component={Features} />
          {this.state.chapter_data?.template?.templateid === 4 &&
            <Route path={chaptername} component={TemplateFour} />
          }

          <Route path='/forgotPassword' component={ForgotPassword} />
          {/* <Route path='/' exact component={Login} /> */}
          <Route path='/dashboard/' component={Dashboard} />
          <Route path='/Bigs_ConnectNew/Home' component={Home} />


          {/* <Route path='/Bigs_ConnectNew/LogIn' component={LogIn} />
          <Route path='/Bigs_ConnectNew/SignUp' component={SignUp} />
          <Route path='/Bigs_ConnectNew/ForgotPassword' component={ForgotPassword} /> */}
        </Switch>
      </Router>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    switchToFeatures: state.switchToFeatures,
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getswitchToFeatureData })(App)
