import { TEMPLATE_LIST_ERROR,TEMPLATE_LIST } from "../types";
import HttpService from '../../Services/HttpService';

export const templateList= (response) => {
    return {
        type: TEMPLATE_LIST,
        response
    }
}


export const templateListError = () => {
    return {
        type: TEMPLATE_LIST_ERROR
    }
}

export const getTemplateListData = () => {
    return (dispatch) => {
        HttpService.templatesListAjax()
            .then(response => {
                console.log("template list:- ",response)
                dispatch(templateList(response.data));
            })
            .catch(error => {
                dispatch(templateListError());
            })
    }
}

