import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createTemplate,
  editTemplate,
} from "../../Store/template/addTemplateAction";
import { viewTemplate } from "../../Store/template/viewTemplateoneAction";
import HttpService from "../../Services/HttpService";
import handleSwalAlert from "../SwalAlert";
import swal from "sweetalert";
import "./TemplateTwo/Templatetwo.css";
import { Row, Col } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { uploadMediaType } from "./UploadTypeResponce";
import UploadMedia from "./UploadMedia";

class TemplateFormThree extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      editTemplate: {},
      previewLogo: "",
      templateLogo: "",
      templateContact: "",
      templateBanner: "",
      TitleOneImage: "",
      logoName: "",
      previewContact: "",

      menuValue: [{ title: "", menu_id: "", show: 0 }],
      editMenuValue: [],

      bannerImageValues: [
        {
          imageName: "",
          imageUrl: "",
          bannerPreview: "",
          banner_id: "",
          media: "",
          index: 0,
          show: 0,
          upload: 'Images',
          videoUrl:'',
          urlStatus: null
        },
      ],
      bannerVideoValues: [
        {
          videoImageName: "",
          videoImageUrl: "",
          videoBannerPreview: "",
          videoBanner_id: "",
          media: "",
          index: 0, show: 0
        },
      ],
      //editBannerValue: [],
      EditBannerImageValues: [],
      titleOneValues: [
        {
          aboutFile: "",
          aboutTitle: "",
          aboutDescription: "",
          bannerPreview: "",
          templateabout_id: "",
          show: 0
        },
      ],
      editTitleOneValues: [],

      titleTwoValues: [
        { serviceTitle: "", serviceDescription: "", templateservice_id: "", show: 0 },
      ],
      editTitleTwoValues: [],

      titleThreeValues: [
        {
          contactTitle: "",
          contactDescription: "",
          contactFile: "",
          templatecontactus_id: "",
          bannerPreview: "",
        },
      ],

      footerValues: [{ footerLabel: "", footerTitle: "", footer_id: "", show: 0 }],
      editFooterValues: [],

      postBannersData: [],
      bannerPreview: [],
      postTitleOneImageData: [],
      previewaboutFile: [],
      contactDescription: "",
      contactFile: "",

      removedMenuIds: [],
      removedBannerIds: [],
      removedTitleOneIds: [],
      removedTitleTwoIds: [],
      removedFooterIds: [],
      menuHasChanged: 0,
      ThirdTitleHasChanged: 0,
      footerHasChanged: 0,
      bannersHasChanged: 0,
      titleOneHasChanged: 0,
      titleTwoHasChanged: 0,
      banner_upload: "0",
      urlName: '',
      urlValue: '',
      uploaded_banner_id:0,

    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.setState({
      spinner: false,
    });

    if (nextProps.addtemplate.template_res.status != 200) {
      this.setState({
        spinner: false,
      });
      swal({
        icon: "warning",
        text: nextProps.addtemplate.template_res.error,
      });
    } else {
      this.setState({
        spinner: false,
      });
      var successAlert = {
        message: nextProps.addtemplate.template_res.message,
        alertType: "success",
        closeOnEsc: false,
        dangerMode: true,
        pathname: "/dashboard/Chapters/ChaptersList",
        propsData: this.props,
        goToList: true,
        closeOnClickOutside: false,
      };
      handleSwalAlert(successAlert);
    }
  }
  /* triggers before the initial render*/
  componentWillMount() {
    if (this.props.location.state.detail.temp_status === 0) {
      this.setState({
        spinner: true,
      });
      var data = {
        chapter_id: this.props.location.state.detail.chapter_id,
        template_id: this.props.location.state.detail.template.templateid,
    
      };

      HttpService.viewTemplate(data).then((response) => {
        this.setState({
          spinner: false,
          editTemplate: response.data,
        });


        this.setState({
          previewLogo: this.state.editTemplate.template.template_logo,
          contactDescription:
            this.state.editTemplate.template.contactus[0]?.contact_description,
            bannerImageValues: this.state.editTemplate.template?.banners.map((item,index)=>{
              return {
                urlStatus: item.url_status,
                imageName: item.banner_name,
                imageUrl: item.banner_image,
                bannerPreview: item.banner_image,
                banner_id: item.banner_id,
                show: item.active_status,
                videoUrl : item.banner_image.includes('s3.amazonaws.com') ? null : item.banner_image,
                upload: item.url_status === 1 ? 'Url' : item.banner_image.includes('.mp4') ? 'Video' : 'Images'
              }
            }),
          titleOneValues: this.state.editTemplate.template.about.map(
            (item, index) => {
              return {
                aboutDescription: item.description,
                aboutTitle: item.title,
                card_id: item.card_id,
                bannerPreview: item.files,
                templateabout_id: item.templateabout_id,
                show: item.active_status
              };
            }
          ),
          titleTwoValues: this.state.editTemplate.template.services.map(
            (item, index) => {
              return {
                serviceTitle: item.title,
                serviceDescription: item.description,
                templateservice_id: item.templateservice_id,
                show: item.active_status
              };
            }
          ),
          titleThreeValues: this.state.editTemplate.template.contactus.map(
            (item, index) => {
              return {
                contactTitle: item.contact_title,
                contactDescription: item.contact_description,
                bannerPreview: item.contact_files,
                templatecontactus_id: item.templatecontactus_id,
              };
            }
          ),

          footerValues: this.state.editTemplate.template.footer.map(
            (item, index) => {
              return {
                footerLabel: item.footer_name,
                footerTitle: item.footer_description,
                footer_id: item.footer_id,
                show: item.active_status
              };
            }
          ),
          menuValue: this.state.editTemplate.template.menus.map(
            (item, index) => {
              return { title: item.menu_title, menu_id: item.menu_id, show: item.active_status };
            }
          ),
        });
      });
    }
  }
  /* api call for creating the template */
  createTemplate() {
   

    if (!this.validator.allValid()) {

      this.setState({
        spinner: false,
      });
      this.validator.showMessages();
    } else {
      var data = new FormData();

      data.append("template_id", this.props.location.state.detail.template.templateid);
      data.append("chapter_id", this.props.location.state.detail.chapter_id);

      data.append("active_status", this.props.location.state.detail.active_status);
      data.append("logo", this.state.logoName);

      if (this.state.menuHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.menuValue.map((item, index) => {
              if (item.menu_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("menu_title[" + index + "]", item.title);
                data.append("menu_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("menu_title[" + index + "]", item.title);
                data.append("menu_id[" + index + "]", item.menu_id);
                data.append("menu_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.menuValue.map((item, index) => {
              data.append("menu[" + index + "]", item.title);
              data.append("menu_active_status[" + index + "]", item.show);
            })
          );
        }
      }
      if (this.state.bannersHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
             if ( item.banner_id === "") {

                data.append("edit_flag[" + index + "]", "create");
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                data.append("video_url[" + index + "]", item.videoUrl):
                data.append("banner_image[" + index + "]", item.imageUrl);
                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                data.append("url_status[" + index + "]", 1):  data.append("url_status[" + index + "]", 0)
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("banner_id[" + index + "]", item.banner_id);
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                data.append("video_url[" + index + "]", item.videoUrl):
                data.append("banner_image[" + index + "]", item.imageUrl);

                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                data.append("url_status[" + index + "]", 1):  data.append("url_status[" + index + "]", 0)
              }
            })
          );



      } else {

        JSON.stringify(
          this.state.bannerImageValues.map((item, index) => {
        data.append("banner_name[" + index + "]", item.imageName);
        item.upload === 'Url' ?
        data.append("video_url[" + index + "]", item.videoUrl):
        data.append("banner_image[" + index + "]", item.imageUrl);
        data.append("banner_active_status[" + index + "]", item.show);
        item.upload === 'Url' ?
        data.append("url_status[" + index + "]", 1):  data.append("url_status[" + index + "]", 0)
          }))
      }
      }

      if (this.state.titleOneHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.titleOneValues.map((item, index) => {
              if (item.templateabout_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("about_title[" + index + "]", item.aboutTitle);
                data.append("about_description[" + index + "]", item.aboutDescription);
                data.append("about_files[" + index + "]", item.aboutFile);
                data.append("about_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("templateabout_id[" + index + "]", item.templateabout_id);
                data.append("about_title[" + index + "]", item.aboutTitle);
                data.append("about_description[" + index + "]", item.aboutDescription);
                data.append("about_files[" + index + "]", item.aboutFile);
                data.append("about_active_status[" + index + "]", item.show);

              }
            })
          );

        } else {
          JSON.stringify(
            this.state.titleOneValues.map((item, index) => {
              data.append("about_title[" + index + "]", item.aboutTitle);
              data.append("about_description[" + index + "]", item.aboutDescription);
              data.append("about_files[" + index + "]", item.aboutFile);
              data.append("about_active_status[" + index + "]", item.show);

            })
          );

        }
      }


    if (this.state.titleTwoHasChanged === 1) {
      if (this.state.editTemplate.success === true) {
        JSON.stringify(
          this.state.titleTwoValues.map((item, index) => {
            if (item.templateservice_id === "") {
              data.append("edit_flag[" + index + "]", "create");
              data.append("service_title[" + index + "]", item.serviceTitle);
              data.append("service_description[" + index + "]", item.serviceDescription);
              data.append("service_active_status[" + index + "]", item.show);

            } else {
              data.append("edit_flag[" + index + "]", "edit");
              data.append("templateservice_id[" + index + "]", item.templateservice_id);
              data.append("service_title[" + index + "]", item.serviceTitle);
              data.append("service_description[" + index + "]", item.serviceDescription);
              data.append("service_active_status[" + index + "]", item.show);
            }
          })
        );
      } else {
        JSON.stringify(
          this.state.titleTwoValues.map((item, index) => {
            data.append("service_title[" + index + "]", item.serviceTitle);
            data.append("service_description[" + index + "]", item.serviceDescription);
            data.append("service_active_status[" + index + "]", item.show);
          })
        );
      }
    }

    if (this.state.ThirdTitleHasChanged === 1) {
      if (this.state.editTemplate.success === true) {
        JSON.stringify(
          this.state.titleThreeValues.map((item, index) => {
            data.append(
              "editcontact_description",
              item.contactDescription
            );
            data.append("editcontact_title", "Contact Us");
            data.append("editcontact", item.templatecontactus_id);
            data.append("editcontact_files", item.contactFile);
          })
        );

      } else {
        JSON.stringify(
          this.state.titleThreeValues.map((item, index) => {
            data.append("contact_description", item.contactDescription);
            data.append("contact_title", "Contact Us");
            data.append("contact_files", item.contactFile);
          })
        );

      }
    }

    if (this.state.footerHasChanged === 1) {
      if (this.state.editTemplate.success === true) {
        JSON.stringify(
          this.state.footerValues.map((item, index) => {
            if (item.footer_id === "") {
              data.append("edit_flag[" + index + "]", "create");
              data.append("footer_name[" + index + "]", item.footerLabel);
              data.append("footer_description[" + index + "]", item.footerTitle);
              data.append("footer_active_status[" + index + "]", item.show);
            } else {
              data.append("edit_flag[" + index + "]", "edit");
              data.append("footer_id[" + index + "]", item.footer_id);
              data.append("footer_name[" + index + "]", item.footerLabel);
              data.append("footer_description[" + index + "]", item.footerTitle);
              data.append("footer_active_status[" + index + "]", item.show);

            }
          })
        );
      } else {
        JSON.stringify(
          this.state.footerValues.map((item, index) => {
            data.append("footer_name[" + index + "]", item.footerLabel);
            data.append("footer_description[" + index + "]", item.footerTitle);
            data.append("footer_active_status[" + index + "]", item.show);

          })
        );
      }
    }

    JSON.stringify(
      this.state.removedMenuIds.map((item, index) => {
        data.append("deletemenu[" + index + "]", item);
      })
    );

    JSON.stringify(
      this.state.removedBannerIds.map((item, index) => {
        data.append("deletebanners[" + index + "]", item);
      })
    );

    JSON.stringify(
      this.state.removedTitleOneIds.map((item, index) => {
        data.append("deleteabout[" + index + "]", item);
      })
    );
    JSON.stringify(
      this.state.removedTitleTwoIds.map((item, index) => {
        data.append("deleteservices[" + index + "]", item);
      })
    );
    JSON.stringify(
      this.state.removedFooterIds.map((item, index) => {
        data.append("deletefooter[" + index + "]", item);
      })
    );

    if (this.state.editTemplate.success === true) {
      this.props.editTemplate(data);
    } else {
      this.props.createTemplate(data);
    }
  }
  }
  /* goto chapters list */
  onCancel() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* to view the created template */
  onViewTemplate() {
    var newWindowTemplate = window.open(`/${this.props.location.state.detail?.view_temp_chapter_name}`)
    localStorage.setItem(
      "chpaterdata",
      JSON.stringify(this.props.location.state.detail)
    );
  }
  // logo code
  onresourceChange(e, title) {
    if (title === "resourcetitle") {
      this.setState({
        resourcetitle: e.currentTarget.value,
      });
    }
  }
  /* upload template logo */
  templateLogoHandleChange(i,e,uploadType) {
    var logoData = UploadMedia(i,e,uploadType)

      this.state.logoName = logoData;
     { logoData && this.setState({
        templateLogo: e.target.file,
        previewLogo: URL.createObjectURL(logoData),
      });} 
  }
  /* handle the contact details  */
  contactHandleChange(i, e, uploadType) {
    let titleThreeValues = this.state.titleThreeValues;

    var titleData = UploadMedia(i,e,uploadType)

    if(titleData?.name && titleData.name != null){
 
      titleThreeValues[i][e.target.name] = URL.createObjectURL(titleData);
      titleThreeValues[i]["contactFile"] = titleData;
      this.setState({
        ThirdTitleHasChanged: 1,
      });
    }
    else{
      titleThreeValues[i][e.target.name] = e.currentTarget.value;
      this.setState({
        ThirdTitleHasChanged: 1,
        contactDescription: e.currentTarget.value
      });
    }
  }
  // menu title add and remove
  menuHandleChange(i, e, from) {
    let menuValue = this.state.menuValue;
    menuValue[i][e.target.name] = e.target.value;
    this.setState({ menuValue, menuHasChanged: 1 });
  }
  /* add multiple menu fields*/
  addMenuTitle() {
    this.setState({
      menuValue: [...this.state.menuValue, { title: "", menu_id: "", show: 0 }],
    });
  }
  /* remove menu fields*/
  removeMenuTitle(i, element, from) {
    if (from === "create") {
      let menuValue = this.state.menuValue;
      menuValue.splice(i, 1);
      this.setState({ menuValue });
      this.setState((previousState) => ({
        removedMenuIds: [...previousState.removedMenuIds, element.menu_id],
      }));
    } else {
      let editMenuValue = this.state.editMenuValue;
      editMenuValue.splice(i, 1);
      this.setState({ editMenuValue });
    }
  }
  // title one add and remove
  titleOneChange(i, e, uploadType) {
    let titleOneValues = this.state.titleOneValues;

    var aboutData = UploadMedia(i, e, uploadType)

    if(aboutData?.name && aboutData.name != null){
      this.state.postTitleOneImageData[i] = aboutData;
        this.state.previewaboutFile[i] = e.target.value;
        titleOneValues[i][e.target.name] = URL.createObjectURL(aboutData);
        titleOneValues[i]["aboutFile"] = aboutData;

        this.setState({ titleOneHasChanged: 1 });
    }
    else{
      titleOneValues[i][e.target.name] = e.target.value;
      this.setState({ titleOneHasChanged: 1 });
    }
    this.setState({ titleOneValues });
  }
  /* add title one */
  addTitleOne() {
    this.setState({
      titleOneValues: [
        ...this.state.titleOneValues,
        {
          bannerPreview: "",
          aboutTitle: "",
          aboutDescription: "",
          templateabout_id: "",
          show: 0
        },
      ],
    });
  }
  /* remove title one */
  removeTitleOne(i, element, from) {
    if (from === "create") {
      let titleOneValues = this.state.titleOneValues;
      titleOneValues.splice(i, 1);
      this.setState({ titleOneValues });

      this.setState((previousState) => ({
        removedTitleOneIds: [
          ...previousState.removedTitleOneIds,
          element.templateabout_id,
        ],
      }));
    } else {
      let editTitleOneValues = this.state.editTitleOneValues;
      editTitleOneValues.splice(i, 1);
      this.setState({ editTitleOneValues });
    }
  }
  // title two add and remove
  titleTwoChange(i, e) {
    let titleTwoValues = this.state.titleTwoValues;
    titleTwoValues[i][e.target.name] = e.target.value;
    this.setState({ titleTwoValues, titleTwoHasChanged: 1 });
  }
  /* add multiple fileds for title two*/
  addTitleTwo() {
    this.setState({
      titleTwoValues: [
        ...this.state.titleTwoValues,
        { serviceTitle: "", serviceDescription: "", templateservice_id: "", show: 0 },
      ],
    });
  }
  /* remove title two */
  removeTitleTwo(i, element, from) {
    if (from === "create") {
      let titleTwoValues = this.state.titleTwoValues;
      titleTwoValues.splice(i, 1);
      this.setState({ titleTwoValues });

      this.setState((previousState) => ({
        removedTitleTwoIds: [
          ...previousState.removedTitleTwoIds,
          element.templateservice_id,
        ],
      }));
    } else {
      let editTitleTwoValues = this.state.editTitleTwoValues;
      editTitleTwoValues.splice(i, 1);
      this.setState({ editTitleTwoValues });
    }
  }
  /* handle the footer inputs */
  footerHandleChange(i, e) {
    let footerValues = this.state.footerValues;
    footerValues[i][e.target.name] = e.currentTarget.value;
    this.setState({ footerValues, footerHasChanged: 1 });

  }

  // footer add and remove
  addFooter() {
    this.setState({
      footerValues: [
        ...this.state.footerValues,
        { footerLabel: "", footerTitle: "", footer_id: "", show: 0 },
      ],
    });
  }
  /* remove footer fields*/
  removeFooter(i, element, from) {
    if (from === "create") {
      let footerValues = this.state.footerValues;
      footerValues.splice(i, 1);
      this.setState({ footerValues });

      this.setState((previousState) => ({
        removedFooterIds: [
          ...previousState.removedFooterIds,
          element.footer_id,
        ],
      }));
    } else {
      let editFooterValues = this.state.editFooterValues;
      editFooterValues.splice(i, 1);
      this.setState({ editFooterValues });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.titleOneValues));
  }
  // banner add remove code

  bannerImageHandleChange(i, e, uploadType) {
    var data = UploadMedia(i, e, uploadType)
    let bannerImageValues = this.state.bannerImageValues;
   
    if (data?.name && data.name != null) {
      this.state.postBannersData[i] = data;
      //this.state.bannerPreview[i] = e.target.value;
      bannerImageValues[i][e.target.name] = URL.createObjectURL(
        data
      );
      bannerImageValues[i]["imageUrl"] = data;
      bannerImageValues[i]["urlStatus"] = 0;

      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
    else {
      bannerImageValues[i][e.target.name] = data;
      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
  }
  /* add multiple banners*/
  addBanners() {
    this.setState({
      bannerImageValues: [
        ...this.state.bannerImageValues,
        { imageName: "", imageUrl: "", bannerPreview: "", banner_id: "", show: 0, upload: 'Images' },
      ],
    });
  }
  /* remove banner fields */
  removeBannerFields(i, element, from) {
    if (from === "edit") {
      let EditBannerImageValues = this.state.EditBannerImageValues;
      EditBannerImageValues.splice(i, 1);
      this.setState({ EditBannerImageValues });
    } else {
      let bannerImageValues = this.state.bannerImageValues;
      bannerImageValues.splice(i, 1);
      this.setState({ bannerImageValues });
      this.setState((previousState) => ({
        removedBannerIds: [
          ...previousState.removedBannerIds,
          element.banner_id,
        ],
      }));
    }
  }

  // goto chapters list
  onChapters() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* handle to upload banner images or uploads*/
  mediaHandleChange = (e, type) => {
    
   if(type === 'url'){
    this.setState({
      banner_upload: e.currentTarget.value,
      //bannerImageValues: [{ bannerPreview: "" }],
    });
   }
    else if (type === "video") {
      if (this.state.bannerPreview != null) {
        this.setState({
          banner_upload: e.currentTarget.value,

        });
      } else {
        this.setState({
          banner_upload: e.currentTarget.value,
          bannerValue: [{ bannerPreview: "" }],
        });
      }
    } else {
      if (this.state.editTemplate.template?.banners != undefined) {
        this.setState({
          banner_upload: e.currentTarget.value,
          // bannerValue: this.state.editTemplate.template?.banners.map(
          //   (item, index) => {
          //     return {
          //       imageName: item.banner_name,
          //       imageUrl: "",
          //       bannerPreview: item.banner_image,
          //       banner_id: item.banner_id,
          //     };
          //   }
          // ),
        });
      }
      else {
        this.setState({
          banner_upload: e.currentTarget.value
        });
      }

    }
  };
  /* to show and hide the fields in view template*/
  showHideFields(i, e, from, typeOfField) {


    switch (typeOfField) {
      case 'menus':
        if (from === "create") {
          let menuValue = this.state.menuValue;
          menuValue[i][e.target.name] === 0 ? menuValue[i][e.target.name] = 1 :
            menuValue[i][e.target.name] = 0;

          this.setState({ menuValue, menuHasChanged: 1 });
        }
        else {
          let formValues = this.state.banners;
          formValues[i][e.target.name] === 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        break;
      case 'banners':
        if (from === "create") {
          let bannerImageValues = this.state.bannerImageValues;
          bannerImageValues[i][e.target.name] === 0 ? bannerImageValues[i][e.target.name] = 1 :
            bannerImageValues[i][e.target.name] = 0;

          this.setState({ bannerImageValues, bannersHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;
      case 'titleOne':
        if (from === "create") {
          let titleOneValues = this.state.titleOneValues;
          titleOneValues[i][e.target.name] === 0 ? titleOneValues[i][e.target.name] = 1 :
            titleOneValues[i][e.target.name] = 0;

          this.setState({ titleOneValues, titleOneHasChanged: 1 });
        }
        else {
          let editImageValues = this.state.editImageValues;
          editImageValues.splice(i, 1);
          this.setState({ editImageValues });
        }
        break;
      case 'titleTwo':
        if (from === "create") {
          let titleTwoValues = this.state.titleTwoValues;
          titleTwoValues[i][e.target.name] === 0 ? titleTwoValues[i][e.target.name] = 1 :
            titleTwoValues[i][e.target.name] = 0;

          this.setState({ titleTwoValues, titleTwoHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;
      case 'footer':
        if (from === "create") {
          let footerValues = this.state.footerValues;
          footerValues[i][e.target.name] === 0 ? footerValues[i][e.target.name] = 1 :
            footerValues[i][e.target.name] = 0;

          this.setState({ footerValues, footerHasChanged: 1 });
        }
        else {
          let editFooterValues = this.state.editFooterValues;
          editFooterValues.splice(i, 1);
          this.setState({ editFooterValues });
        }
      default:
        break;
    }
  }
/* confirmation pop up and remove fields */
  confirmationForRemove(i, element, from, type) {
    let typeOfTitle = ''
    switch (type) {
      case 'menu':
        typeOfTitle = "Menu"
        break;
      case 'banners':
        typeOfTitle = "Banner"
        break;
      case 'cards':
        typeOfTitle = "Card"
        break;
      case 'description':
        typeOfTitle = 'Description'
        break;
      case 'gallery':
        typeOfTitle = "Gallery"
        break;
      case 'footer':
        typeOfTitle = 'Footer'
        break;

      default:
        break;
    }

    swal({
      title: "Delete " + typeOfTitle,
      text: "Are you sure you want to delete this " + typeOfTitle + " item?",
      buttons: ["No, Cancel", "Yes, Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          Spinner: true
        })
        switch (typeOfTitle) {
          case 'Menu':

            this.removeMenuTitle(i, element, from)
            break;
          case 'Banner':
            this.removeBannerFields(i, element, from)
            break;
          case 'Card':
            this.removeTitleOne(i, element, from)
            break;
          case 'Description':
            this.removeTitleTwo(i, element, from)
            break;
          
          case 'Footer':
            this.removeFooter(i, element, from)
            break;
          default:
            break;
        }
      }
    });
  }
  /* render() function is used to update the UI. */
  render() {
    return (
      <>
        <main className="container">
          <div className="row mt-3 mb-4">
            <div className="col-md-12">
              <div className="horizontal-container">
                <label
                  className="label-discount"
                  onClick={() => this.onChapters()}
                >
                  {this.props.location.state?.detail?.chapter_name}
                </label>
                <i
                  class="fa fa-chevron-right right-arrow"
                  aria-hidden="true"
                ></i>
                <label className="label-discount">Create Template-3</label>
              </div>
            </div>
          </div>

          <div className="box">
            <main>
              <div className="viewtemp-mbl">
                {this.props.location.state?.detail?.temp_status === 0 && (
                  <button
                    className="viewTemplateButton"
                    onClick={() => this.onViewTemplate()}
                  >
                    View Template
                  </button>
                )}

                <button
                  type="cancel"
                  className="closeTemplateButton"
                  onClick={() => this.onCancel()}
                >
                  Close
                </button>
              </div>
              <div>
                <label className="lehead">
                  Add Template Logo<span>*</span>
                </label>
                <div>
                  {this.state.previewLogo != "" && (
                    <img
                      className="preview-image"
                      src={this.state.previewLogo}
                    ></img>
                  )}
                </div>
                <div id="upload-right">
                  <div className="profile-upload rect">
                    <label className="custom-file-upload rectangle">
                      <input
                        type="file"
                        name="bannerPreview"
                        className="form-control"
                        multiple
                        onChange={(e) =>
                          this.templateLogoHandleChange(0 ,e, "images")
                        }
                      />
                      <i className="fas fa-plus"></i>
                    </label>
                    <label className="up-lbl">Upload an image</label>
                    <br />
                    <span className="up-span">(2MB Limit)</span>
                  </div>
                </div>
                <button
                  style={{ display: "none" }}
                  className="btn btn-info btn-success mt-4"
                >
                  Upload
                </button>
                <span className="text-danger">
                  {this.validator.message(
                    "template_logo",
                    this.state.previewLogo,
                    "required"
                  )}
                </span>
              </div>
              <hr className="divider-line" />
              {/* template menu */}

              <div className="row">
                <label className="lehead">
                  Template Menu<span>*</span>
                </label>
                <div >
                  <div >
                    {this.state.menuValue.map((element, index, arr) => (
                      <div className="sec-borser" key={index}>

                        <div className="hider">

                        {arr.length - 1 === index ? null : (
                          <div>
                            <div className="addadency-sec d-flex">
                              <p className="lehead-para">Hide In Template View</p>
                              <div>
                                <label class="switchtog ml-2">
                                    <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'menus')} />
                                    <span class="slidered"></span>
                                  </label>
                              </div>
                            </div>
                          </div>
                        )}
                          {
                          arr.length - 1 === index ? null : (
                            <i class="fa fa-trash temp-del ml-2" aria-hidden="true"  
                            onClick={() => 
                              this.confirmationForRemove(index, element, "create",'menu')
                              //this.removeMenuTitle(index, element, "create")
                            }></i>
                          )
                        }
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                          <label class="lehead mr-3">Menu Title</label>
                          <input
                            className="form-control"
                            type="text"
                            name="title"
                            value={element.title || ""}
                            onChange={(e) =>
                              this.menuHandleChange(index, e, "create")
                            }
                          />

                        </div>
                        </div>

                      </div>
                    ))
                    }

                  </div>

                </div>
              </div>
              <span className="text-danger">
                {this.validator.message(
                  "template_menu",
                  this.state.menuValue[0]?.title,
                  "required"
                )}
              </span>
              <div className="row">
                <div className="col-md-3">
                  <div className="m-2">
                    <button
                      className="template-add-button "
                      type="button"
                      onClick={() => this.addMenuTitle()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <hr className="divider-line" />

              <label className="lehead">
                Template Banner<span>*</span>
              </label>
              <div style={{ margin: "5px" }}>
              <label style={{ marginLeft: "5px" }} class="lehead">
                Upload Banners
              </label>


            </div>
            {this.state.bannerImageValues?.map((element, index, arr) => (

              <div className="sec-borser" key={index}>
                  <div className="hider-banner  ">
                    {arr.length - 1 === index ? null : (
                      <div>
                        <div className="addadency-sec d-flex">
                          <p className="lehead-para">Hide In Template View</p>
                          <div>
                            <label>

                              <label class="switchtog ml-2">
                                <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'banners')} />
                                <span class="slidered"></span>
                              </label>

                            </label>
                          </div>
                        </div>
                      </div>

                    )}
                      {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                       onClick={() => 
                        this.confirmationForRemove(index, element, "create",'banners')
                        //this.removeBannerFields(index, element, "create") 
                      }></i>
                    )}
                  </div>
                <div className="dflex">
                  <div>
                    <label class="lehead">Name</label>
                    <input

                      type="text"
                      className="form-control"
                      name="imageName"
                      value={element.imageName || ""}
                      onChange={(e) => this.bannerImageHandleChange(index, e, 'images')}
                    />
                    </div>
                    <div className="ml-2">
                    <label class="lehead">Upload Type</label>
                    <div>
                      <select
                        name='upload'

                        value={element.upload || ""}
                        onChange={(e) => this.bannerImageHandleChange(index, e, 'typeOfUpload')}
                        style={{ width: '120px', height: '45px', padding: '5px' }} >
                        {/* <option value="">Select Type of Upload</option> */}
                        {uploadMediaType?.map((e) => {
                          return <option value={e.type}>{e.name}</option>;
                        })}
                      </select>

                      {/* <span className="text-danger">{this.validator.message("template", this.state.selectedUploadType, "required")}</span> */}
                    </div>
                  </div>
                  <div className="ml-2">
                    <div className="row">
                        {element.upload === 'Images' && <div className="d-flex">
                          {element.bannerPreview && !element.bannerPreview.includes('.mp4') &&
                            <div>

                              <label class="lehead">Image</label>
                              <div className="clearfix"></div>
                              <img
                                name="bannerPreview"
                                value={element.bannerPreview || ""}
                                className="preview-image"
                                src={element.bannerPreview}
                              ></img>

                            </div>
                          }
                          <div>
                          <label class="lehead">
                            Upload a Image
                            <span style={{ fontSize: "11px" }}>

                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                        </div>
                        }
                      {element.upload === 'Video' && <div className="d-flex">
                        
                        {element.upload === 'Video' && element.bannerPreview !== '' && element.urlStatus == '0' && ((!element.bannerPreview.includes('.jfif')) && (!element.bannerPreview.includes('.png')) && (!element.bannerPreview.includes('.jpg') && !element.bannerPreview.includes('.jpeg'))) &&
                          <div>

                            <label class="lehead">Video</label>
                            <div className="clearfix"></div>
                            <video className="preview-image" controls>
                              <source
                                src={element.bannerPreview || ""}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        }

                        <div>
                          <label class="lehead">
                            Upload a Video
                            <span className="mr-1" style={{ fontSize: "11px" }}>

                              (Upload size should be less than 10mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>}
                        {element.upload === 'Url' &&
                          <div className="m-0">
                        <label data-tooltip="Upload url content should be less than 5 mb."
                      data-tooltip-location="right" className="lehead">Upload A URL
                       <i style={{ color: "#cecfd0", marginLeft: "10px" }} 
                       class="fa fa-info-circle" aria-hidden="true">
                        </i>
                        </label>
                            <div className="profile-upload rect">

                              <input
                                type="text"
                                name="videoUrl"
                                className="form-control"
                                value={element.videoUrl}
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'url')
                                }
                              />


                            </div>
                          </div>

                        }

                      </div>
                    </div>
                  </div>

                </div>

            ))}
             <span className="text-danger">
                      {this.validator.message(
                        "template_banner_name",
                        this.state.bannerImageValues[0]?.imageName,
                        "required"
                      )}
                    </span>

                    <span className="text-danger">
                      {this.validator.message(
                        "template_banner_upload",
                        this.state.bannerImageValues[0]?.upload,
                        "required"
                      )}
                    </span>

           <div className="row">
              <div className="col-md-3">
                <div className="m-2">

                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addBanners()}
                  >
                    Add
                  </button>
                </div>

                <div className="m-2">

                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>



              <hr className="divider-line" />

              <label className="lehead"> Title One<span>*</span> </label>
              <div className="row">

                {this.state.titleOneValues.map((element, index, arr) => (
                  <div className="sec-borser">
                   <div className="hider-banner">
                     {arr.length - 1 === index ? null : (
                     <div>
                       <div className="addadency-sec d-flex">
                         <p className="lehead">Hide In Template View</p>
                         <div>
                             <label class="switchtog ml-2">
                               <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'titleOne')} />
                               <span class="slidered"></span>
                             </label>
                         </div>
                       </div>
                     </div>
                   )}
                   { arr.length - 1 === index ? null : (
                    <i class="fa fa-trash temp-del ml-2" aria-hidden="true" 
                    onClick={() => 
                      this.confirmationForRemove(index, element, "create",'cards')
                      //this.removeTitleOne(index, element, "create")
                     }></i>
                        )}
                 </div>
                  <div className="row">
                        <div className="col-md-3">
                          <label className="lehead">Title</label>
                          <input

                            type="text"
                            className="form-template"
                            name="aboutTitle"
                            value={element.aboutTitle || ""}
                            onChange={(e) => this.titleOneChange(index, e,'iamges')}
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="lehead">Description</label>
                          <textarea type="text"
                            className="form-template"
                            row="2"
                            name="aboutDescription"
                            value={element.aboutDescription || ""}
                            onChange={(e) => this.titleOneChange(index, e,'images')}
                          />
                        </div>
                        <div className="col-md-5">
                          <div>
                          <label className="lehead">
                            Image
                            <span style={{ fontSize: "10px", marginLeft:'5px' }}>
                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div  className="d-flex">
                            {element.bannerPreview && (
                              <div>
                                <img
                                  name="bannerPreview"
                                  value={element.bannerPreview || ""}
                                  className="preview-image"
                                  src={element.bannerPreview}
                                ></img>
                              </div>
                            )}
                            <div className="profile-upload rect">
                              <label className="custom-file-upload rectangle">
                                <img src="" />
                                <input
                                  type="file"
                                  name="bannerPreview"
                                  className="form-control"
                                  multiple
                                  onChange={(e) => this.titleOneChange(index, e,'images')}
                                />
                                <i className="fas fa-plus"></i>
                              </label>
                            </div>
                            </div>
                        </div>
                        </div>



                  </div>
                  </div>
                ))}

              </div>
              <span className="text-danger">
                {this.validator.message(
                  "Title",
                  this.state.titleOneValues[0]?.aboutTitle,
                  "required"
                )}
              </span>
              <span className="text-danger">
                {this.validator.message(
                  "Description",
                  this.state.titleOneValues[0]?.aboutDescription,
                  "required"
                )}
              </span>
              <span className="text-danger">
                {this.validator.message(
                  "Image",
                  this.state.titleOneValues[0]?.bannerPreview,
                  "required"
                )}
              </span>
              <div className="row">
                <div className="col-md-3">
                  <div className="m-2">
                    <button
                      className="template-add-button"
                      type="button"
                      onClick={() => this.addTitleOne()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>


              <div className="clearfix"></div>
              <hr className="divider-line" />
              <div>
                <label className="lehead">
                  Title Two<span>*</span>
                </label>

                {this.state.titleTwoValues.map((element, index, arr) => (

                  <div className="sec-borser">
                    <div className="hider">
                       {arr.length - 1 === index ? null : (
                          <div className="addadency-sec d-flex">
                            <p className="lehead">Hide In Template View</p>
                            <div>
                                <label class="switchtog ml-2">
                                  <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'titleTwo')} />
                                  <span class="slidered"></span>
                                </label>
                            </div>
                          </div>

                      )}
                       {arr.length - 1 === index ? null : (
                          <i class="fa fa-trash temp-del ml-2" aria-hidden="true"  
                          onClick={() => 
                            this.confirmationForRemove(index, element, "create",'description')
                            //this.removeTitleTwo(index, element, "create") 
                          }></i>
                        )
                      }
                    </div>
                    <div className="row">
                    <div className="col-md-3">
                      <label className="lehead">Title</label>
                      <input
                        type="text"
                        className="form-template"
                        name="serviceTitle"
                        value={element.serviceTitle || ""}
                        onChange={(e) => this.titleTwoChange(index, e)}
                      />
                    </div>
                    <div className="col-md-3">
                      <div>
                        <label className="lehead">Description</label>

                        <textarea
                          type="text"

                          className="form-template"
                          name="serviceDescription"
                          row="2"
                          value={element.serviceDescription || ""}
                          onChange={(e) => this.titleTwoChange(index, e)}
                        />

                      </div>
                    </div>
                    </div>
                  </div>
                ))}
                <span className="text-danger">
                  {this.validator.message(
                    "Title",
                    this.state.titleTwoValues[0].serviceTitle,
                    "required"
                  )}
                </span>
                <span className="text-danger">
                  {this.validator.message(
                    "Description",
                    this.state.titleTwoValues[0].serviceDescription,
                    "required"
                  )}
                </span>
                <div className="row">
                  <div className="col-md-3">
                    <div className="m-2">
                      <button
                        className="template-add-button "
                        type="button"
                        onClick={() => this.addTitleTwo()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="divider-line" />

              <label className="lehead">
                Title Three<span>*</span>
              </label>
              {this.state.titleThreeValues.map((element, index, arr) => (
                <div className="row">
                  <div className="col-md-3">
                    <label className="lehead">Desription</label>
                    <textarea
                      type="text"
                      className="form-template"
                      row="2"
                      name="contactDescription"
                      value={element.contactDescription || ""}
                      onChange={(e) =>
                        this.contactHandleChange(index, e, "images")
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="lehead">
                      Image
                      <span style={{ fontSize: "10px" }}>
                        (Upload dimentions should be 960 X 360 and below 5 mb)
                      </span>
                    </label>
                    <div className="d-flex">
                      {element.bannerPreview && (
                        <div>
                          <img
                            name="bannerPreview"
                            value={element.bannerPreview || ""}
                            className="preview-image"
                            src={element.bannerPreview}
                          ></img>
                        </div>
                      )}
                      <div className="profile-upload rect">
                        <label className="custom-file-upload rectangle">
                          <img src="" />
                          <input
                            type="file"
                            name="bannerPreview"
                            className="form-control"
                            multiple
                            onChange={(e) =>
                              this.contactHandleChange(index, e, "images")
                            }
                          />
                          <i className="fas fa-plus"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <span className="text-danger">
                    {this.validator.message(
                      "Description",
                      element.contactDescription,
                      "required"
                    )}
                  </span>
                  <span className="text-danger">
                    {this.validator.message(
                      "Image",
                      this.state.titleThreeValues[0]?.bannerPreview,
                      "required"
                    )}
                  </span>
                </div>
              )

              )}

              <hr className="divider-line" />

              <label className="lehead">
                Footer<span>*</span>
              </label>
              {this.state.footerValues.map((element, index, arr) => (
                <div className="sec-borser">
                    <div className="hider">
                    {arr.length - 1 === index ? null : (
                        <div className="addadency-sec d-flex">
                          <p className="lehead">Hide In Template View</p>
                          <div>

                              <label class="switchtog ml-2">
                                <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'footer')} />
                                <span class="slidered"></span>
                              </label>
                          </div>
                        </div>
                    )}
                     {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true"   
                      onClick={() => 
                        this.confirmationForRemove(index, element, "create",'footer')
                      //  this.removeFooter(index, element, "create") 
                       }></i>
                    )}
                  </div>
                  <div className="row">
                  <div className="col-md-3">
                    <label className="lehead">Label</label>
                    <input
                      type="text"
                      className="form-template"
                      name="footerLabel"
                      value={element.footerLabel || ""}
                      onChange={(e) => this.footerHandleChange(index, e)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="lehead">Value</label>
                    <input
                      type="text"
                      className="form-template"
                      name="footerTitle"
                      value={element.footerTitle || ""}
                      onChange={(e) => this.footerHandleChange(index, e)}
                    />
                  </div>
                  </div>

                </div>
              ))}
              <span className="text-danger">
                {this.validator.message(
                  "footer_label",
                  this.state.footerValues[0]?.footerLabel,
                  "required"
                )}
              </span>
              <span className="text-danger">
                {this.validator.message(
                  "footer_value",
                  this.state.footerValues[0]?.footerTitle,
                  "required"
                )}
              </span>
              <div className="row">
                <div className="col-md-3">
                  <div className="m-2">
                    <button
                      className="template-add-button "
                      type="button"
                      onClick={() => this.addFooter()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <Row className="my-4">
                <Col xs lg="6">
                  <div className="save-sec">
                    <button
                      type="submit"
                      onClick={() => this.createTemplate()}
                      className="btn btn-info btn-success mt-1"
                    >
                      Submit
                    </button>
                  </div>
                </Col>

                <Col xs lg="6">
                  <div className="cancel-sec">
                    <button
                      type="cancel"
                      className="cancelbtnnew"
                      onClick={() => this.onCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </main>
          </div>
        </main >
      </>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    addtemplate: state.addtemplate,
    viewtemplate: state.viewtemplate,
  };
};

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
  createTemplate,
  viewTemplate,
  editTemplate,
})(withRouter(TemplateFormThree));
