import { SWITCHTOFEATURE_SUCCESS, SWITCHTOFEATURE_ERROR } from "../types";

let initialState = {   
    switchToFeatute_responce: '',
    error: false,
}

export default function switchToFeatureReducer(state = initialState, action){

    switch(action.type){
        case SWITCHTOFEATURE_SUCCESS:
            return {
                ...state,
                switchToFeatute_responce: action.response
            }
        
        case SWITCHTOFEATURE_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }

}