import { LITTLES_LIST,DELETE_LITTLE } from "../types";

const initialState = {
    littleslist:[],
}

export default function littlesListreducer(state = initialState, action){

    switch(action.type){

        case LITTLES_LIST:
        return {
            ...state,
            littleslist:action.response,
        }
        case DELETE_LITTLE:
            return {
                ...state,
                deletelittle_res: action.response
            }
        default: return state
    }

}