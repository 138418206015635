import React, { Component } from 'react';
import Navbar from './TemplateNavbar';
//import './template.css'
import { viewTemplate } from '../../Store/template/viewTemplateoneAction';
import { connect } from 'react-redux';
import CamelCase from '../CamelCaseSentance'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: {}
    }
  }
  state = {
    activeIndex: null
  }

  handleClick = (index) => this.setState({ activeIndex: index });
  /* render() function is used to update the UI. */
  render() {
    const clickables = [
      { name: "Discounts" },
      { name: "Activities" },
      { name: "Resources" },
      { name: "Events" },
      { name: "Photo Gallary" }
    ];

    this.state.headerData = JSON.parse(localStorage.getItem('ViewTemplateData'))
    return (
      <div className= {this.state.headerData?.template?.template_id === 3 ? 
      'bg-header-three' : this.state.headerData?.template?.template_id === 2 ?
       'bg-header-two' : 'bg-header'}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 col-xs-6'>

              <a className="logo">
                <span className="logo-temp">
                  <img style={{ height: '50px', width: '200px', objectFit: 'contain' }} src={this.state.headerData?.template?.template_logo} />
                </span>
              </a>
            </div>
            <div className='col-md-8 col-xs-6'>
              <ul className='header-ul'>
                {this.state.headerData?.template?.menus.map((clickable, i) => {
                  return <Navbar
                    // key={ clickable.menu_title }
                    name={clickable.menu_title}
                    index={i}
                    isActive={this.state.activeIndex === i}
                  //  onClick={this.handleClick}
                  />
                })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
  return {
    viewtemplate: state.viewtemplate
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { viewTemplate })(Header)