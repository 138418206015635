import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/newstyle.css'
class Home extends Component {
  constructor() {
    super();
    this.state = {
      resposiveNav: '0'
    }
  }
  onHandleResponsive(data) {

    this.state.resposiveNav === '0' ?
      data === 'activate' ?
        this.setState({ resposiveNav: "1" }) :
        this.setState({ resposiveNav: '0' }) :
      this.setState({ resposiveNav: "0" })

  }
    /* render() function is used to update the UI. */

  render() {
    return (
      <main className='body-new'>
        <header className="header-new">
          <div className="header__inner">
            <a href="#" className="header__logo logo-new"></a>
            <div onClick={() => this.onHandleResponsive('active')}
              className={this.state.resposiveNav === '0' ? "header__aside" : "header__aside is-active"}>
              <nav className="nav">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="/features">Features</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <Link to="/login">Log In</Link>
                  </li>
                </ul>
              </nav>
              <Link className="btn btn--yellow btn--medium header__btn" to="/signup">Sign Up</Link>
            </div>
            <a onClick={() => this.onHandleResponsive('activate')} className={this.state.resposiveNav === '0' ? "nav-trigger" : "nav-trigger is-active"}>
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
        <div className="section">
          <div className="section__shapes">
            <div className="section__shape">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1389 1174"><defs></defs><g transform="translate(-1102 245)" fill="#7cc9a9"><ellipse cx="454" cy="376.5" rx="454" ry="376.5" transform="translate(1102 -245)"></ellipse><ellipse cx="454" cy="376.5" rx="454" ry="376.5" transform="translate(1583 176)"></ellipse></g></svg>
            </div>{/* section__shape */}
          </div>{/* section__shapes */}
          <div className="shell section__shell">
            <div className="section__body">
              <div className="section__head">
                <h1 className="section__title">
                  Building Stronger<br />
                  <span>Relationships<br /> Together</span>
                </h1>{/* section__title */}
                <h5 className="section__subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut nisl non nisi tempor congue ut eget lorem. Nullam sit.</h5>{/* section__subtitle */}
              </div>{/* section__head */}
              <div className="section__actions">
                <a href="#" className="btn btn--yellow">Get Started</a>
                <a href="/features" className="btn btn--border-blue">Features</a>
              </div>
              {/* section__actions */}
            </div>
            <div className="section__image">
              <img src="/img/phone-img-1.png" alt="" />
            </div>
          </div>
        </div>
      </main>
    )
  }
}
export default Home;
