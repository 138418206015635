import React, { Component } from "react";
import { connect } from 'react-redux';
import { getAllNotificationsAjax, getSentNotificationsAjax, getScheduledNotificationsAjax
} from '../../Store/notifications/allNotificationsaction';
import { deletenotificationForm } from '../../Store/notifications/addNotificationaction';
import ReactPagenate from 'react-paginate'
import '../../App.css'
import { Row, Col } from 'react-bootstrap';
import Edit from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import HttpService from '../../Services/HttpService';
import * as moment from 'moment';
import { Tooltip, Overlay } from "react-bootstrap";
import { Circles } from 'react-loader-spinner';
import handleSwalAlert from '../SwalAlert'

const currentuser = JSON.parse(localStorage.getItem('userdata'));

class NotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      allnotifications: [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      filterNotification: [],
      slice: [],
      notifications: [],
      DefaultMessage: '',
      deleteResponce: {},
      notifToDeleteRefresh: '',
      notifSearchInput: '',
      notifSearchList: [],
      chapter_id: '',
      showPopover: false,
      notify_id: null,
      eventtarget: null,
      pageCount: 0,
      enabledPage: 1
    };
    this.handlePageClick = this
      .handlePageClick
      .bind(this);
  }
  /* To refresh the notification list*/
  listOfNotifications = (event, notificationType) => {
    this.setState({
      spinner: true
    })
    this.state.notifToDeleteRefresh = notificationType
    this.state.enabledPage = 1
    localStorage.setItem('notificationpagination', this.state.enabledPage)
    var data = {
      chapter_id: this.state.chapter_id,
      page: localStorage.getItem('notificationpagination'),
      limit: 8
    }
    if (notificationType == 'all') {
      this.clearSearhText.value = ''
      this.setState({
        notifSearchList: [],
        DefaultMessage: '',
        notifications: []
      })
      this.props.getAllNotificationsAjax(data);
    } else if (notificationType == 'sent') {
      this.clearSearhText.value = ''
      this.setState({
        notifSearchList: [],
        DefaultMessage: '',
        notifications: []
      })
      this.props.getSentNotificationsAjax(data);
    } else if (notificationType == "scheduled") {
      this.clearSearhText.value = ''
      this.setState({
        notifSearchList: [],
        DefaultMessage: '',
        notifications: []
      })
      this.props.getScheduledNotificationsAjax(data);
    }
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.state.notifToDeleteRefresh = 'all'
    var data = {
      chapter_id: this.state.chapter_id,
      page: 1,
      limit: 8
    }
    this.props.getAllNotificationsAjax(data);
  }
  /* Goto new notification to creating the new notification */
  onnewNotification() {
    this.props.history.push({
      pathname: '/dashboard/notifications/new_notification'
    })
  }
  /* To edit the notification */
  onEdit(item) {
    this.props.history.push({
      pathname: '/dashboard/notifications/new_notification',
      state: { detail: item }
    })
  }
  /* To show the pop up */
  showPopoverDialog = (e, nid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      notify_id: nid
    })
  }
  /* To close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /* To delete the notification */
  onDelete(notify_id) {

    this.setState({
      spinner: !this.state.spinner
    })
    var data = {
      notify_id: this.state.notify_id
    }
    const noteArray = this.state.allnotifications.slice()
    if (this.state.notify_id > -1) {
      noteArray.splice(this.state.notify_id, 1);
    }

    HttpService.deletenotification(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          this.setState({
            spinner: false,
            showPopover: false
          })
          var successAlert = {
            message: this.state.deleteResponce.message,
            alertType: 'success'
          }
          handleSwalAlert(successAlert)

          var data = {
            chapter_id: this.state.chapter_id,
            page: localStorage.getItem('notificationpagination'),
            limit: 8
          }
          if (this.state.notifToDeleteRefresh == 'all') {
            this.props.getAllNotificationsAjax(data);
          } else if (this.state.notifToDeleteRefresh == 'sent') {
            this.props.getSentNotificationsAjax(data);
          } else if (this.state.notifToDeleteRefresh == "scheduled") {
            this.props.getScheduledNotificationsAjax(data);
          }
          this.setState({
            notifications: this.state.notifications
          })
        }
        else {
          this.setState({
            spinner: false,
            showPopover: false
          })
          var errorAlert = {
            alertType: 'warning',
            message: this.state.deleteResponce.message,
          }
        }
      })
      .catch(error => {
        this.setState({
          spinner: false,
          showPopover: false
        })
        var errorAlert = {
          alertType: 'error'
        }
        handleSwalAlert(errorAlert)
      })
    this.setState({
      allnotifications: noteArray
    })
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    if (nextProps.allNotificationsDetails.allnotifications.notifications?.length === 0) {
      this.setState({
        notifications: [],
        spinner: false,
        DefaultMessage: "No Notifications Found",
        pageCount: Math.ceil(nextProps.allNotificationsDetails.allnotifications.count / this.state.perPage)
      })
    }
    else {
      this.state.notifications = nextProps.allNotificationsDetails.allnotifications.notifications
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.allNotificationsDetails.allnotifications.count / this.state.perPage),
      })
    }
  }
  /* For search the notification in the list  */
  searchHandler = (event) => {
    this.setState({
      spinner: true
    })
    this.state.notifSearchInput = event.target.value

    if (this.state.notifSearchInput.length === 0) {
      this.setState({
        notifications: []
      })
      var data = {
        chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
        pageCount: 1,
        limit: 8,
        page: localStorage.getItem('notificationpagination')
      }
      if (this.state.notifToDeleteRefresh === 'all') {
        this.props.getAllNotificationsAjax(data);

      }
      else if (this.state.notifToDeleteRefresh === 'sent') {
        this.props.getSentNotificationsAjax(data);
      }
      else if (this.state.notifToDeleteRefresh === 'scheduled') {
        this.props.getScheduledNotificationsAjax(data);
      }
      this.setState({
        notifSearchList: [],
        notifSearchInput: '',
        DefaultMessage: '',
        searchName: ''
      })
    }
    else {
      if (this.state.notifSearchInput.length === 1) {
        this.state.enabledPage = 1
        localStorage.setItem('notificationpagination', this.state.enabledPage)
        this.setState({
          notifications: [],
        })
      }
      var data = {
        chapter_id: this.state.chapter_id,
        pageCount: 1,
        limit: 8,
        page: localStorage.getItem('notificationpagination'),
        search: this.state.notifSearchInput
      }
      if (this.state.notifToDeleteRefresh === 'all') {
        this.props.getAllNotificationsAjax(data);

      }
      else if (this.state.notifToDeleteRefresh === 'sent') {
        this.props.getSentNotificationsAjax(data);
      }
      else if (this.state.notifToDeleteRefresh === 'scheduled') {
        this.props.getScheduledNotificationsAjax(data);
      }
    }
  }
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.notifications.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.notifications.length / this.state.perPage),
      postData
    })
  }
  /* Go to next,previous page in the pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.state.enabledPage = selectedPage + 1
    localStorage.setItem('notificationpagination', this.state.enabledPage)
    var data = {
      page: localStorage.getItem('notificationpagination'),
      limit: 8,
      chapter_id: this.state.chapter_id,
    }
    this.props.getAllNotificationsAjax(data)

  };
  /* render() function is used to update the UI. */
  render() {
    localStorage.setItem("activescreen", JSON.stringify('notificationlist'));
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    const { showPopover } = this.state
    return (
      <div className="container">
        <Row className='pt-4'>
          <Col xs lg="6">
            <h3 className="pagename mb-3">Notifications</h3>
          </Col>
          <Col xs lg="6">
            <div className='d-flex'>
              <div className='pr-3' style={{ width: '70%' }}>
                <span className="fa fa-search head-search"></span>
                <input style={{ paddingLeft: '40px' }} type="text" ref={ref => this.clearSearhText = ref} className="form-control" placeholder="Search Notification..." onChange={(e) => this.searchHandler(e)} />
              </div>
              <div>
                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewNotification()}>New Notification</button>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ width: '100%', paddingTop: '20px', marginLeft: '0px' }} className="row">
          <div className="box">
            <div className="userManagement-page">
              <div className="tabs-sec notify-tabs-sec">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" onClick={(event) => { this.listOfNotifications(event, 'all') }} href="#">All</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " data-toggle="tab" onClick={(event) => { this.listOfNotifications(event, 'sent') }} href="#">Sent</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listOfNotifications(event, 'scheduled') }} href="#">Scheduled</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row my-3">
              <div className="col mb-3 col-12 text-center">
                <center>
                  <div class="loader-spinner">
                    {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                  </div>
                </center>
                <div className="table-responsive">
                  <table style={{ margin: '10px' }} id="dataTable">
                    <thead>
                      {this.state.notifications?.length > 0 ?
                        <tr>
                          <th>To</th>
                          <th>Message</th>
                          <th>Date Sent</th>
                          <th>Status</th>
                          <th></th>
                        </tr> : null
                      }
                    </thead>
                    <tbody>
                      {
                        this.state.notifications?.length ? this.state.notifications?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
                          <tr key={item.notify_id} >
                            {item.sent_to === 'all'?
                             <a><td data-tooltip={item.username} data-tooltip-location="right"> {item.sent_to} BIGS </td></a>:
                            (item.sent_to===1) ?
                            <a><td data-tooltip={item.username} data-tooltip-location="right"> {item.username}  </td></a>:
                             <a><td data-tooltip={item.username} data-tooltip-location="right"> {item.sent_to} BIGS  </td></a>
                            
                            }
                            
                            <td> {item.message}</td>
                            {item.sent_date !== null ? <td>{moment(item.sent_date).format('MM/DD/YYYY')} </td> : <td></td>}
                            {item.message_status == 'scheduled' ? <td> {item.message_status}-{moment(item.scheduled_date).format('MM/DD/YYYY')}@{item.scheduled_time} {item.timezone} </td> : <td>{item.message_status}</td>}
                            {item.message_status == 'scheduled' ?
                              <td>
                                <div className="float-right">
                                  <span className="pr-2" name="edit" onClick={() => this.onEdit(item)}><Edit /></span>
                                  <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                    <Tooltip>
                                      <div className='text-center p-4'>
                                        <p className='tool-title'>Delete Notification</p>
                                        <p className='tool-para'>Are you sure you want to delete this notification? Deleted notifications may not be restored.</p>
                                      </div>
                                      <div className='d-flex btn-sec'>
                                        <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                                        <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                      </div>
                                    </Tooltip>
                                  </Overlay>
                                  <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.notify_id)}><CloseIcon /></span>
                                </div>
                              </td> : <td></td>}
                          </tr>
                        )) :
                          <tr>
                            <td colspan="4">
                              <div style={{ width: '100%' }}>
                                <h3 style={{ textAlign: 'center' }}>
                                  {this.state.DefaultMessage}
                                </h3>
                              </div>
                            </td>
                          </tr>
                      }
                    </tbody>
                  </table>
                  {this.state.pageCount > 0 ?
                    <div className="col-md-12">
                      <div className="pagination">
                        <ReactPagenate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={10}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}>
                        </ReactPagenate>
                      </div>
                    </div> : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    allNotificationsDetails: state.allNotificationsDetails,
    addNotification: state.addNotification
  }
}
/* Connects a React component to a Redux store */
export default connect(mapStateToProps, { getAllNotificationsAjax, deletenotificationForm, getSentNotificationsAjax, getScheduledNotificationsAjax })(NotificationsList)