import React, { Component } from "react";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { addChapterForm, chapterFetchInput } from '../../Store/chapters/addChaptersAction';
import { getTemplateListData } from '../../Store/template/getTemplageAction'
import HttpService from '../../Services/HttpService';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'
import { Circles } from 'react-loader-spinner';

var FormData = require('form-data');

class NewChapter extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            input: {},
            Uid: '',
            active_status: '0',
            chapter_name: '',
            description: '',
            chapter_id: '',
            spinner: false,
            tempaltes: {},
            selectedTemplate: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /* To make the api call initial page load */
    componentDidMount() {
        this.setState({
            spinner: true
        })
        this.props.getTemplateListData()
        if (this.props.location.state != null) {
            this.setState({
                chapter_id: this.props.location.state.detail.chapter_id,
                chapter_name: this.props.location.state.detail.chapter_name,
                description: this.props.location.state.detail.description,
                active_status: this.props.location.state.detail.active_status,
                selectedTemplate: this.props.location.state.detail.template?.templateid
            })
        }
    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.setState({
            spinner: false
        })
        this.state.tempaltes = nextProps.getTemplateData.templateData
    }
    /* Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
      
        let chaptersInput = {
            key: key,
            value: e.currentTarget.value
        }
        if (key === 'template') {
            this.setState({
                selectedTemplate: e.currentTarget.value
            })
        }
        if (key === 'active_status') {
            this.setState({
                active_status: e.currentTarget.value
            })
        }
        var fd = new FormData();
        for (var key in chaptersInput) {
            if (chaptersInput[key] == "chapter_name") {
                this.setState({
                    chapter_name : chaptersInput.value
                })
            }
            else if (chaptersInput[key] == "description") {
                this.setState({
                    description : chaptersInput.value
                })
             
            }
        }
    }
    /* Calling the api to Submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            spinner: true
        })
        const { addchapter } = this.props
        if (this.props.location.state != null) {
            if (this.validator.allValid()) {
                var data = new FormData();
                data.append('chapter_name', this.state.chapter_name);
                data.append('description', this.state.description);
                data.append('chapter_id', this.state.chapter_id);
                data.append('active_status', this.state.active_status);
                process.env.REACT_APP_SWITCH_TO_FEATURES == 1 &&
                data.append('template_id', this.state.selectedTemplate);
                HttpService.editChapter(data)
                    .then(response => {
                        if (response.data.status === 200) {
                            this.setState({
                                spinner: false
                            })
                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                pathname: '/dashboard/Chapters/ChaptersList',
                                propsData: this.props,
                                goToList: true,
                                closeOnClickOutside: false
                            }
                            handleSwalAlert(successAlert)
                        }
                        else {
                            this.setState({
                                spinner: false
                            })
                            var errorAlert = {
                                message: response.data.error,
                                alertType: 'warning'
                            }
                            handleSwalAlert(errorAlert)
                        }
                    })
                    .catch(error => {
                        this.setState({
                            spinner: false
                        })
                        var errorAlert = {
                            alertType: 'error'
                        }
                        handleSwalAlert(errorAlert)
                    })
            }
            else {
                this.setState({
                    spinner: false
                })
                this.validator.showMessages();
            }
        }
        else {

            if (!this.validator.allValid()) {
                this.setState({
                    spinner: false
                })
                this.validator.showMessages()
            }
            else {
                var data = new FormData();
                data.append('chapter_name', this.state.chapter_name);
                data.append('description', this.state.description);
                data.append('created_by', this.state.Uid);
                data.append('active_status', this.state.active_status);
                data.append('template_id', this.state.selectedTemplate);
                HttpService.addChapterservice(data)
                    .then(response => {
                        if (response.data.status == 200) {
                            this.setState({
                                spinner: false
                            })
                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                pathname: '/dashboard/Chapters/ChaptersList',
                                propsData: this.props,
                                goToList: true,
                                closeOnClickOutside: false
                            }
                            handleSwalAlert(successAlert)

                            this.chapterForm.reset();
                            this.setState({
                                active_status: '',
                                chapter_name: '',
                                partner_name: '',
                                description: '',
                                start_date: '',
                                end_date: ''
                            })
                            this.validator.hideMessages();
                        }

                        else {
                            this.setState({
                                spinner: false
                            })
                            var errorAlert = {
                                alertType: 'warning',
                                message: response.data.error
                            }
                            handleSwalAlert(errorAlert)
                        }
                    })
                    .catch(error => {
                        this.setState({
                            spinner: false
                        })
                        var errorAlert = {
                            alertType: 'error'
                        }
                        handleSwalAlert(errorAlert)
                    })
            }
        }
    };
    /* Goto chapters list after cancel clicked */
    onCancel() {
        this.props.history.push('/dashboard/Chapters/ChaptersList');
    }
    /* Goto chapters list after new chapter has been created */
    onChapters() {
        this.props.history.push('/dashboard/Chapters/ChaptersList');
    }

    /* To creating, updating the views. */
    render() {
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        this.state.Uid = this.currentuser.user.user_id;
        return (
            <div>
                <form className="form" ref={form => this.chapterForm = form} onSubmit={this.handleSubmit}>
                    <div className="container">
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12">
                                <div className="horizontal-container">
                                    <label className="label-discount" onClick={() => this.onChapters()}>Chapters</label>
                                    <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                                    {!this.props.location.state ?
                                        <label className="label-discount">New Chapter</label> : <div className="horizontal-container">
                                            <label className="label-discount">Edit {this.state.chapter_name}</label>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <section className="newuser-sec mt-3">
                            <center>
                                <div className="loader-spinner">
                                    {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                                </div>
                            </center>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="mb-3">
                                        <div>
                                            <label className="lehead" defaultValue={this.state.active_status}>Status<span>*</span></label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline" >
                                            <input type="radio" className="radio" id="active_status" name="active_status" value="0" checked={this.state.active_status == '0'}
                                                onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                            <label className="custom-control-label" htmlFor="active_status">Active</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" id="active_status_two" name="active_status" value="1" checked={this.state.active_status == '1'}
                                                onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                            <label className="custom-control-label" htmlFor="active_status_two">InActive</label>
                                        </div>
                                        <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <div className="form-group">
                                            <label for="chaptername" className="lehead">Chapter Name:<span>*</span></label>
                                            <input
                                                className="form-control"
                                                id="chaptername"
                                                type="text"
                                                onChange={(e) => this.handleChange(e, 'chapter_name')}
                                                defaultValue={this.state.chapter_name}
                                                onBlur={this.handleChange} />
                                            <span className="text-danger">{this.validator.message("chapter_name", this.state.chapter_name, "required")}</span>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-group">
                                            <label for="chapterdesc" className="lehead">Chapter Description:*</label>
                                            <textarea
                                                id="chapterdesc"                                                
                                                defaultValue={this.state.description}
                                                className="form-control"
                                                onChange={(e) => this.handleChange(e, 'description')} />
                                            <span className="text-danger">{this.validator.message("description", this.state.description, "required")}</span>
                                        </div>
                                    </div>
                                
                                   {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ? 
                                   <>
                                    <label className="lehead">Select Template</label>
                                    {
                                        <div>
                                            <select value={this.state.selectedTemplate} onChange={(e) => this.handleChange(e, 'template')} style={{ width: '200px', height: '40px', padding: '5px' }} >
                                                <option value="">Select Template</option>
                                                {this.state.tempaltes.templates_list?.map((e) => {
                                                    return <option value={e.template_id}>{e.template_name}</option>;
                                                })}
                                            </select>
                                            <span className="text-danger">{this.validator.message("template", this.state.selectedTemplate, "required")}</span>
                                        </div>
                                        
                                    }</>:null
                                   }
                                   
                                </div>
                            </div>
                            <Row className="my-4">
                                <Col xs lg="6">
                                    <div className="save-sec">
                                        <button type="submit" className="btn btn-info btn-success mt-1">Save</button>
                                    </div>
                                </Col>
                                <Col xs lg="6">
                                    <div className="cancel-sec">
                                        <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </form >
            </div >
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        addchapter: state.addchapter,
        getTemplateData: state.templateList
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { addChapterForm, chapterFetchInput, getTemplateListData })(withRouter(NewChapter));
