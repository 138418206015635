import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCmshelpcenter, getCmshelpcenterAjax } from '../../Store/cmshelpcenter/cmsAction';
import ReactPagenate from 'react-paginate';
import '../../App.css'
import Edit from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import * as moment from 'moment';
import { Tooltip, Overlay } from 'react-bootstrap'
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import { EditorState } from 'draft-js'; //ContentState
import handleSwalAlert from '../SwalAlert'

class CMSlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      spinner: false,
      offset: 0,
      perPage: 8,
      currentPage: 0,
      cmshelpdata: [],
      filterchapter: [],
      slice: [],
      deleteResponce: '',
      DefaultMessage: '',
      chapterSearchList: [],
      chapterSearchInput: '',
      showPopover: false,
      eventtarget: null,
      chapter_id: null,
      postData: [],
      pageCount: 0,
      enabledPage: 1,
      cmsResponse: {}
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.setState({
      spinner: true
    })
    const data = `page=${this.state.enabledPage}&limit=8`
    this.props.getCmshelpcenterAjax(data);
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.cmsResponse = nextProps.cmshelplist
    if (this.state.cmsResponse.cmsErrorData.status != null) {
      this.setState({
        spinner: false,
        pageCount: 0,
        cmshelpdata:[],
        DefaultMessage: this.state.cmsResponse.cmsErrorData.statusText
      });
    }
    else if (this.state.cmsResponse.cmshelplistdata.help_list.length > 0) {
      this.state.cmshelpdata = nextProps.cmshelplist.cmshelplistdata.help_list

      this.setState({
        spinner: false,
        pageCount: Math.ceil(this.state.cmsResponse.cmshelplistdata.count / this.state.perPage),

      });
    }
    else {
      this.setState({
        spinner: false,
        pageCount: Math.ceil(this.state.cmsResponse.cmshelplistdata.count / this.state.perPage),
        cmshelpdata:[],
        DefaultMessage: nextProps.cmshelplist.cmshelplistdata.message
      });

    }
  }
  /* Goto new cms list to create a new cms  */
  onnewCMSHelpCenter() {
    this.props.history.push({
      pathname: '/dashboard/cmshelpcenter/addcmshelpcenter'
    })
  }
  /*Edit the created cms list */
  onEdit(item) {
    this.props.history.push({
      pathname: "/dashboard/cmshelpcenter/addcmshelpcenter",
      state: { detail: item }
    })
  }
  /* To show the pop up  */
  showPopoverDialog = (e, hid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      help_id: hid
    })
  }
  /* To close the pop up  */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }

  hangleErrorAlert(){
    var errorAlert = {          
      alertType: 'error'
    }
    handleSwalAlert(errorAlert)
  }

  /* To delete the cms list item */
  onDelete = (item, index) => {
    var data = {
      help_id: this.state.help_id
    }
    HttpService.deleteCmsHelpCenter(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          this.setState({
            showPopover: false,
            spinner: false
          })
          var successAlert = {
            message: this.state.deleteResponce.message,
            alertType: 'success'
          }
          handleSwalAlert(successAlert)         
          this.componentDidMount()
        }
        else {
          this.setState({
            showPopover: false,
            spinner: false
          })
          this.hangleErrorAlert()          
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false,
          spinner: false
        })
        this.hangleErrorAlert()
      })
  }
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.cmshelpdata?.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice?.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)

    this.setState({
      pageCount: Math.ceil(this.state.cmshelpdata?.length / this.state.perPage),
      postData: this.state.slice
    })
  }
  /* Go to next ,previous page in the pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const retrivepage = selectedPage+1
    
    localStorage.setItem('cmsselectedpage',retrivepage)
this.state.enabledPage = localStorage.getItem('cmsselectedpage')

    const data = `page=${this.state.enabledPage}&limit=8`
    this.props.getCmshelpcenterAjax(data);
  };
  /* For search name of the cms in the list  */
  searchHandler = (event) => {
    this.state.chapterSearchInput = event.target.value
    if (this.state.chapterSearchInput.length === 0) {
      this.componentDidMount()
      this.setState({ spinner: !this.state.spinner })
    }
    else {
      if(this.state.chapterSearchInput === 1){
        this.setState({
          cmshelpdata:[]
        })

      }
      const data = `page=${this.state.enabledPage}&limit=8&search=${this.state.chapterSearchInput}`
    this.props.getCmshelpcenterAjax(data);     
    }
  }

  /* render() function is used to update the UI. */
  render() {
    const { showPopover, cmshelpdata } = this.state
    return (

      <div className="container">
        <Row className='pt-4'>
          <Col xs lg="6">
            <h3 className="pagename mb-3">CMS Help Center</h3>
          </Col>

          <Col xs lg="6">
            <div className='d-flex'>
              <div className='pr-3' style={{ width: '70%' }}>
                <span className="fa fa-search head-search"></span>
                <input style={{ paddingLeft: '40px' }} type="text" ref="removesearch" className="form-control" placeholder="Search help center name..." onChange={(e) => this.searchHandler(e)} />

              </div>
              <div style={{ width: '50%' }}>
                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewCMSHelpCenter()}>New CMS Help Center </button>
              </div>
            </div>
          </Col>
        </Row>

        <div className="box">
          <center>
            <div class="loader-spinner">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
          {cmshelpdata.length > 0 ?
            <div style={{ width: '100%' }} className="row">
              <div className="mb-3 col-12 text-center">
                <div className="table-responsive">
                  <table id="dataTable">
                    <thead>
                      {cmshelpdata.length > 0 ? <tr>
                        <th>CMS Help Center Name</th>
                        <th>Description</th>
                        <th>Created Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr> : null}
                    </thead>
                    <tbody>
                      {
                        cmshelpdata?.length ? cmshelpdata?.map((item, index) => (
                          <tr key={item.help_id}>
                            <td> {item.title}</td>
                            <td> {<div dangerouslySetInnerHTML={{ __html: item.description }} className="des-para"></div>}</td>
                            <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                            {item.active_status == '0' ? <td>Active</td> : <td>In Active</td>}
                            <td>
                              <div className="float-right">
                                <span className="pr-2" name="edit" onClick={() => this.onEdit(item)}><Edit /></span>
                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                  <Tooltip>
                                    <div className='text-center p-4'>
                                      <p className='tool-title'>Delete CMS Help Center</p>
                                      <p className='tool-para'>Are you sure you want to delete this help center?</p>
                                    </div>
                                    <div className='d-flex btn-sec'>
                                      <button onClick={() => this.onDelete(item, index)} className='tool-btn-yes'>Yes, Delete</button>
                                      <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                    </div>
                                  </Tooltip>
                                </Overlay>
                                <div className='cross-center'>
                                  <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.help_id)}><CloseIcon /></span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                      }
                    </tbody>
                  </table>

                  {this.state.pageCount > 0 ? <div className="pagination">
                    <ReactPagenate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={8}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div> : null
                  }
                </div>
              </div>
            </div> : <center><h3>{this.state.DefaultMessage}</h3></center>}
        </div>

      </div>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  console.log('cms help state:- ', state)
  return {
    cmshelplist: state.cmshelplist,
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getCmshelpcenter, getCmshelpcenterAjax })(CMSlist)
