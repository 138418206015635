import React, {Component} from 'react';

class Footer extends Component {
  /*To update the version number for every release */
    render(){
      return (
          <div className="footer-sec">
            <h5>Version 1.23</h5>
          </div>
              )
      }
}

export default Footer;