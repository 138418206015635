import React, { Component } from "react";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { addChapterForm, chapterFetchInput } from '../../Store/chapters/addChaptersAction';
import Icon from "react-crud-icons";
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import { spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js'; //ContentState
import draftToHtml from 'draftjs-to-html';
import { Circles } from 'react-loader-spinner';
import { stateFromHTML } from 'draft-js-import-html';
import { SketchPicker } from 'react-color'
import { VerticalAlignTopSharp } from "@material-ui/icons";
import handleSwalAlert from '../SwalAlert'

var FormData = require('form-data');
class AgencyTags extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            tagColor: '',
            tagname: '',
            color: '',
            tagId: '',
            tagName: '',
            spinner: false,
            disablebuttons: false,
            active_status: 0

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /* Add the color for tag */
    onChangeColor = (color) => {
        this.setState({
            tagColor: color.hex,
        })
    };
    /* Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
        let chaptersInput = {
            key: key,
            value: e.currentTarget.value
        }

        if (key === 'tagname') {
            this.setState({
                tagName: e.currentTarget.value
            })
        }
        if (key === 'active_status') {
            this.setState({
                active_status: e.currentTarget.value
            })
        }
    }
    /* To make the api call initial page load */
    componentDidMount() {
        if (this.props.location.state != null) {
            this.setState({
                tagName: this.props.location.state.detail.tag_name,
                active_status: this.props.location.state.detail.active_status,
                tagId: this.props.location.state.detail.tag_id,
                tagColor: this.props.location.state.detail.tag_color
            })
        }
    }
    onErrorAlert(){
        var errorAlert = {
            alertType : 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /* Calling the api to Submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            spinner: true,
            disablebuttons: true
        })
        if (this.props.location.state != null) {
            if (this.validator.allValid()) {
                var data = {
                    tag_id: this.state.tagId,
                    tag_name: this.state.tagName,
                    tag_color: this.state.tagColor,
                    active_status: this.state.active_status
                }
                HttpService.editTag(data)
                    .then(response => {
                        console.log('edit tag responce', response.data);
                        if (response.data.status === 200) {
                            this.setState({
                                spinner: false,
                                disablebuttons: false
                            })
                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                closeOnClickOutside: false,
                                goToList : true,
                                pathname : "/dashboard/agencysponsored_tags",
                                propsData : this.props
                            }
                            handleSwalAlert(successAlert)                            
                        }

                        else {
                            this.setState({
                                spinner: false,
                                disablebuttons: false
                            })

                            var erroAlert = {
                                alertType: 'warning',
                                message : response.data.error
                            }
                            handleSwalAlert(erroAlert)
                        }
                    })
                    .catch(error => {
                        this.setState({
                            spinner: false,
                            disablebuttons: false
                        })
                        this.onErrorAlert()
                    })
            }
            else {
                this.setState({
                    spinner: false,
                    disablebuttons: false
                })
                this.validator.showMessages();
            }
        }
        else {
            if (!this.validator.allValid()) {
                this.setState({
                    spinner: false,
                    disablebuttons: false
                })
                this.validator.showMessages()
            }
            else {
                var data = {
                    tag_name: this.state.tagName,
                    tag_color: this.state.tagColor,
                    active_status: this.state.active_status
                }

                HttpService.addTag(data)
                    .then(response => {
                        console.log('add tag response', response);
                        if (response.data.status == 200) {
                            this.setState({
                                spinner: false,
                                disablebuttons: false
                            })

                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                closeOnClickOutside: false,
                                goToList : true,
                                pathname : "/dashboard/agencysponsored_tags",
                                propsData : this.props
                            }
                            handleSwalAlert(successAlert)                          

                            this.validator.hideMessages();
                        }

                        else {
                            this.setState({
                                spinner: false,
                                disablebuttons: false
                            })
                            var errorAlert = {
                                alertType : 'warning',
                                message : response.data.error
                            }
                            handleSwalAlert(errorAlert)
                        }
                    })
                    .catch(error => {
                        this.setState({
                            spinner: false,
                            disablebuttons: false
                        })
                        this.onErrorAlert()
                    })
            }
        }
    };
    /* Goto event tag list after cancel clicked */
    onCancel() {
        this.props.history.push("/dashboard/agencysponsored_tags");
    }
    /* Goto event tag list page */
    gototagslist() {
        this.props.history.push("/dashboard/agencysponsored_tags");
    }
    /* render() function is used to update the UI. */
    render() {
        return (
            <div>
                <form className="form" ref={form => this.chapterForm = form} onSubmit={this.handleSubmit}>
                    <div className="container">
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12">
                                <div className="horizontal-container">
                                    <h3 className="label-discount" onClick={() => this.gototagslist()}>Agency Sponsored Events Tags</h3>
                                    <i style={{ color: "#43425d", margin: "5px" }} class="fa fa-chevron-right" aria-hidden="true"></i>
                                    {true ?
                                        <h3 className="label-discount">New Agency Sponsored Event Tag</h3> : <div className="horizontal-container">
                                            <h3 className="label-discount">Edit </h3>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <section className="newuser-sec">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="mb-3">
                                        <div>
                                            <label className="lehead" defaultValue={this.state.active_status}>Status<span>*</span></label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline" >
                                            <input type="radio" className="radio" id="active_status" name="active_status" value="0" checked={this.state.active_status == '0'}
                                                onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                            <label className="custom-control-label" htmlFor="active_status">Active</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" id="active_status_two" name="active_status" value="1" checked={this.state.active_status == '1'}
                                                onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                            <label className="custom-control-label" htmlFor="active_status_two">InActive</label>
                                        </div>
                                        <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <div className="form-group">
                                            <label for="tagname" className="lehead">Tag Name:<span>*</span></label>
                                            <input className="form-control" type="text" id="tagname" name="tagname" onChange={(e) => this.handleChange(e, 'tagname')} defaultValue={this.state.tagName} onBlur={this.handleChange} />
                                            <span className="text-danger">{this.validator.message("tagname", this.state.tagName, "required")}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <div className="form-group">
                                            <label className="lehead">Tag Color:<span>*</span></label>
                                            <div>
                                                <SketchPicker color={this.state.tagColor} onChange={(e) => this.onChangeColor(e)} />
                                            </div>
                                            <span className="text-danger">{this.validator.message("tagColor", this.state.tagColor, "required")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <center>
                                    <div class="loader-spinner">
                                        {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />}
                                    </div>
                                </center>
                            </div>
                            <div className='row'>
                                <div className="col-md-6 my-4">
                                    <div className="form-group">
                                        <button type="submit" disabled={this.state.disablebuttons} className="btn btn-info btn-success mt-1" onClick={this.handleSubmit}>Save</button>
                                    </div>
                                </div>
                                <div className="col-md-6  my-4">
                                    <div className="float-right cancel-sec">
                                        <button type="cancel" className="cancelbtnnew" disabled={this.state.disablebuttons} onClick={() => this.onCancel()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </form>
            </div>
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        addchapter: state.addchapter
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { addChapterForm, chapterFetchInput })(withRouter(AgencyTags));