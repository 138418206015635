import { ACTIVITY_LIST } from "../types";

const initialState = {
    activitylist: [],
}

export default function activityListreducer(state = initialState, action) {

    switch (action.type) {

        case ACTIVITY_LIST:
            return {
                ...state,
                activitylist: action.response,
            }
        default:
            return state
    }

}