import React, { Component } from "react";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { addChapterForm, chapterFetchInput } from '../../Store/chapters/addChaptersAction';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import { withRouter } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js'; //ContentState
import draftToHtml from 'draftjs-to-html';
import { Circles } from  'react-loader-spinner';
import { stateFromHTML } from 'draft-js-import-html';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'
var FormData = require('form-data');
const styles = {
    editor: {
        border: '0.5px solid lightgray',
        minHeight: '15em',
        left: '2935px',
        padding: "10px"
    }
};

class CmsHelpCenter extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            editorState: EditorState.createEmpty(),
            input: {},
            Uid: '',
            active_status: '0',
            cms_category: '',
            cms_title:'',
            cms_description: '',
            cms_help_id: '',
            spinner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
/* To make the api call initial page load */
    componentDidMount() {
        if (this.props.location.state != null) {
            var contentState = stateFromHTML(this.props.location.state?.detail.description);
            this.setState({
                editorState: EditorState.createWithContent(contentState)
            })
            this.setState({
                cms_category:this.props.location.state.detail.category,
                cms_help_id: this.props.location.state.detail.help_id,
                cms_title: this.props.location.state.detail.title,
                cms_description: this.props.location.state.detail.description,
                active_status: this.props.location.state.detail.active_status
            })      
        }
    }
     /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.state.spinner = false
    }
    /* Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
        let chaptersInput = {
            key: key,
            value: e.currentTarget.value
        }
        if (key === 'active_status') {
            this.setState({
                active_status: e.currentTarget.value
            })
        }
        if (key === 'category') {
            this.setState({
                cms_category: e.currentTarget.value
            })
        }
        if (key === 'title') {
            this.setState({
                cms_title: e.currentTarget.value
            })
        }
        if (key === 'description') {
            this.setState({
                cms_description: e.currentTarget.value
            })
        }       
    }
    /* It  converts the text to html tags*/
    onEditorStateChange = (editorState) => {
        const currentContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        this.setState({
            editorState,
        });
        if (this.state.editorState !== null) {
            let eventInput = {
                key: "draftbody",
                value: this.state.editorState
            }
        }
        else {
            let eventInput = {
                key: "draftbody",
                value: ''
            }
        }

    };
    handleErrorAlert(){
        var errorAlert = {
            alertType: "error",                              
           }
           handleSwalAlert(errorAlert)
    }
    /* Calling the api to Submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        this.state.spinner = true
        const html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.state.cms_description = html
        if (this.props.location.state != null) {
            if (this.validator.allValid()) {
                var data = new FormData();
                data.append('category', this.state.cms_category);
                data.append('title', this.state.cms_title);
                data.append('description', this.state.cms_description);
                data.append('active_status', this.state.active_status);
                data.append('help_id', this.state.cms_help_id);
                HttpService.createHelpCenterEdit(data)
                    .then(response => {
                        if (response.data.status === 200) {

                           this.state.spinner = false

                           var successAlert = {
                            message: response.data.message,
                            alertType: "success",
                            closeOnEsc: false,
                            dangerMode: true,
                            closeOnClickOutside: false,
                            pathname: "/dashboard/cmshelpcenter",
                            goToList : true,
                            propsData : this.props
                           }
                           handleSwalAlert(successAlert)                            
                        }
                        else {
                            this.state.spinner = false

                            var errorAlert = {
                                message: response.data.error,
                                alertType: "warning",                              
                               }
                               handleSwalAlert(errorAlert)                          
                        }
                    })
                    .catch(error => {
                        this.handleErrorAlert()
                      
                    })
            }
            else {
                this.state.spinner = false
                this.validator.showMessages();
            }
        }
        else {

            if (!this.validator.allValid()) {
                this.state.spinner = false
                this.validator.showMessages()
            }
            else {
                var data = new FormData();
                data.append('category', this.state.cms_category);
                data.append('title', this.state.cms_title);
                data.append('description', this.state.cms_description);
                data.append('active_status', this.state.active_status);
                HttpService.createHelpCenter(data)
                    .then(response => {
                        if (response.data.status == 200) {
                            this.state.spinner = false

                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                closeOnClickOutside: false,
                                pathname: "/dashboard/cmshelpcenter",
                                goToList : true,
                                propsData : this.props
                               }
                               handleSwalAlert(successAlert)                           
                            this.chapterForm.reset();
                            this.setState({
                                active_status: '',
                                editorState: '',
                                title: '',
                                th: '',
                                active_status: '',
                            })
                            this.validator.hideMessages();
                        }
                        else {
                            var errorAlert = {
                                message: response.data.error,
                                alertType : 'warning'
                            }
                            handleSwalAlert(errorAlert)                          

                        }
                    })
                    .catch(error => {
                       this.handleErrorAlert()
                  })
            }
        }
    };
/* Goto cms help center list page */
    onCancel() {
        this.props.history.push("/dashboard/cmshelpcenter" );
    }
/* Goto list of cms help center */
    cmshelpcener() {
        this.props.history.push("/dashboard/cmshelpcenter");
    }
    /* render() function is used to update the UI. */
    render() {
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        this.state.Uid = this.currentuser.user.user_id;
        const { editorState } = this.state;
        return (
            <div>
                <form className="form" ref={form => this.chapterForm = form} onSubmit={this.handleSubmit}>
                    <div className="container">
                        <Row className="mt-3 mb-4">
                                <Col md="12">
                                        <label className="label-discount" onClick={() => this.cmshelpcener()}>CMS Help Center</label>
                                        <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                                        {!this.props.location.state ?
                                            <label className="label-discount">New CMS Help Center</label> :
                                            <label className="label-discount">Edit {this.props.location.state.detail.title}</label>
                                  }
                                </Col>
                            </Row>
                        <section className="newuser-sec">
                          <Row>
                                <Col md="5"  className="mb-3">
                                    <label className="lehead" defaultValue={this.state.active_status}>Status<span>*</span></label>
                                       <div className="mb-3">
                                            <div className="custom-control custom-radio custom-control-inline" >
                                                <input type="radio" className="radio" id="active_status" name="active_status" value="0" checked={this.state.active_status == '0'}
                                                    onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                                <label className="custom-control-label" htmlFor="active_status">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="radio" id="active_status_two" name="active_status" value="1" checked={this.state.active_status == '1'}
                                                    onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                                                <label className="custom-control-label" htmlFor="active_status_two">InActive</label>
                                            </div>
                                            <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                                        </div>
                                    <div className="mb-3">
                                        <div className="form-group">
                                            <label className="lehead">Category:<span>*</span></label>
                                            <input className="form-control" type="text" onChange={(e) => this.handleChange(e, 'category')} defaultValue={this.state.cms_category}
                                            onBlur={this.handleChange} />
                                            <span className="text-danger">{this.validator.message("category", this.state.cms_category, "required")}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-group">
                                            <label className="lehead">Title:<span>*</span></label>
                                            <input className="form-control" type="text" onChange={(e) => this.handleChange(e, 'title')} defaultValue={this.state.cms_title}
                                                onBlur={this.handleChange} />
                                            <span className="text-danger">{this.validator.message("title", this.state.cms_title, "required")}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="10">
                                    <div className="form-group">
                                        <label className="lehead">Description:*</label>
                                        <div style={styles.editor} onClick={this.focusEditor}>
                                        <Editor editorState={editorState} placeholder=" Write Something... " wrapperClassName="demo-wrapper" editorClassName="demo-editor"
                                            editorStyle={{ lineHeight: '75%' }} onEditorStateChange={editorState => this.onEditorStateChange(editorState)} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                             <center><div class="loader-spinner">{this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />}</div></center>
                            </div>
                            <Row  className="my-4">
                                <Col md="6">
                                    <div className="save-sec">
                                        <button type="submit" className="btn btn-info btn-success mt-1" onClick={this.handleSubmit}>Save</button>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="cancel-sec">
                                        <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </form>
            </div>
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
    return {
        addchapter: state.addchapter
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { addChapterForm, chapterFetchInput })(withRouter(CmsHelpCenter));
