import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import HttpService from '../../Services/HttpService';
import { getChapters } from '../../Store/chapters/chapterListAction'
import { addActivityForm, activityFetchInput } from '../../Store/activities/addActivitiesaction';
import handleSwalAlert from '../SwalAlert'

class NewActivity extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            input: {},
            activity_name: '',
            description: '',
            activity_date: '',
            chaptersList: [],
            spinner: false,
            selectedChapterIds: [],
            sent_to: 'all',
            active_status: 0,
            ChapterIdsToshow: []
        };
    }
    /* To make the api call initial page load */
    componentDidMount() {
        if (this.props.location.state != null) {
            this.setState({
                spinner: true,
                title: this.props.location.state.detail.title,
                description: this.props.location.state.detail.description,
                active_status: this.props.location.state.detail.active_status,
                activity_date: this.props.location.state.detail.activity_date,
                sent_to: this.props.location.state.detail.chapter_type,
                ChapterIdsToshow: this.props.location.state.detail.chapters?.map((ids) => {
                    return { chapter_name: ids.chapter_name, chapter_id: ids.chapterid }
                }),
                selectedChapterIds: this.props.location.state.detail.chapters?.map((ids) => {
                    return ids.chapterid

                })
            })
        }
        if (this.props.location.state?.detail.chapter_type == 'selected') {
            this.props.getChapters()
        }
    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.setState({
            chaptersList: nextProps.newUserManagementDetails.chaptersList.chapters_list,
        });
    }
    /* Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
        let activityInput = {
            key: key,
            value: e.currentTarget.value
        }
        var fd = new FormData();
        for (var key in activityInput) {
            if (activityInput[key] == 'active_status') {
                this.setState({
                    active_status: activityInput.value
                })
            }
            else if (activityInput[key] == "title") {
                this.state.title = activityInput.value
            }
            else if (activityInput[key] == "description") {
                this.setState({
                    description: activityInput.value
                })
            }
            else if (activityInput[key] == "sent_to") {
                this.setState({ sent_to: activityInput.value })

                if (activityInput.value == 'selected') {
                    this.props.getChapters();
                }
            }
            else if (activityInput[key] == "activity_date") {
                this.setState({
                    activity_date: activityInput.value
                })
            }
        }
        this.props.activityFetchInput(activityInput);
    }
    onErrorAlert() {
        var errorAlert = {
            alertType: 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /* Calling the api to Submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ spinner: true })
        const { addActivity } = this.props
        if (this.validator.allValid()) {
            if (this.props.location.state?.detail != null) {
                var data = {
                    title: this.state.title,
                    description: this.state.description,
                    chapterids: this.state.sent_to == 'all' ? 'all' : this.state.selectedChapterIds.map(id => (id)),
                    activity_date: this.state.activity_date,
                    active_status: this.state.active_status,
                    activity_id: this.props.location.state?.detail ?
                        this.props.location.state.detail.activity_id : null
                }
                HttpService.editActivity(data)
                    .then(response => {
                        if (response.data.status === 200) {
                            this.setState({
                                spinner: false
                            })
                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                closeOnClickOutside: false,
                                goToList: true,
                                pathname: '/dashboard/activities',
                                propsData: this.props
                            }
                            handleSwalAlert(successAlert)                           
                        }
                        else {
                            this.setState({ spinner: false })

                            this.onErrorAlert()
                        }
                    }).catch(error => {
                        this.setState({ spinner: false })
                        this.onErrorAlert()
                    })
            }
            else {
                var data = {
                    title: this.state.title,
                    description: this.state.description,
                    chapterids: this.state.sent_to == 'all' ? 'all' : this.state.selectedChapterIds.map(id => (id)),
                    activity_date: this.state.activity_date,
                    active_status: this.state.active_status,
                }
                HttpService.addActivityData(data)
                    .then(response => {
                        if (response.data.status === 200) {
                            this.setState({
                                spinner: false
                            })
                            var successAlert = {
                                message: response.data.message,
                                alertType: "success",
                                closeOnEsc: false,
                                dangerMode: true,
                                closeOnClickOutside: false,
                                goToList: true,
                                pathname: '/dashboard/activities',
                                propsData : this.props
                            }
                            handleSwalAlert(successAlert)
                        }
                        else {
                            this.setState({ spinner: false })
                            this.onErrorAlert()
                        }
                    }).catch(error => {
                        this.setState({ spinner: false })
                        this.onErrorAlert()
                    })
            }
        }
        else {
            this.setState({ spinner: false })
            this.validator.showMessages();
        }
    };
    /* To select the list of values for multiselect dropdown */
    onSelectItem = (selected, ii) => {
        const chapter_ids = this.state.selectedChapterIds;
        if (chapter_ids == null) {
            this.setState({
                selectedChapterIds: selected.map(id => (
                    id.chapter_id
                ))
            })
        }
        else {
            chapter_ids.push(+ii.chapter_id)
            this.setState({ selectedChapterIds: chapter_ids })
        }
    }
    /* To remove the list of values for multiselect dropdown */
    onRemove = (removeusers, removeitem, yyy) => {
        const remove_chapter_ids = this.state.selectedChapterIds
        let index
        index = remove_chapter_ids.indexOf(removeitem.chapter_id)
        remove_chapter_ids.splice(index, 1)
        this.state.selectedChapterIds = remove_chapter_ids
    }
    /* Goto activity list after new activity has been created */
    onActivities() {
        this.props.history.push('/dashboard/activities');
    }
    /* Goto activity list after cancel clicked */
    onCancel() {
        this.props.history.push('/dashboard/activities');
    }
    /* Disabling for the past dates */
    disablepastdays() {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }
    /* To creating, updating the views. */
    render() {
        return (
            <>
                <form className="form" ref={form => this.activityForm = form} onSubmit={this.handleSubmit}>
                    <div className="container">
                        <Row className="mt-3 mb-4">
                            <Col xs lg="12">
                                <div className="horizontal-container">
                                    <label className="label-discount" onClick={() => this.onActivities()}>Activities</label>
                                    <i className="fa fa-chevron-right right-arrow" aria-hidden="true"></i>

                                    <label className="label-discount">New Activity</label>
                                </div>
                            </Col>
                        </Row>
                        <section className="newuser-sec mt-3">
                            <div className="mb-3">
                                <div>
                                    <label className="lehead" >Status<span>*</span></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="radio" id="active_status" name="active_status" value="0" checked={this.state.active_status == '0'}
                                        onChange={(e) => this.handleChange(e, 'active_status')} />
                                    <label className="custom-control-label" htmlFor="active_status">Active</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="radio" id="active_status_two" name="active_status" value="1" checked={this.state.active_status == '1'}
                                        onChange={(e) => this.handleChange(e, 'active_status')} />
                                    <label className="custom-control-label" htmlFor="active_status_two">InActive</label>
                                </div>
                                <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                            </div>
                            <Row>
                                <Col xs lg="5">
                                    <div className="form-group">
                                        <label className="lehead"> Title:<span>*</span></label>
                                        <input className="form-control" type="text" onChange={(e) => this.handleChange(e, 'title')} defaultValue={this.state.title} onBlur={this.handleChange} />
                                        <span className="text-danger">{this.validator.message("title", this.state.title, "required")}</span>
                                    </div>
                                    <div className="form-group">
                                        <label className="lehead">Description:*</label>
                                        <textarea className="form-control" onChange={(e) => this.handleChange(e, 'description')} defaultValue={this.state.description} onBlur={this.handleChange} />
                                        <span className="text-danger">{this.validator.message("description", this.state.description, "required")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="form-group">
                                    <label className="lehead">Chapters*</label>
                                    <Row>
                                        <Col xs lg="3">
                                            <input checked={this.state.sent_to == 'all'} type="radio" id="defaultRadio" name="sent_to" value='all'
                                                onChange={(e) => this.handleChange(e, 'sent_to')} />
                                            <label for="defaultRadio" className="lehead">All Chapters</label>
                                        </Col>
                                        <Col xs lg="9">
                                            <input checked={this.state.sent_to == "selected"}
                                                type="radio" id="defaultRadioone" name="sent_to" value="selected"
                                                onChange={(e) => this.handleChange(e, 'sent_to')} />
                                            <label className="lehead"
                                                for="defaultRadioone"
                                            >Select Chapters</label>
                                        </Col>
                                    </Row>
                                    {this.state.sent_to == 'selected' ?
                                        <Row>
                                            <Col xs lg="3" className="mb-3 addadency-sec">
                                                <Multiselect
                                                    placeholder="Select Chapters"
                                                    options={this.state.chaptersList}
                                                    displayValue={'chapter_name'}
                                                    onSelect={this.onSelectItem}
                                                    onRemove={this.onRemove}
                                                    selectedValues={this.state.ChapterIdsToshow}
                                                    style={{
                                                        chips: { border: 'none', 'borderRadius': '15px' },
                                                        multiselectContainer: { color: '#8f9199' },
                                                        searchBox: { border: 'none', 'border': '1px solid gray', 'borderRadius': '5px' }
                                                    }}
                                                    className="multiselect"
                                                />
                                            </Col>
                                        </Row> : null}
                                </div>
                                <Col xs lg="3">
                                    <div className="form-group">
                                        <label className="lehead">Date*</label>
                                        <input type="Date" name="activity_date" defaultValue={this.state.activity_date} className="form-control" min={this.disablepastdays()}
                                            onChange={(e) => this.handleChange(e, 'activity_date')} />
                                        <span className="text-danger">{this.validator.message("Date", this.state.activity_date, "required")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col xs lg="6">
                                    <div className="save-sec">
                                        <button type="submit" className="btn btn-info btn-success mt-1">Submit</button>
                                    </div>
                                </Col>
                                <Col xs lg="6">
                                    <div className="cancel-sec">
                                        <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </form>
            </>
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        newUserManagementDetails: state.newUserManagementDetails,
        chaptersList: state.chaptersList,
        addActivity: state.addActivity
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getChapters, addActivityForm, activityFetchInput })(withRouter(NewActivity));
