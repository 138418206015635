import { DASHBOARD_CHEPATER_DATA } from "../types";
const initialState = {
    dashboardlist: [],
}
export default function dashboardreducer(state = initialState, action) {

    switch (action.type) {

        case DASHBOARD_CHEPATER_DATA:
            return {
                ...state,
                dashboardlist: action.response,
            }
        default:
            return state
    }

}