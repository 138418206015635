import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Features extends Component {
  constructor() {
    super();
    this.state = {
      resposiveNav: '0'
    }
  }

  onHandleResponsive(data) {

    this.state.resposiveNav === '0' ?
      data === 'activate' ?
        this.setState({ resposiveNav: "1" }) :
        this.setState({ resposiveNav: '0' }) :
      this.setState({ resposiveNav: "0" })

  }
    /* render() function is used to update the UI. */

  render() {
    return (
      <>
        <main className='body-new'>
          <header className="header-new">
            <div className="header__inner">
              <a href="#" className="header__logo logo-new"></a>
              <div onClick={() => this.onHandleResponsive('active')}
                className={this.state.resposiveNav === '0' ? "header__aside" : "header__aside is-active"}>
                <nav className="nav">
                  <ul>
                    <li>
                      <a href="/">Home</a>

                      {/* <Route path='/' exact component={Home} /> */}
                    </li>
                    <li>
                      <a href="/features">Features</a>
                    </li>
                    <li>
                      <a href="/pricing">Pricing</a>
                    </li>
                    <li>
                      <Link to="/login">Log In</Link>
                    </li>
                  </ul>
                </nav>
                <Link className="btn btn--yellow btn--medium header__btn" to="/signup">Sign Up</Link>
              </div>
              <a onClick={() => this.onHandleResponsive('activate')} className={this.state.resposiveNav === '0' ? "nav-trigger" : "nav-trigger is-active"}>
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </header>
          <div className="section">
            <div className="section__shapes">
              <div className="section__shape">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1389 1174"><defs></defs><g transform="translate(-1102 245)" fill="#7cc9a9"><ellipse cx="454" cy="376.5" rx="454" ry="376.5" transform="translate(1102 -245)"></ellipse><ellipse cx="454" cy="376.5" rx="454" ry="376.5" transform="translate(1583 176)"></ellipse></g></svg>
              </div>{/* section__shape */}
            </div>{/* section__shapes */}
            <div className='container zindex'>
              <div className='row mb-5'>
                <div className='col-md-7'>
                  <div className='boxShadow'>
                    <h2> What is Lorem Ipsum?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
                <div className='col-md-5'>
                  <img src="/img/feature-img2.png" width="100%" height="100%"></img>
                </div>
              </div>
              <div className='row my-5'>
                <div className='col-md-5'>
                  <img src="/img/feature-img1.jpg" width="100%" height="100%"></img>
                </div>
                <div className='col-md-7'>
                  <div className='boxShadow'>
                    <h2> What is Lorem Ipsum?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
              <div class="circle"></div>
              <div className='row mb-5'>
                <div className='col-md-7'>
                  <div className='boxShadow'>
                    <h2> What is Lorem Ipsum?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
                <div className='col-md-5'>
                  <img src="/img/feature-img2.png" width="100%" height="100%"></img>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}
export default Features;