import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getEvents, getRangeEventListAjax } from '../../Store/events/eventsListAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import HttpService from '../../Services/HttpService';
import ReactPagenate from 'react-paginate';
import { OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

const localizer = momentLocalizer(moment);
const currentuser = JSON.parse(localStorage.getItem('userdata'));
class AgecySponsored extends Component {

  constructor(props) {
    super(props);

    this.state = {
      events: [],
      start: '',
      end: '',
      eventSearchList: [],
      allEvents: [],
      searchInput: '',
      showListView: 'calender',
      DefaultMessage: '',
      spinner: false,
      offset: 0,
      perPage: 10,
      currentPage: 0,
      filterResources: [],
      slice: [],
      deleteResponse: {},
      EventSearchList: [],
      EventsearchInput: '',
      calenderEventsData: [],
      toolbardata: {},
      datelbel: '',
      chapter_id: '',
      all_events_text: 'Today',
      showPopover: false,
      eventtarget: null,
      event_id: null,
      pageCount: 0,
      enabledPage: 0
    };
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.state.showListView = this.props.location.state
    this.setState({
      spinner: true
    })
    var date = moment().format("YYYY/MM/DD");
    var data = {
      page: 1,
      limit: 8,
      organized_by: "agency",
      date: date,
      chapter_id: this.state.chapter_id
    }
    this.props.getRangeEventListAjax(data)
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {


    if (nextProps.eventsList.bigseventslist.eventslist.length === 0) {
      this.setState({
        spinner: false,
        DefaultMessage: "No Events Found.",
        pageCount: nextProps.eventsList.bigseventslist.count,
        events: [],
        calenderEventsData: []
      })
    }
    else {

      this.state.events = nextProps.eventsList.bigseventslist.eventslist

      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.eventsList.bigseventslist.count / this.state.perPage),
        calenderEventsData: nextProps.eventsList.bigseventslist.eventslist.map((appointment) => {
          return {
            id: appointment.event_id,
            title: appointment.event_name,
            start: new Date(appointment.start_date),
            end: new Date(appointment.end_date),
            allDay: false,
            hexColor: appointment.tag_color
          }
        })
      })

    }
  }
  /* Go to next and previous page in the pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.state.enabledPage = selectedPage + 1

    localStorage.setItem('eventselectedpage', this.state.enabledPage)

    var date = moment().format("YYYY/MM/DD");
    
    if(this.state.EventsearchInput!=''){
      var data = {
        page: this.state.enabledPage,
        limit: 8,
        organized_by: "agency",
        date: date,
        chapter_id: this.state.chapter_id,
        search : this.state.EventsearchInput
      }
    }
    else {
      var data = {
        page: this.state.enabledPage,
        limit: 8,
        organized_by: "agency",
        date: date,
        chapter_id: this.state.chapter_id
      }
    }
    this.props.getRangeEventListAjax(data)

    // this.setState({
    //   currentPage: selectedPage,
    //   offset: offset
    // }, () => {
    //   this.receivedData()
    // });
  };
  /** For pagination */
  receivedData() {
    this.state.slice = this.state.events.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>

      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.events.length / this.state.perPage),
      postData
    })
  }
  /** Goto new event page to create new event */
  onnewEvent() {
    this.props.history.push({
      state: {from:'agency'},
      pathname: '/dashboard/events/add_event'
    })
  }
  /** to show the pop up */
  showPopoverDialog = (e, eid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      event_id: eid
    })
  }
  /** to close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /** to edit the event */
  onEdit(item) {
    this.props.history.push({
      pathname: '/dashboard/events/add_event',
      state: { detail: item }
    })
  }
  /** To delete the event */
  onDelete(event_id) {
    if (this.state.event_id > -1) {
      this.state.events.splice(this.state.event_id, 1);
    }
    var data = {
      event_id: this.state.event_id
    }
    HttpService.deleteevent(data)
      .then(response => {
        this.state.deleteResponse = response.data
        if (this.state.deleteResponse.status === 200) {
          if (this.state.deleteResponse.message == 'Unable to delete the Event') {
            this.setState({
              showPopover: false
            })
            var successAlert = {
              message: this.state.deleteResponse.message,
              alertType : 'warning'
            }
            handleSwalAlert(successAlert)
         
          }
          else {
            this.refs.removesearch.value = '';
            this.state.events = ""
            this.state.spinner = false
            this.setState({
              showPopover: false
            })
            var successAlert = {
              message: this.state.deleteResponse.message,
              alertType : 'success'
            }
            handleSwalAlert(successAlert)
          
            if(this.state.EventsearchInput != ''){
              var data = {
                page: localStorage.getItem('eventselectedpage'),
                limit: 8,
                organized_by: "agency",
                month: "current",
                chapter_id: this.state.chapter_id,
                search : this.state.EventsearchInput
              }
            }
            else {
              var data = {
                page: localStorage.getItem('eventselectedpage'),
                limit: 8,
                organized_by: "agency",
                month: "current",
                chapter_id: this.state.chapter_id
              }
            }
           
            this.props.getRangeEventListAjax(data)
          }
        }
        else {
          this.setState({
            showPopover: false
          })
          this.state.spinner = false
          var errorAlert = {
            message: this.state.deleteResponse.message,
            alertType : 'warning'
          }
          handleSwalAlert(errorAlert)
        
        }
      })
      .catch(error => {
        this.state.spinner = false
        this.setState({
          showPopover: false
        })
        var errorAlert = {         
          alertType : 'error'
        }
        handleSwalAlert(errorAlert)
      
      })
  }
  /** To style the event in calender view */
  eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event.hexColor);
    var backgroundColor = '#' + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      color: 'white',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  }
  /* For search name of the event in the list  */
  searchHandler = (event) => {
    this.setState({ spinner: true })
    this.state.EventsearchInput = event.target.value
    if (this.state.showListView == 'list') {
      if (this.state.EventsearchInput.length === 0) {
        var data = {
          page: localStorage.getItem('eventselectedpage'),
          limit: 8,
          organized_by: "agency",
          month: "current",
          chapter_id: this.state.chapter_id,

        }
        this.props.getRangeEventListAjax(data)
        //  this.setState({ spinner: !this.state.spinner, EventSearchList: [], EventsearchInput: '', DefaultMessage: '' })
      }
      else {
        if (this.state.EventsearchInput.length === 1) {
          this.setState({ events: [] })
        }
        var data = {
          page: localStorage.getItem('eventselectedpage'),
          limit: 8,
          organized_by: "agency",
          month: "current",
          chapter_id: this.state.chapter_id,
          search: this.state.EventsearchInput
        }
        this.props.getRangeEventListAjax(data)        
      }
    }
    else {

      var data = {
        page: localStorage.getItem('eventselectedpage'),
        limit: 8,
        organized_by: "agency",
        month: "current",
        chapter_id: this.state.chapter_id,
        search: this.state.EventsearchInput

      }
      this.props.getRangeEventListAjax(data)
    }
  }
  /* Event occurs when the value of an element has been changed */
  handleChange = (event) => {

    if (event.target.value == 'list') {
      this.setState({
        showListView: event.target.value
      })
    }
    else {
      this.setState({
        showListView: event.target.value
      })
    }
    console.log('handle:- ', this.state.showListView)
  }
  /** custom calender tool bar */
  CustomToolbar = (toolbar) => {
    this.state.toolbardata = toolbar

    const labellist = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };
    return (
      <Typography variant="headline" style={{ color: '4d4f5c', textTransform: 'capitalize', width: '100%' }}></Typography>
    );
  }
  /** go back to previous month */
  goToBack(prev) {
    
    this.state.toolbardata.date.setMonth(this.state.toolbardata.date.getMonth());

    this.state.toolbardata.onNavigate(prev);
    let senddate = this.state.toolbardata.date.getMonth()

    const date = moment(this.state.toolbardata.date);
    if(this.state.EventsearchInput != ''){
      var data = {
        organized_by: "agency",
        date: date.format('YYYY') + "-" + senddate + "-" + "01",
        chapter_id: this.state.chapter_id,
        page : localStorage.getItem('eventselectedpage'),
        limit : 8,
        search : this.state.EventsearchInput
      }
    }
    else {
      var data = {
        organized_by: "agency",
        date: date.format('YYYY') + "-" + senddate + "-" + "01",
        chapter_id: this.state.chapter_id,
        page : localStorage.getItem('eventselectedpage'),
        limit : 8 
      }
    }
    
    this.props.getRangeEventListAjax(data)
  }
  /** goto next month */
  goToNext(next) {
    this.state.toolbardata.date.setMonth(this.state.toolbardata.date.getMonth());
    this.state.toolbardata.onNavigate(next);

    const date = moment(this.state.toolbardata.date);
    let senddate = this.state.toolbardata.date.getMonth() + 2
if(this.state.EventsearchInput != ''){
  var data = {
    organized_by: "agency",
    date: date.format('YYYY') + "-" + senddate + "-" + "01",
    chapter_id: this.state.chapter_id,
    page: localStorage.getItem('eventselectedpage'),
    limit: 8,
    search : this.state.EventsearchInput
  }
}
else {
  var data = {
    organized_by: "agency",
    date: date.format('YYYY') + "-" + senddate + "-" + "01",
    chapter_id: this.state.chapter_id,
    page: localStorage.getItem('eventselectedpage'),
    limit: 8,
  }
}
    
    this.props.getRangeEventListAjax(data)

    return (
      <Typography variant="headline" style={{ color: '4d4f5c', textTransform: 'capitalize', width: '100%' }}>{this.state.datelbel}</Typography>
    )
  }
  /* Goto current day */
  goToCurrent = () => {

    if (this.state.all_events_text == 'All Events') {
      this.state.all_events_text = 'Today'
      if(this.state.EventsearchInput!=''){
        var data = {
          organized_by: "agency",
          month: "current",
          chapter_id: this.state.chapter_id,
          page : localStorage.getItem('eventselectedpage'),
          limit: 8,
          search : this.state.EventsearchInput
  
        }
      }
      else {
        var data = {
          organized_by: "agency",
          month: "current",
          chapter_id: this.state.chapter_id,
          page : localStorage.getItem('eventselectedpage'),
          limit: 8 
  
        }
      }
     
      console.log("input current data:- ", data)
      this.props.getRangeEventListAjax(data)
    }
    else {
      this.state.all_events_text = 'All Events'
      const now = new Date();
      this.state.toolbardata.date.setMonth(now.getMonth());
      this.state.toolbardata.date.setYear(now.getFullYear());
      this.state.toolbardata.onNavigate('current');
      if (this.state.EventsearchInput !=null ){
        var data = {
          organized_by: "agency",
          day: "today",
          chapter_id: this.state.chapter_id,
          page : localStorage.getItem('eventselectedpage'),
          limit : 8,
          search : this.state.EventsearchInput
        }
      }
      else {
        var data = {
          organized_by: "agency",
          day: "today",
          chapter_id: this.state.chapter_id,
          page : localStorage.getItem('eventselectedpage'),
          limit : 8
        }
      }
    
      this.props.getRangeEventListAjax(data)
    }
  };
  /** To show the month and year */
  label() {
    const date = moment(this.state.toolbardata.date);
    return (
      <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    );
  }
  /* To creating, updating the views. */
  render() {
    const { showPopover } = this.state
    localStorage.setItem("activescreen", JSON.stringify('AgecySponsoredlist'));
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    const { onNavigate } = this.props;
    // if (this.state.EventsearchInput == '') {
    //   this.state.allEvents = this.state.calenderEventsData
    // }
    // else {
    //   this.state.allEvents = this.state.eventSearchList
    // }

    const CustomToolbar = (toolbar) => {
      this.state.toolbardata = toolbar
      const goToBack = () => {
        toolbar.date.setMonth(this.state.toolbardata.date.getMonth() - 1);
        toolbar.onNavigate('prev');
      };

      const goToNext = () => {
        toolbar.date.setMonth(this.state.toolbardata.date.getMonth() + 1);
        toolbar.onNavigate('next');
      };

      const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
      };

      const label = () => {
        const date = moment(toolbar.date);
        return (
          <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
      };

      return (
        <div >
          <div className="col-12 p-0" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-md-4 text-right pr-0">
              <span className="today-sec" onClick={() => goToCurrent('TODAY')}>Today</span>
            </div>
          </div>  </div>
      );
    };


    return (
      <div className="container">

        <div className="events-calender-sec">
          <Row className='pt-4'>

            <Col xs lg="6">
              <h3 className="pagename">Agency Sponsored Events</h3>
            </Col>
            <Col xs lg="6">
              <div className='d-flex'>
                <div className='pr-3' style={{ width: '70%' }}>
                  <div >
                    <span className="fa fa-search head-search"></span>
                    <input style={{ paddingLeft: '40px' }} type="text" ref="removesearch" className="form-control" placeholder="Search Events..." onChange={(e) => this.searchHandler(e)} />
                  </div>
                </div>
                <div>
                  <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewEvent()}>New Event</button>
                </div>

              </div>

            </Col>
          </Row>

          <div style={{ width: '100%', paddingTop: '20px', marginLeft: '0px' }} className="row">
            <div className="box">
              <div className="row">
                <div className="col-12 mb-3">
                  <div style={{ marginTop: '10px' }} className="col-md-4 pl-0">
                    <select
                      onChange={(e) => this.handleChange(e)} className="cal-dpdwn" value={this.state.showListView}>
                      <option value="calender" > Calendar View</option>
                      <option value="list">List View</option>
                    </select>
                  </div>
                  <div className="col-md-4 ">
                    <div className="text-center">
                      <IconButton onClick={() => this.goToBack('PREV')} ><i class="fa fa-chevron-left event-arrow" aria-hidden="true"></i></IconButton>
                      <Typography variant="headline" className="toolbar-data">{this.state.toolbardata.label}</Typography>
                      <IconButton onClick={() => this.goToNext('NEXT')}><i class="fa fa-chevron-right event-arrow" aria-hidden="true"></i></IconButton>
                    </div>
                  </div>
                  <div style={{ marginTop: '15px' }} className="col-md-4 text-right pr-0">
                    <span onClick={() => this.goToCurrent('TODAY')} className="today-sec" >{this.state.all_events_text}</span>
                  </div>
                </div>
              </div>
              <center>
                <div class="loader-spinner mb-5">
                  {this.state.spinner ? <Circles color="#7cc9a9" height={30} width={30} /> : null}
                </div>
              </center>
              <Calendar

                localizer={localizer}

                defaultDate={new Date()}

                events={this.state.calenderEventsData}
                components={{ toolbar: this.CustomToolbar }}
                toolbar={this.state.toolbardata}
                style={{ height: "0px", width: '0px', display: 'none' }}
                eventPropGetter={(this.eventStyleGetter)}

              />
              {

                this.state.showListView == 'list' ?
                  <div className="mb-3 col-12 text-center">

                    <div className="table-responsive">

                      <table id="dataTable">
                        {this.state.events.length > 0 ? <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Event Title</th>

                            <th>Available Seats</th>
                            <th>Status</th>
                            <th>Tag</th>
                          </tr>
                        </thead> : null}
                        <tbody>
                          {

                            this.state.events.length ? this.state.events?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
                              <tr key={item.event_id}>
                                <td>{moment(item.start_date).format('MM/DD/YYYY')}</td>
                                <td>{item.start_time}</td>
                                <td> {item.event_name}</td>
                                <td>{item.available_seats}</td>
                                {item.active_status == '0' ? <td>Active</td> : item.active_status == '1' ? <td>InActive</td> : <td>Canceled</td>}
                                <td>
                                  {item.tag_name !== null ? <label class="tag-name" style={{ backgroundColor: item.tag_color }}>{item.tag_name}</label>:
                                  <label class="tag-name">No Tag</label>}
                                  </td>

                                <div style={{ marginTop: '15px' }} className="float-right">
                                  <span className="pr-2" name="edit" onClick={() => this.onEdit(item)}><Edit /></span>
                                  <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                    <Tooltip>
                                      <div className='text-center p-4'>
                                        <p className='tool-title'>Delete Event</p>
                                        <p className='tool-para'>Are you sure you want to delete this event? Deleted events may not be restored.</p>
                                      </div>
                                      <div className='d-flex btn-sec'>
                                        <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                                        <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                      </div>
                                    </Tooltip>
                                  </Overlay>
                                  <div className='cross-center'>
                                    <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.event_id)}><CloseIcon /></span>
                                  </div>
                                </div>
                              </tr>
                            )) :
                              <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                          }
                        </tbody>
                      </table>
                      <div>
                      </div>
                      {this.state.pageCount > 0 ? <div className="pagination">

                        <ReactPagenate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={10}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        ></ReactPagenate>
                      </div> : null
                      }
                    </div>
                  </div> :
                  this.state.calenderEventsData.length > 0 ?
                    <Calendar
                      localizer={localizer}
                      views={['month']}
                      defaultDate={new Date()}
                      popup
                      events={this.state.calenderEventsData}
                      components={{ toolbar: this.CustomToolbar }}
                      toolbar={this.state.toolbardata}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 600 }}
                      eventPropGetter={(this.eventStyleGetter)}
                    /> :
                    <Calendar
                    localizer={localizer}
                    views={['month']}
                    defaultDate={new Date()}
                    popup
                    events={this.state.calenderEventsData}
                    components={{ toolbar: this.CustomToolbar }}
                    toolbar={this.state.toolbardata}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    eventPropGetter={(this.eventStyleGetter)}
                  />
              }
            </div>
          </div>
        </div>
      </div>

    );
  }
}
AgecySponsored.propTypes = {
  onView: PropTypes.func,
  onNavigate: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,

};
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    eventsList: state.eventsList
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getEvents, getRangeEventListAjax })(AgecySponsored)