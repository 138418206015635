import React, { Component } from 'react';
import Header from './Header.jsx';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService';
import { Circles } from 'react-loader-spinner';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      spinner:false
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  onemailChange = (e) => {
    this.setState({
      email: e.currentTarget.value,
    });
  }

  /** Send the mail to reset password */
  sendPassword(email) {
    this.setState({
      spinner:true
    })
    var email = {
        email: this.state.email,
    };
    if (this.validator.allValid()) {
      this.setState({ validated: false });
      HttpService.sendpasswordresetmail(email)
        .then(response => {
            if (response.data.status === 200) {
                swal({ text: response.data.message, icon: "success"  })
                this.setState({ spinner: false, showPopover: false })
            }
            else {
                swal({ text: response.data.message, icon: "error" })
                this.setState({  spinner: false, showPopover: false })
            }
        })
        .catch(error => {
            swal({ text: "Something went wrong, Please try again after sometime", icon: "error" })
            this.setState({ spinner: false, showPopover: false })
        })
    } else {
      this.setState({ validated: true,spinner: false, })
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
    
}

/** To call the api to sunbmit the values */
handleSubmit() {
    swal({ icon: 'success', title: 'Invitation link has been sent to email' })
}
  /* render() function is used to update the UI. */
  render() {
    return (
      <div className="wrapper">
        <main className='body-new'>
          <Header />
          <section className="section-form">
            <div className="shell">
              <div className="section__form">
                <div className="form">
                  <div className="form__head">
                    <h1 className="form__title">Forgot Password</h1>{/* /.form__title */}
                    <h5>Enter your email address and an email with instructions to reset your password will be sent to you.</h5>
                  </div>{/* /.form__head */}
                  <div class="form__body form__body--small">
                    <div class="form__row">
                      <label for="field-email" class="form__label">Email Address</label>
                      <div className={this.state.validated ? 'redBorder' : 'form__controls'}>
                        <div class="form__icon">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>{/* /.form__icon */}
                        <input type="email" className="field" value={this.state.email} name="email" onChange={(e) => this.onemailChange(e, "email")} />

                      </div>{/* /.form__controls */}
                    </div>{/* /.form__row */}
                    <span className="text-danger">
                      {this.validator.message('email', this.state.email, 'required')}
                    </span>
                  </div>{/* /.form__body */}
                  <div className="form__actions">
                    <button className="btn btn--blue btn--large form__btn" onClick={() => this.sendPassword(this.state.email)}>
                      Request New Password</button>
                  </div>{/* /.form__actions */}
                  <center>
            <div class="loader-spinner mt-3">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
                </div>{/* /.form */}
              </div>{/* /.section__form */}
              <div className="section__foot">
                <p>Already have an account?</p>
                {/* <a href="#" className="btn btn--border-blue">Sign In</a> */}
                <Link className="btn btn--border-blue" to="/login">Sign In</Link>
              </div>{/* /.section__foot */}
            </div>{/* /.shell */}
          </section>{/* /.section */}
          {/* Your footer goes here */}
          {/* /.wrapper */}
        </main>
      </div>
    )
  }
}
export default ForgotPassword;