import React, { Component } from 'react';
import { connect } from 'react-redux';
import { downloadImage, AllusersphotosAjax } from '../../Store/photogallery/photogalleryaction';
import ReactPagenate from 'react-paginate';
import HttpService from '../../Services/HttpService';
import { Tooltip, Overlay } from 'react-bootstrap';
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'
const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
class PhotoGallery extends Component {
  constructor() {
    super();
    this.images = [
      'https://bigsconnect.com/api/images/filename0-1625566401.jpg',
      'https://bigsconnect.com/api/images/filename0-1624523540.jpg',
      'https://bigsconnect.com/api/images/filename0-1624523526.jpg',
    ]
    this.state = {
      user_id: '',
      photos: [],
      spinner: true,
      offset: 0,
      perPage: 8,
      currentPage: 0,
      filterPhotos: [],
      slice: [],
      message: '',
      deleteResponce: {},
      allPhotos: [],
      GallerySearchList: [],
      GallerysearchInput: '',
      previewPhoto: '',
      photoDate: '',
      currentIndex: 0,
      photoname: '',
      separatedImages: [],
      separatedDate: [],
      separatedNames: [],
      slideDate: 0,
      sildeName: '',
      chapter_id: '',
      showPopover: false,
      eventtarget: null,
      photo_id: null,
      pageCount: 0,
      enabledpage: 0
    };
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);
  }
  /* To show the pop up */
  showPopoverDialog = (e, pid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      photo_id: pid
    })
  }
  /* To close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /* To make the api call initial page load */
  componentDidMount() {
    localStorage.setItem('selectedpage', 1)
    this.setState({
      spinner: true
    })
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    this.state.usertypeid = this.currentuser.user.usertypeid;
    if (this.state.usertypeid == 1) {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: this.state.chapter_id,
      }
      this.props.AllusersphotosAjax(data);
    }
    else {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: 0
      }
      this.props.AllusersphotosAjax(data);
    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    if (nextProps.photoGallery.gallery_res.photos.length === 0) {
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.photoGallery.gallery_res.count / this.state.perPage),
        message: "No Photos Found.",
        photos: [],
        allPhotos: []
      })
    }
    else {
      this.state.photos = nextProps.photoGallery.gallery_res.photos
      this.state.allPhotos = [].concat(...this.state.photos)
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.photoGallery.gallery_res.count / this.state.perPage),
      })
    }
    this.state.separatedImages = this.state.allPhotos?.map(item => (
      item.photo_name
    ))
    this.state.separatedDate = this.state.allPhotos?.map(item => (
      item.created_at
    ))
    this.state.separatedNames = this.state.allPhotos?.map(item => (
      item.display_name
    ))
  }
  /* To delet the photo */
  onDelete = (photo_id) => {
    var data = {
      photo_id: this.state.photo_id
    }
    HttpService.deletephoto(data)
      .then(response => {
        console.log('delete photo response', response.data);
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          var data = {
            chapter_id: this.state.chapter_id
          }
          this.setState({
            spinner: false,
            showPopover: false
          })
          this.state.allPhotos = ''
          this.currentuser = JSON.parse(localStorage.getItem('userdata'));
          var user_id = this.currentuser.user_id;
          this.setState({ user_id: user_id });
          this.props.AllusersphotosAjax(data);
          var successAlert = {
            message: this.state.deleteResponce.message,
            alertType: "success"
          }
          handleSwalAlert(successAlert)
        }
        else {
          this.setState({
            spinner: false, showPopover: false
          })
          var errorAlert = {
            message: this.state.deleteResponce.message,
            alertType: "warning"
          }
          handleSwalAlert(errorAlert)
        }
      })
      .catch(error => {
        this.setState({
          spinner: false, showPopover: false
        })
        var errorAlert = {
          alertType: "error"
        }
        handleSwalAlert(errorAlert)
      })
    this.setState({
      photos: this.state.allPhotos,
      spinner: false
    })
  }
  /* Go to next page in the pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.state.enabledpage = selectedPage + 1
    localStorage.setItem('selectedpage', this.state.enabledpage)
    this.setState({ spinner: true })
    if (this.state.ResourcesearchInput != '') {
      var data = {
        page: this.state.enabledpage,
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
        search: this.state.ResourcesearchInput
      }
    }
    else {
      var data = {
        page: this.state.enabledpage,
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }
    }
    this.props.AllusersphotosAjax(data)
    // const selectedPage = e.selected;
    // const offset = selectedPage * this.state.perPage;
    // this.setState({
    //   currentPage: selectedPage,
    //   offset: offset
    // }, () => {
    //   this.receivedData()
    // })
  };
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.allPhotos.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.allPhotos.length / this.state.perPage),
    })
    this.state.separatedImages = this.state.allPhotos?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      item.photo_name
    ))
    this.state.separatedDate = this.state.allPhotos?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      item.created_at
    ))
    this.state.separatedNames = this.state.allPhotos?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      item.display_name
    ))
  }
  /* To download the photo */
  onDownload = (photo) => {
    var image_name = photo.replace(/^.*[\\\/]/, '');
    this.props.downloadImage({ image_name })
  }
  /* To view the photo */
  onPreviewImage = (photoname, createdDate, name, itemIndux) => {
    this.setState({
      previewPhoto: photoname,
      photoDate: createdDate,
      photoname: name,
    })
    this.state.currentIndex = itemIndux
    console.log('currentindex:- ', this.state.currentIndex)
  }
  /* Goto previous photo */
  goToPrevSlide = () => {
    const { currentIndex } = this.state;
    const newPointer = currentIndex === 0 ? this.state.separatedImages.length - 1 : currentIndex - 1;
    const newPrevslideDate = currentIndex === 0 ? this.state.separatedDate.length - 1 : currentIndex - 1
    const newPrevslideName = currentIndex === 0 ? this.state.separatedNames.length - 1 : currentIndex - 1
    this.setState({ currentIndex: newPointer, slideDate: newPrevslideDate, sildeName: newPrevslideName });
  }
  /* Goto next photo */
  goToNextSlide = () => {
    const { currentIndex } = this.state;
    const newPointer = currentIndex === this.state.separatedImages.length - 1 ? 0 : currentIndex + 1;
    const newNextSlideDate = currentIndex === this.state.separatedDate.length - 1 ? 0 : currentIndex + 1;
    const newNextSlideName = currentIndex === this.state.separatedNames.length - 1 ? 0 : currentIndex + 1;
    this.setState({ currentIndex: newPointer, slideDate: newNextSlideDate, sildeName: newNextSlideName });
  }
  /* For search the photo in the list  */
  searchHandler = (event) => {
    this.state.GallerysearchInput = event.target.value
    if (event.target.value.length == 0) {
      this.componentDidMount()
      this.setState({ spinner: false, GallerySearchList: [], GallerysearchInput: '' })
    }
    else {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: this.state.chapter_id,
        search: this.state.GallerysearchInput
      }
      this.props.AllusersphotosAjax(data);
      // let searcjQery = event.target.value,
      //   displayedSearch = this.state.allPhotos.filter((el) => {
      //     let searchValue = el.display_name;
      //     if (searchValue != null) {
      //       return searchValue.toLowerCase().indexOf(searcjQery.toLowerCase()) !== -1;
      //     }
      //   })
      // if (displayedSearch.length > 0) {
      //   this.setState({ GallerySearchList: displayedSearch, pageCount: Math.ceil(displayedSearch.length / this.state.perPage), GallerysearchInput: event.target.value })
      //   this.state.separatedImages = this.state.GallerySearchList?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      //     item.photo_name
      //   ))
      //   this.state.separatedDate = this.state.GallerySearchList?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      //     item.created_at
      //   ))
      //   this.state.separatedNames = this.state.GallerySearchList?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
      //     item.display_name
      //   ))
      // } else {
      //   this.setState({ GallerySearchList: [] })
      // }
    }
  }
  /* render() function is used to update the UI. */
  render() {
    localStorage.setItem("activescreen", JSON.stringify('alluserphotogallery'));
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    const { showPopover, spinner } = this.state
    return (
      <div className="container">
        <Row className='pt-4'>
          <Col md="6" sm="12">
            <h3 className="pagename mb-3">Photo Gallery</h3>
          </Col>
          <Col md="6" sm="12">
            <div style={{ width: '100%' }}>
              <span style={{ position: 'absolute', padding: '15px', zIndex: '2', display: 'block', color: '#aaa' }} className="fa fa-search"></span>
              <input style={{ paddingLeft: '40px' }} type="text" className="form-control" placeholder="Search Name..." onChange={(e) => this.searchHandler(e)} />
            </div>
          </Col>
        </Row>
        <div className="box">
          <div className="App">
            {this.state.spinner && <center> <div class="loader-spinner">  <Circles color="#7cc9a9" height={50} width={50} />
            </div></center>
            }
          </div>
          <div className="imgContainer photogallery-sec">
            <div className="row" style={{ width: '100%' }}>
              {
                this.state.allPhotos.length ? this.state.allPhotos?.slice(this.state.offset, this.state.offset + this.state.perPage).map((item, itemIndux) =>
                  <div className="col-md-3 displayicons-sec">
                    <div data-toggle="modal"
                      data-target="#myModal">
                      <img onClick={() => this.onPreviewImage(item.photo_name, item.created_at, item.display_name, itemIndux)} style={{ border: "1px solid" }}
                        className="photogallery-img" key={item.photo_id} src={item.photo_name} />
                    </div>
                    <div className="img-ontop">
                      <i class="fa fa-download" aria-hidden="true" onClick={() => this.onDownload(item.photo_name)}></i>
                      <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                        <Tooltip>
                          <div className='text-center p-4'>
                            <p className='tool-title'>Delete Photo</p>
                            <p className='tool-para'>Are you sure you want to delete this photo? Deleted photos may not be restored.</p>
                          </div>
                          <div className='d-flex btn-sec'>
                            <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                          </div>
                        </Tooltip>
                      </Overlay>
                      <div className='cross-center'>
                        <i class="fa fa-trash" aria-hidden="true" onClick={(e) => this.showPopoverDialog(e, item.photo_id)}></i>
                      </div>
                    </div>
                    <div className="text-center userdata-sec mb-5">
                      <h9>{(new Date(item.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</h9>
                      <br></br>
                      <h9><span className="pr-2"><i className="fa fa-user-o" aria-hidden="true"></i></span>{item.display_name}</h9>
                    </div>
                    <div className="modal" id="myModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <img style={{ border: "1px solid" }} className="modal-img-slider" src={this.state.separatedImages[this.state.currentIndex]} />
                          </div>
                        </div>
                      </div>
                      <center> <div className="photocaption-content">
                        <span onClick={() => this.goToPrevSlide()} className="angleRight"><i class="fas fa-angle-left"></i></span>
                        <h4>{(new Date(this.state.separatedDate[this.state.currentIndex])).toLocaleDateString('en-US', DATE_OPTIONS) + " - " + this.state.separatedNames[this.state.currentIndex]}</h4>
                        <span onClick={() => this.goToNextSlide()} className="angleLeft"><i class="fas fa-angle-right"></i></span>
                      </div></center>
                    </div>
                  </div>
                ) : <div style={{ justifyContent: 'center', display: 'flex' }}><h3>{this.state.message}</h3></div>
              }
            </div>
            {this.state.pageCount > 0 ?
              <div className="col-md-12 App">
                <div className="pagination">
                  <ReactPagenate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div>
              </div> : null}
          </div>
        </div>
      </div>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => ({
  photoGallery: state.photoGallery
});
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { downloadImage, AllusersphotosAjax })(PhotoGallery)