import React, { Component } from 'react';
import '../../assets/css/style.css';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { deleteMessageService, viewMessagedata, RecievedMessages, sentMessages, draftMessages, trashMessages } from '../../Store/messages/allMessageAction';
import HttpService from './../../Services/HttpService'
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js'; //ContentState
import { Tooltip, Overlay } from 'react-bootstrap'
import { Circles } from 'react-loader-spinner'
import { Editor } from 'react-draft-wysiwyg';
import Autosuggest from 'react-autosuggest';
import { SendMessageInput} from '../../Store/messages/sendMessageAction';
import SimpleReactValidator from 'simple-react-validator';
import handleSwalAlert from '../SwalAlert'

const styles = {
  editor: {
    border: '0.5px solid lightgray',
    minHeight: '15em',
    left: '2935px',
    padding: "10px"
  }
};
const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

class ViewMessage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      user_id: '',
      message_id: '',
      message: '',
      message_type: '',
      actiontype: '',
      spinner: true,
      deleteResponse: {},
      draftsubject: '',
      select_a_message: false,
      showPopover: false,
      openReplayDesign: false,
      suggestions: [],
      email: "",
      editorState: EditorState.createEmpty(),
      Uid: '',
      sender_id: "",
      subject: "",
      body: "",
      attachments: [],
      suggetionsdata: [],
      value: '',
      eventtarget:null
    }
  }

/* To make the api call initial page load */
  componentDidMount() {
    this.setState({
      spinner: true,

    })
    if (this.props.sendMessage.sendMessageDetails.draftbody !== undefined) {
      var drafthtml = draftToHtml(convertToRaw(this.props.sendMessage.sendMessageDetails.draftbody.getCurrentContent()))
    }
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    if (this.props.sendMessage.sendMessageDetails.subject !== undefined || this.props.sendMessage.sendMessageDetails.draftemail != undefined || this.props.sendMessage.sendMessageDetails.draftbody !== undefined) {
      this.state.draftsubject = "draft"

    }
    else {
      this.state.draftsubject = ""
    }
    var messagebody = {
      user_id: this.currentuser.user_id,
      message_id: this.props.messageId,
      message_type: this.state.draftsubject,
      email: this.props.sendMessage.sendMessageDetails.draftemail,
      subject: this.props.sendMessage.sendMessageDetails.subject,
      body: drafthtml
    }


    this.props.viewMessagedata(messagebody);
    this.props.sendMessage.sendMessageDetails.subject = undefined
    this.props.sendMessage.sendMessageDetails.draftemail = undefined
    this.props.sendMessage.sendMessageDetails.draftbody = undefined
  }
  /* The  method allows us to execute the React code when the component is updated. */
  componentDidUpdate(prevProps) {
    if (this.props.messageId !== prevProps.messageId) {
      this.setState({
        spinner: true,
        select_a_message: false,
        email: prevProps.Messageslistdata.viewMessage?.sender_email,

      })
      this.currentuser = JSON.parse(localStorage.getItem('userdata'));
      var user_id = this.currentuser.user_id;
      var message_id = this.props.messageId;
      this.props.viewMessagedata({ message_id, user_id });

    }
    else {
      // this.props.viewMessagedata('');

    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {

    this.setState({
      spinner: false,
      message: nextProps.Messageslistdata.viewMessage
    })
  }
/* To show the pop up */
  showPopoverDialog = (e) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
    
    })
  }
/* To close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /* To delete the message */
  onDelete(message_id) {
    var data = {
      message_id:message_id,
      delete_from:this.props.typeOfMessage
    }
    this.setState({
      spinner: true
    })

    HttpService.deletemessage(data)
      .then(response => {
        this.state.deleteResponse = response.data
        if (this.state.deleteResponse.status === 200) {
          this.setState({
            spinner: false,
            showPopover:false
          })
          var successAlert = {
            message:this.state.deleteResponse.message,
            alertType: "success",
          }
          handleSwalAlert(successAlert)
          // swal( { text:this.state.deleteResponse.message,
          //   icon: "success",
          // });
          if (this.props.typeOfMessage === 'inbox') {
            this.props.RecievedMessages()
          }
          else if (this.props.typeOfMessage === 'sent') {
            this.props.sentMessages()
          }
          else if (this.props.typeOfMessage === 'trash') {
            this.props.trashMessages();
          }

          this.setState({
            select_a_message: true
          })
        }
        else {
          this.setState({
            spinner: false,
            showPopover:false
          })
          var errorAlert = {
            message:this.state.deleteResponse.message,
            alertType: "warning",
          }
          handleSwalAlert(errorAlert)
          // swal( {text:this.state.deleteResponse.message,
          //   icon: "error",
          // });
        }
      })
      .catch(error => {
        this.setState({
          spinner: false,
          showPopover:false
        })
        this.onErrorAlert()
        // swal({text:"Something went wrong, Please try again after sometime.",
        //   icon: "error",
        // });
      })

  }
  /* Replay to opened meesage */
  onReplayToMessage() {

    this.setState({
      openReplayDesign: true
    })
  }

  /* Filter logic */ 
  getSuggestions = async (eventInput) => {
    HttpService.ToListUsersSearch(eventInput)
      .then(response => {
        this.setState({
          suggetionsdata: response.data.users
        })

      })
      .catch(error => {
        //dispatch(SendError)
      })

    return this.state.suggetionsdata;
  };

  /* Trigger suggestions */ 
  getSuggestionValue = suggestion => suggestion.first_name + " " + suggestion.last_name

  /* Render Each Option */ 
  renderSuggestion = suggestion => (
    <div className="sugg-option">
      <h8 style={{ color: '#a1a0ae' }}><b>{suggestion.first_name + " " + suggestion.last_name}</b></h8>
    </div>
  );

  /* OnChange event handler */ 
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  /* Suggestion rerender when user types */ 
  onSuggestionsFetchRequested = (e) => {
    let eventInput = {

      name: e.value
    }

    this.getSuggestions(eventInput)
      .then(data => {
        if (data.Error) {
          this.setState({
            suggestions: []
          });
        } else {
          this.setState({
            suggestions: this.state.suggetionsdata
          });
        }
      })
  };

  /* Triggered on clear */
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  /* Selected item */ 
  onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    event.preventDefault();
    let eventInput = {
      key: "draftemail",
      value: suggestion.email
    }
    this.props.SendMessageInput(eventInput);

  }
  /* to convert the  html tags to text */
  onEditorStateChange = (editorState) => {
    const currentContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    this.setState({
      editorState,
    });
    if (this.state.editorState !== null) {
      let eventInput = {
        key: "draftbody",
        value: this.state.editorState
      }
      this.props.SendMessageInput(eventInput);
    }
    else {
      let eventInput = {
        key: "draftbody",
        value: ''
      }
      this.props.SendMessageInput(eventInput);
    }

  };

  onErrorAlert (){
    var errorAlert = {
      alertType : "error"
    }
    handleSwalAlert(errorAlert)
  }

/* to send the mail */
  sendMail = () => {
    const { to, from, subject, key, text } = this.state;
    const html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

    const { sendMessage } = this.props
    const { Uid } = this.state

    if (this.validator.allValid()) {
      var data = {
        email: this.state.message.sender_email,
        subject: this.state.message.subject,
        body: html,
        message_type: 'sent'
      }
      HttpService.sendmessage(data)
        .then(response => {
          if (response.data.status === 200) {
             var successAlert = {
              message: response.data.message,
              alertType: "success"
             }
             handleSwalAlert(successAlert)
            // swal({
            //   title: response.data.message,
            //   icon: "success"
            // });
            this.props.quitScreen();
            this.setState({
              email: "",
              subject: "",
              body: "",
              attachments: []
            });
            this.validator.hideMessages();

          }
          else {
               this.onErrorAlert()
            // swal({
            //   title: "Something went wrong, Please try again after sometime.",
            //   icon: "error"
            // });
            this.validator.hideMessages();
          }
        })
    }
    else {
      this.validator.showMessages();
    }

  }
/* to close the replay mail */
  CloseReplaymail() {
    this.setState({
      openReplayDesign: false
    })
  }
  /* render() function is used to update the UI. */
  render() {
    const toDeleteRefresh = "messagedeleted"
    const { editorState,suggestions,selectedOption,showPopover ,value} = this.state;
   
    const inputProps = {
      placeholder: 'To:',
      email: this.state.message?.email,
      value: this.state.message?.first_name + " " + this.state.message?.last_name,
      onChange: this.onChange,
      style: { width: "100%", height: "46px" },
    };
    return (
      <div>
        {this.state.select_a_message ?
          <div style={{ display: 'flex', justifyContent: 'center', height: '70vh' }} >
            <h3 style={{ display: 'flex', alignItems: 'center', color: '#ccccd4' }} className="pagename">Select a Message</h3>
          </div> :
          <section>
            <div className="row">
              <div className="col-lg-12 mb-3 mb-lg-0">
                <div id="textbox">
                  <h5 style={{ color: '#4D4F5C' }} className="alignleft">Protective Preventative Maintenance</h5>
                  <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                    <Tooltip>
                      <div className='text-center p-4'>
                        <p className='tool-title'>Delete Message</p>
                        <p className='tool-para'>Are you sure you want to delete this message? Deleted messages may not be restored.</p>
                      </div>
                      <div className='d-flex btn-sec'>
                        <button onClick={() => this.onDelete(this.state.message?.message_id)} className='tool-btn-yes'>Yes, Delete</button>
                        <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                      </div>
                    </Tooltip>
                  </Overlay>
                  <span className="alignright" onClick={(e) =>this.showPopoverDialog(e)} style={{ cursor: 'pointer' }}>
                        <i class="fas fa-trash-alt"></i>
                      </span>
                </div>
                <div className="mail-usercontent-sec">
                  <div className="delete-icon">
                  </div>
                  <div className="media p-3">
                    <div className="d-flex" style={{width:'100%'}}>
                      <div className="mr-4">
                        <img className="image-circle mgslist-img" src={this.state.message?.profile_pic}></img>
                      </div>
                      <div style={{width:'100%'}}>

                        {
                          this.props.typeOfMessage == 'inbox' ?
                            <>
                              <h8 style={{ color: '#4D4F5C' }}><b>{this.state.message?.first_name + " " + this.state.message?.last_name}</b> <small style={{ float: "right" }}>{(new Date(this.state.message?.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>
                              <h9 style={{ color: '#4D4F5C' }} className="truncate">To <b>Me</b></h9></> :

                            this.props.typeOfMessage == 'sent' ?
                              <>
                                <h8 style={{ color: '#4D4F5C' }}> <b>Me</b><small style={{ float: "right" }}>{(new Date(this.state.message?.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>
                                <h9 style={{ color: '#4D4F5C' }} className="truncate">To <b>{this.state.message?.first_name + " " + this.state.message?.last_name}</b></h9></>
                              :
                              this.props.typeOfMessage == 'trash' && this.props.senderId === JSON.parse(localStorage.getItem('userdata')).user.user_id ?
                                <>

                                  <h8 style={{ color: '#4D4F5C' }}> <b>Me</b><small style={{ float: "right" }}>{(new Date(this.state.message?.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>
                                  <h9 style={{ color: '#4D4F5C' }} className="truncate">To <b>{this.state.message?.first_name + " " + this.state.message?.last_name}</b></h9></>
                                : <>
                                  <h8 style={{ color: '#4D4F5C' }}><b>{this.state.message?.first_name + " " + this.state.message?.last_name}</b> <small style={{ float: "right" }}>{(new Date(this.state.message?.created_at)).toLocaleDateString('en-US', DATE_OPTIONS)}</small></h8>
                                  <h9 style={{ color: '#4D4F5C' }} className="truncate">To <b>Me</b></h9></>
                        }
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mail-content">
                    <div className="App">
                      <center>
                        <div class="loader-spinner">
                          {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                          }
                        </div>
                      </center>
                    </div>
                    {
                      this.state.message?.body && <div dangerouslySetInnerHTML={{ __html: this.state.message?.body }}></div>
                    }

                  </div>
                </div>
                <div style={{ marginTop: "-10px" }} className='reply-box'>
                  <p>Click here to <a onClick={() => this.onReplayToMessage()}>Reply</a></p>
                  {this.state.openReplayDesign ?
                    <div className='reply-open'>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={(ev) => this.onSuggestionsFetchRequested(ev, "email")}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                        highlightFirstSuggestion={true}
                        onSuggestionSelected={this.onSuggestionSelected}
                      />
                      <div className="m-10" style={styles.editor}
                        onClick={this.focusEditor}>
                        <Editor
                          editorState={editorState}
                          placeholder=" Write Something... "
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          editorStyle={{ lineHeight: '100%', scrollBehavior: 'smooth', }}
                          onEditorStateChange={editorState => this.onEditorStateChange(editorState)}
                        />
                      </div>
                      <div style={{ marginTop: '-10px' }} className='row'>
                        <div className="col-md-6 my-4">
                          <button type="cancel" className="cancelbtnnew" onClick={() => this.CloseReplaymail()}>Cancel</button>

                        </div>
                        <div className="col-md-6  my-4">
                          <div className="float-right cancel-sec">
                            <button type="submit" className="btn btn-info btn-success mt-1" onClick={() => { this.sendMail() }}>Send</button>

                          </div>
                        </div>
                      </div>
                    </div> : null}
                </div>
              </div>
            </div>
          </section>}
      </div>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    Messageslistdata: state.Messageslistdata,
    sendMessage: state.sendMessage
  }
}
/*Connects a React component to a Redux store */

export default connect(mapStateToProps, { deleteMessageService, SendMessageInput, viewMessagedata, RecievedMessages, sentMessages, trashMessages })(ViewMessage)