import { TEMPLATE_VIEW } from "../types";

const initialState = {
    viewTemplate:[],
}

export default function viewTemplateoneReducer(state = initialState, action){
    switch(action.type){
        case TEMPLATE_VIEW:
        return {
            ...state,
            viewTemplate:action.response,
        }
        default: return state
    }
}