import {CMS_HELP_LIST_DATA,CMS_HELP_LIST_ERROR} from '../types';
import HttpService from '../../Services/HttpService';

export const cmshelpData = (response) =>{
    return {
        type: CMS_HELP_LIST_DATA,
        response: response
    }
}
export const cmsHelpListsError = (responce) => {
    return {
        type: CMS_HELP_LIST_ERROR,
        response:responce
    }
}

export const getCmshelpcenter = () => {
    return (dispatch) => {
        HttpService.cmshelplist()
        .then(response => {
            console.log("cms responce:- ",response)
            dispatch(cmshelpData(response.data));
        })
        .catch(error => {
            console.log('error message:- ',error)
            dispatch(cmsHelpListsError());
        })
    }
}

export const getCmshelpcenterAjax = (data) => {
    return (dispatch) => {
        HttpService.cmshelplistAjax(data)
        .then(response => {
            console.log("cms responce:- ",response)
            dispatch(cmshelpData(response.data));
        })
        .catch(error => {
            console.log('cms error:- ',error.message)
            dispatch(cmsHelpListsError(error.response));
        })
    }
}
    

