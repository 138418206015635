import React, { Component } from 'react';
import { connect } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import { getLittlesAjax, deleteLittle } from '../../Store/mylittle/littlesListaction';
import { getChapters } from '../../Store/chapters/chapterListAction'
import ReactPagenate from 'react-paginate'
import '../../App.css';
import HttpService from '../../Services/HttpService'
import { Tooltip, Overlay } from 'react-bootstrap';
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import handleSwalAlert from '../SwalAlert'
import Select from 'react-select'
import { defaultTheme } from 'react-select';
import onClickOutside from 'react-onclickoutside'

const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

// styled components

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'b',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {console.log('menu:- ',children)}
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>)
class Littles extends Component {
    state = { isOpen: false, value: undefined };
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };
    constructor(props) {
        super();
        this.state = {
            userType: 'ActiveUsers',
            startCount: 0,
            endCount: 8,
            offset: 0,
            perPage: 8,
            pageCount: 0,
            currentPage: 0,
            filterUsers: [],
            slice: [],
            spinner: true,
            littles: [],
            deleteSuccessData: "",
            SearchList: [],
            searchInput: '',
            DefaultMessage: '',
            token: '',
            chapter_id: '',
            user_type_id: '',
            showPopover: false,
            user_id: null,
            eventtarget: null,
            deactivatebutton: null,
            display_name: '',
            postData: [],
            selectedPage: 1,
            searchName: null,
            myChaptersListData: [],
            chaptersList: [],
            selected_chapter_name:''
        }
    }
    /** To refresh the user list after any action done */
    littleslist = (event) => {
        localStorage.setItem('selectedpage', 1)
        var data = {
            page: 1,
            limit: 8,
            chapter_id: this.state.chapter_id
        }
        this.setState({
            littles: [],
            spinner: true,
            pageCount: 0,
            postData: [],
            currentPage: 0,
            offset: 0
        })
        this.state.searchInput = ""
        this.clearSearchText.value = ""
        this.props.getLittlesAjax(data);
    }
    /** To make the api call initial page load */
    componentDidMount() {
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
  
        this.setState({
            user_type_id: this.currentuser.user.usertypeid,
            user_id: this.currentuser.user.user_id,
            spinner:true
        })
        {this.currentuser.user.usertypeid === 4 || this.currentuser.user.usertypeid === 5?


        HttpService.chapterslist()
        .then(response => {

            if (response.data.chapters_list.length === 0) {
                this.setState({
                    spinner: false,
                })
            }
            else {
                this.setState({
                    chaptersList: response.data.chapters_list,
                    spinner: false,
                    selected_chapter_name: response.data.chapters_list[0].chapter_name,
                    chapter_id: response.data.chapters_list[0].chapter_id
                })
            }
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: response.data.chapters_list[0].chapter_id
            }
            this.props.getLittlesAjax(data);
        })
        .catch(error => {
            
                this.setState({
                    spinner: false,
                })
          
        })
        :

        HttpService.myChaptersService()
        .then(response => {

            if (response.data.chapters_list.length === 0) {
                this.setState({
                    spinner: false,
                })
            }
            else {
                this.setState({
                    chaptersList: response.data.chapters_list,
                    spinner: false,
                    selected_chapter_name: response.data.chapters_list[0].chapter_name,
                    chapter_id: response.data.chapters_list[0].chapter_id
                })
            }


            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
            }
    
            this.props.getLittlesAjax(data);
    
        })
        .catch(error => {
            
                this.setState({
                    spinner: false,
                })
          
        })
        
    }

    }

  /** To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {

    if (nextProps.littlesDetails.littleslist.count === 0) {
        this.setState({
            spinner: false,
            DefaultMessage: "No Little Found.",
            SearchList: []
        })
    }
    else {
       
        this.setState({
            DefaultMessage: "",
            spinner: false,
            pageCount: Math.ceil(nextProps.littlesDetails.littleslist.count / this.state.perPage),
            SearchList: nextProps.littlesDetails.littleslist.littles
        })
    }
}
    /** To show the pop up */
    showPopoverDialog = (e, mid, deactivate) => {
        this.setState({
            showPopover: true,
            eventtarget: e.target,
            mylittle_id: mid.mylittle_id,
            deactivatebutton: deactivate,
            display_name: mid.display_name
        })
    }
    /** To close the pop up */
    dismissPopoverDialog = (e) => {
        this.setState({
            showPopover: false
        })
    }
    onErrorAlert() {
        var errorAlert = {
            alertType: 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /** To delete the user */
    onDeletelittle(mylittle_id) {

        var mylittle_id = {
            mylittle_id: this.state.mylittle_id
        };
        this.state.spinner = true
        HttpService.deleteLittle(mylittle_id)
            .then(response => {
                this.state.deleteSuccessData = response.data
                if (this.state.deleteSuccessData.status === 200) {
                    var data = {
                        page: JSON.parse(localStorage.getItem('selectedpage')),
                        limit: 8,
                        chapter_id: this.state.chapter_id
                    }

                    var successAlert = {
                        message: this.state.deleteSuccessData.message,
                        alertType: 'success'
                    }
                    handleSwalAlert(successAlert)

                    this.props.getLittlesAjax(data);
                    this.setState({ showPopover: false });

                }
                else {
                    this.setState({
                        showPopover: false,
                        spinner: false
                    })
                    this.onErrorAlert()
                }

            })
            .catch(error => {
                this.setState({
                    showPopover: false,
                    spinner: false
                })
                this.onErrorAlert()
            })
    }

    /** Goto next, previous page in the pagination */
    handlePageClick = (e) => {
        this.state.spinner = true
        const enabledpage = e.selected;

        this.state.selectedPage = enabledpage + 1

        localStorage.setItem('selectedpage', this.state.selectedPage)

        if (this.state.searchName != null) {
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id,
                search: this.state.searchName
            }
        }
        else {
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id
            }

        }
    };
    /** For pagination */
    receivedData() {
        this.setState({
            pageCount: Math.ceil(this.state.SearchList.length / this.state.perPage),
            postData: this.state.SearchList.slice(this.state.offset, this.state.offset + this.state.perPage)
        })

    }
    /** For search the users in the list  */
    searchHandler = (event) => {
        if (event.target.value.length === 0) {
            this.setState({
                littles: []
            })
            var data = {
                page: 1,
                limit: 8,
                chapter_id: this.state.chapter_id
            }
            this.props.getLittlesAjax(data);

            this.setState({
                spinner: !this.state.spinner,
                SearchList: [],
                searchInput: '',
                DefaultMessage: ''
            })
        }
        else {
            this.state.searchName = event.target.value

            this.setState({
                spinner: true
            })
            if (this.state.searchName.length === 1) {
                this.setState({
                    littles: []
                })
            }
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id,
                search: this.state.searchName
            }
            this.props.getLittlesAjax(data);
        }
    }
    handleUserInput(e) {
        this.toggleOpen();
        this.setState({ SearchList:[], chapter_id: e.chapter_id ,selected_chapter_name:e.chapter_name,spinner:true})
        var data = {
            page: 1,
            limit: 8,
            chapter_id: e.chapter_id
        }


        this.props.getLittlesAjax(data);
    }

  

    /** render() function is used to update the UI. */
    render() {
        localStorage.setItem("activescreen", JSON.stringify('alllittles'));
        const { showPopover,isOpen, value,chaptersList,selected_chapter_name } = this.state
        

        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
       
        return (
            <div className="container">
                <Row className='pt-4'>
                    <Col md="6">
                        <h3 className="pagename">All Littles </h3>
                    </Col>
                    <Col md="6">
                        <div className='d-flex'>
                            <div className='pr-4' style={{ width: '70%' }}>
                                <div>
                                    <span className="fa fa-search head-search"></span>
                                    <input style={{ paddingLeft: '40px' }} type="text" ref={ref => this.clearSearchText = ref} className="form-control" placeholder="Search Name..." onChange={(e) => this.searchHandler(e)} />
                                </div>

                            </div>
                            <div>
                                {this.state.user_type_id == 4 || this.state.user_type_id == 5?
                                
                                    <Dropdown
                                       
                                        isOpen={isOpen}
                                        onClose={this.toggleOpen}
                                        target={
                                            <button
                                            className="chapter-header"
                                            style={{backgroundColor:'white',marginTop:'3px'}}
                                              
                                                onClick={this.toggleOpen}
                                                isSelected={isOpen}
                                            >
                                                {selected_chapter_name ? selected_chapter_name : 'Select a Chapter'}
                                                <i style={{float:"right",marginTop:'5px'}} class="fa fa-chevron-down" aria-hidden="true"></i>
                                            </button>
                                        }
                                    >
                                       
                                        <Select
                                            autoFocus
                                            backspaceRemovesValue={false}
                                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                                            controlShouldRenderValue={false}
                                            hideSelectedOptions={false}
                                            isClearable={false}
                                            menuIsOpen
                                            onChange={(e)=>this.handleUserInput(e)}
                                            options={chaptersList}
                                            getOptionLabel={(options) => options['chapter_name']}
                                            placeholder="Search..."
                                            styles={selectStyles}
                                            tabSelectsValue={false}
                                            value={selected_chapter_name}
                                        />
                                    </Dropdown>
                                   

                                    : null
                                }

                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="userManagement-page">
                  
                    <div className="App">
                        <center>
                            <div class="loader-spinner"> {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />}</div>
                        </center>
                    </div>
                    {this.state.SearchList.length > 0 ? this.state.SearchList?.map(item => (
                        <Col md="6 mt-5" key={item.mylittle_id}>
                            <div className="user-details">
                                <div className="media border p-3 media-sec">
                                    <img src={item.picture} className="mr-3 mt-3 rounded-circle" width="70px" height="70px" />
                                    <div className="media-body" style={{ marginLeft: '10px' }}>
                                        <h4 className="media-heading" style={{ marginBottom: '5px' }}>
                                            {item.display_name}
                                            <div className="float-right">
                                              
                                                <>
                                                    <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                        <Tooltip>
                                                            <div className='text-center p-4'>
                                                                <p className='tool-title'>Delete User Account</p>
                                                                <p className='tool-para'>Are you sure you want to delete the account for <span className='tool-para' style={{ color: '#ff6565' }}></span>? Deleted accounts may not be restored.</p>
                                                            </div>
                                                            <div className='d-flex btn-sec'>
                                                                <button onClick={() => this.onDeletelittle(item.mylittle_id)} className='tool-btn-yes'>Yes, Delete</button>
                                                                <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                            </div>
                                                        </Tooltip>
                                                    </Overlay>
                                                    <span name="remove" onClick={(e) => this.showPopoverDialog(e, item, 'delete')}><CloseIcon /></span>
                                                </>
                                            </div>
                                        </h4>
                                        <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>Guardian: {item.guardian_name}</h8>
                                        <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>Phone : {item.phone_number}</h8>
                                        <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>Bigs User: {item.user_name}</h8>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )) : <h3 style={{ marginTop: '20px', textAlign: 'center' }}>{this.state.DefaultMessage}</h3>
                    }
                </div >
            </div >
        );

    }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {

    return {
        littlesDetails: state.littlesDetails,
        newUserManagementDetails: state.newUserManagementDetails,
        chaptersList: state.chaptersList,
    }
}
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, { getLittlesAjax, deleteLittle, getChapters })(onClickOutside(Littles))


