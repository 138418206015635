import { PLAN_LIST, PLAN_LIST_ERROR } from "../types";

let initialState = {   
    planList_res: '',
    error: false,
}

export default function planListreducer(state = initialState, action){

    switch(action.type){
        case PLAN_LIST:
            return {
                ...state,
                planList_res: action.response
            }
        
        case PLAN_LIST_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }

}