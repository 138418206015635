import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import HttpService from '../../Services/HttpService';
import swal from 'sweetalert';
class AboutBigs extends Component {
    constructor(props){
        super(props)
          this.state={
            helpList:[],
            description:[]
        }
    }
 /* To make the api call initial page load */
    componentDidMount(){
        HttpService.helplistservice()
                    .then(response => {
                        if (response.data.status == 200) {
                            this.setState({
                                helpList: response.data.help_list
                            })
                            this.state.description= this.state.helpList?.map(data=>(
                                data.description
                           ))}
                        else {
                            swal({  icon: 'error', text: response.data.error })
                        }
                    })
                    
    }
    /* Goto help center */
    backToHelpCenter=()=>{
        this.props.history.push('/dashboard/help_center');
    }
    /* Goto getting started page */
    backToGettingStarted=()=>{
        this.props.history.push('/dashboard/help_center/getting_started');
    }
     /* render() function is used to update the UI. */
    render() {
        return (
            <div className="container">
                <div className="head">
                    <div >
                        <div>
                            <div className="horizontal-container">
                            <label className="label-discount" onClick={()=>this.backToHelpCenter()}>Help Center</label>
                                <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                                <div className="horizontal-container">
                                <label className="label-discount"  onClick={()=>this.backToGettingStarted()} >Getting Started</label>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div >
                    <div className="box p-4">
                            <div>
                                <h4 className="helphead mb-4">What is BigS Connect?</h4>
                               <h6 className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h6>
                            <div className="videoimage mb-4"></div>
                            </div>
                            <h6 className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h6>
                            <h6 className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h6>
                            <h6 className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h6>
                            <h6 className="mb-4">Last Updated 06/21/2020</h6>
                        </div>
                    </div>
                </div>
        )
    }
}
/*Default export is used to export a single class, or function from a module*/
export default (withRouter(AboutBigs))