import { SIDENAV_SETTINGS_ERROR,SIDENAV_SETTINGS_DATA } from "../types";

const initialState ={
    showsettingsdata : {}
}

export default function settingsreducer (state = initialState, action) {
    switch(action.type){
        case SIDENAV_SETTINGS_DATA:
            return{
                ...state,
                showsettingsdata : action.response
            }
            default: return state

    }
    
}