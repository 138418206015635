import React, { Component } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { viewTemplate } from "../../Store/template/viewTemplateoneAction";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Carousels extends Component {
  /* render() function is used to update the UI. */
  render() {
    return (
      <div>
        {JSON.parse(localStorage.getItem("ViewTemplateData")) != null && (

          <Carousel showArrows={true} autoPlay={true} showThumbs={false} showStatus={false}>
            {JSON.parse(
              localStorage.getItem("ViewTemplateData"))?.template?.banners.map((banners, index) => {
              return (
                <div className="bg-white">
                  {banners.banner_image.includes('.mp4') ?

                    <video width="100%" height="600px" controls >
                      <source src={banners.banner_image} type="video/mp4" />
                    </video> : <img style={{ height: '600px', width: '100%' }} src={banners.banner_image}></img>

                  }
                </div>
              );
            })} 
          </Carousel>
        )}
      </div>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    viewtemplate: state.viewtemplate,
  };
};

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { viewTemplate })(Carousels);
