import React, { Component } from "react";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import HttpService from '../Services/HttpService';
import { withRouter } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import Rating from 'react-rating-stars-component';
import handleSwalAlert  from './SwalAlert'
var FormData = require('form-data');

class FeedBack extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            input: {},
            rating: '',
            comment: '',
            spinner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /** To make the api call initial page load */
    componentDidMount() {
        if (this.props.location.state != null) {
            this.setState({
                rating: this.props.location.state.detail.rating,
                comment: this.props.location.state.detail.comment,
            })
        }

    }
    /** To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.state.spinner = false
    }
    /** Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
        if (key == 'comment') {
            this.setState({
                comment: e.currentTarget.value
            })
        }

    }
    /** To call the api to submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            spinner : true
        })
      
        if (this.validator.allValid()) {
            var data = new FormData();
            data.append('comment', this.state.comment);
            data.append('rating', this.state.rating);

            HttpService.postFeedback(data)
                .then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            spinner : false
                        })
                        var handleSuccessMessage = {
                            message:response.data.message,
                            alertType: "success",                        
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                            propsData : this.props,
                            goToList:true,
                            pathname : '/dashboard/newdashboard'
                        }
                        handleSwalAlert(handleSuccessMessage)
                   
                    }

                    else {
                        this.setState({
                            spinner : false
                        })
                        var handleErrorMessage = {
                            message: response.data.error,
                            alertType: "warning",
                            
                        }
                        handleSwalAlert(handleErrorMessage)
                       
                    }
                })
                .catch(error => {
                    var handleErrorMessage = {
                        alertType: "error",                      
                    }
                    handleSwalAlert(handleErrorMessage)                   
                })

        }
        else {
            this.validator.showMessages();
            this.setState({ spinner: false })
        }

    };
    /** Goto dashboad */
    onCancel() {
        this.props.history.push('/dashboard/newdashboard');
    }
    /** Goto dashboad */
    onFeedback() {
        this.props.history.push('/dashboard/newdashboard');
    }
    /** For rating */
    ratingChanged(ratingvalue) {
        this.setState({
            rating: ratingvalue
        })
    }
    /** render() function is used to update the UI. */
    render() {
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        this.state.Uid = this.currentuser.user.user_id;
        return (
            <div>
                <form className="form" ref={form => this.chapterForm = form} onSubmit={this.handleSubmit}>
                    <div className="container">
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12">
                                <div className="horizontal-container">
                                    <label className="label-discount" onClick={() => this.onFeedback()}>Feedback</label>

                                </div>
                            </div>
                        </div>
                       
                        <section className="newuser-sec">
                            <div className="row">
                            <div>
                                <center>
                                    <div class="loader-spinner">
                                        {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                                        }
                                    </div>
                                </center>

                            </div>
                                <div className="col-md-5">
                                    
                                    <div className="mb-3">
                                        
                                        <div>
                                            <label className="lehead" defaultValue={this.state.active_status}>Rate Us</label>
                                        </div>
                                        
                                        <div className="custom-control custom-radio custom-control-inline" >
                                            <div>
                                                <Rating count={5} onChange={(rating) => this.ratingChanged(rating, 'rating')}
                                                    size={24} isHalf={true} emptyIcon={<i className="fa fa-star"></i>}
                                                    halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}
                                                    activeColor="#ffd700"></Rating>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-group">
                                            <label className="lehead">Comments:*</label>
                                            <textarea
                                                defaultValue={this.state.comment}
                                                className="form-control"
                                                onChange={(e) => this.handleChange(e, 'comment')} />
                                            <span className="text-danger">{this.validator.message("comments", this.state.comment, "required")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="row" >
                                <div className="col">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-info btn-success mt-1">Submit</button>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group float-right cancel-sec">
                                        <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </form>
            </div>
        );
    }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
}
/** Connects a React component to a Redux store */
export default connect(mapStateToProps)(withRouter(FeedBack));
