import { Public } from '@material-ui/icons';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import HttpService from '../Services/HttpService';
import sample from './sample.pdf'
import swal from 'sweetalert2';
import { Circles } from "react-loader-spinner";

var FormData = require('form-data');
class VideoGallery extends Component {
  constructor() {
    super();
    this.state = {
      bannerPreview: '',
      pdfpreview: '',
      pageNumber: '',
      NumPages: 0,
      pptFileName: '',
      videoName: '',
      pptfile: [],
      pptUpload: '',
      video_name: '',
      videoUrl: '',
      spinner: false,
      upload_type: '0'
    }
    this.uploadppt = this.uploadppt.bind(this)
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }
  onDocumentLoadSuccess(numPages) {
    this.setState({
      NumPages: numPages
    })
  }
  pdfHandleChange(e) {
    console.log('pdf eeee:-', e.target.files[0])
    this.setState({
      videoName: e.target.files[0],
      pdfpreview: URL.createObjectURL(e.target.files[0])
    }, console.log("ppt name:- ", e.target.value))
  }
  videoHandleChange(e) {
    console.log('eeee:-', e.target.files[0])
    this.setState({
      videoName: e.target.files[0],
      bannerPreview: URL.createObjectURL(e.target.files[0])
    })
  }
  handleChange = (e, key) => {
    console.log('media:-', e.currentTarget.value)
    if (key === 'media_upload') {
      this.setState({
        upload_type: e.currentTarget.value
      })
    }

  }
  componentDidMount() {

    HttpService.getvideo()
      .then(response => {
        this.state.getvideo = response.data
        console.log('PPTFile:-', response.data.videos)
        this.setState({
          pptfile: response.data.videos,
        })
      }).catch(error => (console.log('error in video gallery:- ', error)))

  }
  onpptChange(e) {
    console.log('ppppt:- ', e.target.files[0])
    this.setState({
      videoName: e.target.files[0],
    });

  }
  uploadppt() {
    this.setState({ spinner: true })
    //console.log('video name:- ', this.state.videoName)
    var data = new FormData();


    //data.append("videos", this.state.videoUrl)

    if (this.state.videoName == '') {
      data.append("url_status", 0);
      data.append("video_url", this.state.videoUrl);
    }
    else {
      data.append("url_status", 1);
      data.append("videos", this.state.videoName)
    }

    HttpService.uploadvideo(data)
      .then(response => {
        console.log('ppt:-', response)
        response.data.success == true ? swal.fire({
          icon: 'success',
          text: response.data.message
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.history.push('/dashboard/newdashboard');
          }
        }) : swal.fire({
          icon: 'error',
          text: response.data.message
        })
        this.setState({
          uploadvideo: response.data,
          videoName: response.data.videos.video_name,
          spinner: false
        })
      }).catch(error => {
        alert(error)
      })
  }
  handleShowDialog(item) {
    console.log('item:- ', item)
    swal.fire({

      html:

        `<iframe
         width="500" 
         height="315" 
         src=${item.video_name} 
        frameborder="0" 
        allow="accelerometer; 
        autoplay; 
        clipboard-write; 
        encrypted-media; 
        gyroscope; 
        picture-in-picture" allowfullscreen></iframe>`,


    })
  };
  uploadUrl(e) {
    console.log('url', e.target.value)
    this.setState({
      videoUrl: e.target.value
    })

  }
  render() {

    return (

      <div className='container'>
        <div className='box-video p-3'>
          <Row className='mb-4'>
            <Col md="12">
              <div className='d-flex'>
                <div>
                  {this.state.bannerPreview && this.state.upload_type == '0' && (
                    <>
                      <label style={{marginTop:'-5px'}} class="lehead">Preview Video</label>
                      <div className="clearfix"></div>
                      <video style={{marginTop:'5px'}} className="preview-image" controls >
                        <source src={this.state.bannerPreview} type="video/mp4" />
                      </video>
                    </>
                  )}
                </div>
                <div style={{marginTop:'10px'}}>
                  <div  className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="radio" id="upload_video" name="media_upload" value='0' checked={this.state.upload_type == '0'}
                      onChange={(e) => this.handleChange(e, 'media_upload')} />
                    <label className="custom-control-label" htmlFor="upload_video">Upload Video</label>
                  </div>
                  {this.state.upload_type == '0' &&
                  <>
                  <div style={{marginTop:'10px'}} className="clearfix"></div>
                  <div className="profile-upload rect">
                    <label className="custom-file-upload rectangle">
                      <img src="" />

                      <input type="file" name="videoName" className="form-control" multiple
                        onChange={(e) => this.videoHandleChange(e)} />
                      <i className="fas fa-plus"></i>
                    </label>
                  </div>
                  </>
  }
                </div>

              </div>
              <div style={{marginTop:'10px'}} className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="radio" id="upload_url" name="media_upload" value='1' checked={this.state.upload_type == '1'}
                  onChange={(e) => this.handleChange(e, 'media_upload')} />
                <label className="custom-control-label" htmlFor="upload_url">Upload Url</label>
              </div>
              {this.state.upload_type == '1' && <input
                 style={{marginTop:'5px'}}
                type="text"
                className="form-control"
                name="videoName"
                value={this.state.videoUrl}
                onChange={(e) => this.uploadUrl(e)}
              />}

            </Col>
          </Row>
          <Row>
            {this.state.pptfile && this.state.pptfile.map(item => (
              <Col md="4">
                {item.video_name.includes('.mp4') ?
                  <>
                    <div className='video-sec'>
                      <iframe
                        width='327px'
                        height='190px'
                        src={item.video_name}
                        frameborder="0"
                        //  allow="autoplay; encrypted-media"
                        allowfullscreen
                        title="video"
                      />{" "}
                      <div className='video-overlay'>
                        <i class="fa fa-youtube-play" onClick={() => this.handleShowDialog(item)}></i>
                      </div>
                    </div>
                    <div className="modal" id="myModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <video width="100%" controls >
                              <source src={item.video_name} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div></> :
                  <div className='video-sec'>

                    <iframe
                      width='327px'
                      height='190px'
                      src={item.video_name}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                    /> {" "}
                    <div className='video-overlay'>
                      <i class="fa fa-youtube-play" onClick={() => this.handleShowDialog(item)}></i>
                    </div></div>}
              </Col>))}
          </Row>
          <hr className='m-5' />
          <div className='mt-4'>
            <h3>Upload A PDF</h3>
            <input name="videoName" type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => this.pdfHandleChange(e, "video_name")} />
          </div>
          <div className='mt-4'>
            {this.state.pdfpreview && (
              <>
                <label class="lehead">Pdf Preview</label>
                <div className="clearfix"></div>
                <iframe src={this.state.pdfpreview} width="100%" height="400px" />
              </>
            )}
          </div>
          <Row className='m-4'>
            {this.state.pptfile && this.state.pptfile.map(item => (

              <Col md="4">
                {item.video_name.includes('.pdf') ?
                  <div className='video-sec'>
                    <div className='react-pdf__Document'>

                      {/* <Document file={sample} onLoadSuccess={this.onDocumentLoadSucces}>
                      <Page pageNumber={1} height="400" />
                    </Document> */}
                      <iframe src={item.video_name} width="100%" height="400px" />

                    </div>
                    <div className='pdf-overlay'>
                      <i class="fa fa-file-pdf-o" aria-hidden="true" data-toggle="modal"
                        data-target="#Pdf"></i>
                    </div>
                  </div> : null}
              </Col>

            ))}

          </Row>
          <hr className='m-5' />
          <div className='mt-4'>
            <h3>Upload A PPT</h3>
            <input name="videoName" type="file"
              onChange={(e) => this.onpptChange(e, "video_name")} />

          </div>
          <div className='m-4'>
            <Row>
              {this.state.pptfile && this.state.pptfile.map(item => (

                <Col md="4">
                  {item.video_name.includes('.pptx') ?
                    <div className='video-sec'>

                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${item.video_name}`}
                        width="100%"
                        height="250px"
                        frameBorder="0"
                      >
                      </iframe>

                      <div className='pdf-overlay'>
                        <i class="fa fa-file-powerpoint-o" aria-hidden="true" data-toggle="modal"
                          data-target="#Ppt"></i>
                      </div>
                    </div> : null}
                </Col>
              ))}


            </Row>
            <button type='submit' className='btn btn-info btn-success mt-4' onClick={() => this.uploadppt()}>submit</button>
          </div>
          <center>
            <div class="loader-spinner">
              {this.state.spinner ? (
                <Circles color="#7cc9a9" height={50} width={50} />
              ) : null}
            </div>
          </center>
          {/* <div className="modal" id="myModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <video width="100%" controls >
                      <source src="/img/video.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="modal" id="Pdf">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.pptfile && this.state.pptfile.map(item => (
                    <iframe src={item.video_name} width="100%" height="400px" />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal" id="Ppt">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <iframe
                    src={'https://view.officeapps.live.com/op/embed.aspx?src=http://s3.amazonaws.com/barfinder/bigsconnect/discount_documents/samplepptx%20(5)-1661765779.pptx'}
                    width="100%"
                    height="400px"
                    frameBorder="0"
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default VideoGallery;