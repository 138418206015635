import { ACTIVITY_FETCH_INPUT, ACTIVITY_ADD, ACTIVITY_ADD_ERROR } from "../types";
import HttpService from '../../Services/HttpService';

export const activityFetchInput = (Input) => {
    return {
        type: ACTIVITY_FETCH_INPUT,
        Input
    }
}

export const activityForm = (response) => {
    return {
        type: ACTIVITY_ADD,
        response
    }
}

export const activityErrorForm = () => {
    return {
        type: ACTIVITY_ADD_ERROR
    }
}

export const addActivityForm = (activityInput) => {
    console.log('activityInput ', activityInput);
    return (dispatch) => {
        HttpService.addActivityData(activityInput)
            .then(response => {
                console.log('add activity response', response);
                dispatch(activityForm(response.data));
            })
            .catch(error => {
                dispatch(activityErrorForm());
            })
    }
}