import React, { Component } from 'react';
import ReactFormInputValidation from "react-form-input-validation";
import { Row, Col } from 'react-bootstrap';
class Helpcenter extends Component {
    constructor(props) {
        super(props)

    }
    /* Goto gettings started page */
    getStarted = () => {
        this.props.history.push({
            pathname: '/dashboard/help_center/getting_started'
        })
    }
    /* Goto about bigs page */
    aboutbigs=()=>{
        this.props.history.push('/dashboard/help_center/aboutbigs');
    }
  /* render() function is used to update the UI. */
    render() {
        return (
            <div className="container">
                <Row className='pt-4'>
                    <Col md="6">
                    <h3 className="pagename mb-3">Help Center</h3>
                    </Col>
                    <Col md="6">
                        <span  className="fa fa-search head-search"></span>
                         <input style={{ paddingLeft: '40px' }} type="text" className="form-control" placeholder="Search for help on specific topics..."/>
                    </Col>
                </Row>
                <div className="box">
                        <Row>
                            <Col md="3">
                                <h4 className="helphead">Get Started</h4>
                                <ul className='list_image'>
                                    <li style={{fontSize:'11px',fontWeight:'500',marginLeft:'5px'}} className="pagename mb-3 resources-text"onClick={()=>this.aboutbigs()}>What is BigS Connect</li>
                                    <li style={{marginTop:'-8px'}} className="helpcenterlist">How to Begin</li>
                                    <li className="helpcenterlist" >Navigation Basics</li>
                                    <a><h6  onClick={() => this.getStarted()} className='helpshowall'>Show all</h6></a>
                                </ul>
                            </Col>
                       </Row>
                </div>
           
            </div>

        );
    }
}

export default Helpcenter;