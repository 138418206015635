import React, { Component } from 'react';
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchLoginInput, loginUser } from '../../Store/login/login';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import HttpService from './../../Services/HttpService'
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import { Circles } from 'react-loader-spinner'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import handleSwalAlert from '../SwalAlert'

toast.configure()

class Login extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            errorMessage: '',
            username: '',
            password: '',
            spinner: false,
            showPasswordText: false,
            showPassworTextlength: 0,
            onOpenModal: false,
            onCloseModal: false,
            Femail: '',
            forgotvalidation: false,
            forgoterrormessage: ''
        }
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        user: this.props.cookies.get("cookiedata") || ""
    };
    /* If token found it will go to dashboard or else it will redirected to login page */
    navigateTo = () => {

        var user = JSON.parse(localStorage.getItem('userdata'));

        if (user == null) {

            //window.location.href = '/';
            this.props.history.push('/');
        } else {

            this.props.history.push('/dashboard/newdashboard');

        }

    }
/* Event occurs when the value of an element has been changed */
    handleUserInput = (event, key) => {
        let user = {
            key: key,
            value: event.currentTarget.value
        }
        if (key === "username") {
            this.setState({ email: event.currentTarget.value });
        }
        else if (key === "Femail") {
            this.setState({
                Femail: event.currentTarget.value,
                forgotvalidation: false
            });
        }
        else {
            this.setState({
                password: event.currentTarget.value,
                showPassworTextlength: event.currentTarget.value
            });
        }
        // this.props.fetchLoginInput(user);
    }
/* To call api for login */
    handleLoginButton = (e) => {
        const { cookies } = this.props;
        e.preventDefault();
        this.setState({
            spinner: true
        })
        var logindetails = {
            username: this.state.email,
            password: this.state.password,
            login_from: "web"
        }


        if (this.validator.allValid()) {

            HttpService.login(logindetails)
                .then(response => {
                    if (response.status == 200) {
                        cookies.set('cookiedata', response.data.user.token, { path: "/" })
                        this.setState({ user: cookies.get('cookiedata') });
                        localStorage.setItem("userdata", JSON.stringify(response.data.user));
                        localStorage.setItem("chapter_id", JSON.stringify(response.data.user.chapter_id));
                        this.setState({
                            spinner: false
                        })
                        this.props.history.push('/dashboard/newdashboard');
                    }
                    else {
                        this.setState({
                            spinner: false
                        })
                        var errorAlert = {
                            message : response.data.message,
                            alertType : 'warning'
                        }
                        handleSwalAlert(errorAlert)
                        // swal({
                        //     icon: 'error',
                        //     text: response.data.message
                        // })
                    }
                })
                .catch(error => {
                    this.setState({
                        spinner: false
                    })
                    var errorAlert = {
                        alertType : 'error'
                    }
                    handleSwalAlert(errorAlert)
                    // swal({
                    //     icon: 'error',
                    //     text: "Something went wrong please try again after sometime."
                    // })

                })


            //this.props.loginUser(logindetails);
        }
        else {
            this.setState({
                spinner: false
            })
            this.validator.showMessages();
        }

    }
/* To make the api call initial page load */
    componentDidMount() {


        if (this.props.cookies.get("cookiedata") != undefined) {
            const { cookies } = this.props;
            cookies.remove("cookiedata")
            this.navigateTo()
        }


    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {

    }
    /* to hide the password text */
    hidepassword() {
        this.setState({
            showPasswordText: false
        })
    }
    /* to show the password text */
    showpassword() {
        this.setState({
            showPasswordText: true
        })

    }
    /* to show the pop up */
    handleOpenModal() {
        this.setState({
            onOpenModal: true

        })
    }
    /* to close the pop up */
    handleCloseModal() {
        this.setState({
            onOpenModal: false,
            forgotvalidation: false,
            forgoterrormessage: '',
            Femail: ''

        })
    }
/* Goto forgot page */
    forgotLink() {
        const { Femail } = this.state
        var email = { email: Femail }
        this.setState({
            spinner: true
        })
        if (this.state.Femail.length > 0) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.Femail)) {
                this.setState({ forgotvalidation: false })
                HttpService.ForGot_Link(email).then(response => {
                    if (response.data.status == 200) {
                        this.setState({
                            onOpenModal: false,
                            spinner: false,
                            Femail
                        })
                        var successAlert = {
                            message : response.data.message,
                            alertType : 'success'
                        }
                        handleSwalAlert(successAlert)
                        // swal({
                        //     title: response.data.message,
                        //     icon: 'success'
                        // })
                    } else {
                        this.setState({
                            spinner: false,
                            Femail: ''
                        })
                        var errorAlert = {
                            message : response.data.message,
                            alertType : 'warning'
                        }
                        handleSwalAlert(errorAlert)

                        // swal({
                        //     title: response.data.message,
                        //     icon: 'error'
                        // })
                    }
                })
            }
            else {
                this.setState({
                    spinner: false,
                    forgoterrormessage: 'Invalid email address',
                    forgotvalidation: true
                })
            }
        }
        else {
            this.setState({
                spinner: false,
                forgotvalidation: true,
                forgoterrormessage: 'The email field is required'
            })
            // this.setState({
            //     forgoterrormessage:'The email field is required'
            // })
            //this.validator.showMessages()
        }

    }
    /* render() function is used to update the UI. */
    render() {
        const { user, showPasswordText, showPassworTextlength,
        } = this.state;

        const bigsLogo = {
            height: "100px",
            margin: "20px"
        };
        return (

            <div class="wrapper wrapper-login">
                <div className="login-sec">

                    <center>
                        <div class="loader-spinner-login">
                            {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                            }
                        </div>
                    </center>
                    <center>
                        <img class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/img/bigsconnect.png`}
                            alt="logo" style={{ height: '100px', margin: '20px' }} />
                    </center>
                    <p>{user}</p>

                    <div className="FormDiv">
                        <form onSubmit={this.handleLoginButton} className='form-width'>
                            <Row>
                                <Col xs lg="12" className='login-details-fields-new'>
                                    <div className="form-group" style={{ width: "100%" }}>
                                        <label className="login-labels">
                                            User Name
                                        </label>
                                        <div>
                                            <div className="mb-4">
                                                <input type="text" class="form-control mb-0" placeholder="User Name" name="username" value={this.state.email}
                                                    onChange={(event) => { this.handleUserInput(event, 'username') }} />
                                                <span className="text-danger">{this.validator.message("username", this.state.email, "required")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs lg="12" className="login-details-fields-new">
                                    <div className="form-group" style={{ width: "100%" }}>
                                        <label className="login-labels">
                                            Password
                                        </label>

                                        <div className="mb-2">
                                            <input
                                                type={showPasswordText ? 'text' : 'password'}
                                                placeholder="Password"
                                                class="form-control mb-0"
                                                name="password" value={this.state.password}
                                                onChange={(event) => { this.handleUserInput(event, 'password') }} />
                                            {showPassworTextlength == 0 ? null :
                                                showPasswordText ?
                                                    <i class=" fa fa-eye-slash login-eye-slash" aria-hidden="true" onClick={() => this.hidepassword()}></i>
                                                    : <i class="fa fa-eye login-eye" aria-hidden="true" onClick={() => this.showpassword()}></i>}
                                            <span className="text-danger">{this.validator.message("password", this.state.password, "required")}</span>

                                        </div>
                                        <a onClick={() => this.handleOpenModal()} className="forgot-password">Forgot Password?</a>
                                    </div>
                                </Col>
                            </Row>
                            <Modal open={this.state.onOpenModal} onClose={() => this.handleCloseModal()} center>
                                <h2 className='mb-4 text-center'>Forgot Password</h2>
                                <div>
                                    <div>
                                        <input type='email' name='Femail' placeholder='Please enter email' class="form-control" onChange={(event) => { this.handleUserInput(event, 'Femail') }} />
                                        {this.state.forgotvalidation ? <span className="text-danger">{this.state.forgoterrormessage}</span> : null}
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type="submit" class="btn-send" onClick={() => this.forgotLink()}>
                                            Send password reset link
                                        </button>
                                    </div>
                                </div>

                            </Modal>
                            <div class="login-details-fields-new" style={{ margin: "10px 0px" }}>
                                <div class="app">
                                    <div class="ButtonParent" >
                                        <button type="submit" class="btn btn-info btn-login">
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
    return {
        login: state.login
    }
}
/*Connects a React component to a Redux store */

export default connect(mapStateToProps, { fetchLoginInput, loginUser })(withCookies(Login));
