import React, { Component } from 'react'

class CreateTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = { 
           formValues: [{ name: "", email : "" }]
         };
        this.handleSubmit = this.handleSubmit.bind(this)
      }
      
      handleChange(i, e) {
        let formValues = this.state.formValues;
        formValues[i][e.target.name] = e.target.value;
        this.setState({ formValues });
      }
    
      addFormFields() {
        this.setState(({
          formValues: [...this.state.formValues, { name: "", email: "" }]
        }))
      }
    
      removeFormFields(i) {
        let formValues = this.state.formValues;
        formValues.splice(i, 1);
        this.setState({ formValues });
      }
    
      handleSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.state.formValues));
      }
    
      render() {
    
        return (
            <div className='container'>
            <form  onSubmit={this.handleSubmit}>
              {this.state.formValues.map((element, index) => (
                <div className="form-inline m-3" key={index}>
                    {console.log('index:- ',index)}
                  <label className='m-1'>Name</label>
                  <input type="text" className='col-3 p-2' name="name" value={element.name || ""} onChange={e => this.handleChange(index, e)} />
                  <label className='m-1'>Email</label>
                  <input type="text" className='col-3 p-2' name="email" value={element.email || ""} onChange={e => this.handleChange(index, e)} />
                  {
                    index ? 
                      <button type="button"  className="button remove m-2" onClick={() => this.removeFormFields(index)}>Remove</button> 
                    : null
                  }
                </div>
              ))}
              <div className="button-section m-5 p-3 ">
                  <button className="cancelbtnnew mr-5" type="button" onClick={() => this.addFormFields()}>Add</button>
                  <button className="btn btn-info btn-success" type="submit">Submit</button>
              </div>
          </form>
          </div>
        );
      }
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         templateData: {},
    //         values: []
    //     }
    //     this.handleSubmit = this.handleSubmit.bind(this);

    // }
    // createUI() {
    //     return this.state.values.map((el, i) =>
    //         <div className='row m-3' key={i}>
    //             <input type="text" className='col-10 p-2' placeholder={"Enter Template Title " + (i)} value={el || ''} onChange={this.handleChange.bind(this,i+1)} />
    //             {/* <span className='col-1'></span> */}
    //             <input type='button' className='col-1 ml-2' value='remove' onClick={this.removeClick.bind(this, i)} />
    //         </div>
    //     )
    // }

    // handleChange(i, event) {
    
    //         let values = [...this.state.values];
    //         values[i] = event.target.value;
    //         this.setState({ values });
    // }
    // onFirstHandle(i,e){
    //     if(e==='first'){
    //         alert(i.target.value)
    //         let values = [...this.state.values];
    //         values[0] = i.target.value;
    //         this.setState({ values });
    //     }
    //     else{
    //         alert('else')

    //     }
    // }


    // addClick() {
    //     this.setState(prevState => ({ values: [...prevState.values, ''] }))
    // }

    // removeClick(i) {
    //     let values = [...this.state.values];
    //     values.splice(i, 1);
    //     this.setState({ values });
    // }

    // handleSubmit(event) {
    //     alert('A name was submitted: ' + this.state.values.join(', '));
    //     event.preventDefault();
    // }

    // componentDidMount() {

    // }
    // addMore(e) {

    // }
    // render() {

    //     return (
    //         <div className='container'>
    //             <section>
    //             </section>
    //             <section>
    //                 <form onSubmit={this.handleSubmit}>
    //                     <input type='text' className='col-10 p-2 m-3' name='first' placeholder={"Enter Template Title"} onChange={(e) => this.onFirstHandle(e, 'first')}></input>
    //                     {this.createUI()}


    //                     <section> <input style={{ margin: '10px' }} type='button' className='cancelbtnnew' value='add more' onClick={this.addClick.bind(this)} />
    //                         <input style={{ margin: '10px' }} className='btn btn-info btn-success mt-1' type="submit" value="Submit" />
    //                     </section>
    //                 </form>
    //             </section>
    //         </div>
    //     )
    // }

}
export default CreateTemplate