import React, { Component } from 'react';
import Header from '../TemplateHeader';
import BodyConent from './BodyConent';
import Carousels from '../TemplateCarousal';
import Footer from './Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createTemplate } from '../../../Store/template/addTemplateAction'
import { viewTemplate } from '../../../Store/template/viewTemplateoneAction'
import HttpService from '../../../Services/HttpService';
import { Circles } from 'react-loader-spinner';

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapterData: {},
            viewTemplateData: {},
            spinner: false,
            employee: [
                {
                    id: 1,
                    name: "Abc",
                    age: 25
                },
                {
                    id: 2,
                    name: "Def",
                    age: 28
                },
                {
                    id: 3,
                    name: "Ghi",
                    age: 30
                }
            ]

        }

    }

    /* To make the api call initial page load */
    componentDidMount() {


        this.state.chapterData = JSON.parse(localStorage.getItem("chpaterdata"))
        console.log('view tempalte:- ', this.state.chapterData.template)

        var data = {
            chapter_id: this.state.chapterData.chapter_id,
            template_id: this.state.chapterData.template.templateid,
            type:'view'
        }
        this.props.viewTemplate(data)
    }

    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        console.log("template view data:- ", nextProps.viewtemplate.viewTemplate)
        this.state.viewTemplateData = nextProps.viewtemplate.viewTemplate
    }

    /* triggers before the initial render*/
    componentWillMount() {
        var data = {
            chapter_id: JSON.parse(localStorage.getItem("chpaterdata")).chapter_id,
            template_id: JSON.parse(localStorage.getItem("chpaterdata")).template.templateid,
            type:'view'
        }
        HttpService.viewTemplate(data)
            .then(response => {
                console.log("viewTemplateoneData :- ", JSON.stringify(response.data))
                localStorage.setItem("ViewTemplateData", JSON.stringify(response.data))
                // this.setState({
                //     viewTemplateData: response.data
                // })
            })
        // var data = {
        //     chapter_id: JSON.parse(localStorage.getItem("chpaterdata")).chapter_id,
        //     template_id: JSON.parse(localStorage.getItem("chpaterdata")).templateid
        // }
        // this.props.viewTemplate(data)
        //  console.log("will mount:- ", this.state.viewTemplateData.template.template_id)

    }

    /* render() function is used to update the UI. */
    render() {
        return (
            <div className='body-bg'>
                <center>
                    <div class="loader-spinner">
                        {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                    </div>
                </center>
                <Header />
                <Carousels />
                <BodyConent />
                <Footer />
            </div>
        )
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        addtemplate: state.addtemplate,
        viewtemplate: state.viewtemplate
    }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { createTemplate, viewTemplate })(withRouter(Template));
