import React, { Component } from 'react';
import HttpService from '../../Services/HttpService';
import swal from 'sweetalert';
import { Circles } from 'react-loader-spinner';
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import { getsettingsdata } from './../../Store/settings/settingsaction'
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert';

class Settings extends Component {
    constructor() {

        super()
        this.state = {

            pushvalue: false,
            messagevalue: false,
            eventsvalue: false,

            postpush: '0',
            postmessage: '0',
            postevent: '0',

            shownewpasswordtext: false,
            showconfrimpasswordtext: false,
            showoldpasswordtext: false,
            newpasswordtextlength: 0,
            confirmpasswordtextlength: 0,
            oldpasswordtextlength: 0,
            spinner: false,
            fromheader: false,
            showsettingsdata: {},

            changePasswordscreen: null,

        }

    }
    /* To show the password text */
    showpassword() {
        this.setState({
            showpasswordtext: true
        })

    }
    /* To hide the password text */
    hidepassword() {
        this.setState({
            showpasswordtext: false
        })
    }
    /* To show the old password text */
    showoldpassword() {
        this.setState({
            showoldpasswordtext: true
        })

    }
    /* To hide the old password text */
    hideoldpassword() {
        this.setState({
            showoldpasswordtext: false
        })
    }
    /* To show the confrim password text */
    showconfirmpassword() {
        this.setState({
            showconfrimpasswordtext: true
        })
    }
    /* To hide the confrim password text */
    hideconfirmpassword() {
        this.setState({
            showconfrimpasswordtext: false
        })
    }
    /* Event occurs when the value of an element has been changed */
    handlechange(e, key) {

        if (key == 'oldpassword') {
            this.setState({
                oldpasswordtextlength: e.currentTarget.value
            })

        }
        if (key == "newpassword") {
            this.setState({
                newpasswordtextlength: e.currentTarget.value
            })
        }
        if (key == "confirmpassword") {
            this.setState({
                confirmpasswordtextlength: e.currentTarget.value

            })
        }

    }
    /* To enable and disable the radio buttons */
    radiocheck(e, key) {

        if (key == 'push_notification') {
            if (this.state.pushvalue == true) {
                this.setState({
                    // push: 'off',
                    pushvalue: false,
                    postpush: '1'
                })
            }
            else {
                this.setState({
                    //  push: 'on',
                    pushvalue: true,
                    postpush: '0'
                })
            }
        }
        if (key == 'message_notifications') {
            if (this.state.messagevalue == true) {
                this.setState({
                    //  message: 'off',
                    messagevalue: false,
                    postmessage: '1'
                })
            }
            else {
                this.setState({
                    // message: 'on',
                    messagevalue: true,
                    postmessage: '0'
                })
            }
        }
        if (key == 'event_notification') {
            if (this.state.eventsvalue == true) {
                this.setState({
                    // event: 'off',
                    eventsvalue: false,
                    postevent: '1'
                })
            }
            else {
                this.setState({
                    // event: 'on',
                    eventsvalue: true,
                    postevent: '0'
                })
            }
        }
    }
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    /* To call the api to submit the values */
    handlesubmit(e) {
        e.preventDefault();
        if (this.props.fromsidenavsettings != 'fromsidenavsettings') {
            const { cookies } = this.props;
            this.setState({
                spinner: true
            })
            if (this.state.oldpasswordtextlength == 0 && this.state.newpasswordtextlength == 0 &&
                this.state.confirmpasswordtextlength == 0) {
                this.setState({
                    spinner: false
                })
            }
            else {
                if (this.state.oldpasswordtextlength != 0 && this.state.newpasswordtextlength == 0 &&
                    this.state.confirmpasswordtextlength == 0) {
                    var handleWarning = {
                        message: 'Please enter new and confirm password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)

                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.oldpasswordtextlength == 0 && this.state.newpasswordtextlength != 0 &&
                    this.state.confirmpasswordtextlength == 0) {
                    var handleWarning = {
                        message: 'Please enter old and confirm password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)

                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.oldpasswordtextlength == 0 && this.state.newpasswordtextlength == 0 &&
                    this.state.confirmpasswordtextlength != 0) {
                    var handleWarning = {
                        message: 'Please enter old and new password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)

                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.oldpasswordtextlength != 0 && this.state.newpasswordtextlength != 0 &&
                    this.state.confirmpasswordtextlength == 0) {
                    var handleWarning = {
                        message: 'Please enter confirm password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)

                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.oldpasswordtextlength != 0 && this.state.newpasswordtextlength == 0 &&
                    this.state.confirmpasswordtextlength != 0) {
                    var handleWarning = {
                        message: 'Please enter new password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)
                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.oldpasswordtextlength == 0 && this.state.newpasswordtextlength != 0 &&
                    this.state.confirmpasswordtextlength != 0) {
                    var handleWarning = {
                        message: 'Please enter old password',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)
                    this.setState({
                        spinner: false
                    })
                }
                else if (this.state.newpasswordtextlength != this.state.confirmpasswordtextlength) {
                    var handleWarning = {
                        message: 'New and confirm password must be equal',
                        alertType: 'warning'
                    }
                    handleSwalAlert(handleWarning)
                    this.setState({
                        spinner: false
                    })
                }
                else {
                    var data = {
                        old_password: this.state.oldpasswordtextlength,
                        new_password: this.state.newpasswordtextlength,
                        confirm_password: this.state.confirmpasswordtextlength,
                    };
                    HttpService.changePassword(data)
                        .then(response => {
                            if (response.data.status === 200) {
                                this.setState({
                                    spinner: false,
                                    oldpasswordtextlength: '',
                                    newpasswordtextlength: '',
                                    confirmpasswordtextlength: ''
                                })
                                var handleSuccessAlert = {
                                    message: response.data.message,
                                    alertType: 'success'
                                }
                                handleSwalAlert(handleSuccessAlert)
                            }
                            else {
                                this.setState({
                                    spinner: false
                                })
                                var handleErrorAlert = {
                                    message: response.data.message,
                                    alertType: 'warning'
                                }
                                handleSwalAlert(handleErrorAlert)
                            }
                        })
                        .catch(error => {
                            this.setState({
                                spinner: false
                            })
                            var handleErrorAlert = {
                                alertType: 'error'
                            }
                            handleSwalAlert(handleErrorAlert)
                        })
                }
            }
        }
        else {
            this.setState({
                spinner: true,

            })
            var senddata = {
                "push_notifications": this.state.postpush,
                "message_notifications": this.state.postmessage,
                "events_notifications": this.state.postevent
            }
            HttpService.postsettingsdata(senddata)
                .then(response => {
                    if (response.data.status == '200') {

                        this.setState({
                            spinner: false
                        })
                        var handleSuccessAlert = {
                            message: response.data.message,
                            alertType: 'success'
                        }
                        handleSwalAlert(handleSuccessAlert)
                    }
                    else {
                        this.setState({
                            spinner: false
                        })
                        var handleErrorAlert = {
                            alertType: 'error'
                        }
                        handleSwalAlert(handleErrorAlert)
                    }
                }).catch(error => {
                    this.setState({
                        spinner: false
                    })
                    var handleErrorAlert = {
                        alertType: 'error'
                    }
                    handleSwalAlert(handleErrorAlert)
                })
        }

    }fromheader
    /* To make the api call initial page load */
    componentDidMount() {
        this.props.getsettingsdata()
        this.setState({
            spinner: true
        })
        if (this.props.fromsidenavsettings === 'fromsidenavsettings') {
            this.setState({ fromheader: false, changePasswordscreen: false });
        }
        else {
            this.setState({ fromheader: true, changePasswordscreen: true });
        }
    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.setState({
            spinner: false
        })
        this.state.showsettingsdata = nextProps.showsettingsdata.showsettingsdata

        if (this.state.showsettingsdata.settings.push_notifications == '0') {
            this.state.pushvalue = true
            //this.state.push = 'off'
            this.state.postpush = '0'
        }
        else {
            this.state.pushvalue = false
            //this.state.push = 'on'
            this.state.postpush = '1'
        }
        if (this.state.showsettingsdata.settings.message_notifications == '0') {
            this.state.messagevalue = true
            //   this.state.message = 'off'
            this.state.postmessage = '0'
        }
        else {
            this.state.messagevalue = false
            // this.state.message = 'on'
            this.state.postmessage = '1'
        }
        if (this.state.showsettingsdata.settings.events_notifications == '0') {
            this.state.eventsvalue = true
            // this.state.event = 'off'
            this.state.postevent = '0'
        }
        else {
            this.state.eventsvalue = false
            //   this.state.event = 'on'
            this.state.postevent = '1'
        }
    }
    /* Goto the dashboard page */
    onCancel() {
        this.props.history.push('/dashboard/newdashboard');
    }
    /* render() function is used to update the UI. */
    render() {

        const {
            newpasswordtextlength,
            showconfrimpasswordtext,
            confirmpasswordtextlength,
            showpasswordtext,
            showoldpasswordtext,
            oldpasswordtextlength, fromheader } = this.state
        return (
            <div className="container">
                <center>
                    <div class="loader-spinner">
                        {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                    </div>
                </center>
                {this.props.fromsidenavsettings == 'fromsidenavsettings' ? <h3 style={{ marginBottom: '20px' }}>Settings</h3>
                    : <h3 style={{ marginBottom: '20px' }}>Change Password</h3>}

                {/* For side nav settings */}
                <div className='box'>
                    {this.props.fromsidenavsettings == 'fromsidenavsettings' ?
                        <div className='mb-3'>
                            <div>
                                <div>
                                    <label className="lehead">Push Notifications</label>
                                </div>
                                <div>
                                    <label>
                                        <span> No </span>
                                        <label class="switchtog">
                                            <input type="checkbox" checked={this.state.pushvalue} onChange={(e) => this.radiocheck(e, 'push_notification')} />
                                            <span class="slidered"></span>
                                        </label>
                                        <span> Yes </span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div style={{ marginTop: '20px' }}>
                                    <label className="lehead">Messages Notifications</label>
                                </div>
                                <div>
                                    <label>
                                        <span> No </span>
                                        <label class="switchtog">
                                            <input type="checkbox" checked={this.state.messagevalue} onChange={(e) => this.radiocheck(e, 'message_notifications')} />
                                            <span class="slidered"></span>
                                        </label>
                                        <span> Yes </span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div style={{ marginTop: '20px' }}>
                                    <label className="lehead">Event Notifications</label>
                                </div>
                                <div>
                                    <label>
                                        <span> No </span>
                                        <label class="switchtog">
                                            <input type="checkbox" checked={this.state.eventsvalue} onChange={(e) => this.radiocheck(e, 'event_notification')} />
                                            <span class="slidered"></span>
                                        </label>
                                        <span> Yes </span>
                                    </label>
                                </div>
                            </div></div> : <div>
                            <Row>
                                <Col md="3" sm="12">
                                    <div>
                                        <div className="form-group">
                                            <label className="lehead">Old Password:</label>
                                            <input
                                                className="form-control"
                                                type={this.state.showoldpasswordtext ? 'text' : 'password'}
                                                name="oldpassword"
                                                onChange={(e) => this.handlechange(e, 'oldpassword')}
                                            //value={this.state.title}
                                            />
                                            {
                                                oldpasswordtextlength == 0 ? null :
                                                    showoldpasswordtext ?
                                                        <i class=" fa fa-eye-slash setting-eye-slash" aria-hidden="true" onClick={() => this.hideoldpassword()}></i>
                                                        : <i class="fa fa-eye setting-eye" aria-hidden="true" onClick={() => this.showoldpassword()}></i>


                                            }
                                        </div>
                                    </div>
                                </Col>

                                <Col md="3" sm="12">
                                    <div>
                                        <div className="form-group">
                                            <label className="lehead">New Password:</label>
                                            <input
                                                className="form-control"
                                                type={this.state.showpasswordtext ? 'text' : 'password'}
                                                name="newpassword"
                                                onChange={(e) => this.handlechange(e, 'newpassword')}
                                            />
                                            {
                                                newpasswordtextlength == 0 ? null :
                                                    showpasswordtext ?
                                                        <i class=" fa fa-eye-slash setting-eye-slash" aria-hidden="true" onClick={() => this.hidepassword()}></i>
                                                        : <i class="fa fa-eye setting-eye" aria-hidden="true" onClick={() => this.showpassword()}></i>

                                            }
                                        </div>
                                    </div>
                                </Col>

                                <Col md="3" sm="12">
                                    <div>
                                        <div className="form-group">
                                            <label className="lehead">Confirm Password:</label>
                                            <input
                                                className="form-control"
                                                type={this.state.showconfrimpasswordtext ? 'text' : "password"}
                                                name="confrimpassword"
                                                onChange={(e) => this.handlechange(e, 'confirmpassword')}
                                            //value={this.state.title}
                                            />
                                            {
                                                confirmpasswordtextlength == 0 ? null :
                                                    showconfrimpasswordtext ?
                                                        <i class=" fa fa-eye-slash setting-eye-slash" aria-hidden="true" onClick={() => this.hideconfirmpassword()}></i> :
                                                        <i class="fa fa-eye setting-eye" aria-hidden="true" onClick={() => this.showconfirmpassword()}></i>
                                            }

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>}
                    <div>

                        <div className='row'>
                            <div className="col-md-6 my-4">
                                <button onClick={(e) => this.handlesubmit(e)} type="submit" className="btn btn-info btn-success mt-1">Save</button>
                            </div>
                            <div className="col-md-6  my-4">
                                <div className="float-right cancel-sec">
                                    <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
    return {
        showsettingsdata: state.showsettings
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getsettingsdata })(Settings)
