export const uploadMediaType = [
  {
   name:'Images', type: 'Images', id: '1'
  },
  {
   name:'Video', type: 'Video', id: '2'
  },

  {
    name :'URL',type: 'Url', id: '3'
  }
]