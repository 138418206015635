//const baseURL = 'http://54.187.114.170:81';

const baseURL = 'https://bigsconnect.com/api';
// const baseURL = 'http://localhost/Bigs_api_phase2/public'


//local api setup urls
//const baseURL = 'http://localhost/Bigsconnect-Web-API/public';
//const baseURL = 'http://localhost/Bigsconnect-Api-phase2/Bigsconnect-Web-API/public';


const apiUrl = {
    loginUrl: `${baseURL}/api/login`,
    usertypeUrl: `${baseURL}/api/userType`,
    userpermissionsUrl: `${baseURL}/api/userPermissions`,
    adduserUrl: `${baseURL}/api/addUser`,
    deleteuserUrl: `${baseURL}/api/deleteUser`,
    activeusersUrl: `${baseURL}/api/activeUsers`,
    inactiveusersUrl: `${baseURL}/api/inactiveUsers`,
    bigsusersUrl: `${baseURL}/api/bigsUsers`,
    agencyusersUrl: `${baseURL}/api/agencyUsers`,
    updateuserUrl: `${baseURL}/api/updateUser`,
    forgotpasswordUrl: `${baseURL}/api/forgotPassword`,
    activateusersUrl: `${baseURL}/api/activateUser`,
    adddiscountUrl: `${baseURL}/api/addDiscount`,
    editdiscountUrl: `${baseURL}/api/editDiscount`,
    deletediscountUrl: `${baseURL}/api/inactivateDiscount`,
    addnotificationUrl: `${baseURL}/api/addNotification`,
    editnotificationUrl: `${baseURL}/api/editNotification`,
    deletenotificationUrl: `${baseURL}/api/deleteNotification`,
    mylittleUrl: `${baseURL}/api/myLittle`,
    userInformationUrl: `${baseURL}/api/userInformation`,
    permissionUrl: `${baseURL}/api/permissions`,
    accountsettingsUrl: `${baseURL}/api/accountSettings`,
    userdetailsUrl: `${baseURL}/api/userDetails`,
    photogalleryUrl: `${baseURL}/api/photoGallery`,
    deletephotoUrl: `${baseURL}/api/deletePhoto`,
    resourceslistajaxUrl: `${baseURL}/api/resourcesAjax`,
    addresourceUrl: `${baseURL}/api/addResource`,
    editresourceUrl: `${baseURL}/api/editResource`,
    deleteresourceUrl: `${baseURL}/api/inactivateResource`,
    eventtypesUrl: `${baseURL}/api/eventTypes`,
    eventslistUrl: `${baseURL}/api/eventsList`,
    addeventUrl: `${baseURL}/api/addEvent`,
    editeventUrl: `${baseURL}/api/editEvent`,
    inactivateeventUrl: `${baseURL}/api/inactivateEvent`,
    vieweventUrl: `${baseURL}/api/viewEvent`,
    sendmessage: `${baseURL}/api/sendMessage`,
    recievedmessages: `${baseURL}/api/recievedMessages`,
    deletemessage: `${baseURL}/api/deleteMessage`,
    trashmessages: `${baseURL}/api/deletedMessagesList`,
    sentmessages: `${baseURL}/api/sentMessages`,
    draftsmessages: `${baseURL}/api/draftsMessages`,
    viewmessage: `${baseURL}/api/viewMessage`,
    deleteDiscountdocument: `${baseURL}/api/deleteDiscountdocument`,
    profilepicUpload: `${baseURL}/api/profilepicUpload`,
    getusertypename: `${baseURL}/api/getusertypename`,
    photoDownload: `${baseURL}/api/photoDownload`,
    toListUsers: `${baseURL}/api/toListUsers`,
    caseManager: `${baseURL}/api/caseManagers`,
    notificationLog: `${baseURL}/api/notification_log`,
    chaptersList: `${baseURL}/api/chaptersList`,
    addChapter: `${baseURL}/api/addChapter`,
    myChapters: `${baseURL}/api/myChapters`,
    logout: `${baseURL}/api/logout`,
    viewSettings: `${baseURL}/api/viewSettings`,
    organizationUsers: `${baseURL}/api/organizationUsers`,
    deleteChapter: `${baseURL}/api/deleteChapter`,
    editChapter: `${baseURL}/api/editChapter`,
    editLittle: `${baseURL}/api/editLittle`,
    viewNotificationLog: `${baseURL}/api/viewNotificationLog`,
    createhelp: `${baseURL}/api/createhelp`,
    helpList: `${baseURL}/api/helpList`,
    postFeedback: `${baseURL}/api/postfeedback`,
    getDashboardData: `${baseURL}/api/dashboardData`,
    addActivityData: `${baseURL}/api/addActivity`,
    deleteActivityData: `${baseURL}/api/deleteActivity`,
    changePassword: `${baseURL}/api/changePassword`,
    forgotPassword: `${baseURL}/api/forgotPassword`,
    activitieseventsHistory: `${baseURL}/api/activitieseventsHistory`,
    showSettings: `${baseURL}/api/showSettings`,
    postSettings: `${baseURL}/api/postSettings`,
    resendInvite: `${baseURL}/api/resendInvite`,
    helpList: `${baseURL}/api/helpList`,
    deletehelp: `${baseURL}/api/deletehelp`,
    edithelp: `${baseURL}/api/edithelp`,
    editActivity: `${baseURL}/api/editActivity`,
    deleteResourcedocument: `${baseURL}/api/deleteResourcsdocument`,
    addTag: `${baseURL}/api/addTag`,
    editTag: `${baseURL}/api/editTag`,
    tagsList: `${baseURL}/api/tagsList`,
    deleteTag: `${baseURL}/api/deleteTag`,

    discountsListAjax: `${baseURL}/api/discountsListAjax`,
    activeusersUrlAjax: `${baseURL}/api/activeUsersAjax`,
    bigsusersUrlAjax: `${baseURL}/api/bigsUsersAjax`,
    agencyusersUrlAjax: `${baseURL}/api/agencyUsersAjax`,
    organizationUsersAjax: `${baseURL}/api/organizationUsersAjax`,
    inactiveusersUrlAjax: `${baseURL}/api/inactiveUsersAjax`,
    chaptersListAjax: `${baseURL}/api/chaptersListAjax?`,
    activityListDataAjax: `${baseURL}/api/activitiesListAjax`,
    tagsListAjax: `${baseURL}/api/tagsListAjax?`,
    allEventsListAjax: `${baseURL}/api/allEventsListAjax`,
    helpListAjax: `${baseURL}/api/helpListAjax?`,
    allnotificationsUrlAjax: `${baseURL}/api/allNotificationsAjax`,
    sentnotificationsUrlAjax: `${baseURL}/api/sentNotificationsAjax`,
    schedulednotificationsUrlAjax: `${baseURL}/api/scheduledNotificationsAjax`,
    AllusersphotosAjax: `${baseURL}/api/AllusersphotosAjax`,
    templatesListAjax: `${baseURL}/api/templatesListAjax`,
    mylittleListUrlAjax: `${baseURL}/api/mylittleListAjax`,
    createTemplate: `${baseURL}/api/createTemplate`,
    viewTemplate: `${baseURL}/api/viewTemplate`,
    deleteTemplate:`${baseURL}/api/deleteTemplate`,
    editTemplate:`${baseURL}/api/editTemplate`,
    deleteLittleUrl:`${baseURL}/api/deleteLittle`,
    register:`${baseURL}/api/register`,
    plansList:`${baseURL}/api/plansList`,
    uploadvideo:`${baseURL}/api/uploadvideo`,
    getvideo:`${baseURL}/api/getvideo`,
    switchToFeatures:`${baseURL}/api/switchToFeatures`
}
const apiMethods = {
    postMethod: 'POST',
    getMethod: 'GET',
    putMethod: 'PUT'
}


export const environment = "Dev";

export default {
    apiUrl,
    apiMethods
}