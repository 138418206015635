import React, { Component } from "react";
import { connect } from 'react-redux';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { addResourceForm, resourceFetchInput, editResourceForm } from '../../Store/resources/newResourceaction';
import { withRouter } from 'react-router-dom';
import { Tooltip, Overlay } from 'react-bootstrap'
import HttpService from '../../Services/HttpService';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

var FormData = require('form-data');

const currentuser = JSON.parse(localStorage.getItem('userdata'));
class Newresource extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      title: '',
      description: '',
      files: '',
      active_status: '0',
      image: null,
      chapter_id: '',
      documentsFromEdit: '',
      previewImage: '',
      fileType: '',
      Uid: '',
      resource_id: null,
      showPopover: false,
      eventtarget: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /* Event occurs when the value of an element has been changed */
  handleChange = (e, key) => {
    let resourceInput = {
      key: key,
      value: e.currentTarget.value
    }
    if (key === 'active_status') {
      this.setState({
        active_status: e.currentTarget.value
      })
    }
    if (key === 'title') {
      this.setState({
        title: e.currentTarget.value
      })
    }
    if (key === 'description') {
      this.setState({
        description: e.currentTarget.value
      })
    }
    if (key === 'files') {
      if (e.target.files[0].size < 5e+6) {
        this.setState({
          files: e.target.files[0],
          previewImage: URL.createObjectURL(e.target.files[0]),
          documentsFromEdit: URL.createObjectURL(e.target.files[0])
        })
      }
      else {
        this.onFileSizeAlert()
        e.target.value = null
      }
    }
    this.props.resourceFetchInput(resourceInput);
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user_id;
    this.setState({ Uid: user_id });
    if (this.props.location.state != null) {
      this.setState({
        resource_id: this.props.location.state.detail.resource_id,
        title: this.props.location.state.detail.title,
        description: this.props.location.state.detail.description,
        files: this.props.location.state.detail.files,
        active_status: this.props.location.state.detail.active_status,
        documentsFromEdit: this.props.location.state.detail.files,
        resource_id: this.props.location.state.detail.resource_id
      })
    }
  }
  onErrorAlert() {
    var errorAlert = {
      alertType: 'error'
    }
    handleSwalAlert(errorAlert)
  }
  /* To delete the resource document */
  onDelete = (resource_file_id) => {
    console.log('delete document file', resource_file_id);
    var data = {
      resourcedocument_id: this.props.location.state.detail.documents.resourcedocument_id
    }
    HttpService.deleteResourcedocument(data)
      .then(response => {
        console.log('delete document response', response.data);
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          this.setState({
            spinner: false,
            showPopover: false,
            files: [],
            documentsFromEdit: ''
          })
          var successAlert = {
            message: this.state.deleteResponce.message,
            alertType: "success"
          }
          handleSwalAlert(successAlert)
        }
        else {
          this.setState({
            spinner: false, showPopover: false
          })
          var successAlert = {
            message: this.state.deleteResponce.message,
            alertType: "warning"
          }
          handleSwalAlert(successAlert)
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false,
          spinner: false
        })
        var errorAlert = {
          alertType: 'error'
        }
        handleSwalAlert(errorAlert)
      })
  }
  onFileSizeAlert() {
    var errorAlert = {
      message: 'File size should be less than 5 mb',
      alertType: 'warning'
    }
    handleSwalAlert(errorAlert)
  }
  /* For choose the file from device */
  onChange(e) {
    if (e.target.files[0].size < 5e+6) {
      this.setState({
        files: e.target.files[0],
        previewImage: URL.createObjectURL(e.target.files[0]),
        documentsFromEdit: URL.createObjectURL(e.target.files[0])
      })
    }
    else {
      this.onFileSizeAlert()
      e.target.value = null
    }
  }
  /* To call api to submit the values */
  handleSubmit(e) {
    e.preventDefault();
    const { addResource } = this.props;
    const { Uid } = this.state
    if (this.props.location.state != null) {
      if (this.validator.allValid()) {
        var data = new FormData();
        data.append('title', this.state.title);
        data.append('description', this.state.description);
        data.append('active_status', this.state.active_status);
        data.append('resource_id', this.state.resource_id);
        if (this.state.files.toString().startsWith("http://")) {
          // data.append('files', this.state.files);
        }
        else {
          data.append('files', this.state.files);
        }
        this.props.editResourceForm(data);

        var successAlert = {
          message: "Resource Updated Successfully",
          alertType: "success",
          closeOnEsc: false,
          dangerMode: true,
          closeOnClickOutside: false,
          goToList: true,
          pathname: '/dashboard/resources',
          propsData: this.props
        }
        handleSwalAlert(successAlert)
      }
      else {
        this.state.spinner = false
        this.validator.showMessages();
      }
    }
    else {
      if (!this.validator.allValid()) {
        this.state.spinner = false
        this.validator.showMessages();
      }
      else {
        var data = new FormData();
        data.append('title', addResource.resourceDetails.title);
        data.append('description', addResource.resourceDetails.description);
        data.append('active_status', this.state.active_status);
        data.append('files', this.state.files);
        this.props.addResourceForm(data);
        var successAlert = {
          message: "Resource added Successfully",
          alertType: "success",
          closeOnEsc: false,
          dangerMode: true,
          closeOnClickOutside: false,
          goToList: true,
          pathname: '/dashboard/resources',
          propsData: this.props
        }
        handleSwalAlert(successAlert)
      
        this.setState({
          title: '',
          description: '',
          files: '',
          active_status: '',
          image: null
        })
        this.resourceForm.reset();
      }
    }
  }
  /* Goto the list of resources */
  onCancel() {
    this.props.history.push('/dashboard/resources');
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    if (nextProps.addResource.resource_res.success === true) {
    }
    else if (nextProps.addResource.resource_res.success === false) {
      swal('please fill the required fields');
    }
  }
  /* Goto the resource list */
  onResources() {
    this.props.history.push('/dashboard/resources');
  }
  /* To show the pop up */
  showPopoverDialog = (e, did) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      discount_id: did
    })
  }
  /* TO close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  /* render() function is used to update the UI. */
  render() {
    const { showPopover } = this.state
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    return (
      <div>
        <form className="form" ref={form => { this.resourceForm = form }} onSubmit={this.handleSubmit}>
          <div className="container">
            <Row className="mt-3 mb-4">
              <Col xs lg="12">
                <div className="horizontal-container">
                  <label className="label-discount" onClick={() => this.onResources()}>Resources</label>
                  <i className="fa fa-chevron-right right-arrow" aria-hidden="true"></i>

                  <label className="label-discount">New Resource</label>
                </div>
              </Col>
            </Row>
            <section className="newuser-sec p-4">
              <Row>
                <Col xg lg='6'>
                  <Row>
                    <Col xg lg='12'>
                      <div className="mb-3">
                        <div>
                          <label className="lehead" defaultValue={this.state.active_status}>Status<span>*</span></label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline" >
                          <input type="radio" className="radio" id="active_status"
                            name="active_status" value="0" checked={this.state.active_status == '0'}
                            onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} />
                          <label className="custom-control-label" htmlFor="active_status" defaultChecked>Active</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input type="radio" className="radio" id="in_active_status" name="active_status" value="1"
                            onChange={(e) => this.handleChange(e, 'active_status')} onBlur={this.handleChange} checked={this.state.active_status == '1'} />
                          <label className="custom-control-label" htmlFor="in_active_status">InActive</label>
                        </div>
                        <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                      </div>
                    </Col>
                    <Col xg lg='12'>
                      <div className="form-group">
                        <label for="title" className="lehead">Title:<span>*</span></label>
                        <input className="form-control" type="text" id="title" name="title" defaultValue={this.state.title} onChange={(e) => this.handleChange(e, 'title')} onBlur={this.handleChange} />
                        <span className="text-danger">{this.validator.message("title", this.state.title, "required")}</span>
                      </div>
                    </Col>
                    <Col xg lg='12'>
                      <div className="mb-3">
                        <div className="form-group">
                          <label for="description" className="lehead">Description:<span>*</span></label>
                          <textarea id="description" name="description" defaultValue={this.state.description} className="form-control" rows="5"
                            onChange={(e) => this.handleChange(e, 'description')} onBlur={this.handleChange} />
                          <span className="text-danger">{this.validator.message("description", this.state.description, "required")}</span>
                        </div>
                      </div>
                    </Col>
                    <Col xg lg='12'>
                      <div className="form-group">
                        <label className="lehead">Files:</label>
                        <div className="d-flex">
                          {console.log("file name:- ", this.state.documentsFromEdit)}
                          {this.state.documentsFromEdit.length == 0 ? null :
                            <div className="discountsgallery-sec photogallery-sec mr-2">
                              <div className="row">
                                <div className="containerdiscount">
                                  <img style={{ border: "2px solid gray", width: '150px', height: '82px', margin: '5px' }} className="" src={this.state.documentsFromEdit} />
                                  <div className="overlayresources">
                                    <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                      <Tooltip>
                                        <div className='text-center p-4'>
                                          <p className='tool-title'>Delete Resource file</p>
                                          <p className='tool-para'>Are you sure you want to delete this file? Deleted files may not be restored.</p>
                                        </div>
                                        <div className='d-flex btn-sec'>
                                          <button onClick={() => this.onDelete(this.state.documentsFromEdit)} className='tool-btn-yes'>Yes, Delete</button>
                                          <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                        </div>
                                      </Tooltip>
                                    </Overlay>
                                    <div className="discount-img-ontop">
                                      <a href={this.state.documentsFromEdit} target="_blank" ><i class="fa fa-eye" aria-hidden="true" /></a>
                                      <i class="fa fa-trash" aria-hidden="true" onClick={(e) => this.showPopoverDialog(e, this.state.documentsFromEdit)}></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                          <div id="wrapper" className="mt-2">
                            <div id="uploaded-image-left">
                            </div>
                            <div id="upload-right">
                              <div className="profile-upload rect">
                                <label className="custom-file-upload rectangle">
                                  <input type="file" name="documents" defaultValue={this.state.files} className="form-control" multiple onChange={(e) => this.handleChange(e, 'files')} />
                                  <i className="fas fa-plus"></i>
                                </label>
                                <label className="up-lbl">Upload an image or PDF</label><br />
                                <span className="up-span">(5MB Limit)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="my-4">
                <Col xs lg="6">
                  <div className="save-sec">
                    <button type="submit" className="btn btn-info btn-success mt-1" onClick={(e) => { this.handleSubmit(e) }}>Save</button>
                  </div>
                </Col>
                <Col xs lg="6">
                  <div className="cancel-sec">
                    <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                  </div>
                </Col>
              </Row>
            </section>
          </div>
        </form>
      </div>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    addResource: state.addResource
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { addResourceForm, resourceFetchInput, editResourceForm })(withRouter(Newresource));
