import React, { Component } from 'react';
import { connect } from 'react-redux'
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { EventFetchInput, addEventForm, editEventtForm, getEventTypes } from '../../Store/events/addEventAction';
import { NotificationLogList, myChapterList } from '../../Store/notifications/notificationLogaction';
import { getEventTagList } from '../../Store/events/eventsListAction';
import HttpService from './../../Services/HttpService'
import { Multiselect } from 'multiselect-react-dropdown';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

class AddEvent extends Component {
    constructor() {
        super()
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            input: {},
            Uid: '',
            eventtypeid: '1',
            event_name: '',
            chapterid: '',
            description: '',
            prerequisites: '',
            disclaimer: '',
            start_date: "",
            end_date: '',
            noof_seats: '',
            available_seats: '',
            notes: '',
            paid_event: '0',
            self_register: '0',
            send_notifications: '0',
            start_time: '',
            end_time: '',
            organized_by: 'agency',
            myChaptersListData: [],
            userslogindetails: {},
            selectedChapterIds: [],
            sent_to: 'all',
            registered_seats: 0,
            active_status: 0,
            chapter_type: '',
            tagListData: [],
            selectedTagId: '',
            selectedTagName: ''

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /* To make the api call initial page load */
    componentDidMount() {
        this.state.organized_by = this.props.location.state.from
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        var user_id = this.currentuser.user_id;
        this.setState({ Uid: user_id });
        this.props.getEventTagList()
        if (this.state.userslogindetails.user.usertypeid == 5 || this.state.userslogindetails.user.usertypeid == 4) {
            HttpService.chapterslist()
                .then(response => {
                    this.setState({
                        myChaptersListData: response.data.chapters_list
                    })
                })
                .catch(error => {
                    this.onErrorAlert()
                })
        }
        else {
            HttpService.myChaptersService()
                .then(response => {
                    this.setState({
                        myChaptersListData: response.data.chapters_list
                    })
                })
                .catch(error => {
                    this.onErrorAlert()
                })
        }
        if (this.props.location.state.detail != null) {
            this.state.organized_by = this.props.location.state.detail.organized_by
            this.state.event_id = this.props.location.state.detail.event_id
            this.state.active_status = this.props.location.state.detail.active_status
            this.state.eventtypeid = this.props.location.state.detail.eventtypeid
            this.state.event_name = this.props.location.state.detail.event_name
            this.state.registered_seats = this.props.location.state.detail.registered_seats
            this.state.sent_to = this.props.location.state.detail.chapter_type
            this.state.selectedTagId = this.props.location.state.detail.tag_id
            this.state.selectedTagName = this.props.location.state.detail.tag_name
            this.state.selectedChapterIds = this.props.location.state.detail.chapterid?.map((ids) => {
                return { chapter_name: ids.chapter_name, chapter_id: ids.chapterid }
            })
            this.state.cids = this.state.selectedChapterIds?.map((cid) => {
                return cid.chapterids
            })
            this.state.description = this.props.location.state.detail.description
            this.state.prerequisites = this.props.location.state.detail.prerequisites
            this.state.disclaimer = this.props.location.state.detail.disclaimer
            this.state.start_date = this.props.location.state.detail.start_date
            this.state.end_date = this.props.location.state.detail.end_date
            this.state.noof_seats = this.props.location.state.detail.noof_seats
            this.state.notes = this.props.location.state.detail.notes
            this.state.paid_event = this.props.location.state.detail.paid_event
            this.state.self_register = this.props.location.state.detail.self_register
            this.state.send_notifications = this.props.location.state.detail.send_notifications
            this.state.active_status = this.props.location.state.detail.active_status
            this.state.start_time = this.props.location.state.detail.start_time
            this.state.end_time = this.props.location.state.detail.end_time
        }
    }
    /* Event occurs when the value of an element has been changed */
    handleChange = (e, key) => {
        const { Uid } = this.state
        let eventInput = {
            key: key,
            value: e.currentTarget.value
        }
        if (key === 'organized_by') {
            this.state.organized_by = e.currentTarget.value
            this.setState({
                organized_by: e.currentTarget.value
            })
        }
        if (key === 'eventtypeid') {
            this.setState({
                eventtypeid: e.currentTarget.value
            })
        }
        if (key === 'sent_to') {
            this.setState({ sent_to: e.currentTarget.value })

        }
        if (key === 'active_status') {
            this.setState({
                active_status: e.currentTarget.value
            })
        }
        if (key === 'event_name') {
            this.setState({
                event_name: e.currentTarget.value
            })
        }
        if (key === 'description') {
            this.setState({
                description: e.currentTarget.value
            })
        }
        if (key === 'prerequisites') {
            this.setState({
                prerequisites: e.currentTarget.value
            })
        }
        if (key === 'disclaimer') {
            this.setState({
                disclaimer: e.currentTarget.value
            })
        }
        if (key === 'start_date') {

            if (Date.parse(this.state.end_date) <= Date.parse(e.currentTarget.value)) {
                e.currentTarget.value = null
                this.setState({
                    start_date: null
                })
                alert('Start date should be lesser than end date.')
            }
            else {
                this.setState({
                    start_date: e.currentTarget.value
                })
            }
        }
        if (key === 'end_date') {
            if (this.state.eventtypeid === '1') {
                if (Date.parse(e.currentTarget.value) > Date.parse(this.state.start_date)) {
                    e.currentTarget.value = null
                    this.setState({
                        end_date: null
                    })
                    alert('End date should be same date.')
                }
                if (Date.parse(e.currentTarget.value) < Date.parse(this.state.start_date)) {
                    e.currentTarget.value = null
                    this.setState({
                        end_date: null
                    })
                    alert('End date should be same date.')
                }
                else {
                    this.setState({
                        end_date: e.currentTarget.value
                    })
                }
            }
            else {
                if (Date.parse(e.currentTarget.value) <= Date.parse(this.state.start_date)) {
                    e.currentTarget.value = null
                    this.setState({
                        end_date: null
                    })
                    alert('End date should be greater than start date.')
                }
                else {
                    this.setState({
                        end_date: e.currentTarget.value
                    })
                }
            }
        }
        if (key === 'noof_seats') {
            this.setState({
                noof_seats: e.currentTarget.value
            })
        }
        if (key === 'available_seats') {
            this.setState({
                available_seats: e.currentTarget.value
            })
        }
        if (key === 'start_time') {
            this.setState({
                start_time: e.currentTarget.value
            })
        }
        if (key === 'end_time') {
            if (this.state.eventtypeid == '1' && e.currentTarget.value < this.state.start_time) {
                alert('Please select greater than start time')
                e.currentTarget.value = null
            }
            else {
                this.setState({
                    end_time: e.currentTarget.value
                })
            }
        }
        if (key === 'paid_event') {
            this.setState({
                paid_event: e.currentTarget.value
            })
        }
        if (key === 'self_register') {
            this.setState({
                self_register: e.currentTarget.value
            })
        }
        if (key === 'send_notifications') {
            this.setState({
                send_notifications: e.currentTarget.value
            })
        }
        if (key === 'notes') {
            this.setState({
                notes: e.currentTarget.value
            })
        }
        if (key === 'tags') {
            this.setState({
                selectedTagId: e.currentTarget.value
            })
        }
        this.props.EventFetchInput(eventInput);
    }
    onErrorAlert() {
        var errorAlert = {
            alertType: 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /* Calling the api to Submit the values */
    handleSubmit = (e) => {
        e.preventDefault();
        const { addEvent } = this.props
        const { Uid } = this.state

        if (this.props.location.state.detail != null) {
            if (this.validator.allValid()) {
                if (this.state.noof_seats >= this.state.registered_seats) {
                    var data = {
                        organized_by: this.state.organized_by,
                        event_id: this.state.event_id,
                        eventtypeid: this.state.eventtypeid,
                        active_status: this.state.active_status,
                        event_name: this.state.event_name,
                        chapterids: this.state.sent_to == 'selected' ? this.state.selectedChapterIds.map(id => (
                            id.chapter_id
                        )) : 'all',
                        description: this.state.description,
                        prerequisites: this.state.prerequisites,
                        disclaimer: this.state.disclaimer,
                        start_date: this.state.start_date,
                        end_date: this.state.end_date,
                        start_time: this.state.start_time,
                        end_time: this.state.end_time,
                        noof_seats: this.state.noof_seats,
                        notes: this.state.notes,
                        created_userid: Uid,
                        paid_event: this.state.paid_event,
                        self_register: this.state.self_register,
                        send_notifications: this.state.send_notifications,
                        active_status: this.state.active_status,
                        tag_id: this.state.selectedTagId
                    };
                    this.props.editEventtForm(data);
                    swal({
                        text: "Event updated Successfully",
                        icon: "success",
                        closeOnEsc: false,
                        dangerMode: true,
                        closeOnClickOutside: false
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                if (this.state.organized_by == 'bigs') {
                                    this.props.history.push({
                                        pathname: '/dashboard/bigsconnect_community',
                                        state: 'list'
                                    })
                                }
                                else {
                                    this.props.history.push({
                                        pathname: '/dashboard/agencysponsored_events',
                                        state: 'list'
                                    })
                                }
                            }
                        });
                }
                else {
                    alert('Number of seats should be grater than registered seats')
                }
            }
            else {
                this.validator.showMessages();
            }
        }
        else {
            if (!this.validator.allValid()) {
                this.validator.showMessages();
            }
            else {
                var data = {
                    organized_by: this.state.organized_by,
                    event_id: this.state.event_id,
                    eventtypeid: this.state.eventtypeid,
                    active_status: this.state.active_status,
                    event_name: this.state.event_name,
                    chapterids: this.state.selectedChapterIds.length != 0 ? this.state.selectedChapterIds.map(id => (
                        id.chapter_id
                    )) : 'all',
                    description: this.state.description,
                    prerequisites: this.state.prerequisites,
                    disclaimer: this.state.disclaimer,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date,
                    start_time: this.state.start_time,
                    end_time: this.state.end_time,
                    noof_seats: this.state.noof_seats,
                    notes: this.state.notes,
                    created_userid: Uid,
                    paid_event: this.state.paid_event,
                    self_register: this.state.self_register,
                    send_notifications: this.state.send_notifications,
                    active_status: this.state.active_status,
                    tag_id: this.state.selectedTagId
                };
                this.props.addEventForm(data);
                swal({
                    text: "Event Added Successfully",
                    icon: "success",
                    closeOnEsc: false,
                    dangerMode: true,
                    className: 'label-bold-gray',
                    closeOnClickOutside: false
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            if (addEvent.addEventDetails.organized_by == 'bigs') {
                                this.props.history.push({
                                    pathname: '/dashboard/bigsconnect_community'
                                })
                            }
                            else {
                                this.props.history.push({
                                    pathname: '/dashboard/agencysponsored_events'
                                })
                            }
                        }
                    });
                this.eventform.reset();
                this.setState({
                    organized_by: '',
                    eventtypeid: '',
                    event_name: '',
                    chapterid: '',
                    description: '',
                    prerequisites: '',
                    disclaimer: '',
                    start_date: "",
                    end_date: '',
                    noof_seats: '',
                    available_seats: '',
                    notes: '',
                    paid_event: '',
                    self_register: '',
                    send_notifications: '',
                    start_time: "",
                    end_time: ""
                })
                this.validator.hideMessages();
            }
        }
    }
    /**Go to agency sponsored events */
    onCancel() {
        if (this.state.organized_by === 'agency') {
            this.props.history.push('/dashboard/agencysponsored_events');
        }
        else {
            this.props.history.push('/dashboard/bigsconnect_community');
        }
    }
    /* To select the chapter from multiselect drop down*/
    onSelectItem = (selected, ii) => {
        const chapter_ids = this.state.selectedChapterIds;

        if (chapter_ids == 0) {
            this.state.selectedChapterIds = selected.map((id) => {
                return { chapter_name: id.chapter_name, chapter_id: id.chapter_id }
            })
        }
        else {
            chapter_ids.push(+selected)
            this.state.selectedChapterIds = selected.map((pushedcids => {
                return { chapter_id: pushedcids.chapter_id }
            }))
        }
    }
    /* To remove the chapters from multiselection drop down */
    onRemove = (removeusers, removeitem, yyy) => {
        const remove_chapter_ids = this.state.selectedChapterIds
        let index
        index = remove_chapter_ids.indexOf(-removeitem.chapter_id)
        remove_chapter_ids.splice(index, 1)
        this.state.selectedChapterIds = remove_chapter_ids
    }
    /*To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.state.tagListData = nextProps.tagsList.eventtags
    }
    /* Goto agency and bigs events list */
    onEvents() {
        if (this.state.organized_by == 'agency') {
            this.props.history.push('/dashboard/agencysponsored_events');
        }
        else {
            this.props.history.push('/dashboard/bigsconnect_community');
        }
    }
    disablepastdays() {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }
    /* To creating, updating the views. */
    render() {
        this.state.userslogindetails = JSON.parse(localStorage.getItem('userdata'))

        return (
            <form className="form" ref={form => this.eventform = form}>
                <div className="container">
                    <Row className="row mt-3 mb-4">
                        <Col md="12">
                            <div className="horizontal-container">
                                <label className="label-discount" onClick={() => this.onEvents()}>Events</label>
                                <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                                {this.props.location.state.detail ?
                                    <label className="label-discount">Edit {this.state.event_name}</label> : <div className="horizontal-container">
                                        <label className="label-discount">New Event</label>
                                    </div>}
                            </div>
                        </Col>
                    </Row>
                    <section className="newuser-sec">
                        <Row>
                            <Col md="6">
                                <div className="mb-3">
                                    <label className="lehead">Event Organizers<span>*</span></label>
                                </div>
                                <Row>
                                    <Col md="6" className="mb-3">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" id="agency" name='organized_by' value='agency' checked={this.state.organized_by == 'agency'}
                                                onChange={(e) => this.handleChange(e, 'organized_by')} />
                                            <label className="custom-control-label" htmlFor="agency"> &nbsp;Agency Sponsored Events  &nbsp;&nbsp; </label>
                                        </div>
                                    </Col>
                                    <Col md="6" className="mb-3">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" id="bigs" name="organized_by" value='bigs' checked={this.state.organized_by == 'bigs'}
                                                onChange={(e) => this.handleChange(e, 'organized_by')} />
                                            <label className="custom-control-label" htmlFor="bigs"> &nbsp;BigS Connect Community   &nbsp;&nbsp;</label>
                                        </div>
                                    </Col>
                                </Row>
                                <span className="text-danger">{this.validator.message("organized_by", this.state.organized_by, "required")}</span>
                            </Col>
                            <Col md="4">
                                <div>
                                    <div className="mb-3">
                                        <label className="lehead">Status<span>*</span></label>
                                    </div>
                                    <select id="dropdown" value={this.state.active_status} onChange={(e) => this.handleChange(e, 'active_status')} className="form-control">
                                        <option value="0" onChange={(e) => this.handleChange(e, 'active_status')}>Active</option>
                                        <option value="2" onChange={(e) => this.handleChange(e, 'active_status')}>Cancel</option>
                                        <option value="1" onChange={(e) => this.handleChange(e, 'active_status')}>InActive</option>
                                    </select>
                                    <span className="text-danger">{this.validator.message("active_status", this.state.active_status, "required")}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <div className="mb-3">
                                <label className="lehead">Event Type<span>*</span></label>
                            </div>
                            <Col md="2">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="radio" id="one" name='event_type' value='1' checked={this.state.eventtypeid == '1'}
                                        onChange={(e) => this.handleChange(e, 'eventtypeid')} />
                                    <label className="custom-control-label" htmlFor="one"> &nbsp;One Time  &nbsp;&nbsp; </label>
                                </div>
                            </Col>
                            <Col md="2">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="radio" id="recurring-two" name="event_type" value='2' checked={this.state.eventtypeid == '2'}
                                        onChange={(e) => this.handleChange(e, 'eventtypeid')} />
                                    <label className="custom-control-label" htmlFor="recurring-two"> &nbsp;Recurring   &nbsp;&nbsp;</label>
                                </div>
                            </Col>
                            <Col md="2">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="radio" id="multi-three" name="event_type" value='3' checked={this.state.eventtypeid == '3'}
                                        onChange={(e) => this.handleChange(e, 'eventtypeid')} />
                                    <label className="custom-control-label" htmlFor="multi-three"> &nbsp;Multi-Day  &nbsp;&nbsp;</label>
                                </div>
                            </Col>
                            <span className="text-danger">{this.validator.message("eventtypeid", this.state.eventtypeid, "required")}</span>
                        </Row>
                        <Row className="mt-5">
                            <Col md="3">
                                <div>
                                    <label className='lehead'>Select Tag</label>
                                    <div>
                                        <select value={this.state.selectedTagId} onChange={(e) => this.handleChange(e, 'tags')} style={{ width: '200px', height: '40px', padding: '5px' }} >
                                            <option >Select Tags</option>
                                            {this.state.tagListData?.map((e) => {
                                                return <option value={e.tag_id}>{e.tag_name}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md="3">
                                <input checked={this.state.sent_to == 'all'} type="radio" id="defaultRadio" name="sent_to" value='all' onChange={(e) => this.handleChange(e, 'sent_to')} />
                                <label for="defaultRadio" className='lehead'>All Chapters</label>
                            </Col>
                            <Col md="3">
                                <input checked={this.state.sent_to == "selected"} type="radio" id="defaultRadioone" name="sent_to" value="selected" onChange={(e) => this.handleChange(e, 'sent_to')} />
                                <label className='lehead' for="defaultRadioone">Select Chapters</label>
                                {this.state.sent_to == 'selected' ? <>
                                    {this.state.userslogindetails.user.usertypeid == 5 || this.state.userslogindetails.user.usertypeid == 4 ?
                                        <Multiselect placeholder="Select Chapters" options={this.state.myChaptersListData} displayValue={'chapter_name'}
                                            onSelect={this.onSelectItem} onRemove={this.onRemove} selectedValues={this.state.selectedChapterIds}
                                            style={{
                                                chips: { 'border': 'none', 'borderRadius': '15px' },
                                                multiselectContainer: { color: '#8f9199', width: '250px' },
                                                searchBox: { 'width': '250px', 'border': '1px solid gray', 'borderRadius': '5px' }
                                            }}
                                        /> : <Multiselect placeholder="Select Chapters" options={this.state.myChaptersListData} displayValue={'chapter_name'}
                                            onSelect={this.onSelectItem} onRemove={this.onRemove} selectedValues={this.state.chapterid}
                                            style={{
                                                chips: { 'border': 'none', 'borderRadius': '15px' },
                                                multiselectContainer: { color: '#8f9199', width: '250px' },
                                                searchBox: { 'width': '250px', 'border': '1px solid gray', 'borderRadius': '5px' }
                                            }}
                                            className="multiselect" />}</> : null}
                            </Col>
                        </Row>
                        <Row className="mb-3 mt-5">
                            <Col md="3">
                                <div className="form-group">
                                    <label for="event_name" className="lehead">Event Name<span>*</span></label>
                                    <input type="text" id="event_name" className="form-control" name="event_name" defaultValue={this.state.event_name} onChange={(e) => this.handleChange(e, 'event_name')} />
                                </div>
                                <span className="text-danger">{this.validator.message("event_name", this.state.event_name, "required")}</span>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label for="description" className="lehead">Description<span>*</span></label>
                                    <textarea id="description" className="form-control" name="description" defaultValue={this.state.description} onChange={(e) => { this.handleChange(e, 'description') }} />
                                </div>
                                <span className="text-danger">{this.validator.message("description", this.state.description, "required")}</span>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label for="prerequisites" className="lehead">Prerequisites<span>*</span></label>
                                    <textarea id="prerequisites" className="form-control" name="prerequisites" defaultValue={this.state.prerequisites} onChange={(e) => { this.handleChange(e, 'prerequisites') }} />
                                </div>
                                <span className="text-danger">{this.validator.message("prerequisites", this.state.prerequisites, "required")}</span>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="3">
                                <div className="form-group">
                                    <label for="disclaimer" className="lehead">Disclaimer<span></span></label>
                                    <textarea id="disclaimer" className="form-control" name="disclaimer" defaultValue={this.state.disclaimer} onChange={(e) => { this.handleChange(e, 'disclaimer') }} />
                                </div>
                            </Col>

                            <Col md="3">
                                <div className="form-group">
                                    <label for="start_date" className="lehead">Start Date<span>*</span> </label>
                                    <input type="Date" id="start_date" onKeyDown={(e) => e.preventDefault()} name="start_date" defaultValue={this.state.start_date} className="form-control"
                                        min={this.disablepastdays()} onChange={(e) => this.handleChange(e, 'start_date')} />
                                    <span className="text-danger">{this.validator.message("start_date", this.state.start_date, "required")}</span>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label for="end_date" className="lehead">End Date<span>*</span></label>
                                    <input type="date" id="end_date" onKeyDown={(e) => e.preventDefault()} name="end_date" defaultValue={this.state.end_date} className="form-control"
                                        min={this.disablepastdays()} onChange={(e) => this.handleChange(e, 'end_date')} />
                                    <span className="text-danger">{this.validator.message("end_date", this.state.end_date, "required")}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="3">
                                <div className="form-group">
                                    <label for="start_time" className="lehead">Start Time<span>*</span></label>
                                    <input type="time" onKeyDown={(e) => e.preventDefault()} id="start_time" name="start_time" className="form-control"
                                        defaultValue={this.state.start_time} onChange={(e) => this.handleChange(e, 'start_time')} />
                                    <span className="text-danger">{this.validator.message("start_time", this.state.start_time, "required")}</span>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label for="end_time" className="lehead">End Time<span>*</span></label>
                                    <input type="time" onKeyDown={(e) => e.preventDefault()} id="end_time" name="end_time" className="form-control"
                                        defaultValue={this.state.end_time} onChange={(e) => this.handleChange(e, 'end_time')} />
                                    <span className="text-danger">{this.validator.message("end_time", this.state.end_time, "required")}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="3">
                                <div className="form-group">
                                    <label for="no_of_seats" className="lehead">Number of seats<span>*</span></label>
                                    <input type="number" id="no_of_seats" className="form-control" name="no_of_seats" defaultValue={this.state.noof_seats} onChange={(e) => this.handleChange(e, 'noof_seats')} />
                                    <span className="text-danger">{this.validator.message("noof_seats", this.state.noof_seats, "required")}</span>
                                </div>
                            </Col>
                            {this.props.location.state ? <Col md="3">
                                <div className="form-group">
                                    <label className="lehead">Registered Seats</label>
                                    <input readOnly className="form-control" name="registers_seats" value={this.state.registered_seats}></input>

                                </div>
                            </Col> : null}
                            <Col md="3">
                                <div className="form-group">
                                    <label for="notes" className="lehead">Notes<span></span></label>
                                    <textarea id="notes" className="form-control" name="notes" defaultValue={this.state.notes} onChange={(e) => this.handleChange(e, "notes")}></textarea>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="3">
                                <div className="form-group">
                                    <div className="mb-3">
                                        <div>
                                            <label className="lehead">Paid Event<span>*</span></label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" name='paid_event' value='0'
                                                checked={this.state.paid_event == '0'} id="paidevent-yes" onChange={(e) => this.handleChange(e, 'paid_event')} />
                                            <label className="custom-control-label" htmlFor="paidevent-yes"> &nbsp;Yes &nbsp;&nbsp; </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="radio" name="paid_event" value='1' checked={this.state.paid_event == '1'}
                                                id="paidevent-no" onChange={(e) => this.handleChange(e, 'paid_event')} />
                                            <label className="custom-control-label" htmlFor="paidevent-no"> &nbsp;No &nbsp;&nbsp;</label>
                                        </div>
                                        <span className="text-danger">{this.validator.message("paid_event", this.state.paid_event, "required")}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <div>
                                        <label className="lehead">Send Notification<span>*</span></label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="radio" id="noti-test" name='send_yes' value='0'
                                            checked={this.state.send_notifications == '0'} onChange={(e) => this.handleChange(e, 'send_notifications')} />
                                        <label className="custom-control-label" htmlFor="noti-test"> &nbsp;Yes &nbsp;&nbsp;</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="radio" id="notify-no" name="send_no" value='1'
                                            checked={this.state.send_notifications == '1'} onChange={(e) => this.handleChange(e, 'send_notifications')} />
                                        <label className="custom-control-label" for="notify-no"> &nbsp;No &nbsp;&nbsp;</label>
                                    </div>
                                    <span className="text-danger">{this.validator.message("send_notifications", this.state.send_notifications, "required")}</span>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <div>
                                        <label className="lehead">Self Register<span>*</span></label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="radio" id="register-yes" name='self_register' value='0'
                                            checked={this.state.self_register == '0'} onChange={(e) => this.handleChange(e, 'self_register')} />
                                        <label className="custom-control-label" for="register-yes"> &nbsp;Yes &nbsp;&nbsp;</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="radio" id="register-no" name="self_register" value='1'
                                            checked={this.state.self_register == '1'} onChange={(e) => this.handleChange(e, 'self_register')} />
                                        <label className="custom-control-label" for="register-no">&nbsp;No &nbsp;&nbsp;</label>
                                    </div>
                                    <span className="text-danger">{this.validator.message("self_register", this.state.self_register, "required")}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col md="6" className="save-sec">
                                <button type="submit" className="btn btn-info btn-success mt-1" onClick={this.handleSubmit}>Save</button>
                            </Col>
                            <Col md="6">
                                <div className="cancel-sec">
                                    <button type="cancel" className="cancelbtnnew" onClick={() => this.onCancel()}>Cancel</button>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </form>
        );
    }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
    return {
        addEvent: state.addEvent,
        tagsList: state.eventsList.tagListData
    }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
    addEventForm,
    EventFetchInput,
    editEventtForm,
    getEventTypes,
    NotificationLogList,
    myChapterList,
    getEventTagList
})(AddEvent);

