import { TEMPLATE_FETCH_INPUT,TEMPLATE_ADD,TEMPLATE_ADD_ERROR } from "../types";

let initialState = {
    templateDetails: {},   
    template_res: '',
    edittemplate_res:'',
    deletetemplate_res:'',
    error: false,
}

export default function addTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case TEMPLATE_FETCH_INPUT:
            return {
                ...state,
                templateDetails: {
                    ...state.templateDetails,
                    [action.Input.key]: action.Input.value
                }
            }
        case TEMPLATE_ADD:
            return {
                ...state,
                template_res: action.response
            }
            case TEMPLATE_ADD_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}