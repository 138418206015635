import { TEMPLATE_FETCH_INPUT,TEMPLATE_ADD,TEMPLATE_ADD_ERROR } from "../types";
import HttpService from '../../Services/HttpService';

export const templateFetchInput = (Input) => {
    return {
        type: TEMPLATE_FETCH_INPUT,
        Input
    }
}

export const templateForm = (response) => {
    return {
        type: TEMPLATE_ADD,
        response
    }
}


export const templateErrorForm = () => {
    return {
        type: TEMPLATE_ADD_ERROR
    }
}

export const createTemplate = (template_id) => {
    return (dispatch) => {
        HttpService.createTemplate(template_id)
            .then(response => {
                console.log('response',response);
                dispatch(templateForm(response.data));
                console.log(response.data);
            })
            .catch(error => {
                dispatch(templateErrorForm());
            })
    }
}

export const editTemplate = (template_id) => {
    return (dispatch) => {
        HttpService.editTemplate(template_id)
            .then(response => {
                console.log('response',response);
                dispatch(templateForm(response.data));
                console.log(response.data);
            })
            .catch(error => {
                dispatch(templateErrorForm());
            })
    }
}

