import React, { Component } from "react";
import { viewTemplate } from '../../../Store/template/viewTemplateoneAction';
import { connect } from 'react-redux';
import ReactReadMoreReadLess from "react-read-more-read-less";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
class BodyConent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewData: {}

    }
  }
 /* render() function is used to update the UI. */
  render() {
    var settingsdiscounts = {
      dots: true,
      infinite: JSON.parse(
        localStorage.getItem("ViewDiscountData")
      )?.discounts.length > 4,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    };
    var settingsgallery = {
      dots: true,
      infinite: JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery[0]?.length > 4,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    }
    var settingsevents = {
      dots: true,
      infinite: JSON.parse(
        localStorage.getItem("ViewTemplateData")
      )?.template.events.length > 3,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    }
    this.state.viewData = JSON.parse(localStorage.getItem('ViewTemplateData'))
    return (
      <>
        <div className="captalise">
          <section id="team" class="team">
            <div class="container" data-aos="fade-up">

              <div class="section-title">
                <h2>{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.teams?.team_title}</h2>
                <p> <ReactReadMoreReadLess
                  charLimit={300}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.teams?.team_description}
                </ReactReadMoreReadLess></p>
              </div>

              <div class="row">
                {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.teams?.team_details.map((team, index) => {
                  return <>
                    <div class="col-lg-6 mt-4">
                      <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                        <div class="pic"><img src={team.profile_pic} alt="" /></div>
                        <div class="member-info">
                          <h4>{team.name}</h4>
                          <span>{team.designation}</span>
                          <p> <ReactReadMoreReadLess
                            charLimit={100}
                            readMoreText={"Read more ▼"}
                            readLessText={"Read less ▲"}
                          >
                            {team?.about}
                          </ReactReadMoreReadLess></p>
                          <div class="social">
                            <a href=""><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            <a href=""><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            <a href=""><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            <a href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                })}
              </div>
            </div>
          </section>
          <section id="cta" class="cta">
            <div class="">
              <div className="img-sec">

                <img src={JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.actions?.action_files} className="cta-img" />

                <div class="row overlay" >
                  <div class="col-lg-9 text-center text-lg-start">
                    <h3>{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.actions?.action_title}</h3>
                    <p>{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.actions?.action_description}</p>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <section id="services" class="services section-bg">
            <div class="container" data-aos="fade-up">

              <div class="section-title">
                <h2>{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.ourservice?.ourservice_name}</h2>
                <p> <ReactReadMoreReadLess
                  charLimit={300}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.ourservice?.ourservice_description}
                </ReactReadMoreReadLess></p>
              </div>

              <div class="row">

                {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.ourservice?.ourservice_details.map((services, index) => {
                  return <>
                    <div class="col-xl-3 col-md-6 mt-4 mt-md-0" >
                      <div class="icon-box">
                        <div class="icon"><img src={services.service_details_icon} width="100%" /></div>
                        <h4><a href="">{services.service_details_title}</a></h4>
                        <p> <ReactReadMoreReadLess
                          charLimit={100}
                          readMoreText={"Read more ▼"}
                          readLessText={"Read less ▲"}
                        >
                          {services?.service_details_description}
                        </ReactReadMoreReadLess></p>
                      </div>
                    </div>
                  </>
                })}
              </div>
            </div>
          </section>
          <section id="why-us" class="why-us">
            <div class="container-fluid" data-aos="fade-up">

              <div class="row">

                <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                  <div class="content">
                    <h3>{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.discounts?.discount_title}</h3>
                    <p> <ReactReadMoreReadLess
                      charLimit={300}
                      readMoreText={"Read more ▼"}
                      readLessText={"Read less ▲"}
                    >
                      {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.discounts?.discount_description}
                    </ReactReadMoreReadLess></p>
                  </div>

                  <div class="accordion-list">
                    <Accordion>
                      {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.discounts?.discount_details.map((discounts, index) => {
                        return <>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {discounts.title}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p> <ReactReadMoreReadLess
                                charLimit={100}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                              >
                                {discounts?.description}
                              </ReactReadMoreReadLess></p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </>
                      })}
                    </Accordion>
                  </div>

                </div>

                <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img">
                  <img src={this.state.viewData?.template?.discounts?.discount_image} className="cta-img" />
                </div>
              </div>

            </div>
          </section>
          <div className="my-5">
            <h1 className="text-center">{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery[0]?.title}</h1>


            {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery[0]?.title != null ?
              <hr class="divider"></hr>

              : null}

            <div className="row">
              <Slider {...settingsgallery}>
                {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery.map((gallery, index) => {
                  return <div className="col">
                    <div className="gallery-item">
                      <img className="gallery-image" src={gallery.photo_name} />
                    </div>
                  </div>
                })


                }

              </Slider>
            </div>
          </div>
          {/* Events */}
          {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.events != 0 ?
            <>
              <h1 className="text-center">Events</h1>
              <hr class="divider"></hr>


              <div className="row" name="discount">
                <Slider {...settingsevents}>
                  {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.events?.map((element, index) => {
                    return <div className="col mt-3">
                      <div className="p-4 gallery-item" style={{ backgroundColor: 'aliceblue', }}>

                        <div className="d-flex">
                          <h6><b>Event Name:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.event_name}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Description:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                            charLimit={25}
                            readMoreText={"Read more ▼"}
                            readLessText={"Read less ▲"}
                          >
                            {element?.description}
                          </ReactReadMoreReadLess></h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Start Date & Time:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.start_date + " " + element.start_time}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>End Date & Time:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.end_date + " " + element.end_time}</h6>
                        </div>
                        <div className="d-flex">
                          <h6><b>Available Seats:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.available_seats}</h6>
                        </div>
                      </div>
                    </div>
                  })
                  }
                </Slider>
              </div> </> : null}

          {/* Discounts */}

          {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts != 0 ?
            <>
              <h1 className="text-center mt-5">Discounts</h1>
              <hr class="divider"></hr>


              <div className="row mb-5" name="discount">
                <Slider {...settingsdiscounts}>
                  {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts?.map((element, index) => {
                    return <div className="col mt-3">
                      <div className="p-4 gallery-item" style={{ backgroundColor: 'aliceblue' }}>

                        <div className="d-flex">
                          <h6><b>Location:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.program_name}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Description:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                            charLimit={25}
                            readMoreText={"Read more ▼"}
                            readLessText={"Read less ▲"}
                          >
                            {element?.description}
                          </ReactReadMoreReadLess></h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Start Date:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.start_date}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>End Date:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.end_date}</h6>
                        </div>

                      </div>
                    </div>
                  })
                  }
                </Slider>
              </div> </> : null}
        </div>
      </>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */

export const mapStateToProps = (state) => {
  return {
    viewtemplate: state.viewtemplate
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { viewTemplate })(BodyConent)