import React, { Component } from "react";
import { connect } from 'react-redux';
import { viewTemplate } from '../../../Store/template/viewTemplateoneAction';
import './templatethree.css'
class Footer extends Component {
	/* render() function is used to update the UI. */
	render() {
		return (
			<>
				<footer class="footer-three">
					<div className="container">
						<div class="footer-left">

							<a className="logo">
								<span><img src="/img/bigsconnect.png" className="footer-logo" /></span>
							</a>
							{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template.footer.map((footers, index) => {
								return <p class="footer-links">
									.
									<a href='#'>{footers.footer_name}</a>
								</p>
							})}

							<p class="footer-company-name" style={{ color: 'white' }}>Company Name © 2015</p>

							<div class="footer-icons">

								<a href="#"><i class="fa fa-facebook"></i></a>
								<a href="#"><i class="fa fa-twitter"></i></a>
								<a href="#"><i class="fa fa-linkedin"></i></a>
								<a href="#"><i class="fa fa-github"></i></a>

							</div>

						</div>

						<div class="footer-right">

							<p>Contact Us</p>

							<form action="#" method="post">

								<input type="text" name="email" placeholder="Email" />
								<input type="number" name="number" placeholder="Phone Number" />
								<textarea name="message" placeholder="Message"></textarea>
								<button>Send</button>

							</form>

						</div>
					</div>
				</footer>

			</>
		)
	}
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
	return {
		viewtemplate: state.viewtemplate
	}
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { viewTemplate })(Footer)