import React, { Component } from 'react';
import { connect } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import {
    getActiveUsersAjax, getBigsUsersAjax, getAgencyUsersAjax, getOrganiseUsersAjax, getInActiveUsersAjax
} from '../../Store/UserManagement/userManagementAction';
import { userdetailsData, userdeleteaction, useractivateaction } from '../../Store/userprofile/userdetailsaction';
import ReactPagenate from 'react-paginate'
import '../../App.css';
import HttpService from '../../Services/HttpService'
import { Tooltip, Overlay } from 'react-bootstrap';
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

class UserManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            userType: 'ActiveUsers',
            startCount: 0,
            endCount: 8,
            offset: 0,
            perPage: 8,
            pageCount: 0,
            currentPage: 0,
            filterUsers: [],
            slice: [],
            spinner: true,
            users: [],
            deleteSuccessData: "",
            userTypeToDelete: '',
            SearchList: [],
            searchInput: '',
            DefaultMessage: '',
            token: '',
            chapter_id: '',
            usertypeid: '',
            showPopover: false,
            user_id: null,
            eventtarget: null,
            deactivatebutton: null,
            display_name: '',
            postData: [],
            selectedPage: 1,
            searchName: null
        }
    }
    /** To refresh the user list after any action done */
    listUser = (event, userType) => {
        localStorage.setItem('selectedpage', 1)
        this.state.userTypeToDelete = userType
        var data = {
            page: 1,
            limit: 8,
            chapter_id: this.state.chapter_id
        }
        if (userType === 'ActiveUsers') {
            localStorage.setItem("activetab", JSON.stringify('ActiveUsers'));

            this.setState({
                users: [],
                spinner: true,
                pageCount: 0,
                postData: [],
                currentPage: 0,
                userType: userType,
                offset: 0
            })
            this.state.searchInput = ""
            this.clearSearchText.value = ""
            this.props.getActiveUsersAjax(data);
            this.setState({ activeId: 'active' });
        } else if (userType === 'AgencyUsers') {
            localStorage.setItem("activetab", JSON.stringify('AgencyUsers'));
            this.setState({
                users: [],
                spinner: true,
                pageCount: 0,
                postData: [],
                currentPage: 0,
                userType: userType,
                offset: 0
            })
            this.state.searchInput = ""
            this.clearSearchText.value = ""
            this.props.getAgencyUsersAjax(data);
            this.setState({ activeId: 'active' });
        } else if (userType === "BigSUsers") {
            localStorage.setItem("activetab", JSON.stringify('BigSUsers'));
            this.setState({
                users: [],
                spinner: true,
                pageCount: 0,
                postData: [],
                currentPage: 0,
                userType: userType,
                offset: 0
            })
            this.state.searchInput = ""
            this.clearSearchText.value = ""
            this.props.getBigsUsersAjax(data);
            this.setState({ activeId: 'active' });
        } else if (userType === "InactiveUsers") {
            localStorage.setItem("activetab", JSON.stringify('InactiveUsers'));
            this.setState({
                users: [],
                spinner: true,
                pageCount: 0,
                postData: [],
                currentPage: 0,
                userType: userType,
                offset: 0
            })
            this.state.searchInput = ""
            this.clearSearchText.value = ""
            this.props.getInActiveUsersAjax(data);
            this.setState({ activeId: 'active' });
        }
        else if (userType === "OrganizationUser") {
            localStorage.setItem("activetab", JSON.stringify('OrganizationUser'));

            this.setState({
                users: [],
                spinner: true,
                pageCount: 0,
                postData: [],
                currentPage: 0,
                userType: userType,
                offset: 0
            })
            this.state.searchInput = ""
            this.clearSearchText.value = ""
            this.props.getOrganiseUsersAjax(data);
            this.setState({ activeId: 'organise' });
        }
    }
    /** To make the api call initial page load */
    componentDidMount() {
        localStorage.setItem("activetab", JSON.stringify('ActiveUsers'))
        var data = {
            page: this.state.selectedPage,
            limit: 8,
            chapter_id: this.state.chapter_id
        }
        this.state.userTypeToDelete = "ActiveUsers"
        this.props.getActiveUsersAjax(data);
    }
    /** To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.state.users = nextProps.userManagementDetails.data.users
        this.setState({
            spinner: false,
        })
        if (nextProps.userManagementDetails.data.users.length === 0) {
            this.setState({
                spinner: false,
                DefaultMessage: "No Users Found."
            })
        }
        else {
            //this.state.filterUsers = this.state.users?.slice(this.state.offset, this.state.offset + this.state.perPage)
            this.setState({
                spinner: false,
                pageCount: Math.ceil(nextProps.userManagementDetails.data.count / this.state.perPage),
                postData: this.state.users
            })
        }
    }
    /** Goto new user user page to create new user */
    onnewUser() {
        this.props.history.push({
            pathname: '/dashboard/user_management/new_user_management'
        })
    }
    /** Goto user information page */
    onUser(item) {
        this.props.history.push({
            pathname: '/dashboard/user_management/user_data',
            state: { detail: item }
        })
    }
    /** To show the pop up */
    showPopoverDialog = (e, uid, deactivate) => {
        this.setState({
            showPopover: true,
            eventtarget: e.target,
            user_id: uid.user_id,
            deactivatebutton: deactivate,
            display_name: uid.display_name
        })
    }
    /** To close the pop up */
    dismissPopoverDialog = (e) => {
        this.setState({
            showPopover: false
        })
    }
    onErrorAlert() {
        var errorAlert = {
            alertType: 'error'
        }
        handleSwalAlert(errorAlert)
    }
    /** To delete the user */
    onDeleteuser(user_id, usertype) {

        var user = {
            user_id: this.state.user_id,
            action_type: "deleteuser"
        };
        this.state.spinner = true
        HttpService.deleteuser(user)
            .then(response => {
                this.state.deleteSuccessData = response.data
                if (this.state.deleteSuccessData.status === 200) {
                    var data = {
                        page: JSON.parse(localStorage.getItem('selectedpage')),
                        limit: 8,
                        chapter_id: this.state.chapter_id
                    }

                    var successAlert = {
                        message: this.state.deleteSuccessData.message,
                        alertType: 'success'
                    }
                    handleSwalAlert(successAlert)

                    if (this.state.userTypeToDelete === 'ActiveUsers') {
                        //  this.state.users = ""

                        this.props.getActiveUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === 'AgencyUsers') {
                        // this.state.users = ""
                        this.props.getAgencyUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === "BigSUsers") {
                        // this.state.users = ""
                        this.props.getBigsUsersAjax(DataTransferItem);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === "InactiveUsers") {
                        // this.state.users = ""
                        this.props.getInActiveUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    }
                    else if (this.state.userTypeToDelete === "OrganizationUser") {
                        // this.state.users = ""
                        this.props.getOrganiseUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    }
                }
                else {
                    this.setState({
                        showPopover: false,
                        spinner: false
                    })
                    this.onErrorAlert()
                    //swal({ text: "Something went wrong, Please try again after sometime.", icon: 'error' });
                }

            })
            .catch(error => {
                this.setState({
                    showPopover: false,
                    spinner: false
                })
                this.onErrorAlert()
            })
    }
    /** To Deactivate the user */
    onDeactivateuser(user_id) {
        var user = {
            user_id: this.state.user_id,
            action_type: "deactivateuser"
        };

        HttpService.deleteuser(user)
            .then(response => {
                this.state.deleteSuccessData = response.data
                if (this.state.deleteSuccessData.status === 200) {
                    var data = {
                        page: JSON.parse(localStorage.getItem('selectedpage')),
                        limit: 8,
                        chapter_id: this.state.chapter_id
                    }
                    var successAlert = {
                        message: this.state.deleteSuccessData.message,
                        alertType: "success"
                    }
                    handleSwalAlert(successAlert)
                    if (this.state.userTypeToDelete === 'ActiveUsers') {
                        this.props.getActiveUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === 'AgencyUsers') {
                        this.props.getAgencyUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === "BigSUsers") {
                        this.props.getBigsUsersAjax(DataTransferItem);
                        this.setState({ activeId: 'active', showPopover: false });
                    } else if (this.state.userTypeToDelete === "InactiveUsers") {
                        this.props.getInActiveUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    }
                    else if (this.state.userTypeToDelete === "OrganizationUser") {
                        this.props.getOrganiseUsersAjax(data);
                        this.setState({ activeId: 'active', showPopover: false });
                    }
                    this.setState({
                        spinner: false,
                        showPopover: false
                    });
                }
                else {
                    this.setState({ showPopover: false, spinner: false })
                    this.onErrorAlert()
                }
            })
            .catch(error => {
                this.setState({
                    showPopover: false,
                    spinner: false
                })
                this.onErrorAlert()
            })
        this.setState({
            spinner: false
        });
    }
    /** To Activate the user */
    onActivateuser(user_id) {

        var user = {
            user_id: this.state.user_id,
            action_type: "activateuser"
        };
        HttpService.activateuser(user)
            .then(response => {
                this.state.deleteSuccessData = response.data
                if (this.state.deleteSuccessData.status === 200) {
                    var data = {
                        page: JSON.parse(localStorage.getItem('selectedpage')),
                        limit: 8,
                        chapter_id: this.state.chapter_id
                    }
                    var successAlert = {
                        message: this.state.deleteSuccessData.message,
                        alertType: "success"
                    }
                    handleSwalAlert(successAlert)
                    this.props.getInActiveUsersAjax(data);
                    this.setState({
                        spinner: false,
                        showPopover: false
                    });
                }
                else {
                    this.setState({
                        spinner: false,
                        showPopover: false
                    });
                    this.onErrorAlert()
                }
            })
            .catch(error => {
                this.setState({
                    spinner: false,
                    showPopover: false
                });
                this.onErrorAlert()
            })
    }
    /** Goto next, previous page in the pagination */
    handlePageClick = (e) => {
        this.state.spinner = true
        const enabledpage = e.selected;

        this.state.selectedPage = enabledpage + 1

        localStorage.setItem('selectedpage', this.state.selectedPage)

        if (this.state.searchName != null) {
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id,
                search: this.state.searchName
            }
        }
        else {
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id
            }

        }
        if (JSON.parse(localStorage.getItem('activetab')) == 'ActiveUsers') {
            console.log('active tab:- ', localStorage.getItem('activetab'))

            this.props.getActiveUsersAjax(data);
        } else if (JSON.parse(localStorage.getItem('activetab')) == 'AgencyUsers') {
            console.log('active tab:- ', localStorage.getItem('activetab'))

            this.props.getAgencyUsersAjax(data);
        } else if (JSON.parse(localStorage.getItem('activetab')) == "BigSUsers") {
            console.log('active tab:- ', localStorage.getItem('activetab'))

            this.props.getBigsUsersAjax(data);
        } else if (JSON.parse(localStorage.getItem('activetab')) == "InactiveUsers") {
            console.log('active tab:- ', localStorage.getItem('activetab'))


            this.props.getInActiveUsersAjax(data);
        }
        else if (JSON.parse(localStorage.getItem('activetab')) == "OrganizationUser") {
            console.log('active tab:- ', localStorage.getItem('activetab'))

            this.props.getOrganiseUsersAjax(data);
        }
    };
    /** For pagination */
    receivedData() {
        this.setState({
            pageCount: Math.ceil(this.state.users.length / this.state.perPage),
            postData: this.state.users.slice(this.state.offset, this.state.offset + this.state.perPage)
        })

    }
    /** For search the users in the list  */
    searchHandler = (event) => {
        if (event.target.value.length === 0) {
            this.setState({
                users: []
            })
            var data = {
                page: 1,
                limit: 8,
                chapter_id: this.state.chapter_id
            }
            if (JSON.parse(localStorage.getItem('activetab')) == 'ActiveUsers') {
                // console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getActiveUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == 'AgencyUsers') {
                // console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getAgencyUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == "BigSUsers") {

                this.props.getBigsUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == "InactiveUsers") {

                this.props.getInActiveUsersAjax(data);
            }
            else if (JSON.parse(localStorage.getItem('activetab')) == "OrganizationUser") {

                this.props.getOrganiseUsersAjax(data);
            }
            this.setState({
                spinner: !this.state.spinner,
                SearchList: [],
                searchInput: '',
                DefaultMessage: ''
            })
        }
        else {
            this.state.searchName = event.target.value

            this.setState({
                spinner: true
            })
            if (this.state.searchName.length === 1) {
                this.setState({
                    users: []
                })
            }
            var data = {
                page: this.state.selectedPage,
                limit: 8,
                chapter_id: this.state.chapter_id,
                search: this.state.searchName
            }
            if (JSON.parse(localStorage.getItem('activetab')) == 'ActiveUsers') {
                console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getActiveUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == 'AgencyUsers') {
                console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getAgencyUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == "BigSUsers") {
                console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getBigsUsersAjax(data);
            } else if (JSON.parse(localStorage.getItem('activetab')) == "InactiveUsers") {
                console.log('active tab:- ', localStorage.getItem('activetab'))


                this.props.getInActiveUsersAjax(data);
            }
            else if (JSON.parse(localStorage.getItem('activetab')) == "OrganizationUser") {
                console.log('active tab:- ', localStorage.getItem('activetab'))

                this.props.getOrganiseUsersAjax(data);
            }
        }
    }
    /** render() function is used to update the UI. */
    render() {
        const { showPopover } = this.state
        localStorage.setItem("activescreen", JSON.stringify('usermanagmentlist'));
        this.currentuser = JSON.parse(localStorage.getItem('userdata'));
        var user_id = this.currentuser.user.usertypeid;
        if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
            this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
        }
        if (this.state.users.length > 0) {
            this.state.spinner = false
            return (
                <div className="container">
                    <Row className='pt-4'>
                        <Col md="6">
                            <h3 className="pagename">User Management</h3>
                        </Col>
                        <Col md="6">
                            <div className='d-flex'>
                                <div className='pr-3' style={{ width: '70%' }}>
                                    <div>
                                        <span className="fa fa-search head-search"></span>
                                        <input style={{ paddingLeft: '40px' }} type="text" ref={ref => this.clearSearchText = ref} className="form-control" placeholder="Search Name..." onChange={(e) => this.searchHandler(e)} />
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewUser()}>New User</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="userManagement-page">
                        <div className="tabs-sec">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" onClick={(event) => { this.listUser(event, 'ActiveUsers') }} href="#">Active Users</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'AgencyUsers') }} href="#">Agency Users</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'BigSUsers') }} href="#">BigS Users</a>
                                </li>
                                {user_id == 5 ?
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'OrganizationUser') }} href="#">Organization Users</a>
                                    </li> : null}

                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'InactiveUsers') }} href="#">Inactive Users</a>
                                </li>

                            </ul>
                        </div>
                        <div className="App">
                            <center>
                                <div class="loader-spinner"> {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />}</div>
                            </center>
                        </div>
                        {
                            this.state.searchInput.length ? this.state.SearchList.length ? this.state.SearchList?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
                                <Col md="6" key={item.user_id}>
                                    <div className="user-details">
                                        <div className="media border p-3 media-sec">
                                            <img src={item.profile_pic} className="mr-3 mt-3 rounded-circle" width="70px" height="70px"  />
                                            <div className="media-body" style={{ marginLeft: '10px' }}>
                                                <h4 className="media-heading" style={{ marginBottom: '5px' }}>
                                                    {item.display_name}
                                                    {item.usertypeid == 4 || item.active_status == '3' ? null : <div className="float-right">
                                                        {item.active_status == 0 ?
                                                            <div>
                                                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                    <Tooltip>
                                                                        <div className='text-center p-4'>
                                                                            <p className='tool-title'>Deactivate User Account</p>
                                                                            <p className='tool-para'>Are you sure you want to deactivate this user account?</p>
                                                                        </div>
                                                                        <div className='d-flex btn-sec'>
                                                                            <button onClick={() => this.onDeactivateuser(item.user_id)} className='tool-btn-yes'>Yes, Deactivate</button>
                                                                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Overlay>
                                                                <span className="pr-2" onClick={(e) => this.showPopoverDialog(e, item)}><LockIcon /></span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                    <Tooltip>
                                                                        <div className='text-center p-4'>
                                                                            <p className='tool-title'>Activate User Account</p>
                                                                            <p className='tool-para'>Are you sure you want to activate this user account?</p>
                                                                        </div>
                                                                        <div className='d-flex btn-sec'>
                                                                            <button onClick={() => this.onActivateuser(item.user_id)} className='tool-btn-yes'>Yes, Activate User</button>
                                                                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Overlay>
                                                                <span style={{ color: '#ff6565' }} className="pr-2" onClick={(e) => this.showPopoverDialog(e, item, 'deactivate')}><LockIcon /></span>
                                                            </div>
                                                        }
                                                        {<> {this.state.deactivatebutton == 'delete' ?
                                                            <>
                                                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                    <Tooltip>
                                                                        <div className='text-center p-4'>
                                                                            <p className='tool-title'>Delete User Account</p>
                                                                            <p className='tool-para'>Are you sure you want to delete the account for <span className='tool-para' style={{ color: '#ff6565' }}>{this.state.display_name}</span>? Deleted accounts may not be restored.</p>
                                                                        </div>
                                                                        <div className='d-flex btn-sec'>
                                                                            <button onClick={() => this.onDeleteuser(item.user_id, item.user_type)} className='tool-btn-yes'>Yes, Delete</button>
                                                                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Overlay>
                                                            </> : null}
                                                            <span name="remove" onClick={(e) => this.showPopoverDialog(e, item, 'delete')}><CloseIcon /></span>
                                                        </>}
                                                    </div>}
                                                </h4>

                                                <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>User Type: {item.user_type}</h8>
                                                <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>Mobile: {item.phone_number}</h8>
                                                <h8 className="mediatext" style={{ display: 'block', color: '#a1a0ae' }}>Email: {item.email}</h8>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            )) : <div style={{ width: '100%' }}><h1 style={{ textAlign: 'center' }}>{this.state.DefaultMessage}</h1></div> :
                                this.state.postData ? this.state.postData?.map(item => (
                                    <Col md="6" key={item.user_id}>
                                        <div className="user-details">
                                            <div className="media border p-3 media-sec" >
                                                <div style={{cursor:'pointer'}} onClick={() => this.onUser(item)}>
                                                <img style={{float:'left'}} src={item.profile_pic} className="mr-3 mt-3 rounded-circle" width="70px" height="70px" />
                                                <div style={{ marginLeft: '10px'}} className="media-body">
                                                    <h4 className="media-heading" style={{ marginBottom: '5px' }}>
                                                        {item.display_name}

                                                    </h4>
                                                    <div>

                                                        <h8 style={{ display: 'block', color: '#a1a0ae' }} className="mediatext">User Type: {item.user_type}</h8>
                                                        <h8 style={{ display: 'block', color: '#a1a0ae' }} className="mediatext">Mobile: {item.phone_number}</h8>
                                                        <h8 style={{ display: 'block', color: '#a1a0ae' }} className="mediatext">Email: {item.email}</h8>
                                                    </div>
                                                </div>
                                                </div>
                                                <div> 
                                                    {item.usertypeid == 4 || item.active_status == '3' ? null :
                                                    <div className="float-right" style={{marginTop:'10px'}}>
                                                        {item.active_status == 0 ?
                                                            <div>
                                                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                    <Tooltip>
                                                                        <div className='text-center p-4'>
                                                                            <p className='tool-title'>Deactivate User Account</p>
                                                                            <p className='tool-para'>Are you sure you want to deactivate this user account?</p>
                                                                        </div>
                                                                        <div className='d-flex btn-sec'>
                                                                            <button onClick={() => this.onDeactivateuser(item.user_id)} className='tool-btn-yes'>Yes, Deactivate</button>
                                                                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Overlay>
                                                                <span className="pr-2" onClick={(e) => this.showPopoverDialog(e, item)}><LockIcon /></span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                    <Tooltip>
                                                                        <div className='text-center p-4'>
                                                                            <p className='tool-title'>Activate User Account</p>
                                                                            <p className='tool-para'>Are you sure you want to activate this user account?</p>
                                                                        </div>
                                                                        <div className='d-flex btn-sec'>
                                                                            <button onClick={() => this.onActivateuser(item.user_id)} className='tool-btn-yes'>Yes, Activate User</button>
                                                                            <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Overlay>
                                                                <span style={{ color: '#ff6565' }} className="pr-2" onClick={(e) => this.showPopoverDialog(e, item, 'deactivate')}><LockIcon /></span>

                                                            </div>
                                                        }
                                                        {

                                                            <> {this.state.deactivatebutton == 'delete' ?
                                                                <>
                                                                    <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                        <Tooltip>
                                                                            <div className='text-center p-4'>
                                                                                <p className='tool-title'>Delete User Account</p>
                                                                                <p className='tool-para'>Are you sure you want to delete the account for <span className='tool-para' style={{ color: '#ff6565' }}>{this.state.display_name}</span>? Deleted accounts may not be restored.</p>
                                                                            </div>
                                                                            <div className='d-flex btn-sec'>
                                                                                <button onClick={() => this.onDeleteuser(item.user_id, item.user_type)} className='tool-btn-yes'>Yes, Delete</button>
                                                                                <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Overlay>
                                                                </> : null}
                                                                <span name="remove" onClick={(e) => this.showPopoverDialog(e, item, 'delete')}><CloseIcon /></span>
                                                            </>
                                                        }


                                                    </div>
                                                }
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                )) : <h3>{this.state.DefaultMessage}</h3>
                        }
                        {
                            this.state.pageCount > 0 ? <div className="col-md-12">
                                <div className="pagination">

                                    <ReactPagenate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={8}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                </div>

                            </div> : null
                        }
                    </div >
                </div >
            );
        }
        else {
            return (
                <div className="container">
                    <Row className='pt-4'>

                        <Col xs lg="6">
                            <h3 className="pagename">User Management</h3>
                        </Col>

                        <Col xs lg="6">
                            <div className='d-flex'>
                                <div className='pr-3' style={{ width: '70%' }}>
                                    <div>
                                        <span className="fa fa-search head-search"></span>
                                        <input style={{ paddingLeft: '40px' }} type="text" ref={ref => this.clearSearchText = ref} className="form-control" placeholder="Search Name..." onChange={(e) => this.searchHandler(e)} />
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewUser()}>New User</button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <div className="userManagement-page">
                        <div className="tabs-sec">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" onClick={(event) => { this.listUser(event, 'ActiveUsers') }} href="#">Active Users</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'AgencyUsers') }} href="#">Agency Users</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'BigSUsers') }} href="#">BigS Users</a>
                                </li>
                                {user_id == '5' ? <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'OrganizationUser') }} href="#">Organization Users</a>
                                </li> : null}

                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" onClick={(event) => { this.listUser(event, 'InactiveUsers') }} href="#">Inactive Users</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <center>
                                <div class="loader-spinner">
                                    {this.state.spinner && <Circles color="#7cc9a9" height={50} width={50} />
                                    }
                                </div>
                            </center>
                        </div>
                        <div className="tabs-photgallery-sec">
                            <div className="mb-3 col-12 text-center">
                                <div className="table-responsive">
                                    <h1>{this.state.spinner ? null : this.state.DefaultMessage}</h1>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {

    return {
        userManagementDetails: state.userManagementDetails
    }
}
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, {
    userdetailsData,
    userdeleteaction,
    useractivateaction,
    getActiveUsersAjax,
    getBigsUsersAjax,
    getAgencyUsersAjax,
    getOrganiseUsersAjax,
    getInActiveUsersAjax
})(UserManagement)


