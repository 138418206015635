import React, { Component } from 'react';
import { getplanListData } from '../../Store/planList/planListAction';
import { connect } from 'react-redux';
import Header from './Header.jsx';
import { Circles } from 'react-loader-spinner';
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planListResponce: [],
      defaultMessage: '',
      spinner:false
    }
  }
  componentDidMount() {
    this.setState({ spinner: true })
    this.props.getplanListData()
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.planList.planList_res.plans_list.length === 0) {
      this.setState({ spinner: false, defaultMessage: "You don't have any plans" })
    }
    else {
      this.setState({ spinner: false, planListResponce: nextProps.planList.planList_res.plans_list })
    }

  }
  
/* render() function is used to update the UI. */
  render() {
    return (
      <>
        <main className='body-new paddingbottom-100'>
          <Header />
          <div className='form form--alt'>
            <div className="form__group">
              <div className="form__subhead">
                <h3 className="form__subtitle">Subscription Type</h3>{/* <!-- /.form__title --> */}
              </div>{/* <!-- /.form__subhead --> */}


              <div className="form__body">
              <center>
            <div class="loader-spinner mt-3">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
                <div className="radios">
                  <ul>
                    {this.state.planListResponce.length > 0 ?
                      this.state.planListResponce.map((item) => (
                        <li>

                          <div className="radio" onClick={() => this.onPlanHandle(item.plan_id)}>
                            <input type="radio" defaultChecked={item.recommended === 1 ? true : false} name="field-type" id={`field-${item.plan_id}#`} />
                            <label for={`field-${item.plan_id}#`}>
                              <p>{item.plan_name}</p>
                              <div className='clearfix'></div>
                              <span className='ratetag'>{item.amount}</span>
                              <small>{"(" + item.subject + ")"}</small>

                              {item.recommended === 1 ? <span className="radio__circle">
                                Save <br />
                                20%
                              </span> : null}

                            </label>
                          </div>

                        </li>
                      )) : <h3>{this.state.defaultMessage}</h3>}
                  </ul>
                </div>
              </div>

              {/* <!-- /.radios --> */}
              {/* <!-- /.form__body --> */}
            </div>{/* <!-- /.form__group --> */}
            <div className="form__actions">
              <button className="btn btn--blue btn--large form__btn"
                onClick={this.submitForm}>Subscribe</button>
            </div>{/* <!-- /.form__actions --> */}
          </div>
        </main>
      </>
    )
  }
}
export const mapStateToProps = (state) => {
  return {
    planList: state.planList,
  }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getplanListData })(Pricing);