import React, { Component } from "react";
import './Templatetwo.css'
class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footerData: {}
		}
	}
	/* render() function is used to update the UI. */
	render() {
		this.state.footerData = JSON.parse(localStorage.getItem('ViewTemplateData'))
		return (
			<>
				<footer class="footer-distributed-two">
					<div className="container">
						<div class="footer-left">

							<a className="logo">
								<span><img src={this.state.footerData?.template.template_logo} className="footer-logo" /></span>
							</a>

							<p class="footer-links">
								{this.state.footerData?.template.footer.map((item, index) => {
									return <a href="#">·{item.footer_name}</a>
								})}
							</p>
						</div>

						<div class="footer-right">

							<p>Contact Us</p>

							<form action="#" method="post">

								<input type="text" name="email" placeholder="Email" />
								<input type="number" name="number" placeholder="Phone Number" />
								<textarea name="message" placeholder="Message"></textarea>
								<button>Send</button>

							</form>

						</div>
					</div>
				</footer>

			</>
		)
	}
}
export default Footer;