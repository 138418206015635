import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userdetailsData, userdeleteaction, passwordResetMail } from '../../Store/userprofile/userdetailsaction';
import UserInformation from './UserInformation';
import AccountSettings from './AccountSettings';
import UserPhotoGallery from './UserPhotoGallery';
import AccountView from './AccountView';
import MyLittle from './MyLittle';
import EventHistory from './EventHistory';
import swal from 'sweetalert';
import ActivityHistory from './ActivityHistory';
import { Row, Col, Button } from 'react-bootstrap';
class UserData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      userdetails: '',
      picture: null,
      imagePreviewUrl: '',
      activeScreen: 'UserInformation',
      userInfo: {},
      mylittleData: {},
      myprofile: '',
      token: ''
    };
    var date = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));

  }
  /** To make the api call initial page load */
  componentDidMount() {
    this.tokenvalue = JSON.parse(localStorage.getItem('userdata'))
    this.state.token = this.tokenvalue.token
    if (this.props.location.state.from_my_profile == 'myprofile') {
      var user_id = this.props.location.state.profile_id;
      this.setState({ user_id: user_id, myprofile: true });
      this.props.userdetailsData({ user_id, hit_from: 'header' })
    }
    else {
      var user_id = this.props.location.state.detail.user_id;
      this.setState({ user_id: user_id });
      var data = {
        user_id: user_id,
        hit_from: ''
      }
      this.props.userdetailsData(data);
    }

  }
  /** To show the tabs related data */
  activeSettings(event, key) {
    if (key == 'UserInformation') {
      this.setState({
        activeScreen: 'UserInformation',
      })
    } else if (key == 'AccountSettings') {
      this.setState({
        activeScreen: 'AccountSettings'
      })
    } else if (key == 'PhotoGallery') {
      this.setState({
        activeScreen: 'PhotoGallery'
      })
    }
    else if (key == 'MyLittle') {
      this.setState({
        activeScreen: 'MyLittle'
      })
    }
    else if (key == 'eventHistory') {
      this.setState({
        activeScreen: 'eventHistory'
      })
    }
    else if (key == 'activityHistory') {
      this.setState({
        activeScreen: 'activityHistory'
      })
    }
  }
  /** To delete the user */
  onDeleteuser(user_id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user details.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var user = {
            user_id: user_id,
            action_type: "deleteuser"
          };
          this.props.userdeleteaction(user);
          swal({
            title: "User Deleted Successfully",
            icon: "success"
          })
          this.setState({
            spinner: false
          });
        }
      });
  }
  /** To deactivate the user */
  onDeactivateuser(user_id) {
    swal({
      title: "Are you sure?",
      text: "Once deactivated, you will not be able to recover this user details.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var user = {
            user_id: user_id,
            action_type: "deactivateuser"
          };
          this.props.userdeleteaction(user);
          swal({
            title: "User Deactivated Successfully",
            icon: "success"
          })
          this.setState({
            spinner: false
          });
        }
      });
  }
  /** To send the mail to reset the password */
  sendPassword(email) {
    swal({
      title: "Are you sure?",
      text: "You want to reset password.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var email = {
            email: email,
          };
          this.props.passwordResetMail(email);
          swal({
            title: "Password Reset link sent to your mail Successfully",
            icon: "success"
          })
          this.setState({
            spinner: false
          });
        }
      });
  }
  /** Goto list of user management page */
  onUserManagement() {
    this.props.history.push('/dashboard/user_management');
  }
  /** To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.state.userdetails = nextProps.userDetails.userdetails_res.user_details
    this.state.mylittleData = nextProps.userDetails.userdetails_res.mylittle
    this.state.userInfo = nextProps.userDetails.userdetails_res.user_details
  }
  /**  render() function is used to update the UI. */
  render() {

    const { myprofile, token } = this.state
    return (
      <div className="container">
        <Row className="mt-3 mb-4">
          <Col xs lg="12">
            <div className="horizontal-container">
              {
                myprofile ? <label className="label-discount">My Profile</label>
                  : <h3 className="label-discount" onClick={() => this.onUserManagement()}>User Management</h3>
              }
            </div>
          </Col>
        </Row>

        <Row className="pt-5">
          <AccountView user_id={this.state.user_id} userDetails={this.state.userdetails} profiledata={this.state.myprofile} token_value={this.props.location.state?.profile_id} />
          <Col xs lg="9">
            <section style={{ width: '100%' }} className="holltab-sec">
              <div className="userManagement-page">
                <div className="tabs-sec">
                  <ul class="nav nav-tabs">
                    <li class="nav-item pl-4">
                      <a class="nav-link active" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'UserInformation') }} id="defaultOpen" href="#">User Information</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'AccountSettings') }} href="#">Account Settings</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'PhotoGallery') }} href="#">Photo Gallery</a>
                    </li>

                    {this.state.userdetails?.usertype_id == 2 || this.state.userdetails?.usertype_id == 3 ?
                      <>
                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'MyLittle') }} href="#">My Little</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'activityHistory') }} href="#">Activity History</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" onClick={(event) => { this.activeSettings(event, 'eventHistory') }} href="#">Event History</a>
                        </li></> : null
                    }
                  </ul>
                </div>
              </div>
              {
                this.state.activeScreen == 'UserInformation' ?
                  <UserInformation
                    userdata={this.state.userInfo} token={this.state.user_id}
                  />
                  : this.state.activeScreen == 'AccountSettings' ?
                    <AccountSettings userId={this.state.user_id} token={this.state.myprofile} />
                    : this.state.activeScreen == 'PhotoGallery' ?
                      <UserPhotoGallery userId={this.state.user_id} />
                      : this.state.activeScreen == 'MyLittle' ?
                        <MyLittle userId={this.state.user_id} littleData={this.state.mylittleData} />
                        : this.state.activeScreen == 'eventHistory' ?
                          <EventHistory userId={this.state.user_id} token={this.state.user_id} />
                          : this.state.activeScreen == 'activityHistory' ?
                            <ActivityHistory userId={this.state.user_id} token={this.state.user_id} /> : null
              }
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => ({
  userDetails: state.userDetails
});
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, { userdetailsData, userdeleteaction, passwordResetMail })(UserData)