import { TEMPLATE_VIEW } from '../types';
import HttpService from '../../Services/HttpService';

 export const viewTemplateoneData = (response) =>{
    return {
        type: TEMPLATE_VIEW,
        response: response
    }
}


 export const viewTemplate = (data) => {
    return (dispatch) => {
        HttpService.viewTemplate(data)
        .then(response => {
            console.log("viewTemplateoneData :- ",response)
            dispatch(viewTemplateoneData(response.data));
        })
    }
}