import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { getViewSettingsListdata } from '../../Store/viewsettings/viewsettingsaction'
import 'font-awesome/css/font-awesome.min.css';

class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agencyPermissions: [],
      usertypeid: '',
      allPermissions: [],
      show: true,
      addClass: false,
      vw: window.innerWidth,
    }
  }
  /* For close the side navigation bar when redirect the respective page*/
  toggle = () => {
    if (window.innerWidth < 768) {
      this.setState((currentState) => ({ show: !currentState.show }));
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    }
  }

  /* To make the api call initial page load */
  componentDidMount() {
    
    this.props.getViewSettingsListdata()
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
   
    this.setState({
      agencyPermissions: nextProps.getViewSettingsList.givenPermissions.settings,
      allPermissions: nextProps.getViewSettingsList.givenPermissions.allsettings,
    
    })
  }
  /* render() function is used to update the UI. */
  render() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    this.state.usertypeid = this.currentuser.user.usertypeid
    const permissions = this.state.agencyPermissions?.map((e) => {
      return e.permission_name
    })
    const allPermissions = this.state.allPermissions?.map((e) => {
      return e.permission_name
    })
    const phone = "Photo Gallery";
    const customers = this.state.agencyPermissions;
    const isInArray = customers.indexOf(phone) > -1;
    return (
      <>
        {this.state.show && <aside className="main-sidebar sidebar-dark-primary elevation-4" id="left-nav">
          <div className="sidebar">
            <nav className="mt-2" >
              {this.currentuser.user.usertypeid == 5 || this.currentuser.user.usertypeid == 4 ?
                <ul className="nav nav-pills nav-sidebar flex-column mainmenu" data-widget="treeview" role="menu" data-accordion="false">
                  <li className="nav-item">
                    <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/newdashboard' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} >
                      <i class="fa fa-bar-chart" aria-hidden="true"></i>
                      <span className="text-mini">Dashboard</span>  </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/user_management' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                      <span className="text-mini">User Management</span></NavLink>
                  </li>

                  {this.currentuser.user.usertypeid == 5 ? <li className="nav-item">
                    <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/Chapters/ChaptersList' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                      <span className="text-mini">Chapters</span></NavLink>
                  </li> : null}

                  {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ?
                  <>
                  {this.currentuser.user.usertypeid == 5 ? <li className="nav-item">
                  <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/Templates/AllTemplateDesigns' activeStyle={{
                    background: '#3c3b54',
                    fontWeight: '500',
                    borderLeft: '5px solid #7cc9a9',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <i class="fa fa-id-card" aria-hidden="true"></i>
                    <span className="text-mini">Templates</span></NavLink>
                </li> : null}</>:null  
                }

                

                  <li className="nav-item">
                    <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/activities' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <i class="fa fa-tasks" aria-hidden="true"></i>
                      <span className="text-mini">Activities</span>
                    </NavLink>
                  </li>

                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" onClick={this.toggle} to='/dashboard/resources' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                      <span className="text-mini">Resources</span> </NavLink>
                  </li>

                  <li className="nav-icon nav-item disp-block">
                    <div data-toggle="collapse" data-target="#submenu2sub2">
                      <a className="nav-link-padd">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        <span style={{ width: '100%' }} className="text-mini"> Events</span>
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="collapse" id="submenu2sub2" aria-expanded="false">
                      <ul className="flex-column nav pl-3">
                        <li className="nav-item" onClick={this.toggle}>
                          <NavLink to='/dashboard/agencysponsored_events' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">Agency Sponsored Events </span> </NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink to='/dashboard/agencysponsored_tags' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">Agency Sponsored Events Tags </span> </NavLink>
                        </li>
                        <li class="nav-item" onClick={this.toggle}>
                          <NavLink to='/dashboard/bigsconnect_community' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">BigS Connect Community</span> </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-icon nav-item dropdown disp-block">
                    <div
                      data-toggle="collapse"
                      data-target="#submenu1sub1">
                      <a className="nav-link-padd">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span style={{ width: '100%' }} className="text-mini"> Messaging & Notifications</span>
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="collapse" id="submenu1sub1" aria-expanded="false">
                      <ul className="flex-column nav pl-3">
                        <li className="nav-item">
                          <NavLink to='/dashboard/messages'
                            data-toggle="collapse"
                            data-target="#subMessaging" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <a className="nav-link collapsed py-1" href="#subMessaging" ><i class="fa fa-comments" aria-hidden="true"></i> <span className="text-mini">Messaging</span></a>
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink to='/dashboard/notifications'
                            data-toggle="collapse"
                            data-target="#subNotifications" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <a className="nav-link collapsed py-1" href="#subNotifications" ><i class="fa fa-bell-o" aria-hidden="true"></i><span className="text-mini">Notifications</span></a>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/discount_programs' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      <span className="text-mini">Discount Programs</span>
                    </NavLink>
                  </li>

                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/photo_gallery' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      <span className="text-mini">Photo Gallery</span></NavLink>
                  </li>
                  {this.currentuser.user.usertypeid == 5  ? process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ?<>
                   <li className="nav-icon nav-item">
                   <NavLink className="nav-link-padd" to='/dashboard/video_gallery' activeStyle={{
                     background: '#3c3b54',
                     fontWeight: '500',
                     borderLeft: '5px solid #7cc9a9',
                     display: 'flex',
                     alignItems: 'center',
                   }} onClick={this.toggle}>
                     <i class="fa fa-cog" aria-hidden="true"></i>
                     <span className="text-mini">Video Gallery</span></NavLink>
                 </li></>:null :null
                  }
                 
                  <li class="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/alllittles'
                            data-toggle="collapse"
                            data-target="#subNotifications" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <img className='usermanagement'></img><span className="text-mini">Littles</span>
                          </NavLink>
                  </li>
                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/help_center' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <i class="fa fa-life-ring" aria-hidden="true"></i>
                      <span className="text-mini">Help Center</span></NavLink>
                  </li>
                  {this.currentuser.user.usertypeid == 5 ? <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/cmshelpcenter' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <i class="fa fa-life-ring" aria-hidden="true"></i>
                      <span className="text-mini">CMS Help Center</span></NavLink>
                  </li> : null}
                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/settings' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      <span className="text-mini">Settings</span></NavLink>
                  </li></ul> :

                <ul className="nav nav-pills nav-sidebar flex-column mainmenu" data-widget="treeview" role="menu" data-accordion="false">
                  <li className="nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/newdashboard' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <img className='dashboard'></img>
                      <span className="text-mini">Dashboard</span>  </NavLink>
                  </li>
                  {permissions.includes('User Management') ? <li className="nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/user_management' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <img className='usermanagement'></img>
                      <span className="text-mini">User Management</span></NavLink>
                  </li> : null}
                  {permissions.includes("Activities") ? <li className="nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/activities' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <img className='activities'></img>
                      <span className="text-mini">Activities</span>
                    </NavLink>
                  </li> : null}
                  {permissions.includes("Resources") ? <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/resources' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <img className='resources'></img>
                      <span className="text-mini">Resources</span> </NavLink>
                  </li> : null}
                  <li className="nav-icon nav-item disp-block">
                    <div data-toggle="collapse" data-target="#submenu2sub2">
                      <a className="nav-link-padd">
                        <img className='events'></img>
                        <span style={{ width: '100%' }} className="text-mini">Events</span>
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="collapse" id="submenu2sub2" aria-expanded="false">
                      <ul className="flex-column nav pl-3">
                        <li className="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/agencysponsored_events' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }} onClick={this.toggle}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">Agency Sponsored Events </span> </NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/agencysponsored_tags' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">Agency Sponsored Events Tags </span> </NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/bigsconnect_community' activeStyle={{
                            background: '#3c3b54',
                            fontWeight: '500',
                            borderLeft: '5px solid #7cc9a9',
                            display: 'flex',
                            alignItems: 'center',
                          }} onClick={this.toggle}>
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <span className="text-mini">BigS Connect Community</span> </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>

                  {permissions.includes('Messaging & Notifications') ? <li className="nav-icon dropdown disp-block nav-item">
                    <div
                      data-toggle="collapse"
                      data-target="#submenu1sub1">
                      <a className="nav-link-padd">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span style={{ width: '100%' }} className="text-mini">
                          Messaging & Notifications</span>
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="collapse" id="submenu1sub1" aria-expanded="false">
                      <ul className="flex-column nav">
                        <li className="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/messages'
                            data-toggle="collapse"
                            data-target="#subMessaging" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <a className="nav-link collapsed py-1" href="#subMessaging" ><i class="fa fa-comments" aria-hidden="true"></i> <span className="text-mini">Messaging</span></a>
                          </NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/notifications'
                            data-toggle="collapse"
                            data-target="#subNotifications" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <a className="nav-link collapsed py-1" href="#subNotifications" ><i class="fa fa-bell-o" aria-hidden="true"></i><span className="text-mini">Notifications</span></a>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li> : null}
                  {permissions.includes('Discount Programs') ? <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/discount_programs' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <span className="text-mini"><i class="fa fa-envelope-o" aria-hidden="true"></i> Discount Programs</span>
                    </NavLink>
                  </li> : null}                  
                  {permissions.includes('Photo Gallery') ? <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/photo_gallery' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <span className="text-mini"><i class="fa fa-cog" aria-hidden="true"></i> Photo Gallery</span></NavLink>
                  </li> : null}                 
                  {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ?    <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/video_gallery' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <span className="text-mini"><i class="fa fa-cog" aria-hidden="true"></i> Video Gallery</span></NavLink>
                  </li>:null} 
               
                  <li class="nav-item">
                          <NavLink className="nav-link-padd" to='/dashboard/alllittles'
                            data-toggle="collapse"
                            data-target="#subNotifications" activeStyle={{
                              background: '#3c3b54',
                              fontWeight: '500',
                              borderLeft: '5px solid #7cc9a9',
                              display: 'flex',
                              alignItems: 'center',
                            }} onClick={this.toggle}>
                            <img className='usermanagement'></img><span className="text-mini">Littles</span>
                          </NavLink>
                  </li>
                  <li className="nav-icon nav-item">
                    <NavLink className="nav-link-padd" to='/dashboard/help_center' activeStyle={{
                      background: '#3c3b54',
                      fontWeight: '500',
                      borderLeft: '5px solid #7cc9a9',
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={this.toggle}>
                      <img className='helpcenter'></img>
                      <span className="text-mini">Help Center</span></NavLink>
                  </li>
                </ul>}
            </nav>
          </div>

        </aside>}
      </>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    getViewSettingsList: state.getViewSettingsList,

  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getViewSettingsListdata })(SideBar);
