import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userdetailsData, userdeleteaction, passwordResetMail, resendInviteMail } from '../../Store/userprofile/userdetailsaction';
import swal from 'sweetalert';
import * as moment from 'moment';
import { Tooltip, Overlay } from 'react-bootstrap';
import Model from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import HttpService from '../../Services/HttpService';
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
class AccountView extends Component {
    constructor() {
        super();
        this.state = {
            blobFile: '',
            isOpen: false,
            user_id: '',
            userdetails: '',
            picture: null,
            imagePreviewUrl: '',
            user_type: '',
            showPopover: false,
            zoom: 1,
            aspect: 1 / 1,
            src: null,
            profile_pic_user_id: '',
            eventtarget: null,
            other_user_id: null,
            activatepopop: '',
            spinner: false,
            crop: {
                disabled: true,
                locked: true,
                unit: 'px', // default, can be 'px' or '%'
                width: 100,
                height: 100
            },
            disableResend: false
        };
    }
    /** To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        if (this.props.userDetails.userdetails_res.user_details !== nextProps.userDetails.userdetails_res.user_details) {
            this.setState({
                userdetails: nextProps.userDetails.userdetails_res.user_details
            })
        }
        if (this.props.userInfo.userInfo_res.user_update !== nextProps.userInfo.userInfo_res.user_update) {
            this.setState({
                userdetails: nextProps.userInfo.userInfo_res.user_update
            })
        }
        if (this.props.accountSettings.userTypeName !== nextProps.accountSettings.userTypeName) {
            this.setState({
                user_type: nextProps.accountSettings.userTypeName.usertype_name.user_type
            })
            this.props.accountSettings.userTypeName = ''
        }
        if (this.props.userDetails.userdetails_res !== nextProps.userDetails.userdetails_res) {
            this.setState({
                user_type: nextProps.userDetails.userdetails_res.user_details.user_type
            })
        }
    }
    /** change the aspect ratio of crop tool as you preferred */
    onCropChange = (crop) => {
        this.setState({ crop })
    }

    /** to update the profic pic iamge */
    handleImageUpload(e) {
        const { blobFile } = this.state;
        const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });
        var data = new FormData();
        if (this.props.token) {
            data.append('user_id', this.props.token_value);
        }
        else {
            data.append('user_id', this.state.userdetails.user_id);
        }
        data.append('profile_pic', newImage);
        HttpService.profilepicUpload(data)
            .then(response => {
                if (response.data.status == '200') {
                    swal({ title: "Profice picture updated Successfully", icon: "success" })
                    if (this.props.token_value == 1) {
                        this.props.userdetailsData({ user_id, hit_from: 'header' })
                    }
                    else {
                        this.props.userdetailsData({ user_id, hit_from: '' })
                    }
                }
                else {
                    swal({ icon: 'error', text: "Something went wrong, please try again after some time." })
                }
           })
        if (this.props.token_value != null) {
            var user_id = this.props.token_value;
            this.setState({ user_id: user_id });
            this.props.userdetailsData({ user_id, hit_from: 'header' })
        }
        else {
            var user_id = this.state.userdetails.user_id;
            this.setState({ user_id: user_id });
            this.props.userdetailsData({ user_id, hit_from: '' })
        }
        this.setState({ isOpen: false, src: null })
    }
    /** To show the pop up */
    showPopoverDialog = (e, activatepopop, aid) => {
        this.setState({
            showPopover: true,
            eventtarget: e.target,
            other_user_id: aid,
            activatepopop: activatepopop
        })
    }
    /** To close the pop up */
    dismissPopoverDialog = (e) => {
        this.setState({
            showPopover: false
        })
    }
    /** To delete the user */
    onDeleteuser(user) {
        var user = {
            user_id: this.state.userdetails.user_id,
            action_type: "deleteuser"
        };

        HttpService.deleteuser(user)
            .then(response => {
                if (response.data.status === 200) {
                    this.setState({ spinner: false, showPopover: false })
                    swal({  title: response.data.message, icon: "success" })
                    window.location = "/dashboard/user_management"
                }
                else {
                    this.setState({
                        spinner: false,
                        showPopover: false
                    })
                    swal({
                        title: response.data.message,
                        icon: "error"

                    })
                }
            })
    }
    /** To deactivate the user */
    onDeactivateuser(user) {
        var user = {
            user_id: this.state.userdetails.user_id,
            action_type: "deactivateuser"
        };
        this.props.userdeleteaction(user);
        swal({
            title: "User Deactivated Successfully",
            icon: "success"
        })
        this.setState({
            spinner: false
        });
    }
    /** To activate the user */
    onActivateuser(user_id) {
        var user = {
            user_id: this.state.userdetails.user_id,
            action_type: "activateuser"
        };
        HttpService.activateuser(user)
            .then(response => {
                this.state.deleteSuccessData = response.data
                if (this.state.deleteSuccessData.status == 200) {
                    var data = {
                        chapter_id: this.state.chapter_id
                    }
                    swal({
                        title: this.state.deleteSuccessData.message,
                        icon: "success"
                    });
                    this.state.users = ""
                    this.props.getInActiveUsers(data);
                    this.setState({
                        spinner: false,
                        showPopover: false
                    });
                }
                else {
                    this.setState({
                        spinner: false,
                        showPopover: false
                    });
                    swal({
                        icon: 'error',
                        text: "Something went wrong, Please try again after sometime."
                    });
                }
            })
            .catch(error => {
                this.setState({
                    spinner: false,
                    showPopover: false
                });
            })
    }
    /** Send the mail to reset password */
    sendPassword(email) {
        var email = {
            email: email,
        };
        HttpService.sendpasswordresetmail(email)
            .then(response => {
                if (response.data.status === 200) {
                    swal({ text: response.data.message, icon: "success"  })
                    this.setState({ spinner: false, showPopover: false })
                }
                else {
                    swal({ text: response.data.message, icon: "error" })
                    this.setState({  spinner: false, showPopover: false })
                }
            })
            .catch(error => {
                swal({ text: "Something went wrong, Please try again after sometime", icon: "error" })
                this.setState({ spinner: false, showPopover: false })
            })
        this.setState({ spinner: false, showPopover: false });
    }

    /** To call the api to sunbmit the values */
    handleSubmit() {
        swal({ icon: 'success', title: 'Invitation link has been sent to email' })
    }
    /** To send a resend invitaion mail */
    resendinvitemail(data) {
        this.setState({ spinner: true, disableResend: true })
        var data = {
            user_id: this.state.userdetails.user_id,
        };
        HttpService.resendInviteMail(data)
            .then(response => {
                if (response.data.status == 200) {
                    this.setState({ spinner: false })
                    swal({ text: response.data.message, icon: "success" })
                    this.setState({ spinner: false, disableResend: false });
                }
                else {
                    this.setState({ spinner: false, disableResend: false })
                    swal({ text: response.data.message, icon: "error" })
                    this.setState({ spinner: false, disableResend: false });
                }
            })
            .catch(error => {
                this.setState({
                    spinner: false,
                    disableResend: false
                });
            })
    }

    /** To select the file from device */
    onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState({ isOpen: true, src: null })
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    /** This is a callback that is triggered when the image gets loaded. */
    onImageLoaded = (image) => {
        this.imageRef = image;
        return false
    };
    /** A callback which happens after a resize, drag, or nudge. Passes the current crop state object in pixels and percent.  */
    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };
    /** change the aspect ratio of crop tool as you preferred */
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    /** To convert the cropped image into url */
    async makeClientCrop(crop) {
        if (document.getElementById("photo") && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                document.getElementById("photo"),
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }
    /**  To get the cropped image  */
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = document.getElementById("photo").naturalWidth / document.getElementById("photo").width;
        const scaleY = document.getElementById("photo").naturalHeight / document.getElementById("photo").height;
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            document.getElementById("photo"),
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    this.setState({ blobFile: blob })
                    resolve(this.fileUrl);
                }, 'image/jpeg',
                1
            );
        });
    }
    /** To close the dialog */
    handleCloseDialog = () => {
        this.setState({ isOpen: false, src: null });
    };
    /** To open the dialog */
    handleOpenDialog = () => {
        this.setState({ isOpen: true });
    };
    /** render() function is used to update the UI. */
    render() {
        const { crop, croppedImageUrl, src, showPopover } = this.state;
        let $imagePreview = null;
        if (src === null) {
            $imagePreview = (<img for="photo-upload" className="img-circle elevation-2" src={this.state.userdetails.profile_pic} />);

        }

        return (
            <div className="col-md-4 col-lg-3" >
                <div className="useracount-sec">
                    <div className='mt-4'>
                        {src ?
                            <Model isOpen={this.state.isOpen} shouldCloseOnEsc={true} ariaHideApp={false} onRequestClose={this.handleCloseDialog}>
                                <ReactCrop src={src} circularCrop={true} for='photo-upload' crop={crop} locked ruleOfThirds onImageLoaded={() => this.onImageLoaded()}
                                    onComplete={this.onCropComplete} onChange={this.onCropChange} >
                                    <img id='photo' src={src}></img>
                                </ReactCrop>
                               <Row className="my-4">
                                        <Col md="6" className='save-sec'>
                                            <button type="submit" className="btn btn-info btn-success mt-1" onClick={() => { this.handleImageUpload() }}>Upload</button>
                                        </Col>
                                        <Col md="6">
                                            <div className="cancel-sec">
                                                <button type="cancel" className="cancelbtnnew" onClick={() => this.handleCloseDialog()}>Cancel</button>
                                            </div>
                                        </Col>
                                </Row>
                            </Model> :
                            <img for="photo-upload" className="img-circle elevation-2" src={this.state.userdetails.profile_pic} />
                        }
                        <div>
                            <label htmlFor="photo-upload" className="useredit-icon">
                                <span className="camera-icon"><i class="fas fa-camera"></i></span>
                                <input id="photo-upload" style={{ display: 'none' }} type="file" onChange={this.onFileChange} />
                            </label>
                        </div>
                        <div className='user-type'>
                            <h4 className="mb-0 border-0 fontbold" key={this.state.userdetails.user_id}>{this.state.userdetails.display_name}</h4>
                            <h8>{this.state.user_type}</h8>
                        </div>
                        <hr className="hr-gray" />
                        {this.props.profiledata ? null : <div>

                            {this.state.userdetails.lastapp_activity !== null || this.state.userdetails.lastapp_activity == 'undefined' ?
                                <div>
                                    <div className='d-flex m-2'>
                                        <h8 className='act-title'>Active Since </h8>
                                        {this.state.userdetails.active_since !== null ? <h8 className="text-green"> {moment(this.state.userdetails.active_since).format('MM/DD/YYYY')}</h8> : <h8 className="text-green">-</h8>}
                                    </div>
                                    <hr className="hr-gray" />
                                    <div className='d-flex m-2'>
                                        <h8 className='act-title'>Last App Activity</h8>
                                        {this.state.userdetails.lastapp_activity !== null ? <h8 className="text-green"> {moment(this.state.userdetails.lastapp_activity).format('MM/DD/YYYY')}</h8> : <h8 className="text-green">-</h8>}
                                    </div>
                                    <hr className="hr-gray" />
                                    <div className='d-flex m-2'>
                                        <h8 className='act-title'>Activities Completed </h8>
                                        <h8 className="text-green">2</h8>
                                    </div>
                                    <hr className="hr-gray" />
                                    <div className='d-flex m-2'>
                                        <h8 className='act-title'>Events Attended </h8>
                                        <h8 className="text-green">0</h8>
                                    </div>
                                    <hr className="hr-gray" />
                                    <div className="userdatabtn-sec my-3">
                                        {this.state.activatepopop == 'reset' ? <>
                                        <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                            <Tooltip>
                                                <div className='text-center p-4'>
                                                    <p className='tool-title'>Send Password Reset Email</p>
                                                    <p className='tool-para'>Are you sure you want to send mail</p>
                                                </div>
                                                <div className='d-flex btn-sec'>
                                                    <button onClick={() => this.sendPassword(this.state.userdetails.email)} className='tool-btn-yes'>Yes, Send</button>
                                                    <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                                </div>
                                            </Tooltip>
                                        </Overlay>
                                        </> : null
                                        }
                                        <a onClick={(e) => this.showPopoverDialog(e, 'reset')} className="btn-user-data mb-3"> Send Password Reset Email</a>
                                        {this.state.userdetails.usertype_id == 1 ||
                                            this.state.userdetails.usertype_id == 2 ||
                                            this.state.userdetails.usertype_id == 3 ||
                                            this.state.userdetails.usertype_id == undefined ?
                                            <div>
                                                {
                                                    this.state.activatepopop == 'deactivate' ?
                                                        <><Overlay show={showPopover} target={this.state.eventtarget}  placement="left" rootClose="true">
                                                            <Tooltip>
                                                                <div className='text-center p-4'>
                                                                    <p className='tool-title'>Deactivate User Account</p>
                                                                    <p className='tool-para'>Are you sure you want to deactivate this user account?</p>
                                                                </div>
                                                                <div className='d-flex btn-sec'>
                                                                    <button onClick={() => this.onDeactivateuser(this.state.userdetails.user_id)} className='tool-btn-yes'>Yes, Deactivate</button>
                                                                    <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                                                </div>
                                                            </Tooltip>
                                                        </Overlay>
                                                        </> :
                                                        this.state.activatepopop == 'activate' ?
                                                            <><Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                                <Tooltip>
                                                                    <div className='text-center p-4'>
                                                                        <p className='tool-title'>Activate User Account</p>
                                                                        <p className='tool-para'>Are you sure you want to activate this user account?</p>
                                                                    </div>
                                                                    <div className='d-flex btn-sec'>
                                                                        <button onClick={() => this.onActivateuser(this.state.userdetails.user_id)} className='tool-btn-yes'>Yes, Activate</button>
                                                                        <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                                                    </div>
                                                                </Tooltip>
                                                            </Overlay>
                                                            </> : null
                                                }
                                                {this.state.userdetails.active_status == 3 ? null :
                                                    <>
                                                        {this.state.userdetails.active_status == 0 ?
                                                            <a onClick={(e) => this.showPopoverDialog(e, 'deactivate')} className="btn-user-data text-red deleteuserline mb-3">Deactivate User Account</a> :
                                                            <a onClick={(e) => this.showPopoverDialog(e, 'activate')} className="btn-user-data text-red deleteuserline mb-3"> Activate User Account</a>}</>}
                                                {this.state.activatepopop == 'delete' ?
                                                    <><Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                        <Tooltip>
                                                            <div className='text-center p-4'>
                                                                <p className='tool-title'>Delete User Account</p>
                                                                <p className='tool-para'>Are you sure you want to delete the account for <span className='tool-para' style={{ color: '#ff6565' }}>{this.state.userdetails.display_name}?</span> Deleted accounts may not be restored.</p>
                                                            </div>
                                                            <div className='d-flex btn-sec'>
                                                                <button onClick={() => this.onDeleteuser(this.state.userdetails.user_id)} className='tool-btn-yes'>Yes, Delete User</button>
                                                                <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                                            </div>
                                                        </Tooltip>
                                                    </Overlay>
                                                    </> : null
                                                }
                                                {this.state.userdetails.active_status == 3 ? null :
                                                    <a onClick={(e) => this.showPopoverDialog(e, 'delete')} className="btn-user-data text-red deleteuserline mb-3">Delete User Account</a>}
                                            </div> : null}
                                    </div>
                                </div> :
                                <div>
                                    <h4 className="mb-0 border-0 fontbold user-invite"  >User Invitation Pending</h4>
                                    <p className='invite-para'>
                                        An invitation has been sent to this user<br></br>but has not been accepted. Click below to<br></br>resend the account invitation email.</p>
                                    <center>
                                        <div class="loader-spinner">
                                            {this.state.spinner ? <Circles color="#7cc9a9" height={25} width={25} /> : null}
                                        </div>
                                    </center>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className="form-group mt-4">
                                        <input type="button" className="btn btn-info btn-resend mt-1" value="Resend Invite" disabled={this.state.disableResend}
                                            onClick={() => this.resendinvitemail(this.state.userdetails.user_id)}
                                        />
                                    </div>
                                    <hr style={{ marginTop: '31px' }} className="hr-gray" />
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }} className="userdatabtn-sec">
                                        {
                                            this.state.activatepopop == 'delete' ?
                                                <><Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                                    <Tooltip>
                                                        <div className='text-center p-4'>
                                                            <p className='tool-title'>Delete User Account</p>
                                                            <p className='tool-para'>Are you sure you want to delete the account for <span className='tool-para' style={{ color: '#ff6565' }}>{this.state.userdetails.display_name}?</span> Deleted accounts may not be restored.</p>
                                                        </div>
                                                        <div className='d-flex btn-sec'>
                                                            <button onClick={() => this.onDeleteuser(this.state.userdetails.user_id)} className='tool-btn-yes'>Yes, Delete User</button>
                                                            <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                                                        </div>
                                                    </Tooltip>
                                                </Overlay>
                                                </> : null
                                        }
                                        {this.state.userdetails.active_status == 3 ? null :
                                            <a onClick={(e) => this.showPopoverDialog(e, 'delete')} className="btn-user-data text-red deleteuserline mb-3"> Delete User Account</a>}
                                    </div>
                                </div>
                            }
                        </div>}
                    </div>
                </div>
            </div >
        )
    }
}
/** mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        userInfo: state.userInfo,
        accountSettings: state.accountSettings
    }
}
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, { userdetailsData, userdeleteaction, passwordResetMail, resendInviteMail })(AccountView)
