import React, { Component } from 'react';
import { getActivityHistory } from '../../Store/activityhistory/activityhistoryaction';
import { connect } from 'react-redux';
import { Circles } from 'react-loader-spinner';
import ReactPagenate from 'react-paginate';
import * as moment from 'moment';

class ActivityHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            events: [],
            noeventsmessage: '',
            noactivitymessage: '',
            spinner: false,
            DefaultMessage:'',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            chapters: [],
            filterchapter: [],
            slice: [],
        }
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    /** To make the api call initial page load */
    componentDidMount() {
        this.setState({
            spinner: true
        })
        var data = {
            hit_from: 'activities',
            user_id: this.props.userId

        };
        this.props.getActivityHistory(data)
    }
    /** To update the state in response with the new received props */
    componentWillReceiveProps(nextProps) {
        this.state.activities =  nextProps.activitieseventsHistory.activities
        if (this.state.activities?.length == 0) {
          this.setState({
            spinner: false,
            pageCount: 0,
            DefaultMessage: 'No activities Found.'
          });
        }
        else {
          this.state.filterchapter = this.state.activities?.slice(this.state.offset, this.state.offset + this.state.perPage)
          const postData = this.state.filterchapter.map(pd => <React.Fragment>
          </React.Fragment>)
          this.setState({
            spinner: false,
            pageCount: Math.ceil(this.state.activities?.length / this.state.perPage),
            postData
          });
        }

    }
    /** For pagination */
    receivedData() {
        this.state.slice = this.state.activities.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = this.state.slice.map(pd => <React.Fragment>
          <img src={pd.thumbnailUrl} alt="" />
        </React.Fragment>)    
        this.setState({
          pageCount: Math.ceil(this.state.activities.length / this.state.perPage),
          postData
        })
      }    
      /** Goto next,previous page in the pagination */
      handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
          currentPage: selectedPage,
          offset: offset
        }, () => {
          this.receivedData()
        })
      };
      /** Go to next page in the pagination */
    render() {
        const { activities } = this.state
        return (
            <div style={{ justifyContent: 'center', height: '10px' }} className="container">
                <center>
                                 <div class="loader-spinner">
                {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
                </div>
                </center>
                <div> 
                  {activities?.length > 0 ? 
                    <div style={{ width: '100%' }} className="row">
                        <div className="mb-3 col-12 text-center">
                          <div style={{height:'450px'}} className="table-responsive">
                            <table id="dataTable">
                              <thead>
                                {activities?.length > 0 ? <tr>
                                  <th>Activity Name</th>
                                  <th>Created Date</th>                             
                                </tr> : null
                                }
                              </thead>
                              <tbody>
                            {  activities.slice(this.state.offset, this.state.offset + this.state.perPage).map((data) =>
                            <tr>
                            <td>{data.title}</td>
                            <td>{moment(data.activity_date).format('MM/DD/YYYY')}</td>
                            </tr>
                            )}
                            </tbody>
                            </table>                          
                          </div>
                          {activities?.length > 0 ? <div className="pagination">
                              {this.state.postData}
                              <ReactPagenate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />
                            </div> : null
                            }
                        </div>
                    </div> 
                    : <center> <h3 className="pagename">No Activities Available</h3></center>}              
                </div>
            </div>
        );
    }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
    return {
        activitieseventsHistory: state.activityhistoryreducer.activityhistory
    }
}
/** Connects a React component to a Redux store */
export default connect(mapStateToProps, { getActivityHistory })(ActivityHistory)
