import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
class GettingStarted extends Component {
    constructor(props) {
        super(props)
    }
    /* Goto help center page */
    backToHelpCenter = () => {
        this.props.history.push('/dashboard/help_center');
    }
    /* Goto about page */
    aboutbigs = () => {
        this.props.history.push('/dashboard/help_center/aboutbigs');
    }
    /* render() function is used to update the UI. */
    render() {
        return (
            <div className="container">
                <Row>
                    <Col md="6">
                        <div className="horizontal-container">
                        <label className="label-discount" onClick={() => this.backToHelpCenter()}>Help Center</label>
                            <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
                            <div className="horizontal-container">
                            <label className="label-discount">Getting Started</label>
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <span className="fa fa-search head-search"></span>
                        <input style={{ paddingLeft: '40px' }} type="text" className="form-control" placeholder="Search for help on specific topics..." onChange={(e) => this.searchHandler(e)} />
                    </Col>
                </Row>
                <div className="box">
                    <Row>
                        <Col md="3">
                            <h4 className="helphead">Articles</h4>
                            <ul className='list_image'>
                                <li style={{ fontSize: '11px', fontWeight: '500', marginTop: '2px', marginLeft: '5px', marginBottom: '-15px' }} className="pagename mb-3 resources-text" onClick={() => this.aboutbigs()} >What is BigS Connect</li>
                                <li style={{ marginTop: '-8px' }} className="helpcenterlist">How to Begin</li>
                                <li className="helpcenterlist" >Navigation Basics</li>
                                <li className="helpcenterlist" >What is BigS Connect</li>
                                <li className="helpcenterlist">How to Begin</li>
                                <li className="helpcenterlist" >Navigation Basics</li>
                                <li className="helpcenterlist" >What is BigS Connect</li>
                                <li className="helpcenterlist">How to Begin</li>
                                <li className="helpcenterlist" >Navigation Basics</li>
                                <li className="helpcenterlist" >What is BigS Connect</li>
                                <li className="helpcenterlist">How to Begin</li>
                                <li className="helpcenterlist" >Navigation Basics</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
/*Default export is used to export a single class, or function from a module*/
export default (withRouter(GettingStarted))