import { PLAN_LIST,  PLAN_LIST_ERROR, } from '../types';
import HttpService from '../../Services/HttpService';

export const planListres = (response) => {
    return {
        type: PLAN_LIST,
        response
    }
}

export const planListerror = () => {
    return {
        type: PLAN_LIST_ERROR
    }
}


export const getplanListData = () => {
    return (dispatch) => {
        HttpService.planList()
        .then(response => {
            console.log('response',response);
            dispatch(planListres(response.data));
        })
    }
}

    

