import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HttpService from '../../Services/HttpService';
import { Circles } from 'react-loader-spinner';
import { getViewSettingsListdata } from '../../Store/viewsettings/viewsettingsaction'
import swal from 'sweetalert';
import BarChart from 'react-bar-chart';
const defaultLabelStyle = {
  fontSize: '5px',
  fontFamily: 'Source Sans Pro',
};

class NewDashBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationusers_count: 0,
      agencyusers_count: 0,
      bigsusers_count: 0,
      inactive_chapters: 0,
      active_chapters: 0,
      active_acivities: 0,
      inactive_acivities: 0,
      inactive_resources: 0,
      active_resources: 0,
      active_discounts: 0,
      inactive_discounts: 0,
      active_littles: 0,
      inactive_littles: 0,
      scheduled_notifications: 0,
      sent_notifications: 0,
      usertypeid: '',
      agencyPermissions: [],
      allPermissions: [],
      chapter_id: '',
      DefaultMessage: "",
      agencyfutureactive_events:0,
      agencyfutureinactive_events:0,
      agencyfuturecanceled_events:0,
      agencypastactive_events:0,
      agencypastinactive_events:0,
      agencypastcanceled_events:0,
      bigsfutureactive_events:0,
      bigsfutureinactive_events:0,
      bigsfuturecanceled_events:0,
      bigspastactive_events:0,
      bigspastinactive_events:0,
      bigspastcanceled_events:0,

    }

  }
/*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    console.log("dash switchTo:- ",nextProps)
    this.setState({
      agencyPermissions: nextProps.getViewSettingsList.givenPermissions?.settings,
      allPermissions: nextProps.getViewSettingsList.givenPermissions?.allsettings
    })
    if (this.props.dashboardlist_data != nextProps.dashboardlist_data) {
      this.setState({
        agencyusers_count: nextProps.dashboardlist_data.dashboardlist.users_data.agency_users,
        bigsusers_count: nextProps.dashboardlist_data.dashboardlist.users_data.bigs_users,
        organizationusers_count: nextProps.dashboardlist_data.dashboardlist.users_data.organization_users,
        active_acivities: nextProps.dashboardlist_data.dashboardlist.activities_data.active_activities,
        inactive_acivities: nextProps.dashboardlist_data.dashboardlist.activities_data.inactive_activities,
        active_resources: nextProps.dashboardlist_data.dashboardlist.resources_data.active_resources,
        inactive_resources: nextProps.dashboardlist_data.dashboardlist.resources_data.inactive_resources,
        active_discounts: nextProps.dashboardlist_data.dashboardlist.discounts_data.active_discounts,
        inactive_discounts: nextProps.dashboardlist_data.dashboardlist.discounts_data.inactive_discounts,
        scheduled_notifications: nextProps.dashboardlist_data.dashboardlist.notifications_data.scheduled_notifications,
        sent_notifications: nextProps.dashboardlist_data.dashboardlist.notifications_data.sent_notifications,
        agencyfutureactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_agencysponsored_events.futureactive_events,
        agencyfuturecanceled_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_agencysponsored_events.futurecanceled_events,
        agencyfutureinactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_agencysponsored_events.futureinactive_events,
        agencypastactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_agencysponsored_events.pastactive_events,
        agencypastinactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_agencysponsored_events.pastinactive_events,
        agencypastcanceled_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_agencysponsored_events.pastcanceled_events,
        bigsfutureactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_bigs_events.futureactive_events,
        bigsfutureinactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_bigs_events.futureinactive_events,
        bigsfuturecanceled_events: nextProps.dashboardlist_data.dashboardlist.events_data.future_bigs_events.futurecanceled_events,
        bigspastactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_bigs_events.pastactive_events,
        bigspastinactive_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_bigs_events.pastinactive_events,
        bigspastcanceled_events: nextProps.dashboardlist_data.dashboardlist.events_data.past_bigs_events.pastcanceled_events,
      })
    }
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.props.getViewSettingsListdata()
    const { usertypeid } = this.state
    this.setState({
      spinner: true

    })
    var data = {
      chapter_id : JSON.parse(localStorage.getItem('chapter_id'))
    }
    HttpService.getDashboardData(data)
      .then(response => {
        console.log('agency dashboard',response)
        this.state.getDashboardData = response.data
        if (this.state.getDashboardData.status === 200) {

          this.setState({
            spinner: false

          })
          if (usertypeid == 5) {
            this.setState({
              active_chapters: response.data.chapters_data?.active_chapters,
              inactive_chapters: response.data.chapters_data?.inactive_chapters,
              active_littles: response.data.littles_data?.active_littles,
              inactive_littles: response.data.littles_data?.inactive_littles,
            })
          }
          //user data API
          this.setState({
            agencyusers_count: response.data.users_data?.agency_users,
            bigsusers_count: response.data.users_data?.bigs_users,
            organizationusers_count: response.data.users_data?.organization_users,
            active_acivities: response.data.activities_data?.active_activities,
            inactive_acivities: response.data.activities_data?.inactive_activities,
            active_resources: response.data.resources_data?.active_resources,
            inactive_resources: response.data.resources_data?.inactive_resources,
            active_discounts: response.data.discounts_data?.active_discounts,
            inactive_discounts: response.data.discounts_data?.inactive_discounts,
            future_agencysponsored_count: response.data.events_data?.future_agencysponsored_events?.future_count,
            future_bigs_count: response.data.events_data?.future_bigs_events?.future_count,
            past_agencysponsored_count: response.data.events_data?.past_agencysponsored_events?.past_count,
            past_bigs_count: response.data.events_data?.past_bigs_events?.past_count,
            scheduled_notifications: response.data.notifications_data?.scheduled_notifications,
            sent_notifications: response.data.notifications_data?.sent_notifications,
            agencyfutureactive_events: response.data.events_data?.future_agencysponsored_events?.futureactive_events,
            agencyfuturecanceled_events: response.data.events_data?.future_agencysponsored_events?.futurecanceled_events,
            agencyfutureinactive_events: response.data.events_data?.future_agencysponsored_events?.futureinactive_events,
            agencypastactive_events: response.data.events_data?.past_agencysponsored_events?.pastactive_events,
            agencypastinactive_events: response.data.events_data?.past_agencysponsored_events?.pastinactive_events,
            agencypastcanceled_events: response.data.events_data?.past_agencysponsored_events?.pastcanceled_events,
            bigsfutureactive_events: response.data.events_data?.future_bigs_events?.futureactive_events,
            bigsfutureinactive_events: response.data.events_data?.future_bigs_events?.futureinactive_events,
            bigsfuturecanceled_events: response.data.events_data?.future_bigs_events?.futurecanceled_events,
            bigspastactive_events: response.data.events_data?.past_bigs_events?.pastactive_events,
            bigspastinactive_events: response.data.events_data?.past_bigs_events?.pastinactive_events,
            bigspastcanceled_events: response.data.events_data?.past_bigs_events?.pastcanceled_events
          })
          this.setState({
            spinner: false,
          })
        }
        else {
          this.setState({
            spinner: false

          })
          swal({
            text: this.state.getDashboardData.message,
            icon: "error"
          })
        }
      })
  }
  /* Goto list of dicounts page */
  onDiscount() {
    this.props.history.push({
      pathname: '/dashboard/discount_programs'
    })
  }
   /* Goto list of users page */
  onUser() {
    this.props.history.push({
      pathname: '/dashboard/user_management'
    })
  }
   /* Goto list of chapters page */
  onChapters() {
    this.props.history.push({
      pathname: '/dashboard/Chapters/ChaptersList'
    })
  }
  /* Goto list of activities page */
  onAcivities() {
    this.props.history.push({
      pathname: '/dashboard/activities'
    })
  }
  /* Goto list of resources page */
  onResources() {
    this.props.history.push({
      pathname: '/dashboard/resources'
    })
  }
  /* Goto list of notifications page */
  onNotifications() {
    this.props.history.push({
      pathname: '/dashboard/notifications'
    })
  }
    /* Goto agency sponsored events calender page */
  onAgency() {
    this.props.history.push({
      pathname: '/dashboard/agencysponsored_events'
    })
  }
      /* Goto bigs connect community events calender page */
  onBigs() {
    this.props.history.push({
      pathname: '/dashboard/bigsconnect_community'
    })
  }
  /* render() function is used to update the UI. */
  render() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    this.state.usertypeid = this.currentuser.user.usertypeid
    const permissions = this.state.agencyPermissions?.map((e) => {
      return e.permission_name
    })

    const allPermissions = this.state.allPermissions?.map((e) => {
      return e.permission_name
    })

    const customers = this.state.agencyPermissions;

    localStorage.setItem("activescreen", JSON.stringify('dashboard'));
    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));

    this.state.usertypeid = this.currentuser.user.usertypeid
    const { chapters_count, agencyusers_count, bigsusers_count, organizationusers_count, active_chapters, inactive_chapters,
      active_acivities, inactive_acivities, active_resources, inactive_resources, active_discounts, inactive_discounts,
      active_littles, inactive_littles, usertypeid, scheduled_notifications, sent_notifications, agencyfutureactive_events, agencyfuturecanceled_events, agencyfutureinactive_events,
      agencypastactive_events, agencypastinactive_events, agencypastcanceled_events,
      bigsfutureactive_events, bigsfutureinactive_events, bigsfuturecanceled_events,
      bigspastactive_events, bigspastinactive_events,  bigspastcanceled_events } = this.state;

    const margin = { top: 30, right: 30, bottom: 30, left: 40 };
    return (
      <div className="container">
        <div>
            <h3 className="pagename mt-4">Dashboard</h3>
        </div>
      <center>
            <div class="loader-spinner mb-5">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
        <div>
          {this.state.spinner === true ?  null:
        <Row>
            {usertypeid == 5 || usertypeid == 4 ?
              <>
                <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onUser()}>
                    <h4 className='text-center mb-4'>User Management</h4>
                    {organizationusers_count != 0 || agencyusers_count != 0 || bigsusers_count != 0 ?
                      <>
                        <div>
                          <PieChart
                            data={[
                              { title: 'Organization Users', value: parseInt(organizationusers_count), color: '#6A2135' },
                              { title: 'Agency Users', value: parseInt(agencyusers_count), color: '#E38627' },
                              { title: 'Bigs Users', value: parseInt(bigsusers_count), color: '#C13C37' },
                            ]}
                            lineWidth={50}
                            paddingAngle={5}
                            label={({ dataEntry }) => dataEntry.value}
                            labelStyle={defaultLabelStyle}
                            labelPosition={75}
                          />
                        </div>
                        <div className='legends d-flex mt-4'>
                          <div className='d-flex mr-2'>
                            <div className='org-user mr-1'></div>
                            <p>Organization</p>
                          </div>
                          <div className='d-flex mr-2'>
                            <div className='user mr-1'></div>
                            <p>Agency</p>
                          </div>
                          <div className='d-flex mr-2'>
                            <div className='big-user mr-1'></div>
                            <p>Bigs</p>
                          </div>
                        </div>
                        <div className='clearfix'></div> </> : <center><h4>No Data Found</h4></center>}
                  </div>

                </Col>   </> : null}

            {usertypeid == 5 ?
              <Col xs lg="4" className='mb-4'>
                <div className="box-dashboard" onClick={() => this.onChapters()}>
                  <h4 className='text-center  mb-4'>Chapters</h4>
                  {active_chapters != 0 || inactive_chapters != 0 ?
                    <>
                      <div>
                        <PieChart
                          data={[
                            { title: 'Active Chapters', value: parseInt(active_chapters), color: '#E38627' },
                            { title: 'Inactive Chapters', value: parseInt(inactive_chapters), color: '#C13C37' }
                          ]}
                          lineWidth={50}
                          paddingAngle={5}
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={defaultLabelStyle}
                          labelPosition={75}
                        />
                      </div>
                      <div className='legends d-flex mt-4'>
                        <div className='d-flex mr-2'>
                          <div className='user mr-1'></div>
                          <p>Active</p>
                        </div>
                        <div className='d-flex mr-2'>
                          <div className='big-user mr-1'></div>
                          <p>Inactive</p>
                        </div>
                      </div></> : <center><h4>No Data Found</h4></center>}
                </div>
              </Col> : null}
            {usertypeid == 5 || usertypeid == 4 ?
              <>
                <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onAcivities()}>
                    <h4 className='text-center mb-4'>Activities</h4>
                    { active_acivities != 0 || inactive_acivities != 0 ?
                      <div>
                        <PieChart
                          data={[
                            { title: 'Active Acivities', value: parseInt(active_acivities), color: '#E38627' },
                            { title: 'Inactive Acivities', value: parseInt(inactive_acivities), color: '#C13C37' }
                          ]}
                          lineWidth={50}
                          paddingAngle={5}
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={defaultLabelStyle}
                          labelPosition={75}
                        />
                        <div className='legends d-flex mt-4'>
                          <div className='d-flex mr-2'>
                            <div className='user mr-1'></div>
                            <p>Active</p>
                          </div>
                          <div className='d-flex mr-2'>
                            <div className='big-user mr-1'></div>
                            <p>Inactive</p>
                          </div>
                        </div>
                      </div> : <center><h4>No Data Found</h4></center>}
                  </div>
                </Col>

                <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onResources()}>
                    <h4 className='mb-4 text-center'>Resources</h4>
                    {active_resources != 0 || inactive_resources != 0 ? <div>

                      <BarChart
                        width={280}
                        height={300}
                        margin={margin}
                        data={[
                          { text: 'Active', value: parseInt(active_resources) },
                          { text: 'Inactive', value: parseInt(inactive_resources) }
                        ]} />

                    </div> : <center><h4>No Data Found</h4></center>}
                  </div>
                </Col>
                {usertypeid == 5 ?
                  <Col xs lg="4" className='mb-4'>
                    <div className="box-dashboard">
                      <h4 className='mb-4 text-center'>Littles</h4>
                      {active_littles != 0 || inactive_littles != 0 ?
                        <div>
                          <BarChart
                            width={280}
                            height={300}
                            margin={margin}
                            data={[
                              { text: 'Active', value: parseInt(active_littles) },
                              { text: 'Inactive', value: parseInt(inactive_littles) }
                            ]} />

                        </div> : <center><h4>No Data Found</h4></center>}
                    </div>
                  </Col> : null}
                <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onDiscount()}>
                    <h4 className='mb-4 text-center'>Discount Programs</h4>
                    {active_discounts != 0 || inactive_discounts != 0 ?
                      <div>
                        <BarChart
                          width={280}
                          height={300}
                          margin={margin}
                          data={[
                            { text: 'Active', value: parseInt(active_discounts) },
                            { text: 'Inactive', value: parseInt(inactive_discounts) }
                          ]} />

                      </div> : <center><h4>No Data Found</h4></center>}
                  </div>
                </Col>
                <Col  md="4" className='mb-4'>
                  <div className="box-dashboard text-center" onClick={() => this.onBigs()}>
                    <h4 className='mb-3'>Bigs Events</h4>
                    {bigsfutureactive_events != 0 || bigsfutureinactive_events != 0 || bigsfuturecanceled_events != 0 || bigspastinactive_events != 0 || bigspastcanceled_events != 0 || bigspastactive_events != 0 ? 
                   <>
                
                 <p>Future Active Events: {bigsfutureactive_events}</p>
                    <p>Future Inactive Events: {bigsfutureinactive_events}</p>
                    <p>Future Cancel Events: {bigsfuturecanceled_events}</p>
                    <p>Past Active Events: {bigspastactive_events}</p> 
                    <p>Past Inactive Events: {bigspastinactive_events}</p> 
                    <p>Past Cancel Events: {bigspastcanceled_events}</p>  
                    </> : <center><h4>No Data Found</h4></center> } 
                  </div>
                </Col>
                       <Col  md="4" className='mb-4'>
                  <div className="box-dashboard text-center" onClick={() => this. onAgency()}>
                    <h4 className='mb-3'>Agencysponsored Events</h4>
                   
                    {agencyfutureactive_events != 0 || 
                    agencyfuturecanceled_events != 0 || 
                    agencyfutureinactive_events != 0 || 
                    agencypastactive_events != 0 || 
                    agencypastinactive_events != 0 || 
                    agencypastcanceled_events != 0 ? 
                   <>
                   <p>Future Active Events: {agencyfutureactive_events}</p>
                    <p>Future Inactive Events: {agencyfutureinactive_events}</p>
                    <p>Future Cancel Events: {agencyfuturecanceled_events}</p>
                    <p>Past Active Events: {agencypastactive_events}</p>
                    <p>Past Inactive Events: {agencypastinactive_events}</p>
                    <p>Past Cancel Events: {agencypastcanceled_events}</p>
                    </> : <center><h4>No Data Found</h4></center> } 
                  </div>
                </Col>
                <Col md="4" className='mb-4'>
                  <div className="box-dashboard text-center" onClick={() => this.onNotifications()}>
                    <h4 className='mb-3'>Notifications</h4>
                    {sent_notifications != 0 || scheduled_notifications != 0 ? 
                  <>
                   <p>Sent Notifications: {sent_notifications}</p>
                    <p>Scheduled Notifications: {scheduled_notifications}</p>
                    </>:<center><h4>No Data Found</h4></center> }
                  </div>
                </Col>

              </> : <>
                {permissions.includes('User Management') ?
                  <Col xs lg="4" className='mb-4'>
                    <div className="box-dashboard" onClick={() => this.onUser()}>
                      <h4 className='text-center mb-4'>User Management</h4>
                      {bigsusers_count != 0 ? <>
                        <div>
                          <PieChart
                            data={[
                              { title: 'Bigs Users', value: parseInt(bigsusers_count), color: '#C13C37' },
                            ]}
                            lineWidth={50}
                            paddingAngle={5}
                            label={({ dataEntry }) => dataEntry.value}
                            labelStyle={defaultLabelStyle}
                            labelPosition={75}
                          />
                        </div>
                        <div className='legends d-flex mt-4 ml-5'>
                          <div className='d-flex mr-2'>
                            <div className='big-user mr-1'></div>
                            <p>Bigs</p>
                          </div>
                        </div>
                        <div className='clearfix'></div></> : <center><h4>No Data Found</h4></center>}
                    </div>
                  </Col> : null}
                {permissions.includes('Activities') ? <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onAcivities()}>
                    <h4 className='text-center mb-4'>Activities</h4>
                {active_acivities != 0 || inactive_acivities != 0 ?
                    <div>
                      <PieChart
                        data={[
                          { title: 'Active Acivities', value: parseInt(active_acivities), color: '#E38627' },
                          { title: 'Inactive Acivities', value: parseInt(inactive_acivities), color: '#C13C37' }
                        ]}
                        lineWidth={50}
                        paddingAngle={5}
                        label={({ dataEntry }) => dataEntry.value}
                        labelStyle={defaultLabelStyle}
                        labelPosition={75}
                      />
                      <div className='legends d-flex mt-4 ml-6'>
                        <div className='d-flex mr-2'>
                          <div className='user mr-1'></div>
                          <p>Active</p>
                        </div>
                        <div className='d-flex mr-2'>
                          <div className='big-user mr-1'></div>
                          <p>Inactive</p>
                        </div>
                      </div>
                    </div>:<center><h4>No Data Found</h4></center>}
                  </div>
                </Col> : null}

                {permissions.includes('Resources') ? <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onResources()}>
                    <h4 className='mb-4 text-center'>Resources</h4>
                  {active_resources!= 0 || inactive_resources != 0?
                    <div>
                      <BarChart
                        width={280}
                        height={300}
                        margin={margin}
                        data={[
                          { text: 'Active', value: parseInt(active_resources) },
                          { text: 'Inactive', value: parseInt(inactive_resources) }
                        ]}/>
                    </div>:<center><h4>No Data Found</h4></center>}
                  </div>
                </Col> : null}
                {permissions.includes('Discount Programs') ? <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard" onClick={() => this.onDiscount()}>
                    <h4 className='mb-4 text-center'>Discount Programs</h4>
                    {active_discounts != 0 || inactive_discounts != 0?
                    <div>
                      <BarChart
                        width={280}
                        height={300}
                        margin={margin}
                        data={[
                          { text: 'Active', value: parseInt(active_discounts) },
                          { text: 'Inactive', value: parseInt(inactive_discounts) }
                        ]}/>
                    </div>:<center><h4>No Data Found</h4></center>}
                  </div>
                </Col> : null}

                {permissions.includes('Events') ? <Col xs lg="4" className='mb-4'>
                    <div className="box-dashboard text-center" onClick={() => this.onBigs()}>
                    <h4 className='mb-3'>Bigs Events</h4>
                    {bigsfutureactive_events != 0 || bigsfutureinactive_events != 0 || bigsfuturecanceled_events != 0 || bigspastinactive_events != 0 || bigspastcanceled_events != 0 || bigspastactive_events != 0 ? 
                   <>
                
                    <p>Future Active Events: {bigsfutureactive_events}</p>
                    <p>Future Inactive Events: {bigsfutureinactive_events}</p>
                    <p>Future Cancel Events: {bigsfuturecanceled_events}</p>
                    <p>Past Active Events: {bigspastactive_events}</p> 
                    <p>Past Inactive Events: {bigspastinactive_events}</p> 
                    <p>Past Cancel Events: {bigspastcanceled_events}</p> 
                    </> : <center><h4>No Data Found</h4></center> } 
                  </div>
                </Col> : null}
                    {permissions.includes('Events') ? <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard text-center" onClick={() => this. onAgency()}>
                    <h4 className='mb-3'>Agencysponsored Events</h4>
                    {agencyfutureactive_events != 0 || 
                    agencyfuturecanceled_events != 0 || 
                    agencyfutureinactive_events != 0 || 
                    agencypastactive_events != 0 || 
                    agencypastinactive_events != 0 || 
                    agencypastcanceled_events != 0 ? 
                   <>
                   {/* <h5>Future Events</h5> */}
                    <p>Future Active Events: {agencyfutureactive_events}</p>
                    <p>Future Inactive Events: {agencyfuturecanceled_events}</p>
                    <p>Future Cancel Events: {agencyfutureinactive_events}</p>
                    <p>Past Active Events: {agencypastactive_events}</p>
                    <p>Past Inactive Events: {agencypastinactive_events}</p>
                    <p>Past Cancel Events: {agencypastcanceled_events}</p>
                    </> : <center><h4>No Data Found</h4></center> } 
                  </div>
                </Col> : null}
                {permissions.includes('Messaging & Notifications') ? <Col xs lg="4" className='mb-4'>
                  <div className="box-dashboard text-center" onClick={() => this.onNotifications()}>
                    <h4 className='mb-3'>Notifications</h4>
                    {sent_notifications != 0 ||scheduled_notifications != 0 ?
                   <>
                   <p>Sent Notifications: {sent_notifications}</p>
                    <p>Scheduled Notifications: {scheduled_notifications}</p></>:<center><h4>No Data Found</h4></center>}
                  </div>
                </Col> : null}
              </>}
          </Row>
          }
        </div>
      </div>
    )
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    getViewSettingsList: state.getViewSettingsList,
    dashboardlist_data: state.dashboardlist,
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getViewSettingsListdata })(NewDashBoard);
