import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getresourcesAjax } from '../../Store/resources/resourcesListaction';
import { deleteResourceForm } from '../../Store/resources/newResourceaction';
import ReactPagenate from 'react-paginate'
import '../../App.css'
import Edit from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
import HttpService from '../../Services/HttpService'
import { Tooltip, Overlay } from 'react-bootstrap';
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert'

const currentuser = JSON.parse(localStorage.getItem('userdata'));

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      resources: [],
      offset: 0,
      perPage: 8,
      currentPage: 0,
      filterResources: [],
      slice: [],
      spinner: true,
      DefaultMessage: "",
      deleteResponce: {},
      popUpData: {},
      ResourceSearchList: [],
      ResourcesearchInput: '',
      chapter_id: '',
      showPopover: false,
      tooltipref: null,
      isreadmoreOrLess: false,
      rid: 0,
      eventtarget: null,
      resource_id: null,
      pageCount: 0,
      enabledpage: 0
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  /* To make the api call initial page load */
  componentDidMount() {
    localStorage.setItem('selectedpage', 1)
    this.setState({
      spinner: true
    })
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    this.state.usertypeid = this.currentuser.user.usertypeid;
    if (this.state.usertypeid == 1) {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }
      this.props.getresourcesAjax(data);
    }
    else {
      var data = {
        page: localStorage.getItem('selectedpage'),
        limit: 8,
        chapter_id: 0
      }
      this.props.getresourcesAjax(data);
    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    this.setState({
      spinner: false,
      resources: nextProps.getResourceslist.resources.resources
    });
    this.state.resources = nextProps.getResourceslist.resources.resources
    if (this.state.resources?.length === 0) {
      this.setState({
        spinner: false,
        DefaultMessage: "No Resources Found.",
        pageCount: 0
      })
    }
    else {
      this.state.resources = nextProps.getResourceslist.resources.resources
      this.state.filterResources = nextProps.getResourceslist.resources.resources.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = this.state.filterResources.map(pd => <React.Fragment>
      </React.Fragment>)
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.getResourceslist.resources.count / this.state.perPage),
      })
    }
  }
  /* Goto new resource to create the new resource */
  onnewResource() {
    this.props.history.push({
      pathname: '/dashboard/resources/new_resource'
    })
  }
  /* To edit the resource */
  onEdit(item) {
    this.props.history.push({
      pathname: '/dashboard/resources/new_resource',
      state: { detail: item }
    })
  }
  /* To view the image */
  handleShowDialog(item) {
    swal({
      icon: item.files,
      className: 'swal-iamge-preview'
    })
  };
  /* To close the dialog */
  handleCloseDialog = () => {
    this.setState({ isOpen: false });
  };
  /* To show the pop up */
  showPopoverDialog = (e, rid) => {
    this.setState({
      showPopover: true,
      eventtarget: e.target,
      resource_id: rid
    })
  }
  /* To close the pop up */
  dismissPopoverDialog = (e) => {
    this.setState({
      showPopover: false
    })
  }
  onErrorAlert () {
    var errorAlert = {
      alertType : 'error'
    }
    handleSwalAlert(errorAlert)
  }
  /* To delete the resource */
  onDelete = (item) => {

    var data = {
      resource_id: this.state.resource_id
    }
    HttpService.deleteresource(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          if (this.state.deleteResponce.message == 'Resource was in use not able to delete') {
            var successAlert = {
              message: this.state.deleteResponce.message,
              alertType: 'warning'
            }
            handleSwalAlert(successAlert)          
            this.setState({
              showPopover: false
            })
          }
          else {
            this.setState({
              showPopover: false
            })

            var data = {
              page: localStorage.getItem('selectedpage'),
              limit: 8,
              chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
            }
            this.props.getresourcesAjax(data);

            var successAlert = {
              message: this.state.deleteResponce.message,
              alertType: 'success'
            }
            handleSwalAlert(successAlert)          
          }
        }
        else {
          this.setState({
            showPopover: false,
            spinner : false
          })
         this.onErrorAlert()
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false
        })
        this.onErrorAlert()
      })
  }
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.resources.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.resources.length / this.state.perPage),
      postData
    })
  }
  /* To goto next,previous page in pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.state.enabledpage = selectedPage + 1
    localStorage.setItem('selectedpage', this.state.enabledpage)
    if(this.state.ResourcesearchInput!=''){
      var data = {
        page: this.state.enabledpage,
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
        search: this.state.ResourcesearchInput
      }
    }
    else{
      var data = {
        page: this.state.enabledpage,
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id'))
      }
    }   
    this.props.getresourcesAjax(data)
  };
  /* For search resource in the list  */
  searchHandler = (event) => {
    this.setState({spinner:true})
    this.state.ResourcesearchInput = event.target.value
    if (this.state.ResourcesearchInput.length === 0) {
      this.componentDidMount()
      this.setState({ spinner: !this.state.spinner, ResourceSearchList: [], ResourcesearchInput: '', DefaultMessage: '' })
    }
    else {
      if (this.state.ResourcesearchInput.length === 1) {
        this.setState({ resources: [] })
      }
      var data = {
        page: this.state.enabledpage,
        limit: 8,
        chapter_id: JSON.parse(localStorage.getItem('chapter_id')),
        search: this.state.ResourcesearchInput
      }
      this.props.getresourcesAjax(data)
    }
  }
  /* To read the full description */
  readmore(res_id) {
    if(this.state.rid != res_id){
      this.state.isreadmoreOrLess = false
    }
   
    if (this.state.isreadmoreOrLess == false) {
      this.setState({
        rid: res_id
      })
      this.setState({
        isreadmoreOrLess: true
      })
    }
    else {
      this.setState({
        isreadmoreOrLess: false
      })
    }
  }
  /* render() function is used to update the UI. */
  render() {
    localStorage.setItem("activescreen", JSON.stringify('resourcelist'));

    if (JSON.parse(localStorage.getItem('chapter_id')) != null) {
      this.state.chapter_id = JSON.parse(localStorage.getItem('chapter_id'))
    }
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
      }
    };
    const { showPopover } = this.state

    return (
      <div className="container">
        <Row className='pt-4 mb-4'>
          <Col xs lg="6">
            <h3 className="pagename mb-3">Resources</h3>
          </Col>
          <Col xs lg="6">
            <div className='d-flex'>
              <div className='pr-3' style={{ width: '70%' }}>
                <span className="fa fa-search head-search"></span>
                <input style={{ paddingLeft: '40px' }} type="text" ref="removesearch" className="form-control" placeholder="Search Resource Title..." onChange={(e) => this.searchHandler(e)} />
              </div>
              <div>
                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewResource()}>New Resource</button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="box">
          <center>
            <div class="loader-spinner">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
          {this.state.resources.length ?
            <div className="row">
              <div className="mb-3">
                <div className="table-responsive">
                  <table id="dataTable" className='resources'>
                    {this.state.resources.length > 0 ? <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Files</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead> : null
                    }
                    <tbody>
                      {
                        this.state.resources.length ? this.state.resources?.slice(this.state.offset, this.state.offset + this.state.perPage).map(item => (
                          <tr key={item.resource_id}>
                            <td> {item.title}</td>
                            <td> {this.state.isreadmoreOrLess && this.state.rid == item.resource_id ? item.description : item.description.slice(0, 150)}
                              {item.description.length > 50 ? <span className='read-or-hide' onClick={(e) => this.readmore(item.resource_id)}>
                                {this.state.isreadmoreOrLess && this.state.rid == item.resource_id ? ' ...show less' : ' ...read more '}
                              </span> : null}
                            </td>
                            {item.files ? <td> <img src={item.files} width="70px" height="70px" onClick={() => this.handleShowDialog(item)}></img></td> : <td>No Files Found </td>}
                            {item.active_status == '0' ? <td style={{ maxwidth: '460px' }}>Active</td> : <td style={{ maxwidth: '460px' }}>In Active</td>}
                            <td>
                              <div className="float-right">
                                <span className="pr-2" name="edit" onClick={() => this.onEdit(item)}><Edit /></span>
                                <Overlay show={showPopover} target={this.state.eventtarget} placement="left" rootClose="true">
                                  <Tooltip>
                                    <div className='text-center p-4'>
                                      <p className='tool-title'>Delete Resource</p>
                                      <p className='tool-para'>Are you sure you want to delete this user resource?</p>
                                    </div>
                                    <div className='d-flex btn-sec'>
                                      <button onClick={() => this.onDelete(item)} className='tool-btn-yes'>Yes, Delete</button>
                                      <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                    </div>
                                  </Tooltip>
                                </Overlay>
                                <div className='cross-center'>
                                  <span name="remove" onClick={(e) => this.showPopoverDialog(e, item.resource_id)}><CloseIcon /></span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                      }
                    </tbody>
                  </table>
                  {this.state.pageCount === 0 ? null : <div className="pagination">

                    <ReactPagenate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  }
                </div>
              </div>
            </div> : <center><h3>{this.state.DefaultMessage}</h3></center>}
        </div>
      </div >
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    getResourceslist: state.getResourceslist,
    addResource: state.addResource
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { deleteResourceForm, getresourcesAjax })(Resources);
