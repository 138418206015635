import React, { Component } from "react";
import Carousels from '../Templates/TemplateCarousal'
import Header from "../Templates/TemplateHeader";
import Footer from "./TemplateThree/Footer";
import BodyConent from "./TemplateThree/BodyConent";

class ViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateData: {},
        }

    }


    componentDidMount() {
        // window.localStorage.setItem('chpaterdata', JSON.stringify(window.chapterData))
        console.log("view template:- ", JSON.parse(localStorage.getItem("chpaterdata")))
        this.setState({
            templateData: JSON.parse(localStorage.getItem("chpaterdata"))
        })
    }

    render() {
        //  window.localStorage.setItem('chpaterdata', JSON.stringify(window.chapterData))
        // JSON.parse(localStorage.getItem("userdata"))
        return (
            <div className="body-bg">
                <section>
                    <Header />
                </section>
                <section>

                    <Carousels />

                </section>
                <section >
                    <BodyConent />
                </section>
                <section>
                    <Footer />
                </section>

            </div>
        )
    }

}
export default ViewTemplate