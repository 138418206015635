import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js'; //ContentState
import draftToHtml from 'draftjs-to-html';
import swal from 'sweetalert';
import { Input } from 'antd'; //notification
import { connect } from 'react-redux';
import { SendMessageInput, sendMessageForm, ToUsersListData } from '../../Store/messages/sendMessageAction';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './MessStyle.css';
import 'antd/dist/antd.css';
import SimpleReactValidator from 'simple-react-validator';
import HttpService from '../../Services/HttpService'
import Autosuggest from 'react-autosuggest';
import { stateFromHTML } from 'draft-js-import-html';
import { draftMessages } from '../../Store/messages/allMessageAction';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const styles = {
  editor: {
    border: '0.5px solid lightgray',
    minHeight: '15em',
    left: '2935px',
    padding: "10px"
  }
};

class DraftNewMessage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    var contentState = stateFromHTML(this.props.data.item.body);

    this.state = {
      editorState: EditorState.createWithContent(contentState),
      Uid: '',
      sender_id: "",
      email: "",
      subject: "",
      body: "",
      attachments: [],
      suggetionsdata: [],
      value: '',
      suggestions: [],
      display_name: '',
      showPopover: false,

    };
  }

  /* Filter logic */ 
  getSuggestions = async (eventInput) => {

    HttpService.ToListUsersSearch(eventInput)
      .then(response => {
        this.setState({
          suggetionsdata: response.data.users
        })

      })
      .catch(error => {
        //dispatch(SendError)
      })
    return this.state.suggetionsdata;
  };

  /* Trigger suggestions */
  getSuggestionValue = suggestion => suggestion.first_name + " " + suggestion.last_name

  /* Render Each Option */ 
  renderSuggestion = suggestion => (
    <div className="sugg-option">
      <h8><b>{suggestion.first_name + " " + suggestion.last_name}</b></h8>
    </div>
  );

  /* OnChange event handler */ 
  onChange = (event, { newValue }) => {
    this.setState({
      display_name: newValue
    });
  };

  /* Suggestion rerender when user types */ 
  onSuggestionsFetchRequested = (e) => {
    let eventInput = {
      name: e.value
    }

    this.getSuggestions(eventInput)
      .then(data => {
        if (data.Error) {
          this.setState({
            suggestions: []
          });
        } else {
          this.setState({
            suggestions: this.state.suggetionsdata
          });
        }
      })
  };

  /* Triggered on clear */ 
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  /* Selected item */ 
  onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    event.preventDefault();
    this.setState({ email: suggestion.email })

  }
  /* To convert the html tags to text */
  onEditorStateChange = (editorState) => {
    const currentContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    this.setState({
      editorState,
    });
  };
/* Event occurs when the value of an element has been changed */
  handleChange = (e, key) => {
    const { Uid } = this.state
    let eventInput = {
      key: key,
      value: e.currentTarget.value
    }
    if (e.currentTarget.value === 'name') {
      this.setState({
        email: e.currentTarget.value
      })
    }
    else {
      this.setState({ subject: e.currentTarget.value })
    }
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.currentuser = JSON.parse(localStorage.getItem('userdata'));
    var user_id = this.currentuser.user_id;
    this.setState({ Uid: user_id });
    if (this.props.data != null) {
      this.setState({
        message_id: this.props.data.item.message_id,
        email: this.props.data.item.email,
        display_name: this.props.data.item.first_name + " " + this.props.data.item.last_name,
        subject: this.props.data.item.subject,
        body: this.props.data.item.body
      })

    }
    else {
      this.state.message_id = ''
      this.state.email = ''
      this.state.subject = ''
      this.state.body = ''
    }
  }
/* It allows us to execute the React code when the component is updated.  */
  componentDidUpdate(prevProps) {
    if (this.props.data.item.message_id !== prevProps.data.item.message_id) {
      var contentState = stateFromHTML(this.props.data.item.body);

      this.setState({
        message_id: this.props.data.item.message_id,
        email: this.props.data.item.email,
        display_name: this.props.data.item.first_name + " " + this.props.data.item.last_name,
        subject: this.props.data.item.subject,
        body: this.props.data.item.body,
        editorState: EditorState.createWithContent(contentState),
      })
    }
    else {
    }
  }
  /* To send the mail */
  sendMail = () => {
    const { to, from, subject, key, text } = this.state;
    const html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    const { sendMessage } = this.props
    const { Uid } = this.state

    if (this.validator.allValid()) {
      if (this.props.data != null) {
        var data = {
          message_id: this.state.message_id,
          sender_id: Uid,
          email: this.state.email,
          subject: this.state.subject,
          body: html,
          message_type: 'sent'
        }
      }
      else {
        var data = {
          sender_id: Uid,
          email: this.state.email,
          subject: this.state.subject,
          body: html,
          message_type: 'sent'
        }
      }
      HttpService.sendmessage(data)
        .then(response => {
          if (response.data.status === 200) {
            swal({
              title: response.data.message,
              icon: "success"
            });
            this.props.draftMessages();

            this.props.quitScreen();
            this.setState({
              email: "",
              subject: "",
              body: "",
              attachments: []
            });
            this.validator.hideMessages();
          }
          else {
            swal({
              title: "Something went wrong,Please try again after sometime.",
              icon: "error"
            });
            this.validator.hideMessages();
          }
        })
        .catch(error => {
          swal({
            title: "Something went wrong,Please try again after sometime.",
            icon: "error"
          });
        })
    }
    else {
      this.validator.showMessages();
    }

  }
  /*To update the state in response with the new received props */
  UNSAFE_componentWillReceiveProps(nextProps) { 
  }
/* To show the pop up */
  showPopoverDialog() {
    this.setState({
      showPopover: true
    })
  }
/* To close the pop up */
  dismissPopoverDialog() {
    this.setState({
      showPopover: false
    })
  }
  /* To delete the draft message */
  deleteDraftMessage(message_id) {

    var delete_from = this.props.typeOfMessage;


    this.setState({
      spinner: true
    })

    HttpService.deletemessage({ message_id ,delete_from})
      .then(response => {
        this.state.deleteResponse = response.data
        if (this.state.deleteResponse.status === 200) {
          this.setState({
            spinner: false
          })
          this.props.quitScreen()
          swal( { text:this.state.deleteResponse.message,
            icon: "success",
          });
          if (this.props.typeOfMessage === 'inbox') {
            this.props.RecievedMessages()
          }
          else if (this.props.typeOfMessage === 'sent') {
            this.props.sentMessages()
          }
          else if (this.props.typeOfMessage === 'trash') {
            this.props.trashMessages();
          }
          else if (this.props.typeOfMessage === 'drafts') {
            this.props.draftMessages();
          }

          this.setState({
            select_a_message: true
          })
        }
        else {
          this.setState({
            spinner: false
          })
          swal({text:this.state.deleteResponse.message,
            icon: "error",
          });
        }
      })
      .catch(error => {
        this.setState({
          spinner: false
        })
        swal({text:"Something went wrong, Please try again after sometime.",
          icon: "error",
        });
      })
  }
  /* render() function is used to update the UI. */
  render() {
    const { selectedOption } = this.state;
    const { value, suggestions } = this.state;
    // Option props
    const inputProps = {
      placeholder: 'To:',
      value: this.state.display_name,
      onChange: this.onChange,
      style: { width: "100%", height: "46px" },
    };
    return (
      <div >
        <div style={{ marginBottom: "10px" }}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={(ev) => this.onSuggestionsFetchRequested(ev, "email")}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            highlightFirstSuggestion={true}
            onSuggestionSelected={this.onSuggestionSelected}
          />
          <span className="text-danger">{this.validator.message("email", this.state.email, "required")}</span>
        </div>
        <Input className="form-control" placeholder="Subject" value={this.state.subject} onChange={(ev) => { this.handleChange(ev, "subject") }}/>
       <span className="text-danger">{this.validator.message("subject", this.state.subject, "required")}</span>
        <div className="m-10"
          style={styles.editor}
          onClick={this.focusEditor}>
          <Editor
            editorState={this.state.editorState}
            placeholder=" Write Something... "
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={editorState => this.onEditorStateChange(editorState)}
          />
        </div>
        <div style={{ marginTop: '10px' }} className='row'>
          <div className="col-md-6 my-4">
            {this.state.showPopover
              ? <OverlayTrigger placement="left" trigger="click" rootClose = 'true' overlay={(
                  <Tooltip>
                    <div className='text-center p-4'>
                      <p className='tool-title'>Delete Message</p>
                      <p className='tool-para'>Are you sure you want to delete this message? Deleted messages may not be restored.</p>
                    </div>
                    <div className='d-flex btn-sec'>
                      <button onClick={() => this.deleteDraftMessage(this.state.message_id)} className='tool-btn-yes'>Yes, Delete</button>
                      <button onClick={() => this.dismissPopoverDialog()} className='tool-btn-cancel'>No, Cancel</button>
                    </div>
                  </Tooltip>
                )}>
                <button type="cancel" className="cancelbtnnew" style={{marginTop:'2px'}} onClick={() => this.showPopoverDialog()}>Delete</button>
              </OverlayTrigger>
              : <button type="cancel" className="cancelbtnnew" style={{marginTop:'2px'}} onClick={() => this.showPopoverDialog()}>Delete</button>
            }

          </div>
          <div className="col-md-6  my-4">
            <div className="float-right cancel-sec">
              <button type="submit" className="btn btn-info btn-success mt-1" onClick={() => { this.sendMail() }}>Send</button>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    sendMessage: state.sendMessage
  }
}
/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { sendMessageForm, SendMessageInput, ToUsersListData, draftMessages })(DraftNewMessage);
