import React, { Component } from "react";
import { viewTemplate } from '../../../Store/template/viewTemplateoneAction';
import { connect } from 'react-redux';
import ReactReadMoreReadLess from "react-read-more-read-less";
import './Templatetwo.css'
import 'react-alice-carousel/lib/alice-carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

class BodyConent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      bodyConentData: {}

    }
  }
  /* render() function is used to update the UI. */
  render() {
    this.state.bodyConentData = JSON.parse(localStorage.getItem('ViewTemplateData'))
    var settingsdiscounts = {
      dots: true,
      infinite: JSON.parse(
        localStorage.getItem("ViewDiscountData")
      )?.discounts?.length > 4,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    };
    var settingsgallery = {
      dots: true,
      infinite: JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery?.length > 4,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    }
    var settingsevents = {
      dots: true,
      infinite: JSON.parse(
        localStorage.getItem("ViewTemplateData")
      )?.template.events?.length > 3,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }]
    }
    return (
      <>
        <div className='container  my-5'>
          <h1 className="text-center">{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.resources[0]?.title}</h1>
          {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.resources[0]?.title != null ?
            <hr class="divider"></hr>

            : null}
          <div className="row" name="discount">
            {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.resources.map((resources, index) => {
              return <> <div className="col-md-3 mb-4">
                <div className="gallery-item">
                  <img className="gallery-image" src={resources.files} style={{ width: '360px', height: '230px' }} />
                  <div className="p-3 des-para">
                    <p><ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more ▼"}
                      readLessText={"Read less ▲"}
                    >
                      {resources.description}
                    </ReactReadMoreReadLess></p>
                  </div>
                </div>
              </div>

              </>
            })}


          </div>
          {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.resources != 0 ?
            <hr className="mt-5" />

            : null}
          <section className="mt-5">
            <div className="text-center">

              <h1 className="text-center">{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.activities[0]?.title}</h1>
              {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.activities[0]?.title != null ?
                <hr class="divider"></hr>

                : null}
             {this.state.bodyConentData?.template.activities.map((item, index) => {
                return <> <p>{item.description}</p>
                </>
              })}
            </div>
          </section>

          {this.state.bodyConentData?.template.activities != 0 ?
            <hr className="mt-5" />

            : null}
          <div className="my-5">
            <h1 className="text-center">{JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery[0]?.title}</h1>


            {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery[0]?.title != null ?
              <hr class="divider"></hr>

              : null}

            <div className="row">
              <Slider {...settingsgallery}>
                {JSON.parse(localStorage.getItem('ViewTemplateData'))?.template?.gallery.map((gallery, index) => {
                  return <div className="col">
                    <div className="gallery-item">
                      <img className="gallery-image" style={{height:'150px',width:'100%'}} src={gallery.photo_name} />
                    </div>
                  </div>
                })
                }
              </Slider>
            </div>
          </div>
          {/* Events */}
          {this.state.bodyConentData?.template.events != 0 ?
            <>
              <h1 className="text-center">Events</h1>
              <hr class="divider"></hr>


              <div className="row" name="discount">
                <Slider {...settingsevents}>
                  {this.state.bodyConentData?.template.events.map((element, index) => {
                    return <div className="col mt-3">
                      <div className="p-4 gallery-item" style={{ backgroundColor: 'aliceblue', }}>

                        <div className="d-flex">
                          <h6><b>Event Name:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.event_name}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Description:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                            charLimit={25}
                            readMoreText={"Read more ▼"}
                            readLessText={"Read less ▲"}
                          >
                            {element.description}
                          </ReactReadMoreReadLess></h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Start Date & Time:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.start_date + " " + element.start_time}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>End Date & Time:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.end_date + " " + element.end_time}</h6>
                        </div>
                        <div className="d-flex">
                          <h6><b>Available Seats:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.available_seats}</h6>
                        </div>
                      </div>
                    </div>
                  })


                  }
                </Slider>


              </div> </> : null}

          {/* Discounts */}

          {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts != 0 ?
            <>
              <h1 className="text-center mt-5">Discounts</h1>
              <hr class="divider"></hr>


              <div className="row" name="discount">
                <Slider {...settingsdiscounts}>
                  {JSON.parse(localStorage.getItem('ViewDiscountData'))?.discounts?.map((element, index) => {
                    return <div className="col mt-3">
                      <div className="p-4 gallery-item" style={{ backgroundColor: 'aliceblue' }}>

                        <div className="d-flex">
                          <h6><b>Location:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.program_name}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Description:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}><ReactReadMoreReadLess
                            charLimit={25}
                            readMoreText={"Read more ▼"}
                            readLessText={"Read less ▲"}
                          >
                            {element.description}
                          </ReactReadMoreReadLess></h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>Start Date:- </b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.start_date}</h6>
                        </div>

                        <div className="d-flex">
                          <h6><b>End Date:-</b></h6>
                          <h6 style={{ marginLeft: "5px" }}>{element.end_date}</h6>
                        </div>

                      </div>
                    </div>
                  })
                  }
                </Slider>
              </div> </> : null}
        </div>
      </>
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    viewtemplate: state.viewtemplate
  }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { viewTemplate })(BodyConent)