import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChapters, getChaptersAjax } from '../../Store/chapters/chapterListAction';
import ReactPagenate from 'react-paginate';
import '../../App.css'
import { Edit } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import HttpService from '../../Services/HttpService';
import * as moment from 'moment';
import { Tooltip, Overlay } from 'react-bootstrap'
import { Circles } from 'react-loader-spinner';
import { Row, Col } from 'react-bootstrap';
import handleSwalAlert from '../SwalAlert';
import ReactReadMoreReadLess from "react-read-more-read-less";

class ChaptersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      offset: 0,
      perPage: 8,
      currentPage: 0,
      chapters: [],
      filterchapter: [],
      slice: [],
      deleteResponce: '',
      DefaultMessage: '',
      chapterSearchList: [],
      chapterSearchInput: '',
      showPopover: false,
      eventtarget: null,
      chapter_id: null,
      pageCount: 0,
      selectedPage: 1,
      searchChapterName: '',
      showPopoverForTemplate: false,
      template_id: ''

    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  /* To make the api call initial page load */
  componentDidMount() {
    this.setState({
      spinner: true
    })
    const data = `page=${this.state.selectedPage}&limit=8`
    this.props.getChaptersAjax(data);
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {

    if (nextProps.chaptersList.chaptersErrorData.status != null) {
      this.setState({
        spinner: false,
        pageCount: 0,
        DefaultMessage: nextProps.chaptersList.chaptersErrorData.statusText
      });
    }
    else if (nextProps.chaptersList.chapterslist.chapters_list?.length != 0) {
      this.state.chapters = nextProps.chaptersList.chapterslist.chapters_list
      this.setState({
        spinner: false,
        pageCount: Math.ceil(nextProps.chaptersList.chapterslist.count / this.state.perPage),
        postData: this.state.chapters
      });
    }
    else {
      this.setState({
        chapters: [],
        spinner: false,
        pageCount: 0,
        DefaultMessage: "No Chapters Found."
      });
    }

  }
  /* Goto a new chapter */
  onnewChapter() {
    this.props.history.push({
      pathname: '/dashboard/Chapters/NewChapter'
    })
  }
  /*Edit the created chapter */
  onEdit(item) {
    this.props.history.push({
      pathname: '/dashboard/Chapters/EditChapter',
      state: { detail: item }
    })
  }
  /*To view the template. It will be redirect to another web browser*/
  onViewTemplate(item) {
    console.log('temp name:- ',item)
    alert("You will be redirected to another web page")
    switch (item.template.templateid) {
      case 1:
        var newWindowTemplate = window.open(`/${item.view_temp_chapter_name}`);
        localStorage.setItem("chpaterdata", JSON.stringify(item))
        break;

      case 2:
        var newWindowTemplate = window.open(`/${item.view_temp_chapter_name}`);
        localStorage.setItem("chpaterdata", JSON.stringify(item))
        break;
        case 3:
          var newWindowTemplate = window.open(`/${item.view_temp_chapter_name}`);
          localStorage.setItem("chpaterdata", JSON.stringify(item))
          break;
          case 4:
            var newWindowTemplate = window.open(`/${item.view_temp_chapter_name}`);
            localStorage.setItem("chpaterdata", JSON.stringify(item))
            break;
      default:
        return null
    }
  }

  /**Edit template */
  onEditTemplate(item) {
    switch (item.template.templateid) {
      case 1:
        this.props.history.push({
          pathname: '/dashboard/Templates/TemplateFormOne',
          state: { detail: item }
        })
        break;
      case 2:
        this.props.history.push({
          pathname: '/dashboard/Templates/TemplateFormTwo',
          state: { detail: item }
        })
        break;
      case 3:
        this.props.history.push({
          pathname: '/dashboard/Templates/TemplateFormThree',
          state: { detail: item }
        })
        break;
        case 4:
          this.props.history.push({
            pathname: '/dashboard/Templates/TemplateFormFour',
            state: { detail: item }
          })
          break;
      default:
        return alert('Template ' + item + ' you are selected for this chapter has no data')
    }
  }
  /**Delete template */
  onDeleteTemplate(item) {
    var templateData = {
      chapter_id: this.state.chapter_id,
      template_id: this.state.template_id
    }

    HttpService.DeleteTemplate(templateData)
      .then(response => {
        if (response.data.status === 200) {

          this.setState({
            showPopoverForTemplate: false,
            spinner: false
          })
          const page = `page=${JSON.parse(localStorage.getItem('selectedpage'))}&limit=8`

          this.props.getChaptersAjax(page);
          this.refs.removesearch.value = '';
          var successAlert = {
            message: response.data.message,
            alertType: 'success'
          }
          handleSwalAlert(successAlert)
        }
        else {
          this.setState({
            showPopoverForTemplate: false,
            spinner: false
          })
          this.onErrorAlert()
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false,
          spinner: false
        })
        this.onErrorAlert()
      })
  }

  /* To show the pop up */
  showPopoverDialog = (e, cid, type) => {
    if (type === 'template') {
      this.setState({
        showPopoverForTemplate: true,
        eventtarget: e.target,
        chapter_id: cid.chapter_id,
        template_id: cid.template.templateid
      })
    }
    else {
      this.setState({
        showPopover: true,
        eventtarget: e.target,
        chapter_id: cid
      })
    }
  }
  /* To close the pop up */
  dismissPopoverDialog = (e, type) => {
    if (type === 'template') {
      this.setState({
        showPopoverForTemplate: false
      })
    }
    else {
      this.setState({
        showPopover: false
      })
    }
  }
  onErrorAlert() {
    var errorAlert = {
      alertType: 'error'
    }
    handleSwalAlert(errorAlert)
  }
  /* To delete the chapter */
  onDelete = (item, index) => {
    this.setState({
      spinner: true
    })
    var data = {
      chapter_id: this.state.chapter_id
    }
    const page = `page=${JSON.parse(localStorage.getItem('selectedpage'))}&limit=8`

    HttpService.deleteChapter(data)
      .then(response => {
        this.state.deleteResponce = response.data
        if (this.state.deleteResponce.status === 200) {
          if (this.state.deleteResponce.message == 'Chapter was in use not able to delete') {
            this.setState({
              showPopover: false,
              spinner: false
            })
            var successAlert = {
              message: this.state.deleteResponce.message,
              alertType: 'warning'
            }
            handleSwalAlert(successAlert)
          }
          else {
            this.setState({
              showPopover: false,
              spinner: false
            })
            this.props.getChaptersAjax(page);
            this.refs.removesearch.value = '';
            var successAlert = {
              message: this.state.deleteResponce.message,
              alertType: 'success'
            }
            handleSwalAlert(successAlert)
          }
        }
        else {
          this.setState({
            showPopover: false,
            spinner: false
          })
          this.onErrorAlert()
        }
      })
      .catch(error => {
        this.setState({
          showPopover: false,
          spinner: false
        })
        this.onErrorAlert()
      })
  }
  /* For pagination */
  receivedData() {
    this.state.slice = this.state.chapters.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = this.state.slice.map(pd => <React.Fragment>
      <img src={pd.thumbnailUrl} alt="" />
    </React.Fragment>)

    this.setState({
      pageCount: Math.ceil(this.state.chapters.length / this.state.perPage),
      postData
    })
  }
  /* Go to next page in the pagination */
  handlePageClick = (e) => {
    this.setState({
      spinner: true
    })

    this.state.selectedPage = e.selected;
    localStorage.setItem('selectedpage', this.state.selectedPage + 1)

    const offset = this.state.selectedPage * this.state.perPage;
    if (this.state.searchChapterName != '') {
      var data = `page=${this.state.selectedPage + 1}&limit=8&search=${this.state.searchChapterName}`
    }
    else {
      var data = `page=${this.state.selectedPage + 1}&limit=8`
    }
    this.props.getChaptersAjax(data);    
  };

  /* For search name of the activity in the list  */
  searchHandler = (event) => {
    this.state.searchChapterName = event.target.value
    this.setState({
      spinner: true
    })
    if (this.state.searchChapterName.length === 0) {
      this.componentDidMount()
      this.setState({ spinner: !this.state.spinner })
    }
    else {
      if (this.state.searchChapterName.length === 1) {
        this.setState({
          chapters: []
        })
      }
      const data = `page=${this.state.selectedPage}&limit=8&search=${this.state.searchChapterName}`
      this.props.getChaptersAjax(data);      
    }
  }


  /*render() function is used to update the U */
  render() {
    const { showPopover, showPopoverForTemplate } = this.state
    return (
      <div className="container">
        <Row className='pt-4 mb-4'>
          <Col xs lg="6">
            <h3 className="pagename mb-3">Chapters</h3>
          </Col>
          <Col xs lg="6">
            <div className='d-flex'>
              <div className='pr-3' style={{ width: '70%' }}>
                <span className="fa fa-search head-search"></span>
                <input style={{ paddingLeft: '40px' }} type="text" ref="removesearch" className="form-control" placeholder="Search chapter name..." onChange={(e) => this.searchHandler(e)} />
              </div>
              <div>
                <button type="button" className="btn btn-info btn-success" onClick={() => this.onnewChapter()}>New Chapter</button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="box">
          <center>
            <div class="loader-spinner mt-3">
              {this.state.spinner ? <Circles color="#7cc9a9" height={50} width={50} /> : null}
            </div>
          </center>
          {this.state.chapters?.length > 0 ?
            <div className="mb-3 text-center">
              <div className="table-responsive">
                <table id="dataTable">
                  <thead>
                    {this.state.chapters?.length > 0 ? <tr>
                      <th>Chapter Name</th>
                      <th>Description</th>
                      {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ? <th>Template Name</th>:null}

                      <th>Created Date</th>
                      <th>Status</th>
                      <th></th>
                    </tr> : null
                    }
                  </thead>
                  <tbody>
                    {
                      this.state.chapters?.length ? this.state.chapters?.slice(this.state.offset, this.state.offset + this.state.perPage).map((item, index) => (
                        <tr key={item.chapter_id}>
                          <td> {item.chapter_name}</td>
                          <td style={{width: '40%', overflowY:'auto'}}><ReactReadMoreReadLess
                charLimit={100}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
            >
               {item.description}
            </ReactReadMoreReadLess></td>
               
                          {process.env.REACT_APP_SWITCH_TO_FEATURES == 1 ? 
                                <td>
                                {item.template === null ? <h6 onClick={() => this.onEdit(item)} className='template-name'>Add template</h6> :
    
                                  <div style={{width:'150px'}}>
                                  <h6  className='template-name'>{item.template != null && item.template.template_name}
                                    {item.temp_status === 1 ?
    
                                      <i data-title="Edit template" onClick={() => this.onEditTemplate(item)} class="fa fa-edit ml-1" aria-hidden="true"></i> :
                                      <>
    
                                        <i data-title="View template" onClick={() => this.onViewTemplate(item)} class="fa fa-address-card ml-1" aria-hidden="true"></i>
                                        <i data-title="Edit template" onClick={() => this.onEditTemplate(item)} class="fa fa-edit ml-1" aria-hidden="true"></i>

                                        <Overlay
                                          show={showPopoverForTemplate}
                                          target={this.state.eventtarget}
                                          placement="right"
                                          rootClose="true">
                                          <Tooltip>
                                            <div className='text-center p-4'>
    
                                              <p className='tool-title'>Delete Template</p>
                                              <p className='tool-para'>Are you sure you want to delete this user Template?</p>
    
                                            </div>
                                            <div className='d-flex btn-sec'>
                                              <button onClick={() => this.onDeleteTemplate(item)} className='tool-btn-yes'>Yes, Delete</button>
                                              <button onClick={(e) => this.dismissPopoverDialog(e, 'template')} className='tool-btn-cancel'>No, Cancel</button>
                                            </div>
                                          </Tooltip>
                                        </Overlay>
                                        <i data-title="Delete template" onClick={(e) => this.showPopoverDialog(e, item, 'template')} class="fa fa-trash ml-1" aria-hidden="true"></i>
    
                                      </>
                                    }
                                  </h6>
                                  </div>
                                }
                              </td>:null}

                    
                          <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                          {item.active_status == '0' ? <td>Active</td> : <td>InActive</td>}
                          <td>
                            <div className="float-right">                              
                              <span data-title="Edit Chapter" className="pr-2"
                                name="edit"
                                onClick={() => this.onEdit(item)}
                              ><Edit />
                              </span>
                              <Overlay
                                show={showPopover}
                                target={this.state.eventtarget}
                                placement="left"
                                rootClose="true">
                                <Tooltip>
                                  <div className='text-center p-4'>
                                    <p className='tool-title'>Delete Chapter</p>
                                    <p className='tool-para'>Are you sure you want to delete this user Chapter?</p>
                                  </div>
                                  <div className='d-flex btn-sec'>
                                    <button onClick={() => this.onDelete(item, index)} className='tool-btn-yes'>Yes, Delete</button>
                                    <button onClick={(e) => this.dismissPopoverDialog(e)} className='tool-btn-cancel'>No, Cancel</button>
                                  </div>
                                </Tooltip>
                              </Overlay>
                              <div data-title="Delete Chapter" className='cross-center'>
                                <span
                                  name="remove"
                                  onClick={(e) => this.showPopoverDialog(e, item.chapter_id)}
                                ><CloseIcon /></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) : <tr><td colspan="5"><div className='default-text'><h3>{this.state.DefaultMessage}</h3></div></td></tr>
                    }
                  </tbody>
                </table>
                {this.state.pageCount > 0 ? <div className="pagination">
                  {/* {this.state.postData} */}
                  <ReactPagenate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div> : null
                }
              </div>
            </div>
            : <center><h3>{this.state.DefaultMessage}</h3></center>}
        </div>
      </div>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    chaptersList: state.chaptersList,
  }
}

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, { getChapters, getChaptersAjax })(ChaptersList)
