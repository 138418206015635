import { map } from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createTemplate,
  editTemplate,
} from "../../Store/template/addTemplateAction";
import { viewTemplate } from "../../Store/template/viewTemplateoneAction";
import swal from "sweetalert";
import { Circles } from "react-loader-spinner";
import handleSwalAlert from "../SwalAlert";
import { Row, Col } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import HttpService from "../../Services/HttpService";
import { uploadMediaType } from "./UploadTypeResponce";
import UploadMedia from "./UploadMedia";

class TemplateFormFour extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      formValues: [{ name: "", email: "", menu_id: "", index: 0, show: 0 }],
      editFormValues: [],

      editImageValues: [],
      bannerImageValues: [
        {
          imageName: "",
          imageUrl: "",
          bannerPreview: "",
          banner_id: "",
          media: "",
          index: 0,
          show: 0,
          upload: 'Images',
          videoUrl: '',
          urlStatus: null
        },
      ],
      bannerVideoValues: [
        {
          videoImageName: "",
          videoImageUrl: "",
          videoBannerPreview: "",
          videoBanner_id: "",
          media: "",
          index: 0, show: 0
        },
      ],
      EditBannerImageValues: [],
      teamValues: [
        {
          teamImageUrl: "",
          name: "",
          designation: "",
          about: "",
          templateteamdetails_id: "",
          bannerPreview: "",
          show: 0
        },
      ],

      editDescriptionValues: [],

      footerValues: [{ label: "", labelValue: "", footer_id: "", show: 0 }],
      editFooterValues: [],

      templateLogo: "",
      previewLogo: "",
      profilePicPreview: [],
      bannerPreview: [],
      imagePreview: [],
      menuValues: [
        { menuName: "About us", menuId: "1" },
        { menuName: "Contact Us", menuId: "2" },
        { menuName: "Settings", menuId: "3" },
      ],
      logoName: "",
      menuItem: "",
      postBannersData: [],
      postprofilePicData: [],
      postCardsData: [],
      postImagesData: [],
      spinner: false,
      editTemplate: {},

      removedBannerIds: [],
      removedMenuIds: [],
      removedImagesIds: [],
      removedCardIds: [],
      removedFooterIds: [],
      removedSectiononeIds: [],

      bannersHasChanged: 0,
      teamHasChanged: 0,
      menuHasChanged: 0,
      footerHasChanged: 0,
      actionHasChanged: 0,
      titleTwoHasChanged: 0,
      discountsHasChanged: 0,
      teamTitle: "",
      teamDescripition: "",
      removedOurservicesIds: [],

      servicesValues: [
        {
          bannerPreview: "",
          serviceDetailsTitle: "",
          serviceDetailsDescription: "",
          templateourserviceid: "",
          servicesImageUrl: "",
          show: 0
        },
      ],
      templateourservice_id: [],
      iconHasChanged: 0,
      iconPreview: [],
      postIconData: [],
      ourserviceDescription: "",
      ourserviceName: "",
      templateteam_id: "",
      deleteourservice: [],
      templatediscount_id: "",
      discountsValues: [
        {
          discountssubtitle: "",
          discountssubDescription: "",
          templatediscountdetail_id: "",
          show: 0
        },
      ],
      editdiscountsValues: [],
      discountImageHasChanged: 0,
      postdiscountImageData: [],
      discountTitle: "",
      discountDescription: "",
      removedDiscountsIds: [],
      previewdiscountImage: "",
      discountsImageName: "",
      discountsImage: "",

      actionTitle: "",
      actionDescription: "",
      previewactionImage: "",
      actionImageName: "",
      actionImage: "",
      templateaction_id: "",
      actionsImageName: "",
      banner_upload: "0",
      urlName: '',
      urlValue: '',
      uploaded_banner_id: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.ImagehandleSubmit = this.ImagehandleSubmit.bind(this);
  }
  /*triggers before the initial render*/

  componentWillMount() {
    if (this.props.location.state?.detail?.temp_status === 0) {
      this.setState({
        spinner: true,
      });
      var data = {
        chapter_id: this.props.location.state.detail.chapter_id,
        template_id: this.props.location.state.detail.template.templateid,
      };

      HttpService.viewTemplate(data).then((response) => {

        this.setState({
          spinner: false,
          editTemplate: response.data,
        });

        this.setState({
          previewLogo: this.state.editTemplate.template.template_logo,
          bannerImageValues: this.state.editTemplate.template?.banners.map((item, index) => {
            return {
              urlStatus: item.url_status,
              imageName: item.banner_name,
              imageUrl: item.banner_image,
              bannerPreview: item.banner_image,
              banner_id: item.banner_id,
              show: item.active_status,
              videoUrl: item.banner_image.includes('s3.amazonaws.com') ? null : item.banner_image,
              upload: item.url_status === 1 ? 'Url' : item.banner_image.includes('.mp4') ? 'Video' : 'Images'
            }
          }),
          teamTitle: this.state.editTemplate.template.teams.team_title,
          teamDescripition:
            this.state.editTemplate.template.teams.team_description,
          templateteam_id:
            this.state.editTemplate.template.teams.templateteam_id,
          teamValues: this.state.editTemplate.template.teams.team_details.map(
            (item, index) => {
              return {
                teamImageUrl: "",
                bannerPreview: item.profile_pic,
                name: item.name,
                designation: item.designation,
                about: item.about,
                templateteamdetails_id: item.id,
                show: item.active_status
              };
            }
          ),
          discountTitle:
            this.state.editTemplate.template.discounts.discount_title,
          discountDescription:
            this.state.editTemplate.template.discounts.discount_description,
          templatediscount_id:
            this.state.editTemplate.template.discounts.templatediscount_id,
          previewdiscountImage:
            this.state.editTemplate.template?.discounts.discount_image,
          discountsValues:
            this.state.editTemplate.template?.discounts.discount_details.map(
              (item, index) => {
                return {
                  discountssubtitle: item.title,
                  discountssubDescription: item.description,
                  templatediscountdetail_id: item.id,
                  show: item.active_status
                };
              }
            ),
          servicesValues:
            this.state.editTemplate.template.ourservice.ourservice_details?.map(
              (item, index) => {
                return {
                  servicesImageUrl: "",
                  bannerPreview: item.service_details_icon,
                  serviceDetailsTitle: item.service_details_title,
                  serviceDetailsDescription: item.service_details_description,
                  templateourserviceid: item.id,
                  show: item.active_status
                };
              }
            ),
          templateourservice_id:
            this.state.editTemplate.template.ourservice.templateourservice_id,
          ourserviceDescription:
            this.state.editTemplate.template.ourservice.ourservice_description,
          ourserviceName:
            this.state.editTemplate.template.ourservice.ourservice_name,

          previewactionImage:
            this.state.editTemplate.template.actions?.action_files,
          actionTitle: this.state.editTemplate.template.actions?.action_title,
          actionDescription:
            this.state.editTemplate.template.actions?.action_description,
          templateaction_id:
            this.state.editTemplate.template.actions?.templateaction_id,

          footerValues: this.state.editTemplate.template.footer.map(
            (item, index) => {
              return {
                label: item.footer_description,
                labelValue: item.footer_name,
                footer_id: item.footer_id,
                show: item.active_status
              };
            }
          ),
          formValues: this.state.editTemplate.template.menus.map(
            (item, index) => {
              return {
                name: item.menu_title,
                email: item.menu_title,
                menu_id: item.menu_id,
                show: item.active_status
              };
            }
          ),
        });
      });
    }
  }

  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    if (nextProps.addtemplate.template_res.status != 200) {
      this.setState({
        spinner: false,
      });

      swal({
        icon: "warning",
        text: nextProps.addtemplate.template_res.error,
      });
    } else {
      this.setState({
        spinner: false,
      });
      var successAlert = {
        message: nextProps.addtemplate.template_res.message,
        alertType: "success",
        closeOnEsc: false,
        dangerMode: true,
        pathname: "/dashboard/Chapters/ChaptersList",
        propsData: this.props,
        goToList: true,
        closeOnClickOutside: false,
      };
      handleSwalAlert(successAlert);
      // swal({
      //     icon: 'success',
      //     text: nextProps.addtemplate.template_res.message
      // })
    }
  }
  /* handle user input for menu */
  handleChange(i, e, from) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues, menuHasChanged: 1 });
  }
  /* upload images for banners */

  bannerImageHandleChange(i, e, uploadType) {
    var data = UploadMedia(i, e, uploadType)
    let bannerImageValues = this.state.bannerImageValues;

    if (data?.name && data.name != null) {
      this.state.postBannersData[i] = data;
      //this.state.bannerPreview[i] = e.target.value;
      bannerImageValues[i][e.target.name] = URL.createObjectURL(
        data
      );
      bannerImageValues[i]["imageUrl"] = data;
      bannerImageValues[i]["urlStatus"] = 0;

      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
    else {
      bannerImageValues[i][e.target.name] = data;
      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
  }
  /* upload images for template logo */
  templateLogoHandleChange(i, e, uploadType) {
    var logoData = UploadMedia(i, e, uploadType)

    this.state.logoName = logoData;
    {
      logoData && this.setState({
        templateLogo: e.target.file,
        previewLogo: URL.createObjectURL(logoData),
      });
    }
  }
  /* add multiple banners */
  addBannersFields() {
    this.setState({
      bannerImageValues: [
        ...this.state.bannerImageValues,
        { imageName: "", imageUrl: "", bannerPreview: "", banner_id: "", show: 0, upload: 'Images' },
      ],
    });
  }
  /* add multiple footers */
  addFooterFields() {
    this.setState({
      footerValues: [
        ...this.state.footerValues,
        { label: "", labelValue: "", footer_id: "", show: 0 },
      ],
    });
  }
  /* remove menu fields */
  removeFormFields(i, element, from) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
    this.setState((previousState) => ({
      removedMenuIds: [...previousState.removedMenuIds, element.menu_id],
    }));
  }
  /* remove footer fields */
  removeFooterFields(i, element, from) {

    if (from === "create") {
      let footerValues = this.state.footerValues;
      footerValues.splice(i, 1);
      this.setState({ footerValues });
      this.setState((previousState) => ({
        removedFooterIds: [
          ...previousState.removedFooterIds,
          element.footer_id,
        ],
      }));
    } else {
      let editFooterValues = this.state.editFooterValues;
      editFooterValues.splice(i, 1);
      this.setState({ editFooterValues });
    }
  }
  /* remove banners fields */
  removeBannerFields(i, element, from) {
    if (from === "edit") {
      let EditBannerImageValues = this.state.EditBannerImageValues;
      EditBannerImageValues.splice(i, 1);
      this.setState({ EditBannerImageValues });
    } else {
      let bannerImageValues = this.state.bannerImageValues;
      bannerImageValues.splice(i, 1);
      this.setState({ bannerImageValues });
      this.setState((previousState) => ({
        removedBannerIds: [
          ...previousState.removedBannerIds,
          element.banner_id,
        ],
      }));
    }
  }
  /* upload images */
  mediaHandleChange = (e, type) => {
    if (type === "image") {
      if (!this.state.editTemplate.template?.banners[0]?.url_status === '1') {

        this.setState({
          banner_upload: '0',
          bannerImageValues: this.state.editTemplate.template?.banners?.map(
            (item, index) => {
              return {
                imageName: item.banner_name,
                imageUrl: "",
                bannerPreview: item.banner_image,
                banner_id: item.banner_id,
                show: item.active_status
              };
            }
          ),
        });
      }
      else {
        this.setState({
          banner_upload: '0',
        })
      }
    }
    if (type === "video") {
      if (this.state.bannerPreview != null) {
        this.setState({
          banner_upload: '1',

        });
      } else {
        this.setState({
          banner_upload: '1',
          bannerImageValues: [{ bannerPreview: "" }],
        });
      }
    }
    if (type === "url") {
      this.setState({
        banner_upload: '2',
        //bannerImageValues: [{ bannerPreview: "" }],
      });
    }

    // if (type === "video") {
    //   if (this.state.bannerPreview != null) {
    //     this.setState({
    //       banner_upload: e.currentTarget.value,
    //       // bannerImageValues:[{bannerPreview:''}]
    //     });
    //   } else {
    //     this.setState({
    //       banner_upload: e.currentTarget.value,
    //       bannerImageValues: [{ bannerPreview: "" }],
    //     });
    //   }
    // } else {
    //   if (this.state.editTemplate.template?.banners != undefined) {
    //     this.setState({
    //       banner_upload: e.currentTarget.value,
    //       bannerImageValues: this.state.editTemplate.template?.banners?.map(
    //         (item, index) => {
    //           return {
    //             imageName: item.banner_name,
    //             imageUrl: "",
    //             bannerPreview: item.banner_image,
    //             banner_id: item.banner_id,
    //           };
    //         }
    //       ),
    //     });
    //   }
    //   else {
    //     this.setState({
    //       banner_upload: e.currentTarget.value
    //     });
    //   }

    // }
  };
  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  ImagehandleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.imageValues));
  }
  /* handle footer fields */
  onFooterhandleChange(i, e) {
    let footerValues = this.state.footerValues;
    footerValues[i][e.target.name] = e.target.value;
    this.setState({ footerValues, footerHasChanged: 1 });
  }
  /* goto chapters list */
  onChapters() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* add multiple team fields */
  addTeamFields() {
    this.setState({
      teamValues: [
        ...this.state.teamValues,
        {
          profilePicPreview: "",
          name: "",
          designation: "",
          about: "",
          bannerPreview: "",
          templateteamdetails_id: "",
          show: 0
        },
      ],
    });
  }
  /* add multiple team images */
  teamImageHandleChange(i, e, uploadType) {
    let teamValues = this.state.teamValues;

    var teamData = UploadMedia(i, e, uploadType)

    if (teamData?.name && teamData.name != null) {
      this.state.postprofilePicData[i] = e.target.files[0];
      this.state.profilePicPreview[i] = e.target.value;
      teamValues[i][e.target.name] = URL.createObjectURL(e.target.files[0]);
      teamValues[i]["teamImageUrl"] = e.target.files[0];

      this.setState({ teamHasChanged: 1 });
    }
    else {
      teamValues[i][e.target.name] = e.target.value;
      this.setState({ teamHasChanged: 1 });
    }
    this.setState({ teamValues});
    
    
  }
  /* handle team details */
  onteamChange(e, title, i) {
    if (title === "teamTitle") {
      this.setState({
        teamTitle: e.currentTarget.value,
        teamHasChanged: 1,
      });
    }
    if (title === "teamDescripition") {
      this.setState({
        teamDescripition: e.currentTarget.value,
        teamHasChanged: 1,
      });
    }
  }
  /* set the team  details in a state */
  onteamChangeHandle(i, e, from) {
    let teamValues = this.state.teamValues;
    teamValues[i][e.target.name] = e.target.value;
    this.setState({ teamValues, teamHasChanged: 1 });
  }
  /* remove section fields */
  removeSectionTeams(i, element, from) {
    let teamValues = this.state.teamValues;
    teamValues.splice(i, 1);
    this.setState({ teamValues });
    this.setState((previousState) => ({
      removedSectiononeIds: [
        ...previousState.removedSectiononeIds,
        element.templateteamdetails_id,
      ],
    }));
  }
  /* add multiple service fields */
  addServiceFields() {
    this.setState({
      servicesValues: [
        ...this.state.servicesValues,
        {
          bannerPreview: "",
          serviceDetailsTitle: "",
          serviceDetailsDescription: "",
          templateourserviceid: "",
          show: 0
        },
      ],
    });
  }
  /* handle service fields */
  onserviceChange(e, title, i) {
    if (title === "ourserviceName") {
      this.setState({
        ourserviceName: e.currentTarget.value,
        iconHasChanged: 1,
      });
    }
    if (title === "ourserviceDescription") {
      this.setState({
        ourserviceDescription: e.currentTarget.value,
        iconHasChanged: 1,
      });
    }
  }
  /* handle service fields to set the values in states */
  onservicesChangeHandle(i, e, from) {
    let servicesValues = this.state.servicesValues;
    servicesValues[i][e.target.name] = e.target.value;
    this.setState({ servicesValues, iconHasChanged: 1 });
  }
  /* handle edit service fields */
  serviceIconHandleChange(i, e,uploadType) {
    let servicesValues = this.state.servicesValues;

    var serviceData = UploadMedia(i, e, uploadType)

    if(serviceData?.name && serviceData.name != null){
  this.state.postIconData[i] = e.target.files[0];
        this.state.iconPreview[i] = e.target.value;
        servicesValues[i][e.target.name] = URL.createObjectURL(e.target.files[0]);
        servicesValues[i]["servicesImageUrl"] = e.target.files[0];

        this.setState({ iconHasChanged: 1 });
    }
    else{
      servicesValues[i][e.target.name] = e.target.value;
      this.setState({ iconHasChanged: 1 });
    }
    this.setState({ servicesValues });
  

  }
  /* remove sections */
  removeSectionOurservices(i, element, from) {
    let servicesValues = this.state.servicesValues;
    servicesValues.splice(i, 1);
    this.setState({ servicesValues });
    this.setState((previousState) => ({
      removedOurservicesIds: [
        ...previousState.removedOurservicesIds,
        element.templateourserviceid,
      ],
    }));
  }
  /* handle the actions fields */
  onactionsChange(e, title, i) {
    if (title === "actionTitle") {
      this.setState({
        actionTitle: e.currentTarget.value,
        actionHasChanged: 1,
      });
    }
    if (title === "actionDescription") {
      this.setState({
        actionDescription: e.currentTarget.value,
        actionHasChanged: 1,
      });
    }
  }
  /* action images upload */
  actionImageHandleChange(i,e,uploadType) {

    var actionData = UploadMedia(i, e, uploadType)

    if(actionData?.name && actionData.name != null){
      this.state.actionsImageName = actionData;
      this.setState({
        actionsImage: e.target.file,
        previewactionImage: URL.createObjectURL(actionData),
        actionHasChanged: 1,
      });
    }


  }
  /* add multiple discounts fields */
  addDiscountsFields() {
    this.setState({
      discountsValues: [
        ...this.state.discountsValues,
        {
          discountssubtitle: "",
          discountssubDescription: "",
          templatediscountdetail_id: "",
          show: 0
        },
      ],
    });
  }
  /* handle discounts */
  onDiscountsChange(e, title, i) {
    if (title === "discountTitle") {
      this.setState({
        discountTitle: e.currentTarget.value,
        discountsHasChanged: 1,
      });
    }
    if (title === "discountDescription") {
      this.setState({
        discountDescription: e.currentTarget.value,
        discountsHasChanged: 1,
      });
    }
  }
  /* handle discounts values to set the values in states */
  onDiscountsChangeHandle(i, e, from) {
    let discountsValues = this.state.discountsValues;
    discountsValues[i][e.target.name] = e.target.value;
    this.setState({ discountsValues, discountsHasChanged: 1 });
  }
  /* discount image uploads */
  discountImageHandleChange(i, e, uploadType) {

    var discountData = UploadMedia(i, e, uploadType)
    if (discountData?.name && discountData.name != null){
      this.state.discountsImageName = discountData;
      this.setState({
        templatediscount: e.target.file,
        previewdiscountImage: URL.createObjectURL(discountData),
        discountsHasChanged: 1,
      });
    }
  
  }
  /* remove discount fields */
  removeSectionDiscounts(i, element, from) {
    let discountsValues = this.state.discountsValues;
    discountsValues.splice(i, 1);
    this.setState({ discountsValues });
    this.setState((previousState) => ({
      removedDiscountsIds: [
        ...previousState.removedDiscountsIds,
        element.templatediscountdetail_id,
      ],
    }));
  }
  /* add multiple menus */
  addFormFields() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { name: "", email: "", menu_id: "", index: "", show: 0 },
      ],
    });
  }
  /* api call for creating the template */
  createTemplate() {

    if (!this.validator.allValid()) {

      this.setState({
        spinner: false,
        validated: true
      });
      this.validator.showMessages();

    } else {
      this.setState({ validated: false })
      var data = new FormData();
      data.append(
        "template_id",
        this.props.location.state.detail?.template.templateid
      );
      data.append("chapter_id", this.props.location.state.detail?.chapter_id);

      data.append("logo", this.state.logoName);

      if (this.state.menuHasChanged === 1) {
        if (this.state.editTemplate.success === true) {

          JSON.stringify(
            this.state.formValues.map((item, index) => {
              if (item.menu_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("menu_id[" + index + "]", item.menu_id);
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {


          JSON.stringify(
            this.state.formValues.map((item, index) => {
              data.append("menu[" + index + "]", item.name);
              data.append("menu_active_status[" + index + "]", item.show);
            })
          );
        }
      }
      if (this.state.bannersHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              if (item.banner_id === "") {

                data.append("edit_flag[" + index + "]", "create");
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);
                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("banner_id[" + index + "]", item.banner_id);
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);

                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              }
            })
          );



        } else {

          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              data.append("banner_name[" + index + "]", item.imageName);
              item.upload === 'Url' ?
                data.append("video_url[" + index + "]", item.videoUrl) :
                data.append("banner_image[" + index + "]", item.imageUrl);
              data.append("banner_active_status[" + index + "]", item.show);
              item.upload === 'Url' ?
                data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
            }))
        }
      }

      if (this.state.teamHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.teamValues.map((item, index) => {
              if (item.templateteamdetails_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("name[" + index + "]", item.name);
                data.append("designation[" + index + "]", item.designation);
                data.append("about[" + index + "]", item.about);
                data.append("templateteam_id", this.state.templateteam_id);
                data.append("team_title", this.state.teamTitle);
                data.append("team_description", this.state.teamDescripition);
                data.append("profile_pic[" + index + "]", item.teamImageUrl);
                data.append("team_details_active_status[" + index + "]", item.show);

              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("name[" + index + "]", item.name);
                data.append("id[" + index + "]", item.templateteamdetails_id);
                data.append("designation[" + index + "]", item.designation);
                data.append("about[" + index + "]", item.about);
                data.append("profile_pic[" + index + "]", item.teamImageUrl);
                data.append("team_title", this.state.teamTitle);
                data.append("team_description", this.state.teamDescripition);
                data.append("templateteam_id", this.state.templateteam_id);
                data.append("team_details_active_status[" + index + "]", item.show);

              }
            })
          );
        } else {
          JSON.stringify(
            this.state.teamValues.map((item, index) => {
              data.append("name[" + index + "]", item.name);
              data.append("designation[" + index + "]", item.designation);
              data.append("about[" + index + "]", item.about);
              data.append("profile_pic[" + index + "]", item.teamImageUrl);
              data.append("team_active_status[" + index + "]", item.show);

            })
          );

          data.append("team_title", this.state.teamTitle);
          data.append("team_description", this.state.teamDescripition);
        }
      }

      if (this.state.iconHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.servicesValues.map((item, index) => {
              if (item.templateourserviceid === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("service_details_title[" + index + "]", item.serviceDetailsTitle);
                data.append("service_details_description[" + index + "]", item.serviceDetailsDescription);
                data.append("service_details_icon[" + index + "]", item.servicesImageUrl);
                data.append("ourservice_name", this.state.ourserviceName);
                data.append("ourservice_description", this.state.ourserviceDescription);
                data.append("templateourservice_id", this.state.templateourservice_id);
                data.append("service_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("ourservice_id[" + index + "]", item.templateourserviceid);
                data.append("service_details_title[" + index + "]", item.serviceDetailsTitle);
                data.append("service_details_description[" + index + "]", item.serviceDetailsDescription);
                data.append("service_details_icon[" + index + "]", item.servicesImageUrl);
                data.append("ourservice_name", this.state.ourserviceName);
                data.append("ourservice_description", this.state.ourserviceDescription);
                data.append("templateourservice_id", this.state.templateourservice_id);
                data.append("service_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.servicesValues.map((item, index) => {
              data.append("service_details_title[" + index + "]", item.serviceDetailsTitle);
              data.append("service_details_description[" + index + "]", item.serviceDetailsDescription);
              data.append("service_details_icon[" + index + "]", item.servicesImageUrl);
              data.append("service_active_status[" + index + "]", item.show);
            })

          );

          data.append("ourservice_name", this.state.ourserviceName);
          data.append("ourservice_description", this.state.ourserviceDescription);
        }
      }

      if (this.state.actionHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          if (
            this.state.templateaction_id !=''
          ) {
            data.append("edit_flag", "edit");
            data.append("action_title", this.state.actionTitle);
            data.append("action_description", this.state.actionDescription);
            data.append("action_files", this.state.actionsImageName);
            data.append("templateaction_id", this.state.templateaction_id);

          } else {
            data.append("edit_flag", "create");
            data.append("action_title", this.state.actionTitle);
            data.append("action_description", this.state.actionDescription);
            data.append("action_files", this.state.actionsImageName);

          }
        } else {
          data.append("action_title", this.state.actionTitle);
          data.append("action_description", this.state.actionDescription);
          data.append("action_files", this.state.actionsImageName);

        }
      }

      if (this.state.discountsHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.discountsValues.map((item, index) => {
              if (item.templatediscountdetail_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("title[" + index + "]", item.discountssubtitle);
                data.append("description[" + index + "]", item.discountssubDescription);
                data.append("discount_active_status[" + index + "]", item.show);

              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("title[" + index + "]", item.discountssubtitle);
                data.append("discount_id[" + index + "]", item.templatediscountdetail_id);
                data.append("description[" + index + "]", item.discountssubDescription);

                data.append("discount_title", this.state.discountTitle);
                data.append("discount_description", this.state.discountDescription);
                data.append("discount_image", this.state.discountsImageName);
                data.append("templatediscount_id", this.state.templatediscount_id);
                data.append("discount_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.discountsValues.map((item, index) => {
              data.append("title[" + index + "]", item.discountssubtitle);
              data.append("description[" + index + "]", item.discountssubDescription);
              data.append("discount_active_status[" + index + "]", item.show);

            })
          );

          data.append("discount_title", this.state.discountTitle);
          data.append("discount_description", this.state.discountDescription);
          data.append("discount_image", this.state.discountsImageName);
        }
      }

      JSON.stringify(
        this.state.removedBannerIds.map((item, index) => {
          data.append("deletebanners[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedMenuIds.map((item, index) => {
          data.append("deletemenu[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedImagesIds.map((item, index) => {
          data.append("deleteimages[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedFooterIds.map((item, index) => {
          data.append("deletefooter[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedSectiononeIds.map((item, index) => {
          data.append("deleteteam[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedOurservicesIds.map((item, index) => {
          data.append("deleteourservice[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedDiscountsIds.map((item, index) => {
          data.append("deletediscount[" + index + "]", item);
        })
      );
      if (this.state.footerHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              if (item.footer_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);

              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("footer_id[" + index + "]", item.footer_id);
                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              data.append("footer_name[" + index + "]", item.labelValue);
              data.append("footer_description[" + index + "]", item.label);
              data.append("footer_active_status[" + index + "]", item.show);
            })
          );
        }
      }
      data.append(
        "active_status",
        this.props.location.state.detail?.active_status
      );
      if (this.state.editTemplate.success === true) {
        this.props.editTemplate(data);
      } else {
        this.props.createTemplate(data);
      }

      for (const value of data.values()) {
        console.log("form data values:-", value);
      }
      for (const value of data.keys()) {
        console.log("form data keys:-", value);
      }
    }
  }
  /* goto chapters list */
  onCancel() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* to view the created template */
  onViewTemplate() {
    var newWindowTemplate = window.open(`/${this.props.location.state.detail?.view_temp_chapter_name}`)
    localStorage.setItem(
      "chpaterdata",
      JSON.stringify(this.props.location.state?.detail)
    );
  }

  /* to show and hide the all fields in view template */
  showHideFields(i, e, from, typeOfField) {
    switch (typeOfField) {
      case 'menus':
        if (from === "create") {
          let formValues = this.state.formValues;
          formValues[i][e.target.name] == 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        else {
          let formValues = this.state.formValues;
          formValues[i][e.target.name] === 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        break;
      case 'banners':
        if (from === "create") {
          let bannerImageValues = this.state.bannerImageValues;
          bannerImageValues[i][e.target.name] === 0 ? bannerImageValues[i][e.target.name] = 1 :
            bannerImageValues[i][e.target.name] = 0;

          this.setState({ bannerImageValues, bannersHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;
      case 'sectionOne':
        if (from === "create") {
          let teamValues = this.state.teamValues;
          teamValues[i][e.target.name] === 0 ? teamValues[i][e.target.name] = 1 :
            teamValues[i][e.target.name] = 0;

          this.setState({ teamValues, teamHasChanged: 1 });
        }
        else {
          let editImageValues = this.state.editImageValues;
          editImageValues.splice(i, 1);
          this.setState({ editImageValues });
        }
        break;
      case 'sectionTwo':
        if (from === "create") {
          let servicesValues = this.state.servicesValues;
          servicesValues[i][e.target.name] === 0 ? servicesValues[i][e.target.name] = 1 :
            servicesValues[i][e.target.name] = 0;

          this.setState({ servicesValues, iconHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;
      case 'sectionFour':
        if (from === "create") {
          let discountsValues = this.state.discountsValues;
          discountsValues[i][e.target.name] === 0 ? discountsValues[i][e.target.name] = 1 :
            discountsValues[i][e.target.name] = 0;

          this.setState({ discountsValues, discountsHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;
      case 'footer':
        if (from === "create") {
          let footerValues = this.state.footerValues;
          footerValues[i][e.target.name] === 0 ? footerValues[i][e.target.name] = 1 :
            footerValues[i][e.target.name] = 0;

          this.setState({ footerValues, footerHasChanged: 1 });
        }
        else {
          let editFooterValues = this.state.editFooterValues;
          editFooterValues.splice(i, 1);
          this.setState({ editFooterValues });
        }


      default:
        break;
    }


  }

  /* confirmation pop up and remove fields */
  confirmationForRemove(i, element, from, type) {
    let typeOfTitle = ''
    switch (type) {
      case 'menu':
        typeOfTitle = "Menu"
        break;
      case 'banners':
        typeOfTitle = "Banner"
        break;
      case 'team':
        typeOfTitle = "Team"
        break;
      case 'service':
        typeOfTitle = 'Service'
        break;
      case 'question':
        typeOfTitle = "Question"
        break;
      case 'footer':
        typeOfTitle = 'Footer'
        break;

      default:
        break;
    }

    swal({
      title: "Delete " + typeOfTitle,
      text: "Are you sure you want to delete this " + typeOfTitle + " item?",
      buttons: ["No, Cancel", "Yes, Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          Spinner: true
        })
        switch (typeOfTitle) {
          case 'Menu':

            this.removeFormFields(i, element, from)
            break;
          case 'Banner':
            this.removeBannerFields(i, element, from)
            break;
          case 'Team':
            this.removeSectionTeams(i, element, from)
            break;
          case 'Service':
            this.removeSectionOurservices(i, element, from)
            break;
          case 'Question':
            this.removeSectionDiscounts(i, element, from)
            break;

          case 'Footer':
            this.removeFooterFields(i, element, from)
            break;
          default:
            break;
        }
      }
    });
  }

  /* render() function is used to update the UI. */
  render() {
    return (
      <div className="container">
        <div className="row mt-3 mb-4">
          <div className="col-md-12">
            <div className="horizontal-container">
              <label
                className="label-discount"
                onClick={() => this.onChapters()}
              >
                {this.props.location.state?.detail?.chapter_name}
              </label>
              <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
              <label className="label-discount">Create Template-4</label>
            </div>
          </div>
        </div>
        <div className="box">
          {/* Template logo creation */}
          <div className="viewtemp-mbl">
            {this.props.location.state?.detail?.temp_status === 0 && (
              <button
                className="viewTemplateButton"
                onClick={() => this.onViewTemplate()}
              >
                View Template
              </button>
            )}

            <button
              type="cancel"
              className="closeTemplateButton"
              onClick={() => this.onCancel()}
            >
              Close
            </button>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label class="lehead">
                Add Template Logo<span>*</span>
              </label>
              <div className="mt-4">
                {this.state.previewLogo != "" && (
                  <img
                    className="preview-image"
                    src={this.state.previewLogo}
                  ></img>
                )}

                <div id="upload-right">
                  <div className="profile-upload rect">
                    <label className="custom-file-upload rectangle">
                      <input
                        type="file"
                        name="bannerPreview"
                        className="form-control"
                        multiple
                        onChange={(e) =>
                          this.templateLogoHandleChange(0, e, "images")
                        }
                      />
                      <i className="fas fa-plus"></i>
                    </label>
                    <label className="up-lbl">Upload an image</label>
                    <br />
                    <span className="up-span">(2MB Limit)</span>
                  </div>
                </div>
                <span className="text-danger">
                  {this.validator.message(
                    "template_logo",
                    this.state.previewLogo,
                    "required"
                  )}
                </span>
              </div>

              <button
                style={{ display: "none" }}
                className="btn btn-info btn-success mt-4"
              >
                Upload
              </button>
            </div>
          </div>

          {/* Tempalte menu creation */}
          <hr className="divider-line" />
          {/* Template menu */}

          <label class="lehead">
            Template Menu<span>*</span>
          </label>

          <form onSubmit={this.handleSubmit}>
            <div className="row">

              {this.state.formValues.map((element, index, arr) => (
                <div key={index} className="sec-borser">
                  <div className="hider">
                    {
                      arr.length - 1 === index ? null :
                        <div className="addadency-sec d-flex">
                          <p className="lehead">Hide In Template View</p>
                          <div>
                            <label class="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'menus')} />
                              <span class="slidered"></span>
                            </label>
                          </div>
                        </div>
                    }
                    {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                        onClick={() =>
                          this.confirmationForRemove(index, element, "create", 'menu')
                          // this.removeFormFields(index, element, "create")
                        }></i>
                    )}
                  </div>
                  <label class="lehead mr-3">Menu Title</label>
                  <div className="dflex">
                    <div className="col-md-5" style={{ marginLeft: '-12px' }}>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={element.name || ""}
                        onChange={(e) =>
                          this.handleChange(index, e, "create")
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <span className="text-danger">
              {this.validator.message(
                "template_menu",
                this.state.formValues[0]?.name,
                "required"
              )}
            </span>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button
                    className="template-add-button "
                    type="button"
                    onClick={() => this.addFormFields()}
                  >
                    Add
                  </button>
                </div>

                <div className="m-2">
                  {" "}
                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>

          <hr className="divider-line" />
          {/* Template banners */}
          <div>
            <label style={{ marginLeft: "5px" }} class="lehead">
              Template Banners<span>*</span>
            </label>
            <div style={{ margin: "5px" }}>
              <label style={{ marginLeft: "5px" }} class="lehead">
                Upload Banners
              </label>


            </div>
            {this.state.bannerImageValues?.map((element, index, arr) => (

              <div className="sec-borser" key={index}>
                <div className="hider-banner">{arr.length - 1 === index ? null : (
                  <div className="addadency-sec d-flex">
                    <p className="lehead-para">Hide In Template View</p>
                    <div><label class="switchtog ml-2">
                      <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'banners')} />
                      <span class="slidered"></span>
                    </label>
                    </div>
                  </div>
                )}
                  {arr.length - 1 === index ? null : (
                    <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                      onClick={() =>
                        this.confirmationForRemove(index, element, "create", 'banners')
                        // this.removeBannerFields(index, element, "create")
                      }></i>
                  )}
                </div>
                <div className="dflex">
                  <div className="m-2">
                    <label class="lehead">Name</label>
                    <input type="text" className="form-control" name="imageName" value={element.imageName || ""}
                      onChange={(e) => this.bannerImageHandleChange(index, e, 'images')} />
                  </div>
                  <div className="m-0">
                    <label class="lehead mt-2">Upload Type</label>
                    <div>
                      <select
                        name='upload'
                        value={element.upload || ""}
                        onChange={(e) => this.bannerImageHandleChange(index, e, 'typeOfUpload')}
                        style={{ width: '120px', height: '45px', padding: '5px' }} >

                        {uploadMediaType?.map((e) => {
                          return <option value={e.type}>{e.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="m-2">
                    <div className="row">
                      <div className="">

                        {element.upload === 'Images' && <div className="m-0 d-flex">
                          {element.bannerPreview && !element.bannerPreview.includes('.mp4') &&
                            <div>
                              <label class="lehead">Image</label>
                              <div className="clearfix"></div>
                              <img
                                name="bannerPreview"
                                value={element.bannerPreview || ""}
                                className="preview-image"
                                src={element.bannerPreview}
                              ></img>
                            </div>
                          }
                          <div>
                            <label class="lehead">
                              Upload a Image
                              <span className="mr-1" style={{ fontSize: "11px" }}>(Upload dimentions should be 960 X 360 and below 5 mb)
                              </span>
                            </label>
                            <div className="profile-upload rect">
                              <label className="custom-file-upload rectangle">
                                <img src="" />
                                <input
                                  type="file"
                                  name="bannerPreview"
                                  className="form-control"
                                  multiple
                                  onChange={(e) =>
                                    this.bannerImageHandleChange(index, e, 'images')
                                  }
                                />
                                <i className="fas fa-plus"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        }
                        {element.upload === 'Video' && <div className="d-flex">
                         
                          {element.upload === 'Video' && element.bannerPreview !== '' && element.urlStatus == '0' && ((!element.bannerPreview.includes('.jfif')) && (!element.bannerPreview.includes('.png')) && (!element.bannerPreview.includes('.jpg') && !element.bannerPreview.includes('.jpeg'))) &&
                            <div>

                              <label class="lehead">Video</label>
                              <div className="clearfix"></div>
                              <video className="preview-image" controls>
                                <source
                                  src={element.bannerPreview || ""}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          }

                          <div>
                            <label class="lehead">
                              Upload a Video
                              <span className="mr-1" style={{ fontSize: "11px" }}>

                                (Upload size should be less than 10mb)
                              </span>
                            </label>
                            <div className="profile-upload rect">
                              <label className="custom-file-upload rectangle">
                                <img src="" />
                                <input
                                  type="file"
                                  name="bannerPreview"
                                  className="form-control"
                                  multiple
                                  onChange={(e) =>
                                    this.bannerImageHandleChange(index, e, 'images')
                                  }
                                />
                                <i className="fas fa-plus"></i>
                              </label>
                            </div>
                          </div>
                        </div>}
                        {element.upload === 'Url' &&
                          <div className="m-0">
                            <label data-tooltip="Upload url content should be less than 5 mb."
                              data-tooltip-location="right" className="lehead">Upload A URL
                              <i style={{ color: "#cecfd0", marginLeft: "10px" }}
                                class="fa fa-info-circle" aria-hidden="true">
                              </i>
                            </label>
                            <div className="profile-upload rect">

                              <input
                                type="text"
                                name="videoUrl"
                                className="form-control"
                                value={element.videoUrl}
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'url')
                                }
                              />


                            </div>
                          </div>

                        }

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            ))}
            <span className="text-danger">
              {this.validator.message(
                "template_banner_name",
                this.state.bannerImageValues[0]?.imageName,
                "required"
              )}
            </span>

            <span className="text-danger">
              {this.validator.message(
                "template_banner_upload",
                this.state.bannerImageValues[0]?.upload,
                "required"
              )}
            </span>

            <div className="row">
              <div className="col-md-3">
                <div className="m-2">

                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addBannersFields()}
                  >
                    Add
                  </button>
                </div>

                <div className="m-2">

                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider-line" />
          <div>
            <h4>Section 1</h4>
            <div className="row mr-5 sec-borser">
              <div className="col-md-3">
                <label class="lehead">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-template"
                  name="teamTitle"
                  value={this.state.teamTitle}
                  onChange={(e) => this.onteamChange(e, "teamTitle")}
                />
              </div>
              <div className="col-md-3">
                <label class="lehead">
                  Description<span>*</span>
                </label>
                <textarea
                  type="text"
                  className="form-template"
                  name="teamDescripition"
                  value={this.state.teamDescripition}
                  onChange={(e) => this.onteamChange(e, "teamDescripition")}
                />
              </div>
            </div>
            <div className="row">
              {this.state.teamValues.map((element, index, arr) => (
                <div className="sec-borser" key={index}>
                  <div className="hider">
                    {arr.length - 1 === index ? null : (
                      <div className="addadency-sec d-flex">
                        <p className="lehead-para">Hide In Template View</p>
                        <div>
                          <label class="switchtog ml-2">
                            <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'sectionOne')} />
                            <span class="slidered"></span>
                          </label>
                        </div></div>
                    )}
                    {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                        onClick={() =>
                          this.confirmationForRemove(index, element, "edit", 'team')
                          //this.removeSectionTeams(index, element, "edit")
                        }></i>
                    )}
                  </div>
                  <div className="col-8 d-flex">
                    {element.bannerPreview && (
                      <div className="m-0">
                        <label class="lehead">Image</label>
                        <div>
                          <img
                            name="profilePicPreview"
                            value={element.bannerPreview || ""}
                            className="preview-image"
                            src={element.bannerPreview}
                          ></img>
                        </div>
                      </div>
                    )}

                    <div className="m-0">
                      <label class="lehead">Upload </label>
                      <span style={{ fontSize: "10px" }}>
                        (Upload dimentions should be 960 X 360 and below 5 mb)
                      </span>
                      <div className="profile-upload rect">
                        <label className="custom-file-upload rectangle">
                          <img src="" />
                          <input
                            type="file"
                            name="bannerPreview"
                            className="form-control"
                            multiple
                            onChange={(e) =>
                              this.teamImageHandleChange(index, e, 'images')
                            }
                          />
                          <i className="fas fa-plus"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <label class="lehead">User Name</label>
                      <input
                        type="text"
                        className="form-template"
                        name="name"
                        value={element.name}
                        onChange={(e) => this.onteamChangeHandle(index, e)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label class="lehead">User Designation</label>
                      <input
                        type="text"
                        className="form-template"
                        name="designation"
                        value={element.designation}
                        onChange={(e) => this.onteamChangeHandle(index, e)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label class="lehead">User Descripition</label>
                      <textarea
                        type="text"
                        className="form-template"
                        name="about"
                        value={element.about}
                        onChange={(e) => this.onteamChangeHandle(index, e)}
                      />

                    </div>
                  </div>
                </div>
              ))}
            </div>
            <span className="text-danger">
              {this.validator.message(
                "Title",
                this.state.teamTitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Description",
                this.state.teamDescripition,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Profile_Image_Preview",
                this.state.teamValues[0]?.bannerPreview,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Name",
                this.state.teamValues[0]?.name,
                "required"
              )}
            </span>
            <span className="text-danger">{this.validator.message(
              "Designation",
              this.state.teamValues[0]?.designation,
              "required"
            )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Details_Description",
                this.state.teamValues[0]?.about,
                "required"
              )}
            </span>
            <div className="row">
              <div className="col-md-3">
                <div className="m-2">
                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addTeamFields()}
                  >
                    Add
                  </button>
                </div>

                <div className="m-2">
                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="divider-line" />
          <div>
            <h4>Section 2</h4>
            <div className="row sec-borser mt-5">
              <div className="col-md-3">
                <label class="lehead">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-template"
                  name="ourserviceName"
                  value={this.state.ourserviceName}
                  onChange={(e) => this.onserviceChange(e, "ourserviceName")}
                />
              </div>
              <div className="col-md-3">
                <label class="lehead">
                  Description<span>*</span>
                </label>
                <textarea
                  type="text"
                  className="form-template"
                  name="ourserviceDescription"
                  value={this.state.ourserviceDescription}
                  onChange={(e) =>
                    this.onserviceChange(e, "ourserviceDescription")
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="m-2">
                {this.state.servicesValues.map((element, index, arr) => (
                  <div className="sec-borser" key={index}>
                    <div className="hider">

                      {arr.length - 1 === index ? null : (
                        <div className="addadency-sec d-flex">
                          <p className="lehead-para">Hide In Template View</p>
                          <div>
                            <label class="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'sectionTwo')} />
                              <span class="slidered"></span>
                            </label>
                          </div>

                        </div>
                      )}
                      {arr.length - 1 === index ? null : (
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                          onClick={() =>
                            this.confirmationForRemove(index, element, "edit", 'service')
                            // this.removeSectionOurservices( index, element, "edit")
                          }></i>
                      )}
                    </div>
                    <div className="d-flex">
                      {element.bannerPreview && (
                        <div className="m-0">
                          <label class="lehead">Icon</label>
                          <div>
                            <img
                              name="bannerPreview"
                              value={element.bannerPreview || ""}
                              className="preview-image"
                              src={element.bannerPreview}
                            ></img>
                          </div>
                        </div>
                      )}

                      <div className="m-0">
                        <label class="lehead">Upload</label>
                        <span style={{ fontSize: "10px" }}>
                          (Upload dimentions should be 960 X 360 and below 5 mb)
                        </span>
                        <div className="profile-upload rect">
                          <label className="custom-file-upload rectangle">
                            <img src="" />
                            <input
                              type="file"
                              name="bannerPreview"
                              className="form-control"
                              multiple
                              onChange={(e) =>
                                this.serviceIconHandleChange(index, e,'images')
                              }
                            />
                            <i className="fas fa-plus"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label class="lehead">Title</label>
                        <input
                          type="text"
                          className="form-template"
                          name="serviceDetailsTitle"
                          value={element.serviceDetailsTitle}
                          onChange={(e) =>
                            this.onservicesChangeHandle(index, e)
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label class="lehead">Description</label>
                        <textarea
                          type="text"
                          className="form-template"
                          name="serviceDetailsDescription"
                          value={element.serviceDetailsDescription}
                          onChange={(e) =>
                            this.onservicesChangeHandle(index, e)
                          }
                        />

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <span className="text-danger">
              {this.validator.message(
                "Title",
                this.state.ourserviceName,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Description",
                this.state.ourserviceDescription,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Icon",
                this.state.servicesValues[0]?.bannerPreview,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Details_Title",
                this.state.servicesValues[0]?.serviceDetailsTitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Details_Description",
                this.state.servicesValues[0]?.serviceDetailsDescription,
                "required"
              )}
            </span>
            <div className="row">
              <div className="col-md-3">
                <div className="m-2">
                  {" "}
                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addServiceFields()}
                  >
                    Add
                  </button>{" "}
                </div>

                <div className="m-2">
                  {" "}
                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="divider-line" />
          <div>
            <h4>Section 3</h4>
            <div className="my-4">
              {this.state.previewactionImage != "" && (
                <img
                  className="preview-image"
                  src={this.state.previewactionImage}
                ></img>
              )}

              <div id="upload-right">
                <div className="profile-upload rect">
                  <label className="custom-file-upload rectangle">
                    <input
                      type="file"
                      name="bannerPreview"
                      className="form-control"
                      multiple
                      onChange={(e) =>
                        this.actionImageHandleChange(0, e, "images")
                      }
                    />
                    <i className="fas fa-plus"></i>
                  </label>
                  <label className="up-lbl">Upload an image</label>
                  <br />
                  <span className="up-span">(5MB Limit)</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label class="lehead">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-template"
                  name="actionTitle"
                  value={this.state.actionTitle}
                  onChange={(e) => this.onactionsChange(e, "actionTitle")}
                />
              </div>
              <div className="col-md-3">
                <label class="lehead">
                  Description<span>*</span>
                </label>
                <textarea
                  type="text"
                  className="form-template"
                  name="actionDescription"
                  value={this.state.actionDescription}
                  onChange={(e) =>
                    this.onactionsChange(e, "actionDescription")
                  }
                />
              </div>
            </div>
            <span className="text-danger">
              {this.validator.message(
                "File",
                this.state.previewactionImage,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Title",
                this.state.actionTitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Description",
                this.state.actionDescription,
                "required"
              )}
            </span>
          </div>
          <hr className="divider-line" />
          <div>
            <h4>Section 4</h4>
            <div className="mt-5 sec-borser">
              {this.state.previewdiscountImage != "" && (
                <img
                  className="preview-image"
                  src={this.state.previewdiscountImage}
                ></img>
              )}

              <div id="upload-right">
                <div className="profile-upload rect">
                  <label className="custom-file-upload rectangle">
                    <input
                      type="file"
                      name="bannerPreview"
                      className="form-control"
                      multiple
                      onChange={(e) =>
                        this.discountImageHandleChange(0,  e,"images")
                      }
                    />
                    <i className="fas fa-plus"></i>
                  </label>
                  <label className="up-lbl">Upload an image</label>
                  <br />
                  <span className="up-span">(5MB Limit)</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label class="lehead">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-template"
                  name="discountTitle"
                  value={this.state.discountTitle}
                  onChange={(e) => this.onDiscountsChange(e, "discountTitle")}
                />
              </div>
              <div className="col-md-3">
                <label class="lehead">
                  Description<span>*</span>
                </label>
                <textarea
                  type="text"
                  className="form-template"
                  name="discountDescription"
                  value={this.state.discountDescription}
                  onChange={(e) =>
                    this.onDiscountsChange(e, "discountDescription")
                  }
                />
              </div>
            </div>
            {this.state.discountsValues.map((element, index, arr) => (
              <div className="sec-borser" key={index}>
                <div className="hider">
                  {arr.length - 1 === index ? null : (
                    <div className="addadency-sec d-flex">
                      <p className="lehead-para">Hide In Template View</p>
                      <div><label class="switchtog ml-2">
                        <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'sectionFour')} />
                        <span class="slidered"></span>
                      </label></div>

                    </div>
                  )}
                  {arr.length - 1 === index ? null : (
                    <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                      onClick={() =>
                        this.confirmationForRemove(index, element, "edit", 'question')
                        // this.removeSectionDiscounts(index, element, "edit")
                      }></i>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label class="lehead">Question</label>
                    <input
                      type="text"
                      className="form-template"
                      name="discountssubtitle"
                      value={element.discountssubtitle}
                      onChange={(e) => this.onDiscountsChangeHandle(index, e)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label class="lehead">Answer</label>
                    <textarea
                      type="text"
                      className="form-template"
                      name="discountssubDescription"
                      value={element.discountssubDescription}
                      onChange={(e) => this.onDiscountsChangeHandle(index, e)}
                    />

                  </div>
                </div>
              </div>
            ))}
            <span className="text-danger">
              {this.validator.message(
                "Documents",
                this.state.previewdiscountImage,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Title",
                this.state.discountTitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Description",
                this.state.discountDescription,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Question",
                this.state.discountsValues[0]?.discountssubtitle,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "Answer",
                this.state.discountsValues[0]?.discountssubDescription,
                "required"
              )}
            </span>

            <div className="row">
              <div className="col-md-3">
                <div className="m-2">
                  {" "}
                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addDiscountsFields()}
                  >
                    Add
                  </button>{" "}
                </div>

                <div className="m-2">
                  {" "}
                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="divider-line" />
          {/* Template footer */}
          <div>
            <label class="lehead">
              Template Footer<span>*</span>
            </label>

            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {this.state.footerValues.map((element, index, arr) => (
                  <div className="sec-borser" key={index}>
                    <div className="hider">
                      {arr.length - 1 === index ? null : (
                        <div className="addadency-sec d-flex">
                          <p className="lehead">Hide In Template View</p>
                          <div>
                            <label class="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'footer')} />
                              <span class="slidered"></span>
                            </label>
                          </div>

                        </div>
                      )}
                      {arr.length - 1 === index ? null : (
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true"
                          onClick={() =>
                            this.confirmationForRemove(index, element, "create", 'footer')
                            //   this.removeFooterFields(index, element, "create")
                          }></i>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label class="lehead">Label</label>
                      <input
                        className="form-control"
                        type="text"
                        name="label"
                        value={element.label || ""}
                        onChange={(e) => this.onFooterhandleChange(index, e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label class="lehead">Value</label>
                      <input
                        className="form-control"
                        type="text"
                        name="labelValue"
                        value={element.labelValue || ""}
                        onChange={(e) => this.onFooterhandleChange(index, e)}
                      />
                    </div>

                  </div>
                ))}
              </div>
              <div className="clearfix"></div>
              <span className="text-danger">
                {this.validator.message(
                  "footer_label",
                  this.state.footerValues[0]?.label,
                  "required"
                )}
              </span>
              <span className="text-danger">
                {this.validator.message(
                  "footer_value",
                  this.state.footerValues[0]?.labelValue,
                  "required"
                )}
              </span>
              <div className="row">
                <div className="col-md-3 justify-content-between">
                  <div className="m-2">
                    <button
                      className="template-add-button "
                      type="button"
                      onClick={() => this.addFooterFields()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="m-2">
                    {" "}
                    <button
                      style={{ display: "none" }}
                      className="template-upload-button"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="clearfix"></div>
          </div>
          <Row className="my-4">
            <Col xs lg="6">
              <div className="save-sec">
                <button
                  type="submit"
                  onClick={() => this.createTemplate()}
                  className="btn btn-info btn-success mt-1"
                >
                  Submit
                </button>
              </div>
            </Col>

            <Col xs lg="6">
              <div className="cancel-sec">
                <button
                  type="cancel"
                  className="cancelbtnnew"
                  onClick={() => this.onCancel()}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
          {/* <button onClick={() => this.createTemplate()} className="btn btn-info btn-success mt-4 mb-5" type="submit">Submit</button>
                        <button onClick={() => this.createTemplate()} className="template-add-button mt-4 mb-5" type="submit">Cancel</button> */}

          <center>
            <div class="loader-spinner">
              {this.state.spinner ? (
                <Circles color="#7cc9a9" height={50} width={50} />
              ) : null}
            </div>
          </center>
        </div>
      </div >
    );
  }
}

/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    addtemplate: state.addtemplate,
    viewtemplate: state.viewtemplate,
  };
};

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
  createTemplate,
  viewTemplate,
  editTemplate,
})(withRouter(TemplateFormFour));
