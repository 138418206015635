import { map } from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createTemplate,
  editTemplate,
} from "../../Store/template/addTemplateAction";
import { viewTemplate } from "../../Store/template/viewTemplateoneAction";
import swal from "sweetalert";
import { Circles } from "react-loader-spinner";
import handleSwalAlert from "../SwalAlert";
import { Row, Col } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import HttpService from "../../Services/HttpService";
import { uploadMediaType } from "./UploadTypeResponce";
import UploadMedia from "./UploadMedia";

class TemplateFormOne extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      formValues: [{ name: "", email: "", menu_id: "", index: 0, show: 0 }],
      editFormValues: [],

      imageValues: [
        { imageName: "", imageUrl: "", bannerPreview: "", image_id: "", index: 0, show: 0 },
      ],
      editImageValues: [],

      bannerImageValues: [
        {
          imageName: "",
          imageUrl: "",
          bannerPreview: "",
          banner_id: "",
          media: "",
          index: 0,
          show: 0,
          upload: 'Images',
          videoUrl: '',
          urlStatus: null
        },
      ],
      bannerVideoValues: [
        {
          videoImageName: "",
          videoImageUrl: "",
          videoBannerPreview: "",
          videoBanner_id: "",
          media: "",
          index: 0, show: 0
        },
      ],
      EditBannerImageValues: [],

      descriptionValues: [
        { description: "", imageUrl: "", card_id: "", bannerPreview: "", index: 0, show: 0 },
      ],
      editDescriptionValues: [],

      footerValues: [{ label: "", labelValue: "", footer_id: "", index: 0, show: 0 }],
      editFooterValues: [],

      templateLogo: "",
      previewLogo: "",
      bannerPreview: [],
      bannerVideoPreview: [],
      imagePreview: [],
      descriptionPreview: [],
      menuValues: [
        { menuName: "About us", menuId: "1" },
        { menuName: "Contact Us", menuId: "2" },
        { menuName: "Settings", menuId: "3" },
      ],
      logoName: "",
      menuItem: "",
      postBannersData: [],
      postCardsData: [],
      postImagesData: [],
      spinner: false,
      editTemplate: {},

      removedBannerIds: [],
      removedMenuIds: [],
      showHideConent: [],
      removedImagesIds: [],
      removedCardIds: [],
      removedFooterIds: [],

      bannersHasChanged: 0,
      imagesHasChanged: 0,
      cardsHasChanged: 0,
      menuHasChanged: 0,
      footerHasChanged: 0,
      banner_upload: "0",
      showButtonText: 'Show Menu',
      hideButtonText: 'Hide Menu',
      urlName: '',
      urlValue: '',
      uploaded_banner_id: 0,
      selectedUploadType: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.ImagehandleSubmit = this.ImagehandleSubmit.bind(this);
  }
  /* triggers before the initial render*/
  componentWillMount() {
    if (this.props.location.state.detail.temp_status === 0) {
      this.setState({
        spinner: true,
      });
      var data = {
        chapter_id: this.props.location.state.detail.chapter_id,
        template_id: this.props.location.state.detail.template.templateid,
        type: "edit",
      };

      HttpService.viewTemplate(data).then((response) => {
        // this.state.editTemplate = response.data
        this.setState({
          spinner: false,
          editTemplate: response.data,
        });

        this.setState({
          previewLogo: this.state.editTemplate.template?.template_logo,
          bannerImageValues: this.state.editTemplate.template?.banners.map((item, index) => {
            return {
              urlStatus: item.url_status,
              imageName: item.banner_name,
              imageUrl: item.banner_image,
              bannerPreview: item.banner_image,
              banner_id: item.banner_id,
              show: item.active_status,
              videoUrl: item.url_status === 1 ? item.banner_image : null,
              upload: item.url_status === 1 ? 'Url' : item.banner_image.includes('.mp4') ? 'Video' : 'Images'
            }
          }),

          descriptionValues: this.state.editTemplate.template?.cards.map(
            (item, index) => {
              return {
                description: item.card_description,
                imageUrl: "",
                card_id: item.card_id,
                bannerPreview: item.card_image,
                show: item.active_status
              };
            }
          ),
          imageValues: this.state.editTemplate.template?.images.map(
            (item, index) => {
              return {
                imageName: item.image_name,
                imageUrl: "",
                bannerPreview: item.image,
                image_id: item.image_id,
                show: item.active_status
              };
            }
          ),

          footerValues: this.state.editTemplate.template?.footer.map(
            (item, index) => {
              return {
                label: item.footer_description,
                labelValue: item.footer_name,
                footer_id: item.footer_id,
                show: item.active_status
              };
            }
          ),
          formValues: this.state.editTemplate.template?.menus.map(
            (item, index) => {
              return {
                name: item.menu_title,
                email: item.menu_title,
                menu_id: item.menu_id,
                show: item.active_status
              };
            }
          ),

          //    this.state.editTemplate.template?.banners[0].banner_image.includes('.mp4') = '1' ?
        });
      });
    }
  }
  /*To update the state in response with the new received props */
  componentWillReceiveProps(nextProps) {
    if (nextProps.addtemplate.template_res.status != 200) {
      this.setState({
        spinner: false,
      });

      swal({
        icon: "warning",
        text: nextProps.addtemplate.template_res.error,
      });
    } else {
      this.setState({
        spinner: false,
      });
      var successAlert = {
        message: nextProps.addtemplate.template_res.message,
        alertType: "success",
        closeOnEsc: false,
        dangerMode: true,
        pathname: "/dashboard/Chapters/ChaptersList",
        propsData: this.props,
        goToList: true,
        closeOnClickOutside: false,
      };
      handleSwalAlert(successAlert);
      // swal({
      //     icon: 'success',
      //     text: nextProps.addtemplate.template_res.message
      // })
    }
  }
  /* handle user inputs for the menu fields*/
  handleChange(i, e, from) {

    switch (from) {
      case 'typeOfUpload':
        this.setState({
          selectedUploadType: e.currentTarget.value
        })

        break;
      case 'create':
        let formValues = this.state.formValues;
        formValues[i][e.target.name] = e.target.value;
        this.setState({ formValues, menuHasChanged: 1 });

      default:
        break;
    }

  }
  /* upload images*/
  imageHandleChange(i, e, uploadType) {

    var imagesData = UploadMedia(i, e, uploadType)

    let imageValues = this.state.imageValues;
    if (imagesData?.name && imagesData.name != null) {

      this.state.postImagesData[i] = imagesData;
      this.state.imagePreview[i] = e.target.value;
      imageValues[i][e.target.name] = URL.createObjectURL(imagesData);
      imageValues[i]["imageUrl"] = imagesData;

      this.setState({ imagesHasChanged: 1 });
    }
    else {
      imageValues[i][e.target.name] = e.target.value;

      this.setState({ imageValues, imagesHasChanged: 1 });
    }





  }
  /* upload banner images*/
  bannerImageHandleChange(i, e, uploadType) {
    var data = UploadMedia(i, e, uploadType)
    let bannerImageValues = this.state.bannerImageValues;

    if (data?.name && data.name != null) {
      this.state.postBannersData[i] = data;
      //this.state.bannerPreview[i] = e.target.value;
      bannerImageValues[i][e.target.name] = URL.createObjectURL(
        data
      );
      bannerImageValues[i]["imageUrl"] = data;
      bannerImageValues[i]["urlStatus"] = 0;

      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
    else {
      bannerImageValues[i][e.target.name] = data;
      this.setState({ bannerImageValues, bannersHasChanged: 1 });
    }
  }
  /* handle to uploads banners images or videos */
  mediaHandleChange = (e, type) => {
    if (type === "image") {
      if (!this.state.editTemplate.template?.banners[0]?.url_status === '1') {
        this.setState({
          banner_upload: '0',
          bannerImageValues: this.state.editTemplate.template?.banners?.map(
            (item, index) => {
              return {
                imageName: item.banner_name,
                imageUrl: "",
                bannerPreview: item.banner_image,
                banner_id: 1,
                show: item.active_status
              };
            }
          ),
        });
      }
      else {
        this.setState({
          banner_upload: '0',
          uploaded_banner_id: this.state.editTemplate.template?.banners[0].banner_id

        })
      }
    }
    if (type === "video") {
      if (this.state.bannerPreview != null) {
        this.setState({
          banner_upload: '1',

        });
      } else {
        this.setState({
          banner_upload: '1',
          bannerImageValues: [{ bannerPreview: "" }],
        });
      }
    }
    if (type === "url") {
      this.setState({
        banner_upload: '2',
        //bannerImageValues: [{ bannerPreview: "" }],
      });
    }


  };
  /* upload template logo */
  templateLogoHandleChange(i, e, uploadType) {
    var logoData = UploadMedia(i, e, uploadType)

    this.state.logoName = logoData;
    {
      logoData && this.setState({
        templateLogo: e.target.file,
        previewLogo: URL.createObjectURL(logoData),
      });
    }

  }
  /* add multiple menu fields*/
  addFormFields() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { name: "", email: "", menu_id: "", index: "", show: 0 },
      ],
    });
  }
  /* add multiple description fields*/
  addDescriptionFields() {
    this.setState({
      descriptionValues: [
        ...this.state.descriptionValues,
        { description: "", imageUrl: "", card_id: "", bannerPreview: "", show: 0 },
      ],
    });
  }
  /* add multiple images fields */
  addImageFields() {
    this.setState({
      imageValues: [
        ...this.state.imageValues,
        { imageName: "", imageUrl: "", image_id: "", bannerPreview: "", show: 0 },
      ],
    });
  }
  /* add multiple banners fields*/
  addBannersFields() {
    this.setState({
      bannerImageValues: [
        ...this.state.bannerImageValues,
        { imageName: "", imageUrl: "", bannerPreview: "", banner_id: "", show: 0, upload: 'Images' },
      ],
    });
  }
  /* add multiple footer fields */
  addFooterFields() {
    this.setState({
      footerValues: [
        ...this.state.footerValues,
        { label: "", labelValue: "", footer_id: "", show: 0 },
      ],
    });
  }
  /* remove menu fields*/
  removeFormFields(i, element, from) {
    if (from === "create") {
      let formValues = this.state.formValues;
      formValues.splice(i, 1);
      this.setState({ formValues });
      this.setState((previousState) => ({
        removedMenuIds: [...previousState.removedMenuIds, element.menu_id],
      }));
    } else {
      let editFormValues = this.state.editFormValues;
      editFormValues.splice(i, 1);
      this.setState({ editFormValues });
    }
  }
  /* to show and hide the fileds in view template */
  showHideFields(i, e, from, typeOfField) {

    switch (typeOfField) {
      case 'menus':
        if (from === "create") {
          let formValues = this.state.formValues;
          formValues[i][e.target.name] === 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        else {
          let formValues = this.state.formValues;
          formValues[i][e.target.name] === 0 ? formValues[i][e.target.name] = 1 :
            formValues[i][e.target.name] = 0;

          this.setState({ formValues, menuHasChanged: 1 });
        }
        break;
      case 'banners':
        if (from === "create") {
          let bannerImageValues = this.state.bannerImageValues;
          bannerImageValues[i][e.target.name] === 0 ? bannerImageValues[i][e.target.name] = 1 :
            bannerImageValues[i][e.target.name] = 0;

          this.setState({ bannerImageValues, bannersHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;
      case 'images':
        if (from === "create") {
          let imageValues = this.state.imageValues;
          imageValues[i][e.target.name] === 0 ? imageValues[i][e.target.name] = 1 :
            imageValues[i][e.target.name] = 0;

          this.setState({ imageValues, imagesHasChanged: 1 });
        }
        else {
          let editImageValues = this.state.editImageValues;
          editImageValues.splice(i, 1);
          this.setState({ editImageValues });
        }
        break;
      case 'banners':
        if (from === "create") {
          let bannerImageValues = this.state.bannerImageValues;
          bannerImageValues[i][e.target.name] === 0 ? bannerImageValues[i][e.target.name] = 1 :
            bannerImageValues[i][e.target.name] = 0;

          this.setState({ bannerImageValues, bannersHasChanged: 1 });
        }
        else {
          let EditBannerImageValues = this.state.EditBannerImageValues;
          EditBannerImageValues.splice(i, 1);
          this.setState({ EditBannerImageValues });
        }
        break;
      case 'description':
        if (from === "create") {
          let descriptionValues = this.state.descriptionValues;
          descriptionValues[i][e.target.name] === 0 ? descriptionValues[i][e.target.name] = 1 :
            descriptionValues[i][e.target.name] = 0;

          this.setState({ descriptionValues, cardsHasChanged: 1 });
        }
        else {
          let editDescriptionValues = this.state.editDescriptionValues;
          editDescriptionValues.splice(i, 1);
          this.setState({ editDescriptionValues });
        }
        break;
      case 'footer':
        if (from === "create") {
          let footerValues = this.state.footerValues;
          footerValues[i][e.target.name] === 0 ? footerValues[i][e.target.name] = 1 :
            footerValues[i][e.target.name] = 0;

          this.setState({ footerValues, footerHasChanged: 1 });
        }
        else {
          let editFooterValues = this.state.editFooterValues;
          editFooterValues.splice(i, 1);
          this.setState({ editFooterValues });
        }


      default:
        break;
    }


  }
  /* remove footer fields */
  removeFooterFields(i, element, from) {
    if (from === "create") {
      let footerValues = this.state.footerValues;
      footerValues.splice(i, 1);
      this.setState({ footerValues });
      this.setState((previousState) => ({
        removedFooterIds: [
          ...previousState.removedFooterIds,
          element.footer_id,
        ],
      }));
    } else {
      let editFooterValues = this.state.editFooterValues;
      editFooterValues.splice(i, 1);
      this.setState({ editFooterValues });
    }
  }
  /* remove images fields*/
  removeImageFields(i, element, from) {
    if (from === "create") {
      let imageValues = this.state.imageValues;
      imageValues.splice(i, 1);
      this.setState({ imageValues });
      this.setState((previousState) => ({
        removedImagesIds: [...previousState.removedImagesIds, element.image_id],
      }));
    } else {
      let editImageValues = this.state.editImageValues;
      editImageValues.splice(i, 1);
      this.setState({ editImageValues });
    }
  }
  /* remove banners fields */
  removeBannerFields(i, element, from) {
    if (from === "edit") {
      let EditBannerImageValues = this.state.EditBannerImageValues;
      EditBannerImageValues.splice(i, 1);
      this.setState({ EditBannerImageValues });
    } else {
      let bannerImageValues = this.state.bannerImageValues;
      bannerImageValues.splice(i, 1);
      this.setState({ bannerImageValues });
      this.setState((previousState) => ({
        removedBannerIds: [
          ...previousState.removedBannerIds,
          element.banner_id,
        ],
      }));
    }
  }
  /* confirmation pop up and remove fields */
  confirmationForRemove(i, element, from, type) {
    let typeOfTitle = ''
    switch (type) {
      case 'menu':
        typeOfTitle = "Menu"
        break;
      case 'banners':
        typeOfTitle = "Banner"
        break;
      case 'images':
        typeOfTitle = "Images"
        break;
      case 'description':
        typeOfTitle = 'Description'
        break;
      case 'footer':
        typeOfTitle = 'Footer'
        break;

      default:
        break;
    }

    swal({
      title: "Delete " + typeOfTitle,
      text: "Are you sure you want to delete this " + typeOfTitle + " item?",
      buttons: ["No, Cancel", "Yes, Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          Spinner: true
        })
        switch (typeOfTitle) {
          case 'Menu':
            this.removeFormFields(i, element, from)
            break;
          case 'Banner':
            this.removeBannerFields(i, element, from)
            break;
          case 'Images':
            this.removeImageFields(i, element, from)
            break;
          case 'Description':
            this.removeDescriptionFields(i, element, from)
            break;
          case 'Footer':
            this.removeFooterFields(i, element, from)
            break;
          default:
            break;
        }
      }
    });
  }
  /* remove description fields */
  removeDescriptionFields(i, element, from) {
    if (from === "create") {
      let descriptionValues = this.state.descriptionValues;
      descriptionValues.splice(i, 1);
      this.setState({ descriptionValues });
      this.setState((previousState) => ({
        removedCardIds: [...previousState.removedCardIds, element.card_id],
      }));
    } else {
      let editDescriptionValues = this.state.editDescriptionValues;
      editDescriptionValues.splice(i, 1);
      this.setState({ editDescriptionValues });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  ImagehandleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.imageValues));
  }
  /* handle the description user input fields */
  OnDescriptionhandleChange(i, e, uploadType) {
    var descriptionData = UploadMedia(i,e,uploadType)
    let descriptionValues = this.state.descriptionValues;

    if(descriptionData?.name && descriptionData.name != null){
      this.state.postCardsData[i] = descriptionData;
      this.state.descriptionPreview[i] = e.target.value;
      descriptionValues[i][e.target.name] = URL.createObjectURL(
        descriptionData
      );
      descriptionValues[i]["imageUrl"] = descriptionData;

      this.setState({ cardsHasChanged: 1 });
    }
    else{
      descriptionValues[i][e.target.name] = e.target.value;
    
    this.setState({ descriptionValues, cardsHasChanged: 1 });
    }

   
  }
  /* handle the footer user input fields */
  onFooterhandleChange(i, e) {
    let footerValues = this.state.footerValues;
    footerValues[i][e.target.name] = e.target.value;
    this.setState({ footerValues, footerHasChanged: 1 });
  }
  /* goto chapter list */
  onChapters() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* api call to create template */
  createTemplate() {

    this.setState({
      spinner: true,
    });

    if (!this.validator.allValid()) {
      this.setState({
        spinner: false,
      });
      this.validator.showMessages();
    } else {
      var data = new FormData();
      data.append("template_id", this.props.location.state.detail.template.templateid);
      data.append("chapter_id", this.props.location.state.detail.chapter_id);
      data.append("logo", this.state.logoName);
      data.append("active_status", this.props.location.state.detail.active_status);

      if (this.state.menuHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.formValues.map((item, index) => {
              if (item.menu_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("menu_id[" + index + "]", item.menu_id);
                data.append("menu_title[" + index + "]", item.name);
                data.append("menu_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.formValues.map((item, index) => {
              data.append("menu[" + index + "]", item.name);
              data.append("menu_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      if (this.state.bannersHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              if (item.banner_id === "") {

                data.append("edit_flag[" + index + "]", "create");
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);
                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("banner_id[" + index + "]", item.banner_id);
                data.append("banner_name[" + index + "]", item.imageName);
                item.upload === 'Url' ?
                  data.append("video_url[" + index + "]", item.videoUrl) :
                  data.append("banner_image[" + index + "]", item.imageUrl);

                data.append("banner_active_status[" + index + "]", item.show);
                item.upload === 'Url' ?
                  data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
              }
            })
          );



        } else {

          JSON.stringify(
            this.state.bannerImageValues.map((item, index) => {
              data.append("banner_name[" + index + "]", item.imageName);
              item.upload === 'Url' ?
                data.append("video_url[" + index + "]", item.videoUrl) :
                data.append("banner_image[" + index + "]", item.imageUrl);
              data.append("banner_active_status[" + index + "]", item.show);
              item.upload === 'Url' ?
                data.append("url_status[" + index + "]", 1) : data.append("url_status[" + index + "]", 0)
            }))
        }
      }

      if (this.state.footerHasChanged === 1) {
        if (this.state.editTemplate.success === true) {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              if (item.footer_id === "") {
                data.append("edit_flag[" + index + "]", "create");

                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("footer_id[" + index + "]", item.footer_id);
                data.append("footer_name[" + index + "]", item.labelValue);
                data.append("footer_description[" + index + "]", item.label);
                data.append("footer_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {
          JSON.stringify(
            this.state.footerValues.map((item, index) => {
              data.append("footer_name[" + index + "]", item.labelValue);
              data.append("footer_description[" + index + "]", item.label);
              data.append("footer_active_status[" + index + "]", item.show);
            })
          );
        }
      }
      if (this.state.cardsHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.descriptionValues.map((item, index) => {
              if (item.card_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("cards[" + index + "]", item.description);
                data.append("card_images[" + index + "]", item.imageUrl);
                data.append("card_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("card_id[" + index + "]", item.card_id);
                data.append("cards[" + index + "]", item.description);
                data.append("card_images[" + index + "]", item.imageUrl);
                data.append("card_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {


          JSON.stringify(
            this.state.descriptionValues.map((item, index) => {

              data.append("cards[" + index + "]", item.description);
              data.append("card_images[" + index + "]", item.imageUrl);
              data.append("card_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      if (this.state.imagesHasChanged === 1) {
        if (this.state.editTemplate.success === true) {


          JSON.stringify(
            this.state.imageValues.map((item, index) => {
              if (item.image_id === "") {
                data.append("edit_flag[" + index + "]", "create");
                data.append("image_name[" + index + "]", item.imageName);
                data.append("images[" + index + "]", item.imageUrl);
                data.append("image_active_status[" + index + "]", item.show);
              } else {
                data.append("edit_flag[" + index + "]", "edit");
                data.append("image_id[" + index + "]", item.image_id);
                data.append("image_name[" + index + "]", item.imageName);
                data.append("images[" + index + "]", item.imageUrl);
                data.append("image_active_status[" + index + "]", item.show);
              }
            })
          );
        } else {


          JSON.stringify(
            this.state.imageValues.map((item, index) => {

              data.append("image_name[" + index + "]", item.imageName);
              data.append("images[" + index + "]", item.imageUrl);
              data.append("image_active_status[" + index + "]", item.show);
            })
          );
        }
      }

      JSON.stringify(
        this.state.removedBannerIds.map((item, index) => {

          data.append("deletebanners[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedMenuIds.map((item, index) => {

          data.append("deletemenu[" + index + "]", item);
        })
      );
      JSON.stringify(
        this.state.removedImagesIds.map((item, index) => {

          data.append("deleteimages[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedFooterIds.map((item, index) => {

          data.append("deletefooter[" + index + "]", item);
        })
      );

      JSON.stringify(
        this.state.removedCardIds.map((item, index) => {

          data.append("deletecards[" + index + "]", item);
        })
      );

      if (this.state.editTemplate.success === true) {
        this.props.editTemplate(data);
      } else {
        this.props.createTemplate(data);
      }

      for (const value of data.values()) {
        console.log("form data values:-", value);
      }
      for (const value of data.keys()) {
        console.log("form data keys:-", value);
      }
    }
  }
  /* goto chapters list */
  onCancel() {
    this.props.history.push("/dashboard/Chapters/ChaptersList");
  }
  /* to view the create template */
  onViewTemplate() {
    var newWindowTemplate = window.open(`/${this.props.location.state.detail.view_temp_chapter_name}`)
    localStorage.setItem(
      "chpaterdata",
      JSON.stringify(this.props.location.state.detail)
    );
  }
  /* render() function is used to update the UI. */
  render() {
    return (
      <div className="container">
        <div className="row mt-3 mb-4">
          <div className="col-md-12">
            <div className="horizontal-container">
              <label
                className="label-discount"
                onClick={() => this.onChapters()}
              >
                {this.props.location.state.detail.chapter_name}
              </label>
              <i class="fa fa-chevron-right right-arrow" aria-hidden="true"></i>
              <label className="label-discount">Create Template-1</label>
            </div>
          </div>
        </div>
        <div className="box">
          {/* Template logo creation */}
          <div className="row">
            <div className="viewtemp-mbl">
              {this.props.location.state.detail.temp_status === 0 && (
                <button
                  className="viewTemplateButton"
                  onClick={() => this.onViewTemplate()}
                >
                  View Template
                </button>
              )}
              <button
                type="cancel"
                className="closeTemplateButton"
                onClick={() => this.onCancel()}
              >
                Close
              </button>
            </div>
            <div className="col-md-4">
              <div>
                <label class="lehead">
                  Add Template Logo<span>*</span>
                </label>
              </div>
              <div className="mt-4">
                {this.state.previewLogo != "" && (
                  <img
                    className="preview-image"
                    src={this.state.previewLogo}
                  ></img>
                )}

                <div id="upload-right">
                  <div className="profile-upload rect">
                    <label className="custom-file-upload rectangle">
                      <input
                        type="file"
                        name="bannerPreview"
                        className="form-control"
                        multiple
                        onChange={(e) =>
                          this.templateLogoHandleChange(0, e, "images")
                        }
                      />
                      <i className="fas fa-plus"></i>
                    </label>
                    <label className="up-lbl">Upload an image</label>
                    <br />
                    <span className="up-span">(5MB Limit)</span>
                  </div>
                </div>
              </div>
              <span className="text-danger">
                {this.validator.message(
                  "template_logo",
                  this.state.previewLogo,
                  "required"
                )}
              </span>

              <button
                style={{ display: "none" }}
                className="btn btn-info btn-success mt-4"
              >
                Upload
              </button>
            </div>
          </div>

          {/* Tempalte menu creation */}
          <hr className="divider-line" />
          {/* Template menu */}

          <label class="lehead">
            Template Menu<span>*</span>
          </label>

          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div>

                {this.state.formValues?.map((element, index, arr) => (
                  <div className="sec-borser" key={index}>
                    <div className="hider">


                      {arr.length - 1 === index ? null : (
                        <div className="addadency-sec d-flex">
                          <p className="lehead-para">Hide In Template View</p>
                          <div>
                            <label className="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'menus')} />
                              <span class="slidered"></span>
                            </label>
                          </div>
                        </div>

                      )}
                      {arr.length - 1 === index ? null : (
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                          this.confirmationForRemove(index, element, "create", 'menu')}
                        // this.removeFormFields(index, element, "create")}
                        >


                        </i>
                      )}
                    </div>
                    <label class="lehead mr-3">Menu Title</label>
                    <div className="dflex">

                      <div className="col-md-5" style={{ marginLeft: '-12px' }}>

                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={element.name || ""}
                          onChange={(e) =>
                            this.handleChange(index, e, "create")
                          }
                        />

                      </div>

                    </div>
                  </div>

                ))}
              </div>
            </div>
            <span className="text-danger">
              {this.validator.message(
                "template_menu",
                this.state.formValues[0]?.name,
                "required"
              )}
            </span>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button
                    className="template-add-button "
                    type="button"
                    onClick={() => this.addFormFields()}
                  >
                    Add
                  </button>
                </div>
                <div className="m-2">

                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>

          <hr className="divider-line" />
          {/* Template banners */}

          <label style={{ marginLeft: "5px" }} class="lehead">
            Template Banners<span>*</span>
          </label>
          <div style={{ margin: "5px" }}>
            <label style={{ marginLeft: "5px" }} class="lehead">
              Upload Banners
            </label>


          </div>
          {this.state.bannerImageValues?.map((element, index, arr) => (

            <div className="sec-borser" key={index}>
              <div className="hider-banner">

                {arr.length - 1 === index ? null : (
                  <div>
                    <div className="addadency-sec d-flex">
                      <p className="lehead-para">Hide In Template View</p>
                      <div>
                        <label>
                          <label class="switchtog ml-2">
                            <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'banners')} />
                            <span class="slidered"></span>
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>

                )}
                {arr.length - 1 === index ? null : (
                  <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                    this.confirmationForRemove(index, element, "create", 'banners')}
                  //this.removeBannerFields(index, element, "create")}
                  ></i>
                )}
              </div>
              <div className="dflex">
                <div className="m-2">
                  <label class="lehead">Name</label>
                  <input

                    type="text"
                    className="form-control"
                    name="imageName"
                    value={element.imageName || ""}
                    onChange={(e) => this.bannerImageHandleChange(index, e, 'images')}
                  />
                </div>


                <div className="m-0">
                  <label class="lehead mt-2">Upload Type</label>
                  <div>
                    <select
                      name='upload'
                      value={element.upload || ""}
                      onChange={(e) => this.bannerImageHandleChange(index, e, 'typeOfUpload')}
                      style={{ width: '120px', height: '45px', padding: '5px' }} >
                      {/* <option value="">Select Type of Upload</option> */}

                      {uploadMediaType?.map((e) => {
                        return <option value={e.type}>{e.name}</option>;
                      })}
                    </select>

                    {/* <span className="text-danger">{this.validator.message("template", this.state.selectedUploadType, "required")}</span> */}
                  </div>
                </div>
                <div className="m-2">
                  <div className="row">
                    <div className="dflex">

                      {element.upload === 'Images' && <div className="d-flex">
                        {element.bannerPreview && !element.bannerPreview.includes('.mp4') &&
                          <div>

                            <label class="lehead">Image</label>
                            <div className="clearfix"></div>
                            <img
                              name="bannerPreview"
                              value={element.bannerPreview || ""}
                              className="preview-image"
                              src={element.bannerPreview}
                            ></img>

                          </div>
                        }
                        <div>
                          <label class="lehead">
                            Upload a Image
                            <span className="mr-1" style={{ fontSize: "11px" }}>

                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      }
                      {element.upload === 'Video' && <div className="d-flex">
                        {element.upload === 'Video' && element.bannerPreview !== '' && element.urlStatus == '0' && ((!element.bannerPreview.includes('.jfif')) && (!element.bannerPreview.includes('.png')) && (!element.bannerPreview.includes('.jpg') && !element.bannerPreview.includes('.jpeg'))) &&
                          <div>

                            <label class="lehead">Video</label>
                            <div className="clearfix"></div>
                            <video className="preview-image" controls>
                              <source
                                src={element.bannerPreview || ""}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        }

                        <div>
                          <label class="lehead">
                            Upload a Video
                            <span className="mr-1" style={{ fontSize: "11px" }}>

                              (Upload size should be less than 10mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.bannerImageHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>}
                      {element.upload === 'Url' &&
                        <div className="m-0">
                          <label data-tooltip="Uploading url content should be less than 5 mb."
                            data-tooltip-location="right" className="lehead">Upload A URL
                            <i style={{ color: "#cecfd0", marginLeft: "10px" }}
                              class="fa fa-info-circle" aria-hidden="true">
                            </i>
                          </label>
                          <div className="profile-upload rect">

                            <input
                              type="text"
                              name="videoUrl"
                              className="form-control"
                              value={element.videoUrl}
                              onChange={(e) =>
                                this.bannerImageHandleChange(index, e, 'url')
                              }
                            />


                          </div>
                        </div>

                      }

                    </div>
                  </div>
                </div>

              </div>
            </div>

          ))}
          <span className="text-danger">
            {this.validator.message(
              "template_banner_name",
              this.state.bannerImageValues[0]?.imageName,
              "required"
            )}
          </span>

          <span className="text-danger">
            {this.validator.message(
              "template_banner_upload",
              this.state.bannerImageValues[0]?.upload,
              "required"
            )}
          </span>

          <div className="row">
            <div className="col-md-3">
              <div className="m-2">

                <button
                  className="template-add-button"
                  type="button"
                  onClick={() => this.addBannersFields()}
                >
                  Add
                </button>
              </div>

              <div className="m-2">

                <button
                  style={{ display: "none" }}
                  className="template-upload-button"
                  onClick={(e) => this.ImagehandleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <hr className="divider-line" />
          {/* Template images  */}

          <div>
            <label style={{ marginLeft: "5px" }} class="lehead">
              Template Images<span>*</span>
            </label>
            {this.state.imageValues.map((element, index, arr) => (
              <div className="sec-borser" key={index}>
                <div className="hider">

                  {arr.length - 1 === index ? null : (
                    <div>
                      <div className="addadency-sec d-flex">
                        <label
                          className="lehead">Hide In Template View</label>
                        <div>
                          <label>

                            <label class="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'images')} />
                              <span class="slidered"></span>
                            </label>


                          </label>
                        </div>
                      </div>
                    </div>

                  )}
                  {arr.length - 1 === index ? null : (
                    <div className="mb-3">
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                        this.confirmationForRemove(index, element, "create", 'images')}
                      //  this.removeImageFields(index, element, "create")}
                      ></i>
                    </div>
                  )}
                </div>
                <div className="d-flex">
                  <div className="m-2">
                    <label class="lehead">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="imageName"
                      value={element.imageName || ""}
                      onChange={(e) => this.imageHandleChange(index, e, 'images')}
                    />
                  </div>

                  <div className="m-2">
                    <div className="row">
                      <div className="dflex">
                        {element.bannerPreview && (
                          <div className="m-0">
                            <label class="lehead">Image</label>
                            <div className="clearfix"></div>
                            <img
                              name="bannerPreview"
                              value={element.bannerPreview || ""}
                              className="preview-image"
                              src={element.bannerPreview}
                            ></img>
                          </div>
                        )}

                        <div className="m-0">
                          <label class="lehead">
                            Upload
                            <span className="mr-1" style={{ fontSize: "11px" }}>

                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.imageHandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            ))}
            <span className="text-danger">
              {this.validator.message(
                "template_images_name",
                this.state.imageValues[0]?.imageName,
                "required"
              )}
            </span>
            <span className="text-danger">
              {this.validator.message(
                "template_images",
                this.state.imageValues[0]?.bannerPreview,
                "required"
              )}
            </span>

            <div className="row">
              <div className="col-md-3">
                <div className="m-2">

                  <button
                    className="template-add-button"
                    type="button"
                    onClick={() => this.addImageFields()}
                  >
                    Add
                  </button>
                </div>

                <div className="m-2">

                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    onClick={(e) => this.ImagehandleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider-line" />

          {/* Template description */}
          <div>
            <label class="lehead">
              Template Descriptions<span>*</span>
            </label>

            <form onSubmit={this.handleSubmit}>
              {this.state.descriptionValues.map((element, index, arr) => (
                <div className="sec-borser" key={index}>
                  <div className="hider-banner">

                    {arr.length - 1 === index ? null : (
                      <div className="addadency-sec d-flex">
                        <p className="lehead-para">Hide In Template View</p>
                        <div>
                          <label>

                            <label class="switchtog ml-2">
                              <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'description')} />
                              <span class="slidered"></span>
                            </label>

                          </label>
                        </div>
                      </div>

                    )}
                    {arr.length - 1 === index ? null : (
                      <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                        this.confirmationForRemove(index, element, "create", 'description')}
                      //this.removeDescriptionFields(index,element,"create")}

                      ></i>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mr-5">
                        <label class="lehead">Description</label>
                        <textarea
                          className="form-control"
                          rows={3}
                          type="text"
                          name="description"
                          value={element.description || ""}
                          onChange={(e) =>
                            this.OnDescriptionhandleChange(index, e,'images')
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="d-flex">
                        {element.bannerPreview && (
                          <div>
                            <label class="lehead">Image</label>
                            <div className="clearfix"></div>
                            <div className="m-0">
                              <img
                                name="imagePreview"
                                value={element.bannerPreview || ""}
                                className="preview-image"
                                src={element.bannerPreview}
                              ></img>
                            </div>
                          </div>
                        )}

                        <div className="ml-2">
                          <label class="lehead">
                            Upload
                            <span className="mr-1" style={{ fontSize: "10px" }}>

                              (Upload dimentions should be 960 X 360 and below 5 mb)
                            </span>
                          </label>
                          <div className="profile-upload rect">
                            <label className="custom-file-upload rectangle">
                              <img src="" />
                              <input
                                type="file"
                                name="bannerPreview"
                                className="form-control"
                                multiple
                                onChange={(e) =>
                                  this.OnDescriptionhandleChange(index, e, 'images')
                                }
                              />
                              <i className="fas fa-plus"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}


              <div className="clearfix"></div>

              <div className="row">
                <div className="col-md-6 dflex justify-content-between">
                  <div className="m-2">
                    <button
                      className="template-add-button "
                      type="button"
                      onClick={() => this.addDescriptionFields()}
                    >
                      Add
                    </button>
                  </div>

                  <div className="m-2">

                    <button
                      style={{ display: "none" }}
                      className="template-upload-button"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <span className="text-danger">
            {this.validator.message(
              "template_description",
              this.state.descriptionValues[0]?.description,
              "required"
            )}
          </span>
          <span className="text-danger">
            {this.validator.message(
              "template_image",
              this.state.descriptionValues[0]?.bannerPreview,
              "required"
            )}
          </span>
          <hr className="divider-line" />
          {/* Template footer */}

          <label class="lehead">
            Template Footer<span>*</span>
          </label>

          <form onSubmit={this.handleSubmit}>
            <div className="row">
              {this.state.footerValues.map((element, index, arr) => (
                <div className="sec-borser" key={index}>
                  <div className="hider">

                    {arr.length - 1 === index ? null : (
                      <div>
                        <div className="addadency-sec d-flex">
                          <p
                            className="lehead">Hide In Template View</p>
                          <div>
                            <label>

                              <label class="switchtog ml-2">
                                <input name="show" defaultChecked={element.show === 0 ? false : true} type="checkbox" onChange={(e) => this.showHideFields(index, e, "create", 'footer')} />
                                <span class="slidered"></span>
                              </label>


                            </label>
                          </div>
                        </div>
                      </div>

                    )}
                    {

                      arr.length - 1 === index ? null : (
                        <i class="fa fa-trash temp-del ml-2" aria-hidden="true" onClick={() =>
                          this.confirmationForRemove(index, element, "create", 'footer')
                          //this.removeFooterFields(index, element, "create")
                        }></i>
                      )}
                  </div>
                  <div className="col-md-3">
                    <label class="lehead">Label</label>
                    <input
                      className="form-control"
                      type="text"
                      name="label"
                      value={element.label || ""}
                      onChange={(e) => this.onFooterhandleChange(index, e)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label class="lehead">Value</label>
                    <input
                      className="form-control"
                      type="text"
                      name="labelValue"
                      value={element.labelValue || ""}
                      onChange={(e) => this.onFooterhandleChange(index, e)}
                    />
                  </div>

                </div>
              ))}
            </div>
            <div className="clearfix"></div>
            <div className="row">
              <div className="col-md-3 justify-content-between">
                <div className="m-2">
                  <button
                    className="template-add-button "
                    type="button"
                    onClick={() => this.addFooterFields()}
                  >
                    Add
                  </button>
                </div>


                <div className="m-2">

                  <button
                    style={{ display: "none" }}
                    className="template-upload-button"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
          <span className="text-danger">
            {this.validator.message(
              "footer_label",
              this.state.footerValues[0]?.label,
              "required"
            )}
          </span>
          <span className="text-danger">
            {this.validator.message(
              "footer_value",
              this.state.footerValues[0]?.labelValue,
              "required"
            )}
          </span>

          <Row className="my-4">
            <Col xs lg="6">
              <div className="save-sec">
                <button
                  type="submit"
                  onClick={() => this.createTemplate()}
                  className="btn btn-info btn-success mt-1"
                >
                  Submit
                </button>
              </div>
            </Col>

            <Col xs lg="6">
              <div className="cancel-sec">
                <button
                  type="cancel"
                  className="cancelbtnnew"
                  onClick={() => this.onCancel()}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>

          <center>
            <div class="loader-spinner">
              {this.state.spinner ? (
                <Circles color="#7cc9a9" height={50} width={50} />
              ) : null}
            </div>
          </center>
        </div>
      </div>
    );
  }
}
/*mapStateToProps is used for selecting the part of the data from the store that the connected component needs.
It is called every time the store state changes. */
export const mapStateToProps = (state) => {
  return {
    addtemplate: state.addtemplate,
    viewtemplate: state.viewtemplate,
  };
};

/*Connects a React component to a Redux store */
export default connect(mapStateToProps, {
  createTemplate,
  viewTemplate,
  editTemplate,
})(withRouter(TemplateFormOne));
